import React, { useCallback, useMemo } from "react";
import NumAbbr from "number-abbreviate";
import get from "lodash/get";
import { connect } from "react-redux";
import "./Trade-Algo-Card.scss";
import { Link } from "react-router-dom";
import "./progress-bar.scss";
import SparkLines from "../common/SparkLines";
import CircularProgressBar from "./circularProgress/CircularProgressBar.js";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import CssPop from "../common/Tables/animations/CssPop";
import { dummyOptions } from "../TradingView/helpers.js";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import Tooltip from "@mui/material/Tooltip";
import PriceDisplay from "../common/Tables/renderers/PriceDisplay.js";

export const decimal = (input) => input.toFixed(2);
export const abbreviate = (x, y = 1) =>
  new NumAbbr(["K", "M", "B", "T"]).abbreviate(x, y);
const TradeAlgoCard = (props) => {
  const {
    up,
    ticker,
    name,
    trend_start,
    day,
    chart,
    ats,
    multiplier,
    marketStatus,
    last_price,
    options,
    setSymbol,
    themeMode,
    type,
    allData,
  } = props;
  const newsSentiment = useMemo(() => {
    return allData.ai_score?.news_sentiment
      ? Math.floor(allData.ai_score.news_sentiment)
      : 0;
  }, [allData]);

  const [performance, gain] = useMemo(() => {
    let performance = Math.round((last_price / trend_start - 1) * 1000) / 10;
    const gain = Number(last_price || 0) - (trend_start || 0);
    if (
      marketStatus === "pre-open" ||
      marketStatus === "open" ||
      get(day, "close", "") === ""
    ) {
      if (!parseFloat(day?.prev_close || 0)) {
        return [performance, gain];
      }
      return [performance, gain];
    } else if (marketStatus === "after" || marketStatus === "closed") {
      return [performance, gain];
    }
  }, [marketStatus, trend_start]);

  const show = useMemo(() => {
    return up ? gain >= 0 : gain <= 0;
  }, [gain, up]);

  const colors = useMemo(() => {
    let sparklines = up ? "#3EB489" : "#EA3323";
    let bar = up ? "#EA3323" : "#3EB489";
    return {
      sparklines,
      bar,
    };
  }, [up, performance]);

  const updatedOptions = useMemo(() => {
    return {
      ...dummyOptions,
      colors: [colors.sparklines],
    };
  }, [colors]);
  const dFlowTitle = useMemo(() => {
    if (multiplier > 2) {
      return "High";
    } else if (multiplier > 1) {
      return "Medium";
    } else {
      return "Low";
    }
  }, [multiplier]);

  const calcFlowSentimentLevel = useCallback((sentiment) => {
    // A) 0 ~ 0.48 = Bearish
    // B) 0.48 - 0.52 = Neutral
    // C) 0.52 - 1.0 = Bullish
    sentiment = sentiment || 0;

    if (sentiment < 0.48) {
      return "Bearish";
    }

    if (sentiment <= 0.52) {
      return "Neutral";
    }

    return "Bullish";
  }, []);
  const totalPremium = useMemo(() => {
    return (
      parseInt(options?.put_total_prem) + parseInt(options?.call_total_prem)
    );
  }, [options?.put_total_prem, options?.call_total_prem]);

  const callPremiumRatio = useMemo(() => {
    return parseInt(options?.call_total_prem) / totalPremium;
  }, [options?.call_total_prem, totalPremium]);

  const callRatio = useMemo(() => {
    return Number(callPremiumRatio * 100).toFixed(0) + "%";
  }, [callPremiumRatio]);
  const optionSentiment = useMemo(() => {
    return calcFlowSentimentLevel(callPremiumRatio);
  }, [callPremiumRatio]);
  const color = useMemo(() => {
    let col = "";
    if (optionSentiment === "Bullish") {
      col = "#3EB489";
    } else if (optionSentiment === "Bearish") {
      col = "rgb(249 27 27)";
    } else {
      col = "rgb(249 182 60)";
    }
    return col;
  }, [optionSentiment]);
  const hasOptions = useMemo(() => {
    return (
      !!Number(options?.call_total_prem) || !!Number(options?.put_total_prem)
    );
  }, [options]);
  if (show) {
    return (
      <div className="trade-algo-card">
        <div className="trade-algo-card__header">
          <div className="trade-algo-card__header__top">
            <div className="trade-algo-card-type">
              {type} Caps
              {/* <Tooltip
                arrow={true}
                title={
                  <React.Fragment>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industry&apos;s standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with
                    desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </React.Fragment>
                }
              >
                <ErrorOutlineIcon className="icon-space" />
              </Tooltip> */}
            </div>

            <Link
              onClick={() => {
                setSymbol({
                  value: ticker,
                  type: "",
                });
              }}
              className="trade-algo-card-company-ticker"
              to={`/company/${ticker}`}
            >
              {ticker}
            </Link>

            <div className="trade-algo-card-company-name">{name}</div>

            <div className="trade-algo-card-company-price">
              <CssPop ticker={ticker} comparator={last_price} />
              <div
                className={`trade-algo-card-company-performance trade-algo-card-company-performance--${String(performance).includes("-") ? "negative" : "positive"}`}
              >
                <PriceDisplay
                  ticker={ticker}
                  trend_start={trend_start}
                  lastPrice={last_price}
                  showPercent
                />
              </div>
            </div>
          </div>
          <div className="trade-algo-card__header__bottom">
            <SparkLines
              series={[
                {
                  data: [...(chart?.data || [])]
                    .filter((i) => !!i)
                    .map((el) => Number(el).toFixed(2)),
                },
              ]}
              options={updatedOptions}
              type={updatedOptions.chart.type}
              height={updatedOptions.chart.height}
              width={updatedOptions.chart.width}
            />
          </div>
        </div>

        <div className="trade-algo-card__sub-header">
          <div className="trade-algo-card__sub-header__item">
            <div className="trade-algo-card__sub-header__item__title">
              Dark Pool Activ.
            </div>
            <div className="trade-algo-card__sub-header__item__value">
              {(Number(multiplier) * 100).toFixed(0)}%
            </div>
          </div>
          <div className="trade-algo-card__sub-header__item">
            <div className="trade-algo-card__sub-header__item__title">
              Change
            </div>
            <div
              className={`trade-algo-card__sub-header__item__value trade-algo-card__sub-header__item__value--${String(gain).includes("-") ? "negative" : "positive"}`}
            >
              <PriceDisplay
                ticker={ticker}
                trend_start={trend_start}
                lastPrice={last_price}
              />
            </div>
          </div>
          <div className="trade-algo-card__sub-header__item">
            <div className="trade-algo-card__sub-header__item__title">
              Market Cap
            </div>
            <div className="trade-algo-card__sub-header__item__value">
              $520.2B
            </div>
          </div>
        </div>

        <div className="trade-algo-card__seperator"></div>

        <div className="trade-algo-card__circular-progressbars">
          <div className="trade-algo-card__circular-progressbars__item">
            <div className="trade-algo-card__circular-progressbars__item-title">
              Dark Flow
            </div>
            <CircularProgressBar
              percentage={Number(multiplier) * 100}
              title={dFlowTitle}
              value={Number(multiplier).toFixed(2) + "x"}
              color={up ? "#3EB489" : "rgb(249 27 27)"}
              up={up}
            />
          </div>
          {hasOptions && (
            <div className="trade-algo-card__circular-progressbars__item">
              <div className="trade-algo-card__circular-progressbars__item-title">
                Options Flow
              </div>
              <CircularProgressBar
                callRatio={callRatio.replace("%", "")}
                percentage={5}
                title={calcFlowSentimentLevel(callPremiumRatio)}
                color={color}
                up={up}
                options={true}
              />
            </div>
          )}

          <div className="trade-algo-card__circular-progressbars__item">
            <div className="trade-algo-card__circular-progressbars__item-title">
              AI Scoring
            </div>
            <CircularProgressBar
              callRatio={callRatio.replace("%", "")}
              percentage={5}
              title={calcFlowSentimentLevel(callPremiumRatio)}
              color={color}
              up={up}
              options={true}
            />
          </div>
        </div>

        <div className="trade-algo-card__news_sentiment">
          <div className="trade-algo-card__news_sentiment__title">
            News Sentiment
          </div>

          <div
            className={`trade-algo-card__news_sentiment_progressbar progress-wrap-${themeMode} progress-wrap--ta-${newsSentiment > 0 ? "green" : "red"}`}
          >
            <div
              className="progress-bar"
              style={{ width: `${Math.abs(newsSentiment)}%` }}
            />
          </div>
          <div className="trade-algo-card__news_sentiment__value">
            {Math.abs(newsSentiment)}
          </div>
        </div>

        <div className="trade-algo-card__seperator"></div>

        <div className="trade-algo-card__footer">
          <div className="trade-algo-card__footer__section">
            <div className="trade-algo-card__footer__item">
              <div className="trade-algo-card__footer__item__title">
                Share Vol.
              </div>
              <div
                className={`trade-algo-card__footer__item__value trade-algo-card__footer__item__value--${up ? "positive" : "negative"}`}
              >
                ${abbreviate(ats?.current?.day_volume)}
              </div>
            </div>

            <div className="trade-algo-card__footer__item">
              <div className="trade-algo-card__footer__item__title">
                Call Ratio
              </div>
              <div className={`trade-algo-card__footer__item__value`}>
                {callRatio}
              </div>
            </div>
          </div>

          <div className="trade-algo-card__footer__section">
            <div className="trade-algo-card__footer__item">
              <div className="trade-algo-card__footer__item__title">
                Trade Flow
              </div>
              <div
                className={`trade-algo-card__footer__item__value trade-algo-card__footer__item__value--${up ? "positive" : "negative"}`}
              >
                {abbreviate(ats?.current?.day_trades)}
              </div>
            </div>

            <div className="trade-algo-card__footer__item">
              <div className="trade-algo-card__footer__item__title">
                Call Prem
              </div>
              <div className={`trade-algo-card__footer__item__value`}>
                ${abbreviate(options?.call_total_prem)}
              </div>
            </div>
          </div>

          <div className="trade-algo-card__footer__section">
            <div className="trade-algo-card__footer__item">
              <div className="trade-algo-card__footer__item__title">
                Total $ Vol
              </div>
              <div
                className={`trade-algo-card__footer__item__value trade-algo-card__footer__item__value--${up ? "positive" : "negative"}`}
              >
                ${abbreviate(ats?.current?.day_dollar_volume)}
              </div>
            </div>

            <div className="trade-algo-card__footer__item">
              <div className="trade-algo-card__footer__item__title">
                Put Prem
              </div>
              <div className={`trade-algo-card__footer__item__value`}>
                ${abbreviate(options?.put_total_prem)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  weekly: state.darkflowTable.weekly,
});
const mapDispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});
export default connect(stateToProps, mapDispatchToProps)(TradeAlgoCard);
