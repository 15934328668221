import React, { useEffect, useState, useMemo } from "react";
import NDatePicker from "./Field/DatePicker";
import moment from "moment";
import "./option-scanner.scss";
import { connect } from "react-redux";
import { lastPriceLoad } from "../../appRedux/ducks/polyLastPrice";
import { optionExpiryLoad } from "../../appRedux/ducks/optionExpirations";
import { searchLoad } from "../../appRedux/ducks/search";
import TickerSearch from "./TickerSearch";
import useDebounce from "./useDebounce";
import { optionChainLoad } from "../../appRedux/ducks/optionChain";
import { equitiesPriceLoad } from "../../appRedux/ducks/equities";
import { Helmet } from "react-helmet";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CollapsibleTable from "../common/Tables/CollapsableOptionsTable";
import { useParams } from "react-router-dom";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import { useMedia } from "react-media";
import history from "../TradingView/history";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { addUserSettingsLoad } from "../../appRedux/ducks/userSettings";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { isInternalWS } from "../../appRedux/actions/helpers";
import { companyDataLoad } from "../../appRedux/ducks/companyData";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { widgetSymbolSetter } from "../../appRedux/ducks/widgetTicker";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.os} !important`,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
export function returnRows(socketData, data = []) {
  return data.map((row) => {
    const lastPrice = Number(
      socketData.get(row.symbol)?.price || row?.lastPrice || 0,
    ).toFixed(2);
    const priceAdded = row?.price_added || 0;
    const change = Math.round(Number(lastPrice) * 100) / 100 - row.openPrice;
    const openPrice = row.openPrice ? row.openPrice : 0;

    let changePercent =
      Math.round(Number(lastPrice) * 100) / 100 - Number(openPrice);
    changePercent = (changePercent / openPrice) * 100;

    const changeSinceAdded =
      Math.round(Number(lastPrice) * 100) / 100 - priceAdded;
    let changePercentSinceAdded =
      Math.round(Number(lastPrice) * 100) / 100 - Number(priceAdded);
    changePercentSinceAdded = (changePercentSinceAdded / priceAdded) * 100;

    const bid = Number(socketData.get(row.symbol)?.bidPrice || row.bid).toFixed(
      2,
    );
    const ask = Number(socketData.get(row.symbol)?.askPrice || row.ask).toFixed(
      2,
    );
    return {
      ...row,
      changeSinceAdded,
      changePercentSinceAdded,
      bid,
      lastPrice,
      ask,
      change,
      changePercent,
      rsi: Math.round(row.rsi),
    };
  });
}
const OptionScanner = ({
  searchedSymbols,
  optionExpiry,
  lastPrice,
  optionChain,
  getOptionExpirations,
  getLastPrice,
  getOptionChainData,
  searchAllSymbols,
  price,
  marketStatus,
  loadPrice,
  loading,
  optionsLoading,
  dxFeedListen,
  socketData,
  themeMode,
  userSettings,
  setUserSettings,
  company = false,
  companyTicker,
  companyDataLoading,
  fetchCompanyData,
  tickerDetails,
  setWidgetSymbol,
}) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const search = useLocation().search;
  const { sym } = useParams();
  const [date, setDate] = useState("");
  const [symbol, setSymbol] = useState(company ? companyTicker : sym);
  const [ticker, setTicker] = useState(company ? companyTicker : sym);
  const [active, setActive] = useState("all");
  const [midpoint, setMidpoint] = useState(0);
  const [midpointChange, setMidpointChange] = useState(false);
  const debouncedSymbol = useDebounce(symbol, 500);
  const optionChainData = useMemo(() => {
    return optionChain.length ? optionChain : [];
  }, [JSON.stringify(optionChain), ticker, date]);
  useEffect(() => {
    if (ticker) {
      setWidgetSymbol(ticker);
    }
  }, [ticker]);
  useEffect(() => {
    return () => setWidgetSymbol("");
  }, []);
  const formattedOptionsData = useMemo(() => {
    const firstStrike = optionChainData[0] && optionChainData[0]?.strike;
    const lastStrike =
      optionChainData[optionChainData.length - 1] &&
      optionChainData[optionChainData.length - 1]?.strike;
    let calls = optionChainData.filter((item) => item.cp === "C");
    var closestCall = calls.length
      ? calls.reduce(function (r, a, i, aa) {
          return i &&
            Math.abs(aa[r].strike - lastPrice) < Math.abs(a.strike - lastPrice)
            ? r
            : i;
        }, -1)
      : 0;
    const bestCalls = calls.slice(
      closestCall + midpoint - 5 >= 0 ? closestCall + midpoint - 5 : 0,
      closestCall + midpoint + 5,
    );

    const strikeString = `${bestCalls[0] ? bestCalls[0]?.strike.toFixed(2) : ""}  - ${
      bestCalls[bestCalls.length - 1]
        ? bestCalls[bestCalls.length - 1]?.strike.toFixed(2)
        : ""
    }`;

    const nextStrikesUp = calls.slice(
      closestCall + midpoint - 5 >= 0 ? closestCall + midpoint : 0,
      closestCall + midpoint + 10,
    );

    const nextUpString = `${
      nextStrikesUp[0] ? nextStrikesUp[0]?.strike : ""
    } - ${
      nextStrikesUp[nextStrikesUp.length - 1]
        ? nextStrikesUp[nextStrikesUp.length - 1]?.strike
        : ""
    }`;

    const nextStrikesDown = calls.slice(
      closestCall + midpoint - 10 >= 0 ? closestCall + midpoint - 10 : 0,
      closestCall + midpoint,
    );

    const nextDownString = `${
      nextStrikesDown[0] ? nextStrikesDown[0]?.strike : ""
    } - ${
      nextStrikesDown[nextStrikesDown.length - 1]
        ? nextStrikesDown[nextStrikesDown.length - 1]?.strike
        : ""
    }`;

    let puts = optionChainData.filter((item) => item.cp === "P");

    const bestPuts = puts.slice(
      closestCall + midpoint - 5 >= 0 ? closestCall + midpoint - 5 : 0,
      closestCall + midpoint + 5,
    );

    const subs = bestCalls.concat(bestPuts).map((item) => item.symbol);
    return {
      calls: bestCalls,
      puts: bestPuts,
      subs,
      firstStrike,
      lastStrike,
      currentString: strikeString,
      upString: nextUpString,
      upStringDisabled:
        lastStrike === bestPuts[bestPuts.length - 1]?.strike || false,
      downString: nextDownString,
      downStringDisabled: firstStrike === bestCalls[0]?.strike || false,
    };
  }, [optionChainData, active, midpoint, lastPrice]);

  useEffect(() => {
    if (formattedOptionsData.subs.concat([ticker]).length > 1) {
      const optionTickers = isInternalWS
        ? formattedOptionsData.subs
            .concat([ticker])
            .map((ticker) => `options_tns_dx;${ticker}`)
        : formattedOptionsData.subs.concat([ticker]);
      dxFeedListen(optionTickers, true);
    }
  }, [formattedOptionsData.subs, ticker]);
  useEffect(() => {
    return () => dxFeedListen([], true, true);
  }, []);
  const liveRowData = useMemo(() => {
    const calls = returnRows(socketData, formattedOptionsData.calls);
    const puts = returnRows(socketData, formattedOptionsData.puts);
    return { calls, puts };
  }, [
    optionChainData,
    socketData,
    formattedOptionsData.calls,
    formattedOptionsData.puts,
  ]);
  const livePrice = useMemo(() => {
    return socketData?.get(ticker) || lastPrice;
  }, [socketData, lastPrice, ticker]);

  const performance = useMemo(() => {
    let lastPriceUsed = livePrice;
    let compare = {
      "pre-open": parseFloat(
        price?.close_price || price?.prev_close_price,
      ).toFixed(2),
      open: parseFloat(price.open_price).toFixed(2),
      after: parseFloat(price.open_price).toFixed(2),
      closed: parseFloat(price.open_price).toFixed(2),
    }[marketStatus];
    return {
      present: lastPriceUsed,
      change: Number(lastPriceUsed - compare).toFixed(2),
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
      positive: Number(lastPriceUsed - compare) >= 0,
    };
  }, [marketStatus, price, ticker, livePrice]);

  const expDates = useMemo(() => {
    return optionExpiry.expirationDates.length
      ? optionExpiry.expirationDates.filter((i) => {
          const futureDate = moment.utc(Number(i)).format("YYYY-MM-DD");
          const today = moment.utc().format("YYYY-MM-DD");
          return moment(futureDate).isSameOrAfter(today, "day");
        })
      : [];
  }, [JSON.stringify(optionExpiry), ticker]);
  useEffect(() => {
    const urlDate = new URLSearchParams(search).get("date");
    if (expDates.length && optionExpiry.ticker === ticker) {
      // if there is no date in the url, set the date to the first exp date
      if (!urlDate || !moment.utc(urlDate).isAfter(moment.utc())) {
        if (!company) {
          history.push(
            `/option-chain/${symbol.toUpperCase()}?date=${moment.utc(Number(expDates[0])).format("MM/DD/YY")}`,
          );
        }
        setDate(moment.utc(Number(expDates[0])).format("MM/DD/YY"));
      }
    }
  }, [expDates, ticker, company]);
  useEffect(() => {
    const urlDate = new URLSearchParams(search).get("date");
    // if there is a date in the url and the date is not before today then set the date
    if (urlDate && moment.utc(urlDate).isAfter(moment.utc())) {
      setDate(urlDate);
    }
  }, []);

  useEffect(() => {
    getLastPrice(ticker);
    loadPrice(ticker);
    getOptionExpirations(ticker);
    if (!company) {
      fetchCompanyData(ticker);
    }
  }, [ticker, company]);

  useEffect(() => {
    if (ticker && date) {
      setMidpoint(0);
      getOptionChainData(ticker, date);
      if (!company) {
        history.push(
          `/option-chain/${symbol.toUpperCase()}?date=${moment(date).format("MM/DD/YY")}`,
        );
      }
    }
  }, [date, ticker, company]);

  useEffect(() => {
    searchAllSymbols(debouncedSymbol);
  }, [debouncedSymbol]);

  const DateAndFilter = useMemo(() => {
    return (
      <div className="option-scanner-datepicker">
        <div className="option-scanner-datepicker-title">Expiration Date</div>
        <NDatePicker
          availableDates={expDates}
          onChange={(e) => {
            const date = moment(e);
            const isValid = date.isValid();
            if (isValid) setDate(e);
          }}
          value={date}
        />
      </div>
    );
  }, [expDates, date]);

  const livePriceComponent = (
    <span className={clsx(classes.text, "option-scanner-robin-hood-wrapper")}>
      ${Number(livePrice)?.toFixed(2)}
    </span>
  );

  const Tables = useMemo(() => {
    return (
      <>
        {active !== "puts" && (
          <CollapsibleTable
            loading={optionsLoading || !date}
            expDate={date}
            symbol={symbol}
            livePrice={livePrice}
            midpointChange={midpointChange}
            themeMode={themeMode}
            swingTrades={false}
            calls={true}
            rows={liveRowData.calls}
            company={company}
          />
        )}
        {active !== "calls" && (
          <>
            {active === "all" && <br />}
            <CollapsibleTable
              loading={optionsLoading || !date}
              expDate={date}
              symbol={symbol}
              livePrice={livePrice}
              midpointChange={midpointChange}
              themeMode={themeMode}
              swingTrades={false}
              calls={false}
              rows={liveRowData.puts}
              company={company}
            />
          </>
        )}
      </>
    );
  }, [active, liveRowData, optionsLoading, midpointChange, themeMode, company]);
  return (
    <div className="container">
      <Helmet>
        <title>Option Chain | TradeAlgo</title>
      </Helmet>
      {!company && (
        <div className="option-scanner-header">
          <div className="option-scanner-company-info">
            <div className="option-scanner-company-info-left">
              <div className="option-scanner-company-info-ticker">
                {companyDataLoading ? (
                  <BeautifulSkeleton width={100} height={39} />
                ) : (
                  tickerDetails?.ticker
                )}
              </div>
              <div className="option-scanner-company-info-name">
                {companyDataLoading ? (
                  <BeautifulSkeleton width={75} height={17} />
                ) : (
                  tickerDetails?.name
                )}
              </div>
            </div>
            <div className="option-scanner-company-info-right">
              <div className="option-scanner-company-price-info">
                <div className="option-scanner-company-live-price">
                  {livePriceComponent}
                </div>
                <div className="option-scanner-company-price-symbol">USD</div>
              </div>

              <div className="option-scanner-company-price-change">
                <div
                  className={`option-scanner-company-percent-value option-scanner-company-percent-value--${performance.positive ? "positive" : "negative"}`}
                >
                  {performance.positive
                    ? "+ " + performance.percent
                    : performance.percent}{" "}
                  %
                </div>
                <div
                  className={`option-scanner-company-performance option-scanner-company-performance--${performance.positive ? "positive" : "negative"}`}
                >
                  (
                  {performance.positive
                    ? "+" + performance.change
                    : performance.change}
                  )
                </div>
              </div>
            </div>
          </div>

          <div className="option-scanner-header-search-wrapper">
            <TickerSearch
              company={company}
              userSettings={userSettings}
              options={searchedSymbols}
              loading={loading}
              setTicker={setTicker}
              symbol={symbol}
              setSymbol={setSymbol}
              setUserSettings={setUserSettings}
            />
          </div>
        </div>
      )}

      <div className="option-scanner-filters">
        {DateAndFilter}

        <div className="option-scanner-filters-strike-items">
          <div className="option-scanner-filters-strike-item">
            <IconButton
              disabled={formattedOptionsData.downStringDisabled}
              onClick={() => {
                setMidpoint(midpoint - 5);
                setMidpointChange(true);
                setTimeout(() => {
                  setMidpointChange(false);
                }, 300);
              }}
            >
              <ArrowBackIosIcon style={{ fill: "#CCCCCC" }} />
            </IconButton>
            {!formattedOptionsData.downStringDisabled &&
              !isMobile &&
              formattedOptionsData.downString}
          </div>

          <div className="option-scanner-filters-strike-current-item">
            <div>Strike Range</div>
            <div className="option-scanner-filters-strike-current-item-value">
              {formattedOptionsData.currentString}
            </div>
          </div>

          <div className="option-scanner-filters-strike-item">
            {!formattedOptionsData.upStringDisabled &&
              !isMobile &&
              formattedOptionsData.upString}
            <IconButton
              disabled={formattedOptionsData.upStringDisabled}
              onClick={() => {
                setMidpoint(midpoint + 5);
                setMidpointChange(true);
                setTimeout(() => {
                  setMidpointChange(false);
                }, 300);
              }}
            >
              <ArrowForwardIosIcon style={{ fill: "#CCCCCC" }} />
            </IconButton>
          </div>
        </div>

        <div className="option-scanner-filters-types">
          <span
            onClick={() => setActive("all")}
            className={`option-scanner-filter-type ${
              active === "all" ? "active" : ""
            }`}
          >
            All
          </span>
          <span className={"separator-borders"} />
          <span
            onClick={() => setActive("calls")}
            className={`option-scanner-filter-type ${
              active === "calls" ? "active" : ""
            }`}
          >
            Calls
          </span>
          <span className={"separator-borders"} />
          <span
            onClick={() => setActive("puts")}
            className={`option-scanner-filter-type ${
              active === "puts" ? "active" : ""
            }`}
          >
            Puts
          </span>
        </div>
      </div>
      {Tables}
    </div>
  );
};

const stateToProps = (state) => ({
  searchedSymbols: state.searchSymbols.searchSymbols,
  loading: state.searchSymbols.symbolsLoading,
  lastPrice: state.lastPrice.lastPrice,
  optionExpiry: state.optionExpiry,
  marketStatus: state.marketStatus.marketStatus,
  optionChain: state.optionChain.optionChain,
  price: state.equities.price,
  optionsLoading: state.optionChain.loading,
  socketData: isInternalWS
    ? state.EquitiesSocket.currentPrices
    : state.dxFeedSocket.socketData,
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  companyDataLoading: state.companyData.companyDataLoading,
  tickerDetails: state.companyData.companyData.tickerDetails,
});

const dispatchToProps = (dispatch) => ({
  setWidgetSymbol: (symbol) => dispatch(widgetSymbolSetter(symbol)),
  getLastPrice: (symbol) => dispatch(lastPriceLoad(symbol)),
  searchAllSymbols: (symbol) => dispatch(searchLoad(symbol)),
  getOptionExpirations: (symbol) => dispatch(optionExpiryLoad(symbol)),
  getOptionChainData: (symbol, date) => dispatch(optionChainLoad(symbol, date)),
  dxFeedListen: (symbols, options, disconnect) =>
    dispatch(
      isInternalWS
        ? equtiesSocketListen(symbols)
        : dxFeedSocketListen(symbols, options, disconnect),
    ),
  loadPrice: (ticker) => dispatch(equitiesPriceLoad(ticker)),
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
  fetchCompanyData: (symbol) => dispatch(companyDataLoad(symbol)),
});

export default connect(stateToProps, dispatchToProps)(OptionScanner);
