import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const ORDERBOOK_LOAD_TOP = "OB/LOAD/TOP";
export const ORDERBOOK_LOAD_TOP_SUCCESS = "OB/LOAD/TOP/SUCCESS";
export const ORDERBOOK_LOAD_TOP_FAILURE = "OB/LOAD/TOP/FAILURE";

// Action Creators
export const orderBookLoad = (ticker) => ({
  type: ORDERBOOK_LOAD_TOP,
  ticker,
});
export const orderBookLoadSuccess = (data, ticker) => ({
  type: ORDERBOOK_LOAD_TOP_SUCCESS,
  data,
  ticker,
});
export const orderBookLoadFailure = (error) => ({
  type: ORDERBOOK_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchOrderBook(action) {
  try {
    const ticker = action.ticker;
    const response = yield axios.get(`${apiURL}/orderbook/${ticker}`);
    if (response.data) {
      yield put(orderBookLoadSuccess(response.data, ticker));
    }
  } catch (error) {
    yield put(orderBookLoadFailure(error));
  }
}

function* listenOrderBookLoad() {
  yield takeEvery(ORDERBOOK_LOAD_TOP, fetchOrderBook);
}

// Root Saga
export function* saga() {
  yield all([fork(listenOrderBookLoad)]);
}

const INIT_STATE = {
  orderBook: {
    refPrice: "",
    asks: [],
    bids: [],
  },
  orderBookTicker: "",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORDERBOOK_LOAD_TOP_SUCCESS:
      return {
        ...state,
        orderBook: action.data,
        orderBookTicker: action.ticker,
      };
    default:
      return state;
  }
};

export default reducer;
