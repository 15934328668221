import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TradeGPTIcon from "../../common/Icon/TradingToolsIcons/TradeGPTIcon";
import ScannerIcon from "../../common/Icon/TradingToolsIcons/ScannerIcon";
import ChartGridIcon from "../../common/Icon/TradingToolsIcons/ChartGridIcon";
import CapMovementIcon from "../../common/Icon/TradingToolsIcons/CapMovementIcon";
import OptionsChainIcon from "../../common/Icon/TradingToolsIcons/OptionsChainIcon";
import SwingTradesAIIcon from "../../common/Icon/TradingToolsIcons/SwingTradesAIIcon";
import TradeAlertIcon from "../../common/Icon/TradingToolsIcons/TradeAlertsIcon";
import LiveOptionsIcon from "../../common/Icon/TradingToolsIcons/LiveOptionsIcon";
import HistoricalDarkflowIcon from "../../common/Icon/TradingToolsIcons/HistoricalDarkflowIcon";

import "./TradingTools.scss";

const TradingTools = ({ themeMode, userSettings }) => {
  const hardHistory = useHistory();

  const { minPremium, expWithinMin, expWithinMax, minContracts } = userSettings;

  const fillColor = useMemo(() => {
    return themeMode === "dark" ? "#1C1C1E" : "#007BFF";
  }, [themeMode]);

  const goToURL = (url) => {
    hardHistory.push(url);
  };

  return (
    <div className="trading-tools-component">
      <div className="trading-tools-component__title">Trading Tools</div>
      <div className="trading-tools-component__content">
        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/tradegpt")}
        >
          <div className="trading-tools-component__content__item__icon">
            <TradeGPTIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            TradeGPT
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/scanner")}
        >
          <div className="trading-tools-component__content__item__icon">
            <ScannerIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Scanner
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/chart-grid")}
        >
          <div className="trading-tools-component__content__item__icon">
            <ChartGridIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Chart Grid
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/movement")}
        >
          <div className="trading-tools-component__content__item__icon">
            <CapMovementIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Cap Movement
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() =>
            goToURL(
              `/option-chain/${userSettings?.defaultOptionChainTicker || "AAPL"}`,
            )
          }
        >
          <div className="trading-tools-component__content__item__icon">
            <OptionsChainIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Options Chain
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/swing-trades/0")}
        >
          <div className="trading-tools-component__content__item__icon">
            <SwingTradesAIIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Swing Trades AI
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/alerts/Auto/Up")}
        >
          <div className="trading-tools-component__content__item__icon">
            <TradeAlertIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Trade Alerts
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() =>
            goToURL(
              `/live-options/${minPremium}/${expWithinMin},${expWithinMax}/${minContracts}`,
            )
          }
        >
          <div className="trading-tools-component__content__item__icon">
            <LiveOptionsIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Live Options
          </div>
        </div>

        <div
          className="trading-tools-component__content__item"
          onClick={() => goToURL("/historical/Auto/Up")}
        >
          <div className="trading-tools-component__content__item__icon">
            <HistoricalDarkflowIcon fill={fillColor} />
          </div>
          <div className="trading-tools-component__content__item__caption">
            Historical Darkflow
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(TradingTools);
