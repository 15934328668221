import CssPop from "../../common/Tables/animations/CssPop";
import PriceDisplay from "../../common/Tables/renderers/PriceDisplay";

function IndividualPopStockRow({ item, goCompanyDetailPage }) {
  const livePrice = item.price;
  const price = livePrice;
  return (
    <div className="popular-stocks-item">
      <div
        className="popular-stocks-item-left"
        onClick={() => goCompanyDetailPage(item.symbol)}
      >
        <div className="popular-stocks-item-ticker">{item.symbol}</div>
        <div className="popular-stocks-item-name">{item.companyName}</div>
      </div>

      <div className="popular-stocks-item-right">
        <div className="popular-stocks-item-price">
          <CssPop ticker={item.symbol} comparator={price} />
        </div>
        <div className={`popular-stocks-item-change`}>
          <PriceDisplay
            className
            lastPrice={price}
            ticker={item.symbol}
            trend_start={item.openPrice}
            showPercent
          />
        </div>
      </div>
    </div>
  );
}

export default IndividualPopStockRow;
