import React from "react";

const ResizeHandle = React.forwardRef(({ handleAxis, ...restProps }, ref) => {
  let cursor;
  switch (handleAxis) {
    case "s":
      cursor = "s-resize";
      break;
    case "w":
      cursor = "w-resize";
      break;
    case "e":
      cursor = "e-resize";
      break;
    case "n":
      cursor = "n-resize";
      break;
    case "sw":
      cursor = "sw-resize";
      break;
    case "nw":
      cursor = "nw-resize";
      break;
    case "se":
      cursor = "se-resize";
      break;
    case "ne":
      cursor = "ne-resize";
      break;
    default:
      cursor = "se-resize";
  }

  return (
    <div
      style={{ cursor }}
      ref={ref}
      className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
      {...restProps}
    />
  );
});

ResizeHandle.displayName = "ResizeHandle";

export default ResizeHandle;
