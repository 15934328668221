import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import "./NewOptionsMentorship.scss";
import NewOptionsMentorshipTabs from "./NewOptionsMentorshipTabs/NewOptionsMentorshipTabs";
import NewOptionsMentorshipOverview from "./NewOptionsMentorshipOverview/NewOptionsMentorshipOverview";
import AllPricePlans from "./AllPricePlans/AllPricePlans";

const NewOptionsMentorship = () => {
  const hardHistory = useHistory();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    setActiveTab(["overview", "plans"].includes(tab) ? tab : "plans");
  }, [tab]);

  useEffect(() => {
    hardHistory.push(`/options-mentorship/${activeTab}`);
  }, [activeTab, hardHistory]);

  return (
    <div className="om-page">
      <NewOptionsMentorshipTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === "overview" && <NewOptionsMentorshipOverview />}
      {activeTab === "plans" && <AllPricePlans />}
    </div>
  );
};

export default NewOptionsMentorship;
