import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const UPDATE_LAYOUT_LOAD = "UL/LOAD/TOP";
export const UPDATE_LAYOUT_LOAD_SUCCESS = "UL/LOAD/TOP/SUCCESS";
export const UPDATE_LAYOUT_LOAD_FAILURE = "UL/LOAD/TOP/FAILURE";

// Action Creators
export const updateLayoutLoad = (layouts, userData = false) => ({
  type: UPDATE_LAYOUT_LOAD,
  layouts,
  userData,
});
export const updateLayoutLoadSuccess = (data) => ({
  type: UPDATE_LAYOUT_LOAD_SUCCESS,
  data,
});
export const updateLayoutLoadFailure = (error) => ({
  type: UPDATE_LAYOUT_LOAD_FAILURE,
  error,
});

// Sagas
function* updateLayout(action) {
  try {
    const { layouts } = action;
    const response = yield axios.post(
      `${apiURL}/update-layouts`,
      { layouts },
      { withCredentials: true },
    );
    yield put(updateLayoutLoadSuccess(response.data));
  } catch (error) {
    yield put(updateLayoutLoadFailure(error));
  }
}

function* listenUpdateLayoutLoad() {
  yield takeEvery(UPDATE_LAYOUT_LOAD, updateLayout);
}

// Root Saga
export function* saga() {
  yield all([fork(listenUpdateLayoutLoad)]);
}

const INIT_STATE = {
  layouts: {
    lg: [
      {
        w: 4,
        h: 3,
        x: 0,
        y: 0,
        i: "0",
        static: false,
        ticker: "AAPL",
      },
      {
        w: 4,
        h: 3,
        x: 4,
        y: 0,
        i: "1",
        ticker: "TSLA",
        static: false,
      },
      {
        w: 4,
        h: 3,
        x: 8,
        y: 0,
        i: "2",
        ticker: "SHOP",
        static: false,
      },
      {
        w: 4,
        h: 3,
        x: 0,
        y: 3,
        i: "3",
        ticker: "AMD",
        static: false,
      },
      {
        w: 4,
        h: 3,
        x: 4,
        y: 3,
        i: "4",
        ticker: "NVDA",
        static: false,
      },
      {
        w: 4,
        h: 3,
        x: 8,
        y: 3,
        i: "5",
        ticker: "SPY",
        static: false,
      },
    ],
  },
  userData: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_LAYOUT_LOAD_SUCCESS:
      return {
        ...state,
        layouts: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
