import React, { useEffect, useRef, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./AnimatedMentorsDropdown.scss";

const AnimatedMentorsDropdown = ({
  comingSoon = false,
  mentors,
  selectedMentor,
  setSelectedMentor,
}) => {
  const [toggle, setToggle] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`animated-mentors-dropdown ${toggle ? "dropdown-open" : ""} ${comingSoon ? "dropdown-coming-soon" : ""}`}
      onClick={() => !comingSoon && setToggle(!toggle)}
      ref={ref}
    >
      <div className="animated-mentors-dropdown__title">
        {!comingSoon
          ? (selectedMentor.name ?? "Select Your Mentor")
          : "Coming Soon"}
        {!comingSoon && (toggle ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </div>
      <div className="animated-mentors-dropdown__items">
        {mentors.map((mentor, index) => (
          <div
            key={index}
            className={`animated-mentors-dropdown__item ${selectedMentor.id === mentor.id ? "animated-mentors-dropdown__item--selected" : ""}`}
            onClick={() => setSelectedMentor(mentor)}
          >
            {mentor.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnimatedMentorsDropdown;
