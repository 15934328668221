import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const SWING_TRADES_OPTIONSLOAD_TOP = "SWTO/LOAD/TOP";
export const SWING_TRADES_OPTIONSLOAD_TOP_SUCCESS = "SWTO/LOAD/TOP/SUCCESS";
export const SWING_TRADES_OPTIONSLOAD_TOP_FAILURE = "SWTO/LOAD/TOP/FAILURE";

// Action Creators
export const swingTradesOptionsLoad = (start, end) => ({
  type: SWING_TRADES_OPTIONSLOAD_TOP,
  start,
  end,
});
export const swingTradesOptionsLoadSuccess = (data) => ({
  type: SWING_TRADES_OPTIONSLOAD_TOP_SUCCESS,
  data,
});
export const swingTradesOptionsLoadFailure = (error) => ({
  type: SWING_TRADES_OPTIONSLOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchSwingTrades(action) {
  try {
    const { start, end } = action;
    yield put(
      swingTradesOptionsLoadSuccess({
        swingTradesOptions: [],
        loading: true,
        noData: false,
      }),
    );
    const response = yield axios.get(
      `${apiURL}/swing-trades-options?start=${start}&end=${end}`,
      { withCredentials: true },
    );
    if (response.data === "") {
      // set the noDataFlag to true
      yield put(
        swingTradesOptionsLoadSuccess({
          swingTradesOptions: [],
          loading: false,
          noData: true,
        }),
      );
    } else {
      yield put(
        swingTradesOptionsLoadSuccess({
          swingTradesOptions: response.data,
          loading: false,
          noData: false,
        }),
      );
    }
  } catch (error) {
    yield put(swingTradesOptionsLoadFailure(error));
  }
}

function* listenSwingTradesLoad() {
  yield takeEvery(SWING_TRADES_OPTIONSLOAD_TOP, fetchSwingTrades);
}

// Root Saga
export function* saga() {
  yield all([fork(listenSwingTradesLoad)]);
}

const INIT_STATE = {
  swingTradesOptions: [],
  loading: true,
  noData: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SWING_TRADES_OPTIONSLOAD_TOP_SUCCESS:
      return {
        ...state,
        swingTradesOptions: action.data.swingTradesOptions,
        loading: action.data.loading,
        noData: action.data.noData,
      };
    default:
      return state;
  }
};

export default reducer;
