import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import { swingSuggestionsLoad } from "../../appRedux/ducks/swingSuggestions";
import moment from "moment";
import { numberWithCommas } from "../utilities";
import { isInternalWS } from "../../appRedux/actions/helpers";
import { equtiesSocketListen } from "../../appRedux/ducks/equitiesSocket";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";

const SwingTradesContainer = ({
  loading,
  swingTrades = [],
  socketData,
  fetchSwingTrades,
  dxFeedListen,
}) => {
  const [sortModel, setSortModel] = useState([
    {
      field: "put_volume",
      sort: "desc",
    },
  ]);
  const summary = useMemo(() => {
    let Winners = 0;
    let Losers = 0;
    let No_Change = 0;
    let totalPerformance = 0;
    swingTrades.forEach((row) => {
      const performance = Number(
        (
          (((socketData.get(row.id)?.price || Number(row.lastPrice)) -
            Number(row.price_bought)) /
            Number(row.price_bought)) *
          100
        ).toFixed(2),
      );
      totalPerformance += performance;
      if (performance > 0) {
        Winners++;
      } else if (performance < 0) {
        Losers++;
      } else {
        No_Change++;
      }
    });
    const totalPercentageOfWinners = (Winners / (Winners + Losers)) * 100;
    return {
      Winners,
      Losers,
      No_Change,
      Percentage_Winners: Number(totalPercentageOfWinners).toFixed(2),
      Total_Performance_Percent: Number(totalPerformance).toFixed(2),
    };
  }, [socketData, swingTrades]);
  const columns = useMemo(() => {
    return [
      {
        field: "symbol",
        headerName: "symbol",
        flex: 1.4,
      },

      {
        field: "premium",
        headerName: "prem",
        flex: 1.2,
        valueGetter: ({ value }) => `$${numberWithCommas(Number(value))}`,
      },
      {
        field: "price_bought",
        headerName: "PB",
        flex: 1,
      },
      {
        field: "lastPrice",
        headerName: "LP",
        flex: 1,
        valueGetter: ({ row, value }) =>
          socketData?.get(row.id)?.price || value,
      },
      {
        field: "performance",
        headerName: "perf",
        flex: 1,
        valueGetter: ({ row }) => {
          return Number(
            (socketData.get(row.id)?.price || Number(row.lastPrice)) -
              Number(row.price_bought),
          ).toFixed(2);
        },
      },
      {
        field: "put_volume",
        headerName: "percent",
        flex: 1.3,
        valueGetter: ({ row }) => {
          return `${Number(((((socketData.get(row.id)?.price || Number(row.lastPrice)) - Number(row.price_bought)) / Number(row.price_bought)) * 100).toFixed(2))}%`;
        },
        sortComparator: (v1, v2, param1, param2) => {
          const performance1 = Number(
            (
              (((socketData.get(param1.id)?.price ||
                Number(param1.api.getCellValue(param1.id, "lastPrice"))) -
                Number(param1.api.getCellValue(param1.id, "price_bought"))) /
                Number(param1.api.getCellValue(param1.id, "price_bought"))) *
              100
            ).toFixed(2),
          );
          const performance2 = Number(
            (
              (((socketData.get(param2.id)?.price ||
                Number(param2.api.getCellValue(param2.id, "lastPrice"))) -
                Number(param2.api.getCellValue(param2.id, "price_bought"))) /
                Number(param2.api.getCellValue(param2.id, "price_bought"))) *
              100
            ).toFixed(2),
          );
          return performance1 - performance2;
        },
      },
      {
        field: "date_alerted",
        headerName: "date alerted",
        flex: 1.5,
        valueGetter: ({ value }) => moment(value).format("MM/DD/YY HH:MM"),
      },
      {
        field: "bid",
        headerName: "bid",
        valueGetter: ({ row, value }) =>
          socketData?.get(row.symbol)?.bid || value,
      },
      {
        field: "ask",
        headerName: "ask",
        valueGetter: ({ row, value }) =>
          socketData?.get(row.symbol)?.ask || value,
      },
      {
        field: "spot_price",
        headerName: "spot",
        flex: 1.2,
      },
      {
        field: "day_volume",
        headerName: "vol",
        flex: 1,
      },
      {
        field: "exp",
        headerName: "exp",
        valueGetter: ({ value }) => moment(value).format("MM/DD/YY"),
        sortComparator: (v1, v2) => {
          return moment(v1).valueOf() - moment(v2).valueOf();
        },
        flex: 1,
      },
    ];
  }, [socketData, swingTrades]);

  const optionTickers = useMemo(() => {
    return swingTrades.map((item) => item.id);
  }, [swingTrades]);
  useEffect(() => {
    if (optionTickers.length > 1) {
      const tickers = isInternalWS
        ? optionTickers.map((ticker) => `options_tns_dx;${ticker}`)
        : optionTickers;
      dxFeedListen(tickers, true);
    }
  }, [optionTickers]);

  useEffect(() => {
    return () => dxFeedListen([], true, true);
  }, []);

  useEffect(() => {
    fetchSwingTrades();
  }, []);

  let rows = useMemo(() => {
    return swingTrades;
  }, [swingTrades]);

  return (
    <div style={{ textAlign: "center" }}>
      <Helmet>
        <title>Swing Suggestions | TradeAlgo</title>
      </Helmet>
      <br />
      <div className="padding-15-row">
        <div className="card" style={{ background: "white" }}>
          <div className="card-body">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {Object.keys(summary)?.map((item, index) => (
                <span key={index} className="swinging-suggest-text">
                  {item}: {summary[item]}
                  {item.includes("Percent") ? "%" : ""}
                </span>
              ))}
            </div>
            <TradeAlgoTable
              rows={rows}
              columns={columns}
              loading={loading}
              sortModel={sortModel}
              setSortModel={setSortModel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  loading: state.swingSuggestions.loading,
  swingTrades: state.swingSuggestions.swingSuggestions,
});
const dispatchToProps = (dispatch) => ({
  fetchSwingTrades: () => dispatch(swingSuggestionsLoad()),
  dxFeedListen: (symbols, options, disconnect) =>
    dispatch(
      isInternalWS
        ? equtiesSocketListen(symbols)
        : dxFeedSocketListen(symbols, options, disconnect),
    ),
});

export default connect(stateToProps, dispatchToProps)(SwingTradesContainer);
