import React from "react";
import Skeleton from "@mui/material/Skeleton";

import "./BeautifulSkeleton.scss";

function BeautifulSkeleton({ variant = "text", ...props }) {
  const newClassName = `${props.className ? props.className : ""} default-beautiful-skeleton`;
  const newProps = { ...props, className: newClassName };

  return (
    <React.Fragment>
      <Skeleton variant={variant} {...newProps} />
    </React.Fragment>
  );
}

export default BeautifulSkeleton;
