import * as React from "react";

const OptionsChainIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66699 5.41645C1.66699 4.56082 2.41318 3.86719 3.33366 3.86719H36.6669C37.5874 3.86719 38.3336 4.56082 38.3336 5.41645V14.0645L38.3336 14.0707V34.2112C38.3336 34.2124 38.3336 34.2136 38.3336 34.2147C38.3337 34.2208 38.3337 34.2269 38.3337 34.233C38.3337 35.0886 37.5875 35.7822 36.667 35.7822H10.8467C9.9262 35.7822 9.18001 35.0886 9.18001 34.233C9.18001 33.3773 9.9262 32.6837 10.8467 32.6837H35.0003V16.2613H5.00032V24.9154C5.00032 25.7711 4.25413 26.4647 3.33365 26.4647C2.41318 26.4647 1.66699 25.7711 1.66699 24.9154V14.7121V14.0706V5.41645ZM5.00032 6.96572V13.1628H35.0003V6.96572H5.00032ZM24.4879 21.362C25.1331 20.7632 26.1761 20.7575 26.8289 21.349L30.5626 24.7326C31.2217 25.3299 31.2351 26.3108 30.5926 26.9235C29.95 27.5361 28.8948 27.5486 28.2358 26.9513L25.6794 24.6347L20.1402 29.7752C19.4992 30.3701 18.4642 30.3801 17.8099 29.7977L13.5112 25.9709L4.51818 34.4164C3.87064 35.0246 2.81538 35.0296 2.16119 34.4277C1.507 33.8257 1.5016 32.8448 2.14913 32.2367L12.2954 22.7081C12.9354 22.107 13.9757 22.0941 14.6327 22.679L18.9385 26.5121L24.4879 21.362Z"
      fill={fill}
    />
  </svg>
);

export default OptionsChainIcon;
