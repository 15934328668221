import { Helmet } from "react-helmet";
import "./tradegpt.scss";
import { Button } from "@mui/material";

function TradeGPT() {
  return (
    <div className="tradegpt-container">
      <div className="frame">
        <Helmet>
          <title>TradeGPT | TradeAlgo</title>
        </Helmet>
        <div className="content-container">
          <div className="tradegpt-title">TradeGPT</div>
          <div className="line"> </div>
          <div className="tradealgo-launched para-text">
            TradeAlgo has launched its newest AI product called TradeGPT. It is
            the world&apos;s most powerful AI trading agent that is designed to
            help TradeAlgo members reach their financial goals with
            “super-intelligent” insights into stocks, options, forex and
            cryptos.
          </div>
          <div className="simply-ask para-text">
            Simply ask TradeGPT any question and instantly receive AI-powered
            answers based on over 50 million data points.
          </div>
          <div className="para-text">
            Instant data, analysis and even trade recommendations are all
            engineered to help TradeAlgo members boost their trading profits.
          </div>
          <div className="jump-start">
            Jump-start your trading with TradeAlgo&apos;s “Super-Intelligent” AI
            today
          </div>

          <div className="">
            <Button
              href="https://tradegpt-beta.tradealgo.com/"
              target="_blank"
              classes={{ root: "tradegpt-button" }}
            >
              Try TradeGPT Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TradeGPT;
