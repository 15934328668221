import React, { useMemo } from "react";
import "./MovementContainer.scss";
import NumAbbr from "number-abbreviate";
import { numberWithCommas } from "../utilities";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.tableBackground} !important`,
    color: theme.palette.primary.text,
  },
  contrast: {
    backgroundColor: `${theme.palette.primary.movement} !important`,
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));
// #3e444d
const abbreviate = (x) => new NumAbbr(["K", "M", "B", "T"]).abbreviate(x, 2);

function MovementBlocks({ bullishFlow, bearishFlow, type }) {
  const classes = useStyles();
  const base = useMemo(() => {
    let bullMax = Math.max.apply(
      Math,
      bullishFlow?.map(function (o) {
        return Number(o?.ats?.current?.day_dollar_volume || 0);
      }),
    );
    let bearMax = Math.max.apply(
      Math,
      bearishFlow?.map(function (o) {
        return Number(o?.ats?.current?.day_dollar_volume || 0);
      }),
    );
    return { bullMax, bearMax };
  }, [bullishFlow, bearishFlow]);

  function getPercentage(smaller, bullish) {
    if (bullish) return Number(smaller / base.bullMax) * 100 + "%";
    else return Number(smaller / base.bearMax) * 100 + "%";
  }
  return (
    <>
      <div
        style={{ fontSize: "18px !important" }}
        className={clsx(classes.text, "movement-row-block-title")}
      >
        Top {type} Cap Flow
      </div>
      <div className="row">
        <div className={`movement-half-width-block`}>
          <div className={clsx(classes.background, "movement-row-block")}>
            <div className={clsx(classes.text, "movement-row-block-title")}>
              Bullish Volume
            </div>
            <div className={"movement-row-block-header"}>
              <span className={"movement-row-block-header-item"}>Ticker</span>
              <span className={"movement-row-block-header-item"}>
                Daily Trades
              </span>
              <span className={"movement-row-block-header-item"}>
                Daily Volume
              </span>
            </div>

            <div className={"movement-row-block-content"}>
              {typeof bullishFlow[0] !== "number"
                ? bullishFlow
                    .sort(
                      (a, b) =>
                        b?.ats?.current?.day_dollar_volume -
                        a?.ats?.current?.day_dollar_volume,
                    )
                    .map((item) => (
                      <Link
                        className={clsx(
                          classes.contrast,
                          "movement-row-block-content-row bullish-record",
                        )}
                        key={item.ticker}
                        style={{ cursor: "pointer" }}
                        to={`/company/${item.ticker}`}
                      >
                        <div
                          className={
                            "movement-row-block-content-row-progressbar"
                          }
                          style={{
                            width: getPercentage(
                              item?.ats?.current?.day_dollar_volume || 0,
                              true,
                            ),
                          }}
                        />

                        <span
                          className={clsx(
                            classes.text,
                            "movement-row-block-content-item",
                          )}
                        >
                          {item.ticker}
                        </span>
                        <span
                          className={clsx(
                            classes.text,
                            "movement-row-block-content-item",
                          )}
                        >
                          {numberWithCommas(
                            item?.ats?.current?.day_trades || 0,
                          )}
                        </span>
                        <span
                          className={clsx(
                            classes.text,
                            "movement-row-block-content-item",
                          )}
                        >
                          $
                          {abbreviate(
                            item?.ats?.current?.day_dollar_volume || 0,
                          )}
                        </span>
                      </Link>
                    ))
                : null}
            </div>
          </div>
        </div>

        <div className={`movement-half-width-block`}>
          <div className={clsx(classes.background, "movement-row-block")}>
            <div className={clsx(classes.text, "movement-row-block-title")}>
              Bearish Volume
            </div>
            <div className={"movement-row-block-header"}>
              <span className={"movement-row-block-header-item"}>Ticker</span>
              <span className={"movement-row-block-header-item"}>
                Daily Trades
              </span>
              <span className={"movement-row-block-header-item"}>
                Daily Volume
              </span>
            </div>

            <div className={clsx(classes.text, "movement-row-block-content")}>
              {typeof bearishFlow[0] !== "number"
                ? bearishFlow
                    .sort(
                      (a, b) =>
                        b?.ats?.current?.day_dollar_volume -
                        a?.ats?.current?.day_dollar_volume,
                    )
                    .map((item) => (
                      <Link
                        className={clsx(
                          classes.contrast,
                          "movement-row-block-content-row bearish-record",
                        )}
                        key={item.ticker}
                        style={{ cursor: "pointer" }}
                        to={`/company/${item.ticker}`}
                      >
                        <div
                          className={
                            "movement-row-block-content-row-progressbar-bear"
                          }
                          style={{
                            width: getPercentage(
                              item?.ats?.current?.day_dollar_volume,
                              false,
                            ),
                          }}
                        />

                        <span
                          className={clsx(
                            classes.text,
                            "movement-row-block-content-item",
                          )}
                        >
                          {item.ticker}
                        </span>
                        <span
                          className={clsx(
                            classes.text,
                            "movement-row-block-content-item",
                          )}
                        >
                          {numberWithCommas(
                            item?.ats?.current?.day_trades || 0,
                          )}
                        </span>
                        <span
                          className={clsx(
                            classes.text,
                            "movement-row-block-content-item",
                          )}
                        >
                          $
                          {abbreviate(
                            item?.ats?.current?.day_dollar_volume || 0,
                          )}
                        </span>
                      </Link>
                    ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MovementBlocks;
