import React from "react";

import EmeraldCommonPage from "../EmeraldCommonPage";

const EmeraldLivestream = () => {
  return (
    <>
      <EmeraldCommonPage
        title="Livestream"
        subTitle="Emerald Income Alerts Livestream"
        description="This is a live trading experience where viewers can learn from a professional trader, Bronco, as he shares his desktop platform and provides analysis, trading tactics, and risk management strategies. The show offers insights into price action on charts and empirical reasons for projected profits. "
        eventID="4438921"
        from="livestream"
      />
    </>
  );
};

export default EmeraldLivestream;
