import React from "react";

import "./AllVideosList.scss";

const AllVideosList = ({ allVideos, selectVideo, currentVideo }) => {
  return (
    <div className="all-videos-list">
      {allVideos.map((video, index) => (
        <div
          key={index}
          className={`all-videos-list__video ${currentVideo.id === video.id ? "active" : ""}`}
          onClick={() => selectVideo(video.id)}
        >
          <div className="all-videos-list__video__thumbnail">
            {video.thumbnail}
            {currentVideo.id === video.id && (
              <div className="all-videos-list__video__now_playing">
                Now Playing
              </div>
            )}
          </div>
          <div className="all-videos-list__video__info">
            <div className="all-videos-list__video__title">{video.title}</div>
            <div className="all-videos-list__video__length">{video.length}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllVideosList;
