import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import VideoCardsContainer from "../../OptionsAcademy/VideoCardsContainer";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import "./EmeraldTutorials.scss";

const EmeraldTutorials = ({ allVideos }) => {
  const [currentVideo, setCurrentVideo] = useState({
    id: 0,
    title: "",
    videoUrl: "",
    overview: "",
    description: null,
    length: "",
    //checked: false,
    type: "",
    thumbnail: null,
  });

  const hardHistory = useHistory();

  useEffect(() => {
    if (currentVideo.id) {
      hardHistory.push(`/emerald/tutorials/${currentVideo.id}`);
    }
  }, [currentVideo]);

  return (
    <div className="emerald-tutorials">
      <div className="emerald-tutorials__hint">
        Welcome to Emerald Income Alerts! Our premium program is tailored to
        help you achieve elite level trading skills. Start below now.
      </div>

      <div className="emerald-tutorials__header">
        <div className="emerald-tutorials__title">Emerald Income Alerts</div>
        <div className="emerald-tutorials__info">
          12 lessons • 3 hours 33 mins
        </div>
      </div>

      <VideoCardsContainer
        courses={allVideos}
        setCurrentVideo={setCurrentVideo}
        courseName="Emerald Inc. Alerts"
        from="emerald"
      />
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(EmeraldTutorials);
