// Action Types
export const LAYOUT_SET = "LAYOUT/SET";

// Action Creators
export const layoutSetter = (layoutMode) => {
  return {
    type: LAYOUT_SET,
    layoutMode,
  };
};

const INIT_STATE = {
  layoutMode: "new",
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LAYOUT_SET:
      return {
        ...state,
        layoutMode: action.layoutMode,
      };
    default:
      return state;
  }
};

export default reducer;
