import React from "react";

import "./TradeAlertsAndSignals.scss";
import TelegramDarkIcon from "../../OptionsAcademy/assets/Telegram.png";
import TelegramWhiteIcon from "../../OptionsAcademy/assets/Telegram-White.png";

const TradeAlertsAndSignals = ({ themeMode, url }) => {
  return (
    <div className="trade-alerts-signals">
      <div className="trade-alerts-signals-title">Trade Alerts and Signals</div>
      <div className="trade-alerts-signals-description">
        <img
          src={themeMode === "light" ? TelegramDarkIcon : TelegramWhiteIcon}
          alt="Telegram Icon"
          className="trade-alerts-signals-telegram-icon"
        />
        <div>
          <div className="trade-alerts-signals-join-community">
            Join the Community
          </div>
          <div className="trade-alerts-signals-signal-text">
            Bronco provides profitable trade alerts in our exclusive telegram
            group. Join the community and start making smarter trades.
          </div>
        </div>
      </div>

      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="trade-alerts-signals-button"
      >
        Join Telegram
      </a>
    </div>
  );
};

export default TradeAlertsAndSignals;
