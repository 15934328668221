import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

const ConfirmDeleteModal = ({ open, handleClose, handleChatDelete }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>
      {/* Use Typography with appropriate variant */}
      <Typography variant="h6">Confirm Delete</Typography>
    </DialogTitle>
    <DialogContent>
      {/* Use Typography instead of nesting <p> */}
      <Typography variant="body1">
        Are you sure you want to delete this chat?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="contained">Cancel</Button>
      <Button onClick={handleChatDelete} variant="contained" color="error">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteModal;
