import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Checkbox, IconButton } from "@material-ui/core";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

import PlayIcon from "../../../assets/images/icon-play.png";
import PlayIconLight from "../../../assets/images/icon-play-light.png";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VideoPopup from "./VideoPopup";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";

function TutorialVideoGroup({
  currentVideoGroup,
  userSettings,
  setUserSettings,
  themeMode,
  openTutorialMenu,
}) {
  const hardHistory = useHistory();
  const { userData, loading } = useAuth();
  const [open, setOpen] = useState(false);
  const [i, setI] = useState(0);

  const [videoData, setVideoData] = useState([
    /* FUNDAMENTAL ANALYSIS VIDEOS */
    {
      id: 1,
      title: "Financial Statement Analysis: Income Statement",
      src: "https://player.vimeo.com/video/808539123?h=a1238f4649&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Financial statement analysis is the process of analyzing a company's financial statements to make better economic decisions.",
      period: "14:26",
      checked: userSettings?.viewed_videos?.beginner?.video_1 || false,
      type: "fundamental_analysis",
    },
    {
      id: 2,
      title: "Financial Statement Analysis: Ratio Analysis",
      src: "https://player.vimeo.com/video/808539149?h=d9ca51781b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "By assessing the financial performance of a company, investors can be better informed about their investments.",
      period: "11:14",
      checked: userSettings?.viewed_videos?.beginner?.video_2 || false,
      type: "fundamental_analysis",
    },
    /* RISK MANAGET VIDEOS */
    {
      id: 3,
      title: "Money Management",
      src: "https://player.vimeo.com/video/808539178?h=21b302604e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Money management is key to successful trading. It involves setting goals, understanding risk, and making sound decisions. It is important to understand when to enter and exit trades, and a plan for managing losses.",
      period: "09:44",
      checked: userSettings?.viewed_videos?.beginner?.video_3 || false,
      type: "risk_management",
    },
    {
      id: 4,
      title: "Building A Trading Plan",
      src: "https://player.vimeo.com/video/808539194?h=a20cbada4b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Trading plans are essential for a successful trader. A trading plan should be well thought out and contain strategies for entry and exit, risk management, and overall trading objectives.",
      period: "07:25",
      checked: userSettings?.viewed_videos?.beginner?.video_4 || false,
      type: "risk_management",
    },
    {
      id: 5,
      title: "Investor Sentiment And Contracts Of Interest",
      src: "https://player.vimeo.com/video/808539203?h=68c2480c95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Managing risks using sentiment analysis and contracts is key to successful trading, increasing chances of success while minimizing losses.",
      period: "09:23",
      checked: userSettings?.viewed_videos?.beginner?.video_5 || false,
      type: "risk_management",
    },
    {
      id: 6,
      title: "Leveraging Dark Pool Prints As Support / Resistance Area",
      src: "https://player.vimeo.com/video/808539001?h=6b11138de2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Traders use support and resistance levels to manage risk and make informed trading decisions by setting stop-loss and take-profit orders, limiting losses and maximizing gains.",
      period: "10:08",
      checked: userSettings?.viewed_videos?.beginner?.video_6 || false,
      type: "risk_management",
    },

    /* TECHNICAL ANALYSIS VIDEOS */
    {
      id: 7,
      title: "Price Action And Order Types",
      src: "https://player.vimeo.com/video/808539016?h=d90b0f9fb2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "The first video will provide an introduction to technical analysis and its applications in trading. It will cover the keys to technical analysis, including chart patterns, indicators, and trading strategies.",
      period: "12:33",
      checked: userSettings?.viewed_videos?.beginner?.video_7 || false,
      type: "technical_analysis",
    },
    {
      id: 8,
      title: "Basic Concepts of Trend",
      src: "https://player.vimeo.com/video/808539036?h=ee5d37a769&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "This strategy helps traders take advantage of the momentum of the market and capitalize on it. It also helps traders avoid costly mistakes by trading against the trend.",
      period: "11:44",
      checked: userSettings?.viewed_videos?.beginner?.video_8 || false,
      type: "technical_analysis",
    },
    {
      id: 9,
      title: "Major Reversal Patterns",
      src: "https://player.vimeo.com/video/808539046?h=2b7608f4d3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "A major reversal pattern is a chart pattern in technical analysis that indicates a shift in the direction of a stock's price trend. It is used by traders to make decisions about when to buy and sell a stock.",
      period: "10:43",
      checked: userSettings?.viewed_videos?.beginner?.video_9 || false,
      type: "technical_analysis",
    },
    {
      id: 10,
      title: "Continuation Patterns",
      src: "https://player.vimeo.com/video/808539062?h=7be23f9a93&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Continuation patterns are technical analysis indicators used to identify potential changes in the direction of a stock's price. They are typically seen as a sign of a trend's continuation.",
      period: "09:33",
      checked: userSettings?.viewed_videos?.beginner?.video_10 || false,
      type: "technical_analysis",
    },
    {
      id: 11,
      title: "Moving Averages",
      src: "https://player.vimeo.com/video/808539080?h=18fc7ea583&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "This indicator is used to identify trends in the stock market and is especially useful for traders who are looking to leverage the power of averages in trading.",
      period: "10:39",
      checked: userSettings?.viewed_videos?.beginner?.video_11 || false,
      type: "technical_analysis",
    },
    {
      id: 12,
      title: "Oscillators",
      src: "https://player.vimeo.com/video/808539097?h=539ce92d84&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      description:
        "Oscillators are a powerful tool in trading, as they provide traders with insight into price trends. They are used by traders to identify potential buy and sell signals, and can be used to analyze the overall market trend.",
      period: "16:27",
      checked: userSettings?.viewed_videos?.beginner?.video_12 || false,
      type: "technical_analysis",
    },
  ]);

  const handleClose = (video) => {
    setOpen(false);
    const checked = videoData.map((copy) => ({
      ...copy,
      checked: copy.id === video.id ? true : copy.checked,
    }));
    setI((prev) => prev + 1);
    setVideoData(checked);
  };
  useEffect(() => {
    if (userData) {
      // set default settings for sidebar, send flag to not send network req
      setUserSettings(userData?.userSettings || userSettings, true);
    }
  }, [userData]);

  useEffect(() => {
    if (!loading) {
      const beginner = userSettings?.viewed_videos?.beginner || {
        video_1: false,
        video_2: false,
        video_3: false,
        video_4: false,
        video_5: false,
        video_6: false,
        video_7: false,
        video_8: false,
        video_9: false,
        video_10: false,
        video_11: false,
        video_12: false,
      };
      const mapped = videoData.map((item) => ({
        ...item,
        checked: !!beginner[`video_${item.id}`],
      }));
      setVideoData(mapped);
    }
  }, [loading]);

  useEffect(() => {
    if (i >= 1) {
      const beginner = videoData.reduce((acc, obj, i) => {
        if (i === 1) {
          return {
            [`video_${acc.id}`]: acc.checked,
            [`video_${obj.id}`]: obj.checked,
          };
        }
        return { ...acc, [`video_${obj.id}`]: obj.checked };
      });
      setUserSettings({
        ...userSettings,
        viewed_videos: {
          ...(userSettings?.viewed_videos || {}),
          beginner,
        },
      });
    }
  }, [JSON.stringify(videoData)]);

  const [selectedVideo, setSelectedVideo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const selectVideo = (index) => {
    setSelectedVideo(
      videoData.filter((video) => video.type === currentVideoGroup)[index],
    );
    setCurrentPage(index + 1);
    setOpen(true);
  };

  const moveToOtherTrack = (value) => {
    hardHistory.push(`/platinum-program/tutorials/${value}`);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setSelectedVideo(
      videoData.filter((video) => video.type === currentVideoGroup)[
        currentPage - 1
      ],
    );
  }, [currentPage, videoData]);

  return (
    <div className="videos-container">
      <div className="beginner-videos">
        <div
          onClick={openTutorialMenu}
          className="current-video-group-navigator"
        >
          <ArrowCircleLeftOutlinedIcon className="current-video-group-crumb" />{" "}
          Tutorials
        </div>
        <div className="beginner-track-text">
          {currentVideoGroup === "fundamental_analysis" && (
            <span>Fundamental Analysis</span>
          )}
          {currentVideoGroup === "risk_management" && (
            <span>Risk Management</span>
          )}
          {currentVideoGroup === "technical_analysis" && (
            <span>Technical Analysis</span>
          )}
        </div>

        {currentVideoGroup === "fundamental_analysis" && (
          <div className="building-text">
            Fundamental analysis is a method of evaluating investments by
            analyzing the economic, financial, and other qualitative and
            quantitative factors related to the asset.
          </div>
        )}
        {currentVideoGroup === "risk_management" && (
          <div className="building-text">
            Risk Management Trading is the art of managing risk to maximize
            returns. Here will learn how a good trader understands risk and make
            informed decisions. Learn to manage your risk and take control of
            your trading today.
          </div>
        )}
        {currentVideoGroup === "technical_analysis" && (
          <div className="building-text">
            Technical analysis in financial trading is the study of past market
            data to identify patterns and trends that can be used to forecast
            future market behaviour. It involves the use of charts, indicators,
            and other tools to analyze market conditions and make informed
            trading decisions.
          </div>
        )}
      </div>
      <div className="summary-bg">Summary</div>
      {currentVideoGroup === "fundamental_analysis" && (
        <div className="completely-text">
          Fundamental analysis is an important part of financial trading, and it
          is a method used to assess the intrinsic value of a security. This
          means that it looks at the underlying financial and economic factors
          that affect the value of a security. Fundamental analysis looks at
          many different factors which include the overall economy, the industry
          and sector of the security, the company’s financials and management,
          and the price and volume of the security itself.
        </div>
      )}

      {currentVideoGroup === "risk_management" && (
        <div className="completely-text">
          Risk management is an essential part of successful trading. It
          involves assessing and managing potential risks associated with
          trading activities, such as market fluctuations and potential losses.
          A trader must be aware of the potential risks involved in trading and
          be prepared to manage them in order to maximize their profits.
          <br />
          <br />
          Traders should also keep a log of their trades and review it regularly
          to assess their performance and identify any potential risks. As well
          as helping traders monitor their performance, this can also help them
          identify potential areas of improvement or mistakes that they may have
          made.
          <br />
          <br />
          Overall, risk management is an essential part of successful trading.
          It involves assessing and managing potential risks.
        </div>
      )}

      {currentVideoGroup === "technical_analysis" && (
        <div className="completely-text">
          Technical analysis is used in all forms of trading, from long-term
          investing to short-term day trading, and is a useful tool for
          predicting future price movements. By identifying support and
          resistance levels, traders can use these levels to enter or exit
          trades.
          <br />
          <br />
          Trend lines can help identify current direction of the market and
          potential support and resistance levels. Chart patterns, such as
          triangles and double tops, can be used to make trading decisions.
          Indicators such as moving averages and Bollinger bands can provide
          signals to enter or exit trades.
        </div>
      )}

      <div className="objective-text">Objectives</div>

      {currentVideoGroup === "risk_management" && (
        <ul className="ul-videos">
          <li className="list-item-videos">
            Identify support and resistance levels in price charts
          </li>
          <li className="list-item-videos">
            Utilize trend lines to predict future price movements
          </li>
          <li className="list-item-videos">
            Utilize different types of moving averages to assess price momentum
          </li>
          <li className="list-item-videos">
            Interpret and utilize candlestick patterns to make trading decisions
          </li>
          <li className="list-item-videos">
            Understand the concept of Elliott Wave Theory and Fibonacci
            Retracements
          </li>
        </ul>
      )}

      {currentVideoGroup === "fundamental_analysis" && (
        <ul className="ul-videos">
          <li className="list-item-videos">
            Understand the concept of risk and return in trading.
          </li>
          <li className="list-item-videos">
            Discover the different types of financial risks and how to manage
            them.
          </li>
          <li className="list-item-videos">
            Develop an understanding of the financial tools used to manage risk.
          </li>
          <li className="list-item-videos">
            Learn how to identify and analyze market trends and conditions.
          </li>
          <li className="list-item-videos">
            Understand the concept of hedging and its application in trading.
          </li>
          <li className="list-item-videos">
            Learn the different strategies used to manage risk in trading.
          </li>
          <li className="list-item-videos">
            Develop risk management policies and procedures.
          </li>
        </ul>
      )}

      {currentVideoGroup === "technical_analysis" && (
        <ul className="ul-videos">
          <li className="list-item-videos">
            Identify and interpret various chart patterns and indicators
          </li>
          <li className="list-item-videos">
            Analyze market trends and assess the implications for trading
            strategies
          </li>
          <li className="list-item-videos">
            Learn how to identify and capitalize on price reversals
          </li>
          <li className="list-item-videos">
            Analyze and interpret market volatility and how it affects trading
            strategies
          </li>
          <li className="list-item-videos">
            Develop an understanding of the implications of market sentiment and
            how to factor it into trading decisions
          </li>
        </ul>
      )}

      <div className="videos-text">Videos</div>
      <div className="video-table-container">
        {videoData &&
          videoData.filter((video) => video.type === currentVideoGroup) &&
          videoData
            .filter((video) => video.type === currentVideoGroup)
            .map((item, index) => {
              return (
                <div
                  className="video-table-row"
                  key={index}
                  onClick={selectVideo.bind(this, index)}
                >
                  <div className="flex-row align-center no-margin no-flex-wrap">
                    <IconButton>
                      <img
                        className="w-18"
                        src={themeMode === "light" ? PlayIconLight : PlayIcon}
                        alt="video-play-icon"
                      />
                    </IconButton>
                    <div className="table-video-text">{item.title}</div>
                  </div>

                  <div className="flex-row align-center no-margin no-flex-wrap">
                    <div className="time-stamp">{item.period}</div>
                    <div className="radio-video" name="radio-buttons">
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={
                          <CheckCircleIcon className="checked-icon" />
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          const checked = videoData.map((copy) => ({
                            ...copy,
                            checked:
                              copy.id === item.id
                                ? !item.checked
                                : copy.checked,
                          }));
                          setVideoData(checked);
                          setI((prev) => prev + 1);
                        }}
                        checked={item.checked}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
      {currentVideoGroup === "fundamental_analysis" && (
        <div className="tutorial-video-group-footer">
          <div className="tutorial-video-group-footer-item visibility-hidden">
            <ArrowCircleLeftOutlinedIcon className="arrow-circle-left-icon" />
            View track
          </div>
          <div
            className="tutorial-video-group-footer-item"
            onClick={moveToOtherTrack.bind(this, "risk_management")}
          >
            Risk Management{" "}
            <ArrowCircleRightOutlinedIcon className="arrow-circle-right-icon" />
          </div>
        </div>
      )}

      {currentVideoGroup === "risk_management" && (
        <div className="tutorial-video-group-footer">
          <div
            className="tutorial-video-group-footer-item"
            onClick={moveToOtherTrack.bind(this, "fundamental_analysis")}
          >
            <ArrowCircleLeftOutlinedIcon className="arrow-circle-left-icon" />
            Fundamental Analysis
          </div>
          <div
            className="tutorial-video-group-footer-item"
            onClick={moveToOtherTrack.bind(this, "technical_analysis")}
          >
            Technical Analysis{" "}
            <ArrowCircleRightOutlinedIcon className="arrow-circle-right-icon" />
          </div>
        </div>
      )}

      {currentVideoGroup === "technical_analysis" && (
        <div className="tutorial-video-group-footer">
          <div
            className="tutorial-video-group-footer-item"
            onClick={moveToOtherTrack.bind(this, "risk_management")}
          >
            <ArrowCircleLeftOutlinedIcon className="arrow-circle-left-icon" />
            Risk Management
          </div>
          <div className="tutorial-video-group-footer-item visibility-hidden">
            View Track{" "}
            <ArrowCircleRightOutlinedIcon className="arrow-circle-right-icon" />
          </div>
        </div>
      )}

      <VideoPopup
        open={open}
        handleClose={handleClose}
        video={selectedVideo}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totals={
          videoData.filter((video) => video.type === currentVideoGroup).length
        }
        setVideoData={setVideoData}
        videoData={videoData}
        setI={setI}
      />
    </div>
  );
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(TutorialVideoGroup);
