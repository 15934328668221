import React, { useState } from "react";
import { Menu, MenuItem, IconButton, ListItemIcon } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteModal from "./Modals/ConfirmDeleteModal";

const SubMenu = ({ onRename, onDelete, sessionId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleSubmenuClick = (event) => {
        event.stopPropagation(); // Prevent parent click events
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRename = () => {
        handleClose();
        if (onRename) onRename();
    };

    const open = Boolean(anchorEl);

    return (
        <div onClick={(e) => e.stopPropagation()} className="tgpt-submenu">
            <ConfirmDeleteModal
                open={deleteOpen}
                handleClose={() => setDeleteOpen(false)}
                handleChatDelete={() => {
                    onDelete(sessionId)
                    setDeleteOpen(false)
                }}
            />
            <IconButton size="small" onClick={handleSubmenuClick}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                classes={{
                    paper: "menu-paper",
                    list: "menu-list",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={handleRename} className="menu-item text-white">
                    <ListItemIcon className="menu-item-icon">
                        <DriveFileRenameOutlineIcon />
                    </ListItemIcon>
                    Rename
                </MenuItem>
                <MenuItem onClick={() => {
                    setDeleteOpen(true)
                    setAnchorEl(false)
                }}
                    className="menu-item text-red">
                    <ListItemIcon className="menu-item-icon">
                        <DeleteIcon />
                    </ListItemIcon>
                    Delete Chat
                </MenuItem>
            </Menu>
        </div>
    );
};

export default SubMenu;
