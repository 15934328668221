import React, { useState } from "react";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Collapse from "@mui/material/Collapse";
import { tradingAlertsData } from "./RealTimeTradingAlertsData";
import "./style.scss";

const RealTimeTradingAlertsComponent = ({ selectNavigation }) => {
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    let duplicatedItems = [...openItems];

    if (duplicatedItems.includes(index)) {
      duplicatedItems = duplicatedItems.filter((obj) => obj !== index);
    } else {
      duplicatedItems.push(index);
    }

    setOpenItems(duplicatedItems);
  };

  return (
    <div className="real-time-trading-alerts-component">
      <div
        className="real-time-trading-alerts-navigator"
        onClick={() => selectNavigation("livestream")}
      >
        <ArrowCircleLeftOutlinedIcon /> Main
      </div>

      <div className="real-time-trading-alerts-title">
        Real-Time Trade Alerts on Telegram
      </div>
      <div className="real-time-trading-alerts-guide">
        <strong style={{ color: "#FFFFFF", textDecoration: "underline" }}>
          Platinum Program Telegram Channel
        </strong>{" "}
        is designed to make trading easier for beginners and more lucrative for
        experienced traders. Whether users want to copy an expert trader or
        build and monetize their existing trades. To access the channel, click
        the link below.
      </div>

      <a
        href="https://t.me/+12TAYz7H9nhhMzk5"
        target="_blank"
        rel="noreferrer"
        className="platinum-program-telegram-button"
      >
        Platinum Program Channel
      </a>

      <div className="real-time-trading-alerts-guide">
        Here are 3 easy steps to setup your Telegram and access to Platinum
        Program Telegram Channel to receive real-time trade and movement
        notifications.
      </div>

      {tradingAlertsData.map((section, sectionKey) => (
        <div className="real-time-trading-alerts-section" key={sectionKey}>
          <div className="real-time-trading-alerts-section-title">
            {section.title}
          </div>

          {section.items.map((item) => (
            <div
              className={`real-time-trading-alerts-section-item ${openItems.includes(item.id) ? "active" : ""}`}
              onClick={toggleItem.bind(this, item.id)}
              key={item.id}
            >
              <div className="real-time-trading-alerts-section-item-main">
                {item.title}{" "}
                {openItems.includes(item.id) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(item.id)}>
                <div
                  className="real-time-trading-alerts-section-item-more"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                  onClick={(e) => e.stopPropagation()}
                />
              </Collapse>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RealTimeTradingAlertsComponent;
