import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const RADAR_TOKEN_LOAD = "RADAR/LOAD";
export const RADAR_TOKEN_LOAD_SUCCESS = "RADAR/LOAD/SUCCESS";
export const RADAR_TOKEN_LOAD_FAILURE = "RADAR/LOAD/FAILURE";

// Action Creators
export const radarTokenLoad = () => ({
  type: RADAR_TOKEN_LOAD,
});
export const radarTokenLoadSuccess = (data) => ({
  type: RADAR_TOKEN_LOAD_SUCCESS,
  data,
});
export const radarTokenLoadFailure = (error) => ({
  type: RADAR_TOKEN_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchRadarToken() {
  try {
    const response = yield axios.get(`${apiURL}/radar-token`, {
      withCredentials: true,
    });
    yield put(radarTokenLoadSuccess(response.data));
  } catch (error) {
    yield put(radarTokenLoadFailure(error));
  }
}

function* listenRadarTokenLoad() {
  yield takeEvery(RADAR_TOKEN_LOAD, fetchRadarToken);
}

// Root Saga
export function* saga() {
  yield all([fork(listenRadarTokenLoad)]);
}

const INIT_STATE = {
  radarToken: "",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RADAR_TOKEN_LOAD_SUCCESS:
      return {
        ...state,
        radarToken: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
