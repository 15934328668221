import React from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import TALogo from "../../assets/images/products/logo-w-text.png";
import TALogoD from "../../assets/images/products/logo-w-text-dark.png";

import TADiamond from "../../assets/images/diamond.png";
import TACalculatorImg from "../../assets/images/products/terminal_calculator.png";

import "./TradealgoProduct.scss";

const TradealgoProduct = ({
  title,
  description1,
  description2,
  buttonLink,
  type,
  logoAlwaysDark = false,
  isDarkLogo = false,
  themeMode,
}) => {
  return (
    <div className={`ta-product ${type}-product`}>
      <div className="ta-product__container">
        <div className="ta-product__inner">
          <div className="ta-product__header">
            {logoAlwaysDark === true ? (
              <img src={TALogoD} alt="ta-logo-d" className="ta-product-logo" />
            ) : isDarkLogo === true ? (
              themeMode === "light" ? (
                <img
                  src={TALogoD}
                  alt="ta-logo-d"
                  className="ta-product-logo"
                />
              ) : (
                <img src={TALogo} alt="ta-logo" className="ta-product-logo" />
              )
            ) : (
              <img src={TALogo} alt="ta-logo" className="ta-product-logo" />
            )}
          </div>
          <div className="ta-product__content">
            <div className="ta-product__title">{title}</div>
            <div className="ta-product__descriptions">
              <div className="ta-product__description1">{description1}</div>
              <div className="ta-product__description2">{description2}</div>
            </div>
          </div>
        </div>

        <Link to={buttonLink}>
          <Button className="ta-product__button" variant="contained">
            Learn More
          </Button>
        </Link>
      </div>

      {type === "terminal" && (
        <img
          src={TACalculatorImg}
          alt="ta-calculator"
          className="ta-product__calculator"
        />
      )}

      {type === "platinum" && (
        <img src={TADiamond} alt="ta-diamond" className="ta-product__diamond" />
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TradealgoProduct);
