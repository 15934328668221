import React, { useMemo, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./styles.css";
import { makeStyles } from "@material-ui/core";
import "../Chat/ChatContainer.scss";
import { connect } from "react-redux";
import clsx from "clsx";
import { findLongestName } from "../homePage/DashboardHeader";
import { useAuth } from "../../contexts/Auth";
import { apiURL, pyApiURL } from "../../appRedux/actions/helpers";
import { TradeGptWidget } from "tradegpt-widget";

const useStyles = makeStyles(() => ({
  card: {
    "& .MuiCardContent-root": {
      height: "100% !important",
    },
  },
}));

function WidgetWrapper({ onClose, themeMode, widgetTicker, companyTicker }) {
  const { user, userData } = useAuth();
  const classes = useStyles();
  const [fullscreen, setFullscreen] = useState(false);
  const userName = useMemo(() => findLongestName(userData), [userData]);
  const uid = useMemo(() => user?.uid, [user]);
  return (
    <div
      style={{
        position: "fixed",
        bottom: fullscreen ? "55px" : "75px",
        right: fullscreen ? "0px" : "20px",
        zIndex: 1499,
      }}
    >
      <Card
        className={clsx(
          classes.card,
          fullscreen ? "fullscreen-widget-ui" : "",
          "card-box-shadow",
        )}
        style={{
          height: fullscreen ? "auto" : "700px",
          width: "380px",
          position: "relative",
        }}
      >
        <CardContent style={{ padding: 0 }}>
          {uid && (
            <TradeGptWidget
              ticker={widgetTicker || companyTicker}
              userName={userName}
              user={userData}
              user_id={uid}
              splUrl={apiURL}
              pyApiUrl={pyApiURL}
              darkTheme={themeMode === "dark"}
              fullscreen={fullscreen}
              onToggleFullscreen={(value) => {
                setFullscreen(value);
              }}
              onCloseWidget={onClose}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

const stateToProps = (state) => ({
  widgetTicker: state.widgetTicker.symbol,
  companyTicker: state.symbol.symbol.value,
});

export default connect(stateToProps, null)(WidgetWrapper);
