import React, { useMemo } from "react";
import Chip from "@mui/material/Chip";
import { MenuItem, Select } from "@mui/material";

const labelLookup = {
  1: "Above Ask",
  2: "At Ask",
  3: "Lean Ask",
  4: "Below Bid",
  5: "At Bid",
  6: "Lean Bid",
  7: "In Between",
};

function BidAskSelect({ value, setValue, themeMode }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const calculateChipBackgroundColor = (value) => {
    switch (value) {
      case "1":
        return themeMode === "light" ? "#0D9488" : "#0D9488";
      case "2":
        return themeMode === "light" ? "rgb(255, 149, 0)" : "rgb(255, 149, 0)";
      case "3":
        return themeMode === "light" ? "#DB2777" : "#DB2777";
      case "4":
        return themeMode === "light" ? "#2563EB" : "#2563EB";
      case "5":
        return themeMode === "light" ? "rgb(0, 122, 255)" : "rgb(0, 122, 255)";
      case "6":
        return themeMode === "light" ? "#EA580C" : "#EA580C";
      case "7":
        return themeMode === "light" ? "#007bff" : "#007bff";
    }
  };

  const Component = useMemo(() => {
    return (
      <div className="bid-ask-select-wrapper">
        <div className="option-ticker-search-label">Filter</div>

        <Select
          value={value}
          onChange={handleChange}
          displayEmpty
          multiple
          renderValue={() => "Bid Ask Filters"}
          className="bid-ask-select"
        >
          <MenuItem value={"1"}>Above Ask</MenuItem>
          <MenuItem value={"2"}>At Ask</MenuItem>
          <MenuItem value={"3"}>Lean Ask</MenuItem>
          <MenuItem value={"4"}>Below Bid</MenuItem>
          <MenuItem value={"5"}>At Bid</MenuItem>
          <MenuItem value={"6"}>Lean Bid</MenuItem>
          <MenuItem value={"7"}>In Between</MenuItem>
        </Select>

        {!!value.length > 0 && (
          <div className="bid-ask-selected-options">
            {value.map((d, i) => {
              return (
                <Chip
                  key={i}
                  className="bid-ask-chip"
                  style={{ backgroundColor: calculateChipBackgroundColor(d) }}
                  deleteIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g opacity="0.7">
                        <path
                          d="M8.00016 1.33334C4.3135 1.33334 1.3335 4.31334 1.3335 8.00001C1.3335 11.6867 4.3135 14.6667 8.00016 14.6667C11.6868 14.6667 14.6668 11.6867 14.6668 8.00001C14.6668 4.31334 11.6868 1.33334 8.00016 1.33334ZM11.3335 10.3933L10.3935 11.3333L8.00016 8.94001L5.60683 11.3333L4.66683 10.3933L7.06016 8.00001L4.66683 5.60668L5.60683 4.66668L8.00016 7.06001L10.3935 4.66668L11.3335 5.60668L8.94016 8.00001L11.3335 10.3933Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  }
                  label={labelLookup[d]}
                  variant="outlined"
                  onDelete={() => {
                    let clone = [...value];
                    let data = clone.filter((x) => x !== d);
                    setValue(data);
                  }}
                />
              );
            })}

            <span
              className="clear-all-chips-button"
              onClick={() => {
                setValue([]);
              }}
            >
              Clear all
            </span>
          </div>
        )}
      </div>
    );
  }, [themeMode, value]);

  return Component;
}

export default BidAskSelect;
