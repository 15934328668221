import { Slider } from "@mui/material";
import { withStyles } from "@material-ui/styles";

const CustomSlide = withStyles({
  root: {
    color: "#E2E8F0 !important",
    height: "8px!important",
    padding: "18px 0!important",
  },
  rail: {
    opacity: "1!important",
  },
  track: {
    borderRadius: "6px!important",
    background: "#007BFF!important",
    border: "1px solid #007BFF!important",
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#007BFF;",
    border: "1px solid currentColor",
    boxShadow: "#007BFF 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#007BFF 0 2px 3px 1px",
    },
    color: "#007BFF",
  },
})(Slider);

export default function CustomSlider({
  value,
  min,
  step,
  max,
  valueLabelFormat,
  onChange,
}) {
  return (
    <CustomSlide
      value={value}
      min={min}
      step={step}
      max={max}
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      onChange={onChange}
      aria-labelledby="non-linear-slider"
    />
  );
}
