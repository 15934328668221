import React, { useMemo } from "react";
import { randomSelection } from "../helpers";
import { Skeleton } from "@mui/material";



const GetStartedPrompts = ({ isStarted, prompts, loading, followUpPrompts, ticker, onSendMessage, fullscreen = false }) => {
  const possiblePrompts = [
    { title: "Summarize", description: `the latest SEC filing for ${ticker}` },
    { title: "When is", description: `${ticker}'s upcoming earnings?` },
    { title: "Recent news", description: `related to ${ticker}` },
    { title: "Analysts' outlook", description: `on ${ticker}` },
    { title: "Do a technical analysis", description: `on ${ticker}` },
    { title: "Give me the earning date", description: `for ${ticker}` },
    { title: "Give me the top option recommendation", description: `for ${ticker}` },
    { title: "What are the interesting news", description: `happening for ${ticker}` },
    { title: "Do a technical analysis", description: `for ${ticker}` },
    { title: "Summarize the latest SEC filing", description: `for ${ticker}` },
    { title: "How did", description: `${ticker} perform compared to SPY` }
  ];

  const suggestionPrompts = useMemo(() => {
    return !!followUpPrompts?.length ? followUpPrompts : randomSelection(ticker ? possiblePrompts : prompts, 4) 
  }, [ticker, followUpPrompts, prompts]);

  return (
    <div className="tradegpt-widget__content__get_started">
      <div className="tradegpt-widget__content__get_started__title">
        {isStarted ? "Get Started with these Prompts" : "Related Prompts"}
      </div>
      <div className={`${fullscreen === false ? isStarted ? 'tradegpt-widget__content__get_started__items-vertical' : 'tradegpt-widget__content__get_started__items' : 'tradegpt-widget__content__get_started__items full-screen-view'}`}>
        {
          suggestionPrompts?.map((prompt, index) => loading && !ticker ? <Skeleton width={336} height={50} sx={{ borderRadius: '16px' }} /> : (
            <div onClick={() => onSendMessage(prompt.title + " " + prompt.description, true)} key={index} className="tradegpt-widget__content__get_started__item">
              <div className="tradegpt-widget__content__get_started__item__title">
                {prompt.title}
              </div>
              <div className="tradegpt-widget__content__get_started__item__description">
                {prompt.description}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default GetStartedPrompts;
