import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import ChatMessage from "../ChatMessage/ChatMessage";
import '@assets/index.css';
import '@assets/darkmode.css';
import '@assets/responsiveness.css';
import '@assets/feedbackModal.css';
import { CloseViewIcon, CollapseViewIcon, ExpandViewIcon, NewChatIcon, RecentPromptsIcon, TradeGPTLogo } from './Icons';
import axios from "axios";
import {  transformArray } from "../helpers";
import FeedbackModal from "./FeedbackModal";
import TradeGPTFooter from "./TradeGPTFooter";
import GetStartedPrompts from "./GetStartedPrompts";
import { IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import RecentPrompts from "./RecentPrompts";
import moment from "moment"
import { extractTitleAndDescription } from "../helpers";



const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied to clipboard");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};
const filterChats = (arr) => {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
  const sevenDaysAgo = moment().subtract(7, "days").format("YYYY-MM-DD");
  const thirtyDaysAgo = moment().subtract(1, "month").format("YYYY-MM-DD");
  const final = {
    "Today": [],
    "Yesterday": [],
    "Previous 7 Days": [],
    "Previous 30 Days": [],
    "Over 30 Days Ago": [],
  };

  arr.forEach((item) => {
    const date = moment(item.date).format("YYYY-MM-DD");
    if (moment(date).isSame(today)) {
      final["Today"].push(item);
    } else if (moment(date).isSame(yesterday)) {
      final["Yesterday"].push(item);
    } else if (
      moment(date).isSameOrAfter(sevenDaysAgo) &&
      !moment(date).isSame(yesterday) &&
      !moment(date).isSame(today)
    ) {
      final["Previous 7 Days"].push(item);
    } else if (
      moment(date).isSameOrAfter(thirtyDaysAgo) &&
      !moment(date).isSame(yesterday) &&
      !moment(date).isSame(today) &&
      !moment(date).isSameOrAfter(sevenDaysAgo)
    ) {
      final["Previous 30 Days"].push(item);
    } else {
      final["Over 30 Days Ago"].push(item);
    }
  });

  // Sort each category by date descending
  Object.keys(final).forEach((key) => {
    final[key].sort((a, b) => moment(b.date).diff(moment(a.date)));
  });

  return final;
};
function textEllipsis(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  }
  return text;
}
const App = ({ user, userName, user_id, splUrl, pyApiUrl, ticker, darkTheme = false, fullscreen = false, onToggleFullscreen, onCloseWidget }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [session_id, setSession_id] = useState(uuidv4());
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [actionMessage, setActionMessage] = useState({});
  const [followUpPrompts, setFollowUpPrompts] = useState([]);
  const [prevChats, setPrevChats] = useState([]);
  const [openNewChat, showOpenNewChatView] = useState(true); // default view is new chat view
  const [showRecentPrompts, setShowRecentPrompts] = useState(false); // show recent prompts widget instead of new chat view
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [searchTerm, setSearchTerm] = useState('');
  const abortControllerRef = useRef(null); // Use useRef for AbortController
  const [recentChatsLoading, setRecentChatsLoading] = useState(true);
  const [promptsLoading, setPromptsLoading] = useState(true);
  const [suggestionPrompts, setSuggestionPrompts] = useState([]);

  const chatMessagesMemo = useMemo(() => {
    return searchTerm?.length
      ? prevChats.filter(item =>
        item?.title
          ?.toLocaleLowerCase()
          ?.includes(searchTerm?.toLocaleLowerCase()),
      )
      : prevChats;
  }, [searchTerm, prevChats]);

  const filteredChats = useMemo(() => {
    return filterChats(chatMessagesMemo);
  }, [chatMessagesMemo])

  const currChatTitle = useMemo(() => {
    return chatMessagesMemo.find((item) => item.sessionId === session_id)?.title || "New Chat"
  }, [session_id, chatMessagesMemo])
  useEffect(() => {
    setIsLoading(false);
    onCancelRequest();
    setMessages([]);
  }, [ticker]);

  const onSendMessage = (text, suggested) => {
    if (!text) return;

    if (!isLoading) {
      setMessages((prev) => [
        ...prev,
        { isBot: false, output: text, outputLoading: false },
        { isBot: true, output: '', outputLoading: false },
      ]);
      fetchMessage(text, messages.length + 1, suggested); // Pass the index of the bot message
      setText('');
    }
  };

  const onCancelRequest = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null; // Reset the ref
      setIsLoading(false);
    }
  }, []);

  async function fetchPastChats() {
    setRecentChatsLoading(true);
    const { data } = await axios.get(`${splUrl}/trade-chats-list/${user_id}`, { withCredentials: true });
    setPrevChats(data);
    setRecentChatsLoading(false);
  }
  async function onRenameTitle(session_id, session_title) {
    await axios.put(`${splUrl}/tradegpt-update-chat-title`, {
      session_title, session_id,
    }, { withCredentials: true });
    await fetchPastChats()
  }
  async function onDeleteChat(session_id) {
    showNewChatView()
    await axios.delete(`${splUrl}/tradegpt-delete-chat`, {
      data: {
        user_id,
        session_id,
      },
      withCredentials: true,
    });
    await fetchPastChats()
  }
  useEffect(() => {
    if (!!user_id) {
      fetchPastChats();
    }
  }, [ticker, user_id]);
  async function fetchPrompts() {
    setPromptsLoading(true)
    const { data } = await axios.get(`${splUrl}/tradegpt-recommended-prompts?limit=10`, { withCredentials: true });
    const prompts = data.map((item) => ({
      ...extractTitleAndDescription(item.query)
    }));
    setSuggestionPrompts(prompts);
    setPromptsLoading(false);
  }

  useEffect(() => {
    fetchPrompts()
  },[])
  const fetchMessage = useCallback(async (user_query, botMessageIndex, suggested) => {
    setIsLoading(true);
    abortControllerRef.current = new AbortController(); // Assign new AbortController
    const { signal } = abortControllerRef.current;
    let accumulatedMessage = '';

    try {
      const query = {
        user_query,
        session_id,
        chat_id: uuidv4(),
        user_id,
        input_type: suggested ? "recommend" : "user_type",
        ticker
      };
      const response = await fetch(`${pyApiUrl}/v1/assistant`, {
        method: 'POST',
        signal, // Pass the signal
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(query),
      });

      if (!response.body) {
        throw new Error('No response body');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // Fetch final output after streaming is done
          const finalResponse = await fetch(`${pyApiUrl}/v1/get_stream_output`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(query),
          });

          if (!finalResponse.ok) {
            throw new Error('Failed to fetch final output');
          }

          const finalData = await finalResponse.json();

          if (finalData) {
            const followUp = finalData.follow_up ?? [];
            const customFollowUp = followUp.map((item) => ({
              ...item,
              description: item.details
            }));

            setFollowUpPrompts(customFollowUp);
          }
          if (messages.length === 0) {
            fetchPastChats()
          }
          setMessages((prev) => {
            const updatedMessages = [...prev];
            updatedMessages[botMessageIndex] = {
              ...updatedMessages[botMessageIndex],
              output: accumulatedMessage,
              outputLoading: false,
              chat_id: finalData.chat_id
            };
            return updatedMessages;
          });
          break;
        }
        const chunk = decoder.decode(value);
        accumulatedMessage += chunk;
        setMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[botMessageIndex] = {
            ...updatedMessages[botMessageIndex],
            output: accumulatedMessage,
            outputLoading: true
          };
          return updatedMessages;
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [messages]);

  function scrollIntoView() {
    queueMicrotask(() => {
      // queueMicrotask to allow markdown formatting to kick in so that it can scroll
      const messagesContainer = document.querySelector('.tradegpt-widget__content__messages');
      if (messagesContainer) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    });
  }

  useEffect(() => {
    scrollIntoView();
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSendMessage(text, false);
    }
  };

  const handleCopy = (text) => {
    copyToClipboard(text);
  };

  const handleLike = (message) => {
    setActionMessage(message);
    setIsLiked(true);
    setModalOpen(true);
  };

  const handleDislike = (message) => {
    setActionMessage(message);
    setIsLiked(false);
    setModalOpen(true);
  };

  const onFeedbackModalClose = () => {
    setActionMessage(null);
    setModalOpen(false);
  };

  const showNewChatView = () => {
    onCancelRequest(); // Abort any ongoing requests
    showOpenNewChatView(true);
    setShowRecentPrompts(false);
    setSession_id(uuidv4())
    setMessages([]);
  };

  const showRecentPromptsViews = () => {
    showOpenNewChatView((prev) => !prev);
    setShowRecentPrompts((prev) => !prev);
  };

  useEffect(() => {
    if (isMobile && fullscreen) {
      if (onToggleFullscreen) {
        onToggleFullscreen(false);
        setDrawerOpen(true);
      }
    }
  }, [isMobile, fullscreen, onToggleFullscreen]);
  async function fetchPastSession(sessionId) {
    onCancelRequest(); // Abort any ongoing requests
    const { data } = await axios.get(`${splUrl}/tradegpt-session/${sessionId}`, { withCredentials: true });
    const transformed = transformArray(data)
    setMessages(transformed)
    showRecentPromptsViews()
  }
  return (
    <div className={`${darkTheme ? 'tradegpt-dark-widget' : ''} tradegpt-widget relative`}>
      {
        fullscreen && !isMobile && (
          <div className={`tradegpt-widget__sidebar ${drawerOpen ? 'display-flex' : 'display-hidden'}`}>
            <div className="tradegpt-widget__sidebar__header">
              {/* <TradeGPTLogo width={61} height={60}/>
              <div className="tradegpt-widget__sidebar__header__title">AI Trade Assistant</div>
              <div className="tradegpt-widget__sidebar__header__splitter" /> */}
              <div className="tradegpt-widget__sidebar__header__title">
                <TradeGPTLogo />
                <span>TradeGPT</span>
              </div>

              <Tooltip
                arrow
                title="New Chat"
                placement="bottom"
              >
                <IconButton
                  className="tradegpt-widget__header__action_button"
                  onClick={() => showNewChatView()}
                >
                  <NewChatIcon />
                </IconButton>
              </Tooltip>


            </div>

            <RecentPrompts loading={recentChatsLoading} onRenameTitle={onRenameTitle} onDeleteChat={onDeleteChat} setSession={setSession_id} currSessionId={session_id}
              fullscreen={fullscreen} fetchSession={fetchPastSession} chats={filteredChats} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

            {/* <SuggestedPrompts suggestionPrompts={suggestionPrompts} onSendMessage={onSendMessage} /> */}
          </div>
        )
      }

      {
        fullscreen && !isMobile && (
          <Tooltip
            title={`${drawerOpen ? "Close" : "Open"} sidebar`}
            placement="right"
          >
            <div
              onClick={() => setDrawerOpen(prev => !prev)}
              className={`${isMobile ? "display-hidden" : "display-flex"} sidebar-carrot ${!drawerOpen ? "tradegpt-widget__sidebar_closed closed" : ""}`}
            >
              <div className="sidebar-carrot-inner">
                <div className="sidebar-carrot-inner-content">
                  <div className="carrot-translator translator-a"></div>
                  <div className="carrot-translator translator-b"></div>
                </div>
              </div>
            </div>
          </Tooltip>
        )
      }

      <div className={`tradegpt-widget__content ${fullscreen ? !drawerOpen ? 'full-screen-view tradegpt-widget-full-width' : 'full-screen-view' : ''}`}>
        <div className="tradegpt-widget__header">
          <Tooltip title={currChatTitle}>
            <div className="tradegpt-widget__header__title">{textEllipsis(currChatTitle, fullscreen ? 55 : 25)}</div>
          </Tooltip>
          <div className="tradegpt-widget__header__action_buttons">
            {
              !fullscreen && (
                <>
                  <Tooltip
                    arrow
                    title="New Chat"
                    placement="bottom"
                  >
                    <IconButton
                      className="tradegpt-widget__header__action_button"
                      onClick={() => showNewChatView()}
                    >
                      <NewChatIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    arrow
                    title="Recent Chats"
                    placement="bottom"
                  >
                    <IconButton
                      className="tradegpt-widget__header__action_button"
                      onClick={() => showRecentPromptsViews()}
                    >
                      <RecentPromptsIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )
            }

            {
              fullscreen ? (
                <Tooltip
                  arrow
                  title={'Switch to Panel'}
                  placement="bottom"
                >
                  <IconButton
                    className="tradegpt-widget__header__action_button"
                    onClick={() => onToggleFullscreen && onToggleFullscreen(!fullscreen)}
                  >
                    <CollapseViewIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  arrow
                  title={'Full Page Chat'}
                  placement="bottom"
                >
                  <IconButton
                    className="tradegpt-widget__header__action_button"
                    onClick={() => onToggleFullscreen && onToggleFullscreen(!fullscreen)}
                  >
                    <ExpandViewIcon />
                  </IconButton>
                </Tooltip>
              )
            }

            <Tooltip
              arrow
              title="Close"
              placement="bottom"
            >
              <IconButton
                className="tradegpt-widget__header__action_button"
                onClick={() => onCloseWidget && onCloseWidget()}
              >
                <CloseViewIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {
          (openNewChat || fullscreen) && (
            <div className="tradegpt-widget__content__messages">
              {
                messages.length === 0 ? (
                  <div className={`tradegpt-widget__content-start-message ${fullscreen ? 'full-screen-view' : ''}`}>How can I help you today?</div>
                ) : (
                  messages.map((message, index) => (
                    <div key={index} className={`tradegpt-widget__content__message`}>
                      <div className="tradegpt-widget__content__message__text">
                        <ChatMessage
                          fullscreen={fullscreen}
                          key={index}
                          userName={userName}
                          user={user}
                          onCopy={handleCopy}
                          onLike={() => { handleLike(message) }}
                          onDislike={() => { handleDislike(message) }}
                          loading={isLoading && message?.isBot && !message.outputLoading && index === messages.length - 1} // Only pass loading to the last bot message when not streaming
                          message={message}
                          showActions={!isLoading && messages.length > 1 && (index + 1) === messages.length && message?.isBot}
                          darkTheme={darkTheme}
                        />
                      </div>
                    </div>
                  ))
                )
              }
              {
                !isLoading && (
                  <GetStartedPrompts
                    loading={promptsLoading}
                    prompts={suggestionPrompts}
                    isStarted={messages.length === 0}
                    followUpPrompts={followUpPrompts}
                    ticker={ticker}
                    onSendMessage={onSendMessage}
                    fullscreen={fullscreen}
                  />
                )
              }
            </div>
          )
        }

        {
          showRecentPrompts && !fullscreen && (
            <RecentPrompts loading={recentChatsLoading} onRenameTitle={onRenameTitle} onDeleteChat={onDeleteChat} setSession={setSession_id} currSessionId={session_id} fullscreen={fullscreen} fetchSession={fetchPastSession} chats={filteredChats} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          )
        }

        <TradeGPTFooter showRecentPromptsViews={showRecentPromptsViews} text={text} setText={setText} handleKeyDown={handleKeyDown} isLoading={isLoading} onSendMessage={onSendMessage} onCancelRequest={onCancelRequest} fullscreen={fullscreen} showRecentPrompts={showRecentPrompts} setShowRecentPrompts={setShowRecentPrompts} />
      </div>

      <FeedbackModal splUrl={splUrl} isLiked={isLiked} open={modalOpen} onClose={onFeedbackModalClose} message={actionMessage} />
    </div>
  )
}
export default App;