import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const OAD_LOAD_TOP = "OAD/LOAD/TOP";
export const OAD_LOAD_TOP_SUCCESS = "OAD/LOAD/TOP/SUCCESS";
export const OAD_LOAD_TOP_FAILURE = "OAD/LOAD/TOP/FAILURE";

// Action Creators
export const optionAlertDatesLoad = () => ({
  type: OAD_LOAD_TOP,
});
export const optionAlertDatesLoadSuccess = (data) => ({
  type: OAD_LOAD_TOP_SUCCESS,
  data,
});
export const optionAlertDatesLoadFailure = (error) => ({
  type: OAD_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchOptionAlertDates() {
  try {
    const response = yield axios.get(`${apiURL}/swing-trades-options-dates`, {
      withCredentials: true,
    });
    yield put(optionAlertDatesLoadSuccess(response.data));
  } catch (error) {
    yield put(optionAlertDatesLoadFailure(error));
  }
}

function* listenOptionAlertDatesLoad() {
  yield takeEvery(OAD_LOAD_TOP, fetchOptionAlertDates);
}

// Root Saga
export function* saga() {
  yield all([fork(listenOptionAlertDatesLoad)]);
}

const INIT_STATE = {
  dates: [],
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OAD_LOAD_TOP_SUCCESS:
      return {
        ...state,
        dates: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
