import React from "react";
import { Link } from "react-router-dom";
import logoIcon from "../../../assets/images/logo.png";
import logoIconWithText from "../../../assets/images/logo-text.png";
import logoDarkIcon from "../../../assets/images/logo-black.png";
import logoWhiteIcon from "../../../assets/images/logo-white.png";

export const TagLink = ({ className, label, to }) => {
  return (
    <a className={`tag-link ${className}`} href={to}>
      {label}
    </a>
  );
};

export const ArrowLink = (props) => {
  const { to, label, className } = props;

  return (
    <Link to={to} className={`${className}`}>
      {label}→
    </Link>
  );
};

export const LogoIcon = () => (
  <img src={logoIcon} alt="logo" className="logo-icon" />
);

export const LogoDarkIcon = () => (
  <img src={logoDarkIcon} alt="logo" className="logo-icon" />
);
export const LogoWhiteIcon = () => (
  <img src={logoWhiteIcon} alt="logo" className="logo-icon" />
);
export const LogoIconWithText = () => (
  <img src={logoIconWithText} alt="logo" className="logo-icon" />
);
