import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { IconButton } from "@mui/material";

const VideoCard = ({
  setCurrentVideo,
  courseName,
  title,
  length,
  card,
  from,
  type,
}) => {
  return (
    <div
      onClick={() => setCurrentVideo(card)}
      className={`video-card ${from !== "options" ? "intermediate" : type}-bg`}
    >
      <div className="content-card-wrap flex-col">
        <div className="course-tab-title">{courseName}</div>
        <div className="course-title-card">{title}</div>
        <div className="course-length">{length}</div>
      </div>
      <div className="view-video">
        View Video
        <IconButton aria-label="delete" size="small">
          <ArrowRightAltIcon sx={{ color: "white" }} fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
};

export default VideoCard;
