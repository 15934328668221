import * as React from "react";

const TradeAlertIcon = ({ fill }) => (
  <svg
    width="48"
    height="46"
    viewBox="0 0 48 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9537_86556)">
      <path
        d="M36.0469 13.7546L40.5165 18.0341L36.0469 22.3135L35.6697 22.6746L36.0468 23.0358L40.5166 27.3171L36.0469 31.5967L35.6697 31.9578L36.0469 32.319L40.5165 36.5984L38.4705 38.5574L31.5775 31.9561L36.0471 27.6784L36.4244 27.3173L36.0472 26.9561L31.5774 22.6748L36.0472 18.3952L36.4244 18.0341L36.0472 17.6729L31.5775 13.3935L38.4722 6.79386L40.5167 8.75268L36.0469 13.0323L35.6697 13.3934L36.0469 13.7546ZM25.3349 7.48396L25.3572 7.51011C25.4244 7.58872 25.4585 7.68363 25.4587 7.77843V37.5716V37.5719C25.4587 37.6501 25.4357 37.7283 25.3899 37.7977C25.3439 37.8673 25.2761 37.926 25.1921 37.9641C25.108 38.0022 25.0129 38.0172 24.9185 38.0061C24.8242 37.995 24.7373 37.9587 24.6674 37.9039L24.6671 37.9037L14.3017 29.7793L14.1658 29.6728L13.9931 29.6728L6.37533 29.6747C6.37529 29.6747 6.37525 29.6747 6.37522 29.6747C5.98164 29.6747 5.60832 29.5247 5.33636 29.2644C5.06511 29.0047 4.91699 28.6572 4.91699 28.2997V17.0497C4.91699 16.6922 5.06511 16.3447 5.33636 16.085L4.99242 15.7258L5.33636 16.085C5.60835 15.8246 5.98171 15.6747 6.37533 15.6747H13.9913H14.1638L14.2996 15.5683L24.6671 7.44578L24.6675 7.44546C24.7158 7.4075 24.7727 7.37815 24.8351 7.36007C24.8975 7.34197 24.9635 7.33574 25.0291 7.34198C25.0946 7.34822 25.1574 7.3667 25.2139 7.39569L25.2374 7.34989L25.2139 7.39569C25.2599 7.4193 25.3006 7.4493 25.3349 7.48396ZM22.542 13.7122V12.6854L21.7337 13.3186L15.217 18.4228H8.33366H7.83366V18.9228V26.4247V26.9248L8.33379 26.9247L15.217 26.9229L21.7336 32.0289L22.542 32.6623V31.6353V13.7122Z"
        fill={fill}
        stroke={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_9537_86556">
        <rect
          width="47"
          height="45"
          fill="white"
          transform="translate(0.5 0.171875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default TradeAlertIcon;
