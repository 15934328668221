import React, { useState } from "react";
import "./index.scss";
import TerminalThumbnail from "./assets/TerminalThumbnail.png";
import PlatThumbnail from "./assets/PlatThumbnail.png";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function FreeTier() {
  const [tab, setTab] = useState("premium");
  const hardHistory = useHistory();

  return (
    <div className="free-tier-wrapper">
      <div className="platinum-program-page-navigator mw-420">
        <div
          className={`free-tier-nav-item ${tab === "premium" ? "active" : ""}`}
          onClick={() => setTab("premium")}
        >
          Premium
        </div>
        <div
          className={`free-tier-nav-item ${tab === "mentorships" ? "active" : ""}`}
          onClick={() => {
            setTab("mentorships");
            hardHistory.push("/options-mentorship-signup");
          }}
        >
          Mentorships
        </div>
        <div
          className={`free-tier-nav-item ${tab === "academy" ? "active" : ""}`}
          onClick={() => {
            setTab("academy");
            hardHistory.push("/options-academy-signup");
          }}
        >
          Academy
        </div>
      </div>
      <div className="prem-programs">Premium Programs</div>
      <div className="free-cards-wrapper">
        <div className="free-card">
          <div className="card-img">
            <img src={TerminalThumbnail} className="term-img" />
          </div>
          <div className="free-card-title">TradeAlgo Terminal</div>
          <div className="free-desc">
            Gain access to powerful trading tools and resources that will help
            you make informed trading decisions. Our platform is designed to
            help traders of all levels, whether you&apos;re a beginner or an
            experienced trader.
          </div>
          <div className="card-price">
            $2,995
            <span className="usd"> USD</span>
            <div className="monthly">/ annually</div>
          </div>
          <div className="card-button-wrap">
            <Button
              onClick={() =>
                window.open(
                  "https://checkout.tradealgo.com/OTesZx3zJo37D6UnR0vGPjMCQDmHx4cazhk-RsPLuaV_qzfh",
                  "_blank",
                )
              }
              className="upgrade-free-button"
            >
              Upgrade Account
            </Button>
          </div>
        </div>
        <div className="free-card">
          <div className="card-img">
            <img src={PlatThumbnail} className="term-img" />
          </div>
          <div className="free-card-title">Platinum Program</div>
          <div className="free-desc">
            Designed to give you an advantage in the options market. Our system
            will teach you how to make profits consistently by recognizing
            market trends and spotting those sweet high-probability trades. Our
            step-by-step approach makes options trading a breeze!
          </div>
          <div className="card-button-wrap">
            <Button
              className="upgrade-free-button"
              onClick={() => hardHistory.push("/platinum-program/sign-up")}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeTier;
