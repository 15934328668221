import React, { useEffect, useState } from "react";
import TickersList from "../TickersList/TickersList";
import TickersInfo from "../TickersInfo/TickersInfo";
import "./TopPicksOfTheDay.scss";
import { connect } from "react-redux";
import { topPicksLoad } from "../../../appRedux/ducks/topPicks";
import { widgetSymbolSetter } from "../../../appRedux/ducks/widgetTicker";

const TopPicksOfTheDay = ({
  loading,
  topPicks,
  loadTopPicks,
  setWidgetSymbol,
}) => {
  const [activeTicker, setActiveTicker] = useState({});
  const [timeframe, setTimeframe] = useState("24h");
  const getTopPicks = () => {
    loadTopPicks(10, timeframe);
  };

  useEffect(() => {
    if (activeTicker?.ticker) {
      setWidgetSymbol(activeTicker?.ticker);
    }
  }, [activeTicker?.ticker]);

  useEffect(() => {
    return () => setWidgetSymbol("");
  }, []);

  useEffect(() => {
    getTopPicks();
  }, [timeframe]);

  return (
    <div className="top-picks-of-the-day">
      <div className="top-picks-of-the-day__title">
        Top Picks Of The Day
        <span className="beta-badge">Beta</span>
      </div>
      <div className="top-picks-of-the-day__content">
        <div className="top-picks-of-the-day__content__body">
          <TickersList
            refetch={getTopPicks}
            loading={loading}
            data={topPicks}
            ticker={activeTicker}
            setTicker={setActiveTicker}
            timeframe={timeframe}
            setTimeframe={setTimeframe}
          />
          <TickersInfo activeTicker={activeTicker} loading={loading} />
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  topPicks: state.topPicks.topPicks,
  loading: state.topPicks.loading,
});
const dispatchToProps = (dispatch) => ({
  loadTopPicks: (limit, timeframe) => dispatch(topPicksLoad(limit, timeframe)),
  setWidgetSymbol: (symbol) => dispatch(widgetSymbolSetter(symbol)),
});
export default connect(stateToProps, dispatchToProps)(TopPicksOfTheDay);
