import React, { useState } from "react";
import { courseDataMap, courseTitleMap } from "./optionsVideoMetadata";
import CourseDropdown from "./CourseDropdown";
import Button from "@mui/material/Button";
import ML from "./assets/Machine-Learning.png";
import MlWhite from "./assets/Machine-Learning-White.png";
import { connect } from "react-redux";

function VideoPage({
  setCurrentVideo,
  currentVideo,
  courseTab,
  setCourseTab,
  allVideos,
  themeMode,
}) {
  const [currentTab, setCurrentTab] = useState("overview");
  return (
    <div className="video-page-wrapper">
      <div className="video-section">
        <span className="video-course-title">{currentVideo.title}</span>
        <iframe
          className="course-event-video"
          src={currentVideo?.videoUrl}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Livestream Learning"
          frameBorder="0"
        ></iframe>
        <div className="course-tabs-wrapper">
          <span
            onClick={() => setCurrentTab("overview")}
            className={`course-tabs ${currentTab === "overview" && "active-course-tab"}`}
          >
            Overview
          </span>
          <span
            onClick={() => setCurrentTab("downloads")}
            className={`course-tabs ${currentTab === "downloads" && "active-course-tab"}`}
          >
            Downloads
          </span>
        </div>
        <div className="seperator" />
        {currentTab === "overview" && (
          <div className="overview-section">
            <div className="video-description-wrapper">
              <span>{currentVideo.length}</span>
              <span> | </span>
              <span>{courseTitleMap[courseTab]}</span>
            </div>
            <div className="tut-descript">Tutorial Description </div>
            In this tutorial, we will be covering the following topics:
            {courseDataMap[courseTab]?.find(
              (item) => item.title === currentVideo.title,
            )?.description || ""}
          </div>
        )}
        {currentTab === "downloads" && (
          <div className="overview-section">
            <div className="tut-descript">Download Slides Here </div>
            <a
              href={currentVideo.pdf}
              download={currentVideo.title}
              target="_blank"
              rel="noreferrer"
            >
              {currentVideo.title}.pptx
            </a>
          </div>
        )}
        <div className="description-blocks mt-20 br-10">
          <span className="title">Trading Courses</span>
          <div className="image-title-description">
            <img src={themeMode === "light" ? ML : MlWhite} className="bell" />
            <div className="title-description">
              <span className="inner-title">Video Tutorials</span>
              <span className="inner-description">
                Learn about options and trading techniques.
              </span>
            </div>
          </div>
          <Button
            onClick={() => setCurrentVideo({})}
            className="supports-widget-item-button"
          >
            View Courses
          </Button>
        </div>
      </div>
      <div className="playlist-section">
        <div className="playlist-dropdown-wrapper">
          <span className="current-playlist">Current Playlist</span>
          <div className="course-dropdown">
            <CourseDropdown
              setCurrentVideo={setCurrentVideo}
              setCourseTab={setCourseTab}
              courseTab={courseTab}
            />
          </div>
        </div>
        <div className="playlist-video-section">
          {allVideos
            .sort((a, b) =>
              a.title === currentVideo.title
                ? -1
                : b.title === currentVideo.title
                  ? 1
                  : 0,
            )
            .map((video, index) => (
              <div
                key={index}
                onClick={() => setCurrentVideo(video)}
                className="video-block"
              >
                <div
                  id={
                    courseTab === "fundamentals"
                      ? video.src
                      : video.title.toLowerCase().split(" ").join("-")
                  }
                  className={`video-preview`}
                >
                  {video.title === currentVideo.title && (
                    <div className="now-playing"> Now Playing </div>
                  )}
                </div>
                <div className="video-text-container">
                  <div className="video-text-title"> {video.title}</div>
                  <div className="video-text-duration"> {video.length} </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(VideoPage);
