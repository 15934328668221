import React from "react";
import Modal from "@mui/material/Modal";
import { IconButton } from "@material-ui/core";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import YoutubeVideo from "../../../assets/images/alpha_youtube.png";
import "./videoPopup.scss";

const VideoPopup = ({
  open,
  handleClose,
  video,
  totals,
  currentPage,
  setCurrentPage,
  setVideoData,
  videoData,
  setI,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => handleClose(video)}
      aria-labelledby="video-popup"
    >
      <div className="video-popup-content">
        <IconButton
          className="video-popup-close-button"
          onClick={() => handleClose(video)}
        >
          <CloseIcon />
        </IconButton>

        <div className="video-popup-title">{video.title}</div>
        <div className="video-popup-description">
          <span>
            {video && video.description
              ? video.description
              : "Learn the details of an options contract, including the rights and obligations of the two parties involved."}
          </span>
          <span className="video-popup-index">
            Video {currentPage} of {totals}
          </span>
        </div>

        <div className="video-popup-progress-bar">
          <div
            className="video-popup-progress-bar-inner"
            style={{ width: `calc(100% / ${totals} * ${currentPage})` }}
          ></div>
        </div>

        <div className="video-popup-ele">
          {video.src ? (
            <div>
              <iframe
                className="tutorial-group-video"
                src={video.src}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={video.title}
                frameBorder="0"
              ></iframe>
            </div>
          ) : (
            <img src={YoutubeVideo} alt="video-element" />
          )}
        </div>

        <div className="video-popup-footer">
          <Button
            className={`video-popup-footer-action-button ${currentPage === 1 ? "visibility-hidden" : ""}`}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            <ChevronLeftIcon /> Back
          </Button>
          <Button
            className={`video-popup-footer-action-button ${currentPage === totals ? "visibility-hidden" : ""}`}
            onClick={() => {
              const checked = videoData.map((copy) => ({
                ...copy,
                checked: copy.id === video.id ? true : copy.checked,
              }));
              setI((prev) => prev + 1);
              setVideoData(checked);
              setCurrentPage(currentPage + 1);
            }}
          >
            Next <ChevronRightIcon />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VideoPopup;
