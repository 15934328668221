import React from "react";

import "./SignUpHint.scss";

const SignUpHint = () => {
  return (
    <div className="sign-up-hint">
      <div className="platinum-program-big-title">
        Get the Inside Scoop on Earnings Surprises Before Everyone Else.
      </div>
      <div className="platinum-program-small-title">
        Hey there, tired of feeling lost in the options market and not making
        any gains? Well, check out our awesome new option trading program!
        It&apos;s designed to give you an advantage in the options market.
        <br />
        <br />
        Our system will teach you how to make profits consistently by
        recognizing market trends and spotting those sweet high-probability
        trades. And the best part? Our step-by-step approach makes options
        trading a breeze! no more stress, just financial success. So, what are
        you waiting for? Let&apos;s start trading!
        <br />
        <br />
        Whether you&apos;re a beginner or an experienced trader, the Platinum
        Program is the solution you&apos;ve been searching for. Say goodbye to
        uncertainty and hello to consistent profits with the Platinum Program.
      </div>

      <div className="platinum-program-big-title">Key Benefits</div>
      <div className="platinum-program-color-title">What You Will Learn:</div>
      <div className="platinum-program-ul">
        <li className="platinum-program-li">
          <span>Comprehensive training program</span>
        </li>
        <li className="platinum-program-li">
          <span>
            Daily live streams with Platinum trading expert Eric Armenteros.
          </span>
        </li>
        <li className="platinum-program-li">
          <span>
            Daily Live Q&A section for clarifying doubts and getting answers to
            questions
          </span>
        </li>
        <li className="platinum-program-li">
          <span>
            Daily trade alerts for valuable insights and recommendations
          </span>
        </li>
        <li className="platinum-program-li">
          <span>
            Step-by-step approach to options trading that takes the guesswork
            out of the process
          </span>
        </li>
        <li className="platinum-program-li">
          <span>
            Opportunities to learn from a seasoned options trader in real-time
          </span>
        </li>
        <li className="platinum-program-li">
          <span>Access to a supportive community of like-minded traders</span>
        </li>
        <li className="platinum-program-li">
          <span>
            Improved understanding of market trends and high-probability trade
            opportunities
          </span>
        </li>
        <li className="platinum-program-li">
          <span>Increased confidence and consistency in options trading</span>
        </li>
      </div>
    </div>
  );
};

export default SignUpHint;
