import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import RealTimeTradingAlertsWidget from "./RealTimeTradingAlertsWidget";
import SupportsWidget from "./SupportsWidget";
import PastVideos from "./PastVideos";
import RealTimeTradingAlertsComponent from "./RealTimeTradingAlertsComponent";
import SupportComponent from "./SupportComponent";
import Collapse from "@mui/material/Collapse";
import AuthorAvatar from "../../assets/images/eric.jpg";
import Rome from "../../assets/images/Rome.png";
import "./index.scss";
import TutorialVideoGroup from "./TutorialVideoGroup";

const PlatinumProgram = () => {
  const [currentNav, setCurrentNav] = useState("livestream");
  const [isTutorialsToggle, setIsTutorialsToggle] = useState(false);
  const [currentVideoGroup, setCurrentVideoGroup] = useState("");
  const [isShowFAQ, setIsShowFAQ] = useState(false);
  const [mentor, setMentor] = useState(["Eric Armenteros"]);
  const availableMentors = ["Eric Armenteros", "Roman Radenski"];
  const hardHistory = useHistory();
  const { main, sub } = useParams();

  const selectNavigation = (nav, extraField = false) => {
    if (nav === "tutorials") {
      setIsTutorialsToggle(!isTutorialsToggle);
    } else {
      if (nav === "support" && extraField) {
        hardHistory.push(`/platinum-program/faq`);
      } else {
        hardHistory.push(`/platinum-program/${nav}`);
      }
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const openTutorialMenu = () => {
    setIsTutorialsToggle(true);
  };

  const doNavigation = (main, sub) => {
    let invalidRoute = false;

    if (main && sub) {
      if (main !== "tutorials") {
        invalidRoute = true;
      } else {
        if (
          ![
            "fundamental_analysis",
            "risk_management",
            "technical_analysis",
          ].includes(sub)
        ) {
          invalidRoute = true;
        } else {
          setCurrentNav(main);
          setIsTutorialsToggle(false);
          setCurrentVideoGroup(sub);
        }
      }
    } else {
      if (
        ![
          "tutorials",
          "livestream",
          "real-time-trading-alerts",
          "support",
          "faq",
        ].includes(sub)
      ) {
        invalidRoute = true;
      } else {
        if (sub === "tutorials") {
          setCurrentNav(sub);
          setIsTutorialsToggle(true);
          setCurrentVideoGroup("");
        } else {
          if (sub !== "faq") {
            setCurrentNav(sub);
            setIsTutorialsToggle(false);
            setIsShowFAQ(false);
            setCurrentVideoGroup("");
          } else {
            setCurrentNav("support");
            setIsTutorialsToggle(false);
            setIsShowFAQ(true);
            setCurrentVideoGroup("");
          }
        }
      }
    }

    if (invalidRoute) {
      hardHistory.push(`/platinum-program/livestream`);
    }
  };

  useEffect(() => {
    doNavigation(main, sub);
  }, [main, sub]);
  const mentorFName = mentor[0].split(" ")[0];
  const streamTimes = mentor.includes("Eric Armenteros")
    ? "9:30 - 10:30am EST"
    : "2:00 - 3:00pm EST";
  return (
    <div className="platinum-program-page">
      <div className="platinum-program-page-content-wrapper">
        <div className="platinum-program-page-body">
          <div className="platinum-program-page-navigator">
            <div
              className={`platinum-program-page-navItem ${currentNav === "tutorials" ? "active" : ""}`}
              onClick={() => selectNavigation("tutorials")}
            >
              Tutorials
              {isTutorialsToggle ? (
                <ExpandMoreIcon className="expand-icon" />
              ) : (
                <ExpandLessIcon className="expand-icon" />
              )}
            </div>
            <div
              className={`platinum-program-page-navItem ${currentNav === "livestream" ? "active" : ""}`}
              onClick={() => selectNavigation("livestream")}
            >
              Livestream Learning
            </div>
            <div
              className={`platinum-program-page-navItem ${currentNav === "real-time-trading-alerts" ? "active" : ""}`}
              onClick={() => selectNavigation("real-time-trading-alerts")}
            >
              Real-time Trading Alerts
            </div>
            {mentor.includes("Eric Armenteros") && (
              <div
                className={`platinum-program-page-navItem ${currentNav === "support" ? "active" : ""}`}
                onClick={() => selectNavigation("support")}
              >
                Support
              </div>
            )}
          </div>
          <div className="platinum-program-page-main-view">
            <div className="platinum-program-page-content">
              <Collapse in={isTutorialsToggle}>
                <div className="platinum-program-page-tutorials-section">
                  <div
                    className="platinum-program-page-tutorial-section"
                    onClick={() => {
                      hardHistory.push(
                        "/platinum-program/tutorials/fundamental_analysis",
                      );
                    }}
                  >
                    <div>
                      <div className="platinum-program-page-tutorial-section-header">
                        <PlayCircleIcon className="play-circle-icon" />
                        <div className="platinum-program-page-tutorial-section-header-info">
                          <div className="platinum-program-page-tutorial-section-title">
                            Fundamental Analysis
                          </div>
                          <div className="platinum-program-page-tutorial-section-video-info">
                            2 Videos • 25 mins 40 secs
                          </div>
                        </div>
                      </div>

                      <div className="platinum-program-page-tutorial-section-body">
                        Examining macroeconomic conditions, company financials,
                        and other outside factors to gauge the potential of an
                        asset.
                      </div>
                    </div>

                    <div className="platinum-program-page-tutorial-section-view-video">
                      View track{" "}
                      <ArrowCircleRightOutlinedIcon className="arrow-circle-right-icon" />
                    </div>
                  </div>

                  <div
                    className="platinum-program-page-tutorial-section"
                    onClick={() => {
                      hardHistory.push(
                        "/platinum-program/tutorials/risk_management",
                      );
                    }}
                  >
                    <div>
                      <div className="platinum-program-page-tutorial-section-header">
                        <PlayCircleIcon className="play-circle-icon" />
                        <div className="platinum-program-page-tutorial-section-header-info">
                          <div className="platinum-program-page-tutorial-section-title">
                            Risk Management
                          </div>
                          <div className="platinum-program-page-tutorial-section-video-info">
                            4 Videos • 36 mins 40 secs
                          </div>
                        </div>
                      </div>

                      <div className="platinum-program-page-tutorial-section-body">
                        Using strategies and tools to manage risk in order to
                        protect capital and make sure a trading strategy is
                        successful.
                      </div>
                    </div>
                    <div className="platinum-program-page-tutorial-section-view-video">
                      View track{" "}
                      <ArrowCircleRightOutlinedIcon className="arrow-circle-right-icon" />
                    </div>
                  </div>

                  <div
                    className="platinum-program-page-tutorial-section"
                    onClick={() => {
                      hardHistory.push(
                        "/platinum-program/tutorials/technical_analysis",
                      );
                    }}
                  >
                    <div>
                      <div className="platinum-program-page-tutorial-section-header">
                        <PlayCircleIcon className="play-circle-icon" />
                        <div className="platinum-program-page-tutorial-section-header-info">
                          <div className="platinum-program-page-tutorial-section-title">
                            Technical Analysis
                          </div>
                          <div className="platinum-program-page-tutorial-section-video-info">
                            6 Videos • 1 hour 11 mins
                          </div>
                        </div>
                      </div>

                      <div className="platinum-program-page-tutorial-section-body">
                        Looking at chart patterns, indicators,and
                        support/resistance levels to make predictions about
                        future price movements.
                      </div>
                    </div>
                    <div className="platinum-program-page-tutorial-section-view-video">
                      View track{" "}
                      <ArrowCircleRightOutlinedIcon className="arrow-circle-right-icon" />
                    </div>
                  </div>
                </div>
              </Collapse>

              {(currentNav === "livestream" || currentNav === "tutorials") &&
                (currentVideoGroup ? (
                  <TutorialVideoGroup
                    setCurrentVideoGroup={setCurrentVideoGroup}
                    currentVideoGroup={currentVideoGroup}
                    openTutorialMenu={openTutorialMenu}
                  />
                ) : (
                  <>
                    {/* Live Streams section */}
                    <div
                      className="platinum-program-page-live-streams-content"
                      id="live-stream-section"
                    >
                      <div>
                        <iframe
                          key={mentor}
                          className="live-stream-event-video"
                          src={`https://vimeo.com/event/${mentor.includes("Eric Armenteros") ? "3162415" : "3745400"}/embed`}
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          title="Livestream Learning"
                          frameBorder="0"
                        ></iframe>
                      </div>
                      <div className="platinum-program-page-live-streams-info">
                        <div className="platinum-program-page-live-streams-info-header">
                          <img
                            src={
                              mentor.includes("Eric Armenteros")
                                ? AuthorAvatar
                                : Rome
                            }
                            alt="Eric"
                            className="live-streams-author"
                          />
                          <div className="platinum-program-page-live-streams-author-info">
                            <div className="platinum-program-page-live-streams-author-name">
                              {mentor[0]}
                            </div>
                            <div className="platinum-program-page-live-streams-author-job">
                              TradeAlgo Professional Analyst
                            </div>
                          </div>
                        </div>

                        <div className="platinum-program-page-live-streams-info-content">
                          <div className="platinum-program-page-live-streams-title">
                            The Platinum Daily Livestreams
                          </div>
                          <div className="platinum-program-page-live-streams-description">
                            Join us and get ready to buckle up and blast off
                            with the one and only {mentor[0]} at the
                            TradeAlgo&apos;s Platinum Daily livestream event!
                            With {mentorFName} at the helm, you&apos;ll be taken
                            on an electrifying journey through the markets as he
                            scours for the most profitable trades out there.
                            This daily event is THE place to be if you want to
                            gain invaluable insights, stay on top of your game,
                            and receive explosive trade ideas that&apos;ll make
                            your portfolio soar to new heights !
                          </div>
                        </div>

                        <div className="platinum-program-page-live-streams-schedule">
                          <div className="platinum-program-page-live-streams-schedule-title">
                            Live Schedule
                          </div>
                          <div className="platinum-program-page-live-streams-schedule-list">
                            <div className="platinum-program-page-live-streams-schedule-day-item">
                              <div className="platinum-program-page-live-streams-schedule-day">
                                Mon
                              </div>
                              <div className="platinum-program-page-live-streams-schedule-time">
                                {streamTimes}
                              </div>
                            </div>

                            <div className="platinum-program-page-live-streams-schedule-day-item">
                              <div className="platinum-program-page-live-streams-schedule-day">
                                Tue
                              </div>
                              <div className="platinum-program-page-live-streams-schedule-time">
                                {streamTimes}
                              </div>
                            </div>

                            <div className="platinum-program-page-live-streams-schedule-day-item">
                              <div className="platinum-program-page-live-streams-schedule-day">
                                Wed
                              </div>
                              <div className="platinum-program-page-live-streams-schedule-time">
                                {streamTimes}
                              </div>
                            </div>

                            <div className="platinum-program-page-live-streams-schedule-day-item">
                              <div className="platinum-program-page-live-streams-schedule-day">
                                Thu
                              </div>
                              <div className="platinum-program-page-live-streams-schedule-time">
                                {streamTimes}
                              </div>
                            </div>

                            <div className="platinum-program-page-live-streams-schedule-day-item">
                              <div className="platinum-program-page-live-streams-schedule-day">
                                Fri
                              </div>
                              <div className="platinum-program-page-live-streams-schedule-time">
                                {streamTimes}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <RealTimeTradingAlertsWidget
                      selectNavigation={selectNavigation}
                    />
                    {mentor.includes("Eric Armenteros") && (
                      <SupportsWidget selectNavigation={selectNavigation} />
                    )}
                  </>
                ))}
            </div>

            {currentNav === "real-time-trading-alerts" && (
              <RealTimeTradingAlertsComponent
                selectNavigation={selectNavigation}
              />
            )}

            {currentNav === "support" && (
              <SupportComponent
                selectNavigation={selectNavigation}
                isShowFAQ={isShowFAQ}
              />
            )}
          </div>
        </div>
      </div>

      <div className={`past-videos-content`}>
        <PastVideos
          mentor={mentor}
          setMentor={setMentor}
          availableMentors={availableMentors}
          selectNavigation={selectNavigation}
          isTutorialsToggle={isTutorialsToggle}
          currentNav={currentNav}
          currentVideoGroup={currentVideoGroup}
        />
      </div>
    </div>
  );
};

export default PlatinumProgram;
