import React, { useState } from "react";
import { connect } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import UserAvatar from "../../../assets/images/brian.png";
import TradeAlertsAndSignals from "../TradeAlertsAndSignals";
import "./EmeraldCommonPage.scss";
import PreviousVideos from "../../BasicsTraining/PreviousVideos";
import { defaultVideoState } from "../../../helpers/defaultVideoState";

const EmeraldCommonPage = ({
  title,
  subTitle,
  description,
  eventID,
  from,
  themeMode,
}) => {
  const [currentVideo, setCurrentVideo] = useState(defaultVideoState);
  const [tab, setTab] = useState("1");
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  return (
    <div className="emerald-common-page">
      <div className="emerald-common-page__title">
        {currentVideo.live ? title : "Past Stream"}
      </div>
      {currentVideo.live ? (
        <div className="emerald-common-page__header">
          <div className="emerald-common-page__left">
            <iframe
              className="emerald-common-page__video"
              src={`https://vimeo.com/event/${eventID}/embed`}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={"LiveStream"}
              frameBorder="0"
            ></iframe>
          </div>

          <div className="emerald-common-page__right">
            <iframe
              src={`https://vimeo.com/event/${eventID}/chat`}
              width="100%"
              height="100%"
              frameBorder="0"
              title="livestream-chat"
            />
          </div>
        </div>
      ) : (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: currentVideo.url,
            }}
          />
          <div style={{ position: "relative", top: "5px", left: "29px" }}>
            <div className="row">
              <span
                className="col-12"
                style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
              >
                {currentVideo.duration} | {currentVideo.date} |{" "}
                {tab === "1" ? "Group Training" : ""}
              </span>
            </div>
            <div className="row">
              <span className="col-12" style={{ paddingTop: "10px" }}>
                {currentVideo.description}
              </span>
            </div>
          </div>
          <br />
          <div
            style={{
              width: "99%",
              border: "1px solid #F1F1F1",
              marginLeft: "24px",
            }}
          ></div>
          <br />
        </div>
      )}
      <div className="emerald-common-page__body">
        <div className="emerald-common-page__left">
          <div className="emerald-common-page__content">
            <div className="emerald-common-page-author">
              <img
                src={UserAvatar}
                alt="Brian Avatar"
                className="emerald-common-page-author-avatar"
              />
              <div className="emerald-common-page-author-info">
                <div className="emerald-common-page-author-name">
                  Brian Anderson <VerifiedIcon />
                </div>
                <div className="emerald-common-page-author-title">
                  TradeAlgo Professional Analyst
                </div>
              </div>
            </div>

            <div className="emerald-common-page__content-title">{subTitle}</div>
            <div className="emerald-common-page__content-description">
              {description}
            </div>
            <div className="emerald-common-page__live_schedule-title">
              Live Schedule
            </div>
            <div className="emerald-common-page__schedules">
              {days.map((day, index) => (
                <div className="emerald-common-page__schedule" key={index}>
                  <div className="emerald-common-page__schedule-day">{day}</div>
                  <div className="emerald-common-page__schedule-time">
                    {from === "livestream" ? "3:00pm EST" : "12:00pm EST"}
                  </div>
                  {/* <div className="emerald-common-page__schedule-time">({ from ==='livestream' ? '12:00pm PST' : '9:00am PST' })</div> */}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="emerald-common-page__right">
          <TradeAlertsAndSignals
            themeMode={themeMode}
            url="https://t.me/+WKhGeIKkzPE5YTZh"
          />
        </div>
      </div>
      <PreviousVideos
        tab={tab}
        setTab={setTab}
        basics={false}
        titleFilter={
          title === "Livestream" ? "Trade Algo Emerald Income Alerts" : null
        }
        customPageSize={title === "Livestream" ? 19 : 9}
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
        videoKey={
          title === "Livestream" ? "emerald-income" : "emerald-income-qa"
        }
      />
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(EmeraldCommonPage);
