import React, { useEffect } from "react";

import { useAuth } from "../../contexts/Auth";
import Loader from "../common/Loader";

const SignOut = () => {
  const { signOut } = useAuth();
  useEffect(() => {
    signOut();
  }, []);
  return (
    <Loader type="screen">
      <div style={{ textAlign: "center" }}>
        <p style={{ fontSize: "13pt", marginTop: "10px", color: "#fff" }}>
          Signing Out.
        </p>
      </div>
    </Loader>
  );
};

export default SignOut;
