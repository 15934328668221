/* eslint-disable */
import { Helmet } from "react-helmet";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { numberWithCommas } from "../utilities";
import { eventEmitter } from "../TradingView/datafeed";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";

const CompanyPrice = (props) => {
  const {
    ticker,
    marketStatus,
    price,
    lastPrice,
    pLastPrice,
    setLastPrice,
    securityPrice,
    themeMode,
    tickerDetailsTicker,
  } = props;
  useEffect(() => {
    setLastPrice("");
  }, [ticker]);
  useEffect(() => {
    if (ticker === tickerDetailsTicker) {
      setLastPrice(pLastPrice);
    }
  }, [pLastPrice, ticker, tickerDetailsTicker]);
  const performance = useMemo(() => {
    let lastPriceUsed = {
      "pre-open": parseFloat(lastPrice || price?.last_price || 0).toFixed(2),
      open: parseFloat(lastPrice || price?.last_price || 0).toFixed(2),
      after: parseFloat(
        price?.close_price || lastPrice || price?.last_price || 0,
      ).toFixed(2),
      closed: parseFloat(
        price?.close_price || lastPrice || price?.last_price || 0,
      ).toFixed(2),
    }[marketStatus];

    let compare = {
      "pre-open": parseFloat(
        price?.pre_open || price?.prev_close_price,
      ).toFixed(2),
      open: parseFloat(price.open_price).toFixed(2),
      after: parseFloat(price.open_price).toFixed(2),
      closed: parseFloat(price.open_price).toFixed(2),
    }[marketStatus];

    return {
      present: lastPriceUsed,
      change: lastPriceUsed - compare,
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
    };
  }, [marketStatus, price, ticker, lastPrice, securityPrice]);
  // Subscribe to the priceUpdate event
  useEffect(() => {
    const handlePriceUpdate = (event) => {
      const currentSub = event.detail;
      if (
        !!currentSub &&
        (currentSub?.eventSymbol?.includes(ticker) ||
          currentSub?.symbol?.includes(ticker))
      ) {
        setLastPrice(currentSub.close);
      }
    };
    eventEmitter.addEventListener("priceUpdate", handlePriceUpdate);

    return () => {
      eventEmitter.removeEventListener("priceUpdate", handlePriceUpdate);
    };
  }, [ticker, setLastPrice, tickerDetailsTicker, themeMode]);

  if (performance.present == 0) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>
          {ticker} {!!lastPrice ? `| ${lastPrice}` : ""} | TradeAlgo
        </title>
      </Helmet>
      {performance.present && (
        <div
          style={{
            display: "flex",
            color: themeMode === "light" ? "black" : "white",
          }}
          className="company-price-item"
        >
          {Number(performance.present) == 0 ? (
            <BeautifulSkeleton animation="wave" />
          ) : (
            <>${performance?.present}</>
          )}
          <span className="company-price-usd-symbol">USD</span>
        </div>
      )}
      <div className="company-price-change">
        <div
          className="company-price-percentage-change"
          style={{
            background: String(performance.percent).includes("-")
              ? "rgba(234, 51 , 35, 0.1)"
              : "rgba(62, 180, 137, 0.1)",
            color: String(performance.percent).includes("-")
              ? "rgba(234, 51 , 35)"
              : "rgba(62, 180, 137)",
          }}
        >
          {String(performance.percent).includes("-") ? "" : "+ "}
          {numberWithCommas(performance.percent ?? "") + "%"}
        </div>
        <span
          className="company-price-change-amount"
          style={{
            color: String(performance.percent).includes("-")
              ? "rgba(234, 51 , 35)"
              : "rgba(62, 180, 137)",
          }}
        >
          ({String(performance.percent).includes("-") ? "" : "+ "}
          {performance.change.toFixed(2)})
        </span>
      </div>
    </>
  );
};
const stateToProps = (state) => ({
  price: state.companyData.companyData.secPrice,
  pLastPrice: state.companyData.companyData.lastPrice,
  themeMode: state.themeMode.themeMode,
  tickerDetailsTicker: state.companyData.companyData.tickerDetails?.ticker,
});

export default connect(stateToProps, null)(CompanyPrice);
