import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";

import "./EmeraldSignup.scss";
import {
  EmeraldBenefitCheckIcon,
  EmeraldBigGreenIcon,
  DarkEmeraldBigGreenIcon,
  EmeraldBigTextIcon,
  IncomeAlertsTextIcon,
} from "../common/Icon";
import EmeraldSalesContactForm from "./EmeraldSalesContactForm";
import moment from "moment";
import { useAuth } from "../../contexts/Auth";
import { useHistory } from "react-router-dom";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";

const EmeraldSignup = ({ themeMode, userSettings }) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { loading, hasEmerald } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (hasEmerald) {
      history.push("/emerald/tutorials");
    }
  }, [loading, hasEmerald]);

  const hasSubmitted = useMemo(() => {
    const submitDate = userSettings?.emeraldContact;
    const weekSinceSubmit = moment(submitDate).add(1, "week");
    const today = moment();
    // Return true if today is before weekSinceSubmit (within a week since submitDate)
    return submitDate ? today.isBefore(weekSinceSubmit) : false;
  }, [userSettings, loading]);

  const color = useMemo(() => {
    return themeMode === "light" ? "#000" : "#fff";
  }, [themeMode]);

  const fillColor = useMemo(() => {
    return themeMode === "light" ? "#3EB489" : "#65C3A1";
  }, [themeMode]);

  return (
    <div className="emerald-signup-page">
      <div className="emerald-signup__header">
        {themeMode === "light" ? (
          <EmeraldBigGreenIcon />
        ) : (
          <DarkEmeraldBigGreenIcon />
        )}
        <div className="emerald-signup__header_text">
          <EmeraldBigTextIcon fill={color} />
          <IncomeAlertsTextIcon fill={color} />
        </div>
      </div>

      <div className="emerald-signup__content">
        <div className="emerald-signup__content_title">
          Premium Trading signals for Elite Traders
        </div>
        <div className="emerald-signup__content_subtitle">
          Go beyond rudimentary alerts. Emerald Income Alerts leverages AI and
          short interest data to deliver premium trading signals and in-depth
          training on elite-level strategies.
        </div>
        <div className="emerald-signup__content_benefits">
          <div className="emerald-benefit-row">
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Short Interest
              </span>
            </div>
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Artificial Intelligence
              </span>
            </div>
          </div>

          <div className="emerald-benefit-row">
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Premium Support
              </span>
            </div>
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Real-Time Alerts for Trading
              </span>
            </div>
          </div>
        </div>
        <div className="emerald-signup__content_splitter" />
        <div className="emerald-price">
          <div className="emerald-price__value">$15,000</div>
          <div className="emerald-price__symbol">USD</div>
        </div>
      </div>

      <div className="emerald-signup__footer">
        {loading ? (
          <BeautifulSkeleton
            sx={{ position: "relative", bottom: 22 }}
            height={100}
          />
        ) : (
          <>
            <Button
              className="emerald-get-access-button"
              variant="contained"
              onClick={openModal}
              disabled={hasSubmitted}
            >
              {hasSubmitted ? "Access Requested" : "Contact Us For Pricing"}
            </Button>
            <div className="emerald-get-access-button__shadow" />
          </>
        )}
      </div>

      <Modal open={open}>
        <EmeraldSalesContactForm onClose={handleClose} />
      </Modal>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(EmeraldSignup);
