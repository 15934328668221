import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const UPDATE_PROFILE_LOAD = "UPL/LOAD/TOP";
export const UPDATE_PROFILE_LOAD_SUCCESS = "UPL/LOAD/TOP/SUCCESS";
export const UPDATE_PROFILE_LOAD_FAILURE = "UPL/LOAD/TOP/FAILURE";

// Action Creators
export const updateProfileLoad = (profileSettings) => ({
  type: UPDATE_PROFILE_LOAD,
  profileSettings,
});
export const updateProfileLoadSuccess = (data) => ({
  type: UPDATE_PROFILE_LOAD_SUCCESS,
  data,
});
export const updateProfileLoadFailure = (error) => ({
  type: UPDATE_PROFILE_LOAD_FAILURE,
  error,
});

// Sagas
function* updateProfile(action) {
  try {
    const { profileSettings } = action;
    const response = yield axios.put(
      `${apiURL}/user/update-profile`,
      { userProfile: profileSettings },
      { withCredentials: true },
    );
    yield put(updateProfileLoadSuccess(response.data));
  } catch (error) {
    yield put(updateProfileLoadFailure(error));
  }
}

function* listenUpdateProfileLoad() {
  yield takeEvery(UPDATE_PROFILE_LOAD, updateProfile);
}

// Root Saga
export function* saga() {
  yield all([fork(listenUpdateProfileLoad)]);
}

const INIT_STATE = {
  profileSettings: {},
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        profileSettings: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
