import React, { useState } from "react";
import { connect } from "react-redux";

import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import AllVideosList from "./AllVideosList";
import "./VideoPage.scss";

const VideoPage = ({ allVideos, currentVideo, goBack, selectVideo }) => {
  const [tab, setTab] = useState("overview");

  return (
    <div className="video-page">
      <div className="video-page__left">
        <div className="video-page__left__title">{currentVideo.title}</div>
        <div className="video-page__video-player">
          <iframe
            className="video-page__video"
            src={currentVideo.videoUrl}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={currentVideo.title}
            frameBorder="0"
          ></iframe>
        </div>
        <div className="video-page__left__tabs">
          <div
            className={`video-page__left__tab ${tab === "overview" ? "active" : ""}`}
            onClick={() => setTab("overview")}
          >
            Overview
          </div>
          <div
            className={`video-page__left__tab ${tab === "download" ? "active" : ""}`}
            onClick={() => setTab("download")}
          >
            Download
          </div>
        </div>

        {tab === "overview" ? (
          <>
            <div className="video-page__left__infos">
              <div className="video-page__left__info">
                {currentVideo.overview}
              </div>
              <div className="video-page__left__info">
                {currentVideo.length}
                <span>•</span>Emerald Income Alerts
              </div>
            </div>

            <div className="video-page__left__description">
              <div className="video-page__left__description__title">
                Tutorial Description
              </div>
              <div className="video-page__left__description__subTitle">
                In this tutorial, we will be covering the following topics:{" "}
              </div>
              <div className="video-page__left__description__content">
                {currentVideo.description}
              </div>
            </div>
          </>
        ) : (
          <div className="video-page__left__downloads">
            <div className="video-page__left__downloads_title">
              Download Slides Here
            </div>
            <a
              href={currentVideo.pdf}
              download={currentVideo.title}
              target="_blank"
              rel="noreferrer"
            >
              <div className="video-page__slides">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.5463 15.7583H8.72759C8.47039 15.7583 8.22372 15.8586 8.04185 16.0371C7.85999 16.2156 7.75781 16.4578 7.75781 16.7102C7.75781 16.9627 7.85999 17.2048 8.04185 17.3833C8.22372 17.5618 8.47039 17.6621 8.72759 17.6621H14.5463C14.8035 17.6621 15.0501 17.5618 15.232 17.3833C15.4139 17.2048 15.516 16.9627 15.516 16.7102C15.516 16.4578 15.4139 16.2156 15.232 16.0371C15.0501 15.8586 14.8035 15.7583 14.5463 15.7583Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M8.72759 13.8545H11.6369C11.8941 13.8545 12.1408 13.7542 12.3227 13.5757C12.5045 13.3972 12.6067 13.1551 12.6067 12.9026C12.6067 12.6501 12.5045 12.408 12.3227 12.2295C12.1408 12.051 11.8941 11.9507 11.6369 11.9507H8.72759C8.47039 11.9507 8.22372 12.051 8.04185 12.2295C7.85999 12.408 7.75781 12.6501 7.75781 12.9026C7.75781 13.1551 7.85999 13.3972 8.04185 13.5757C8.22372 13.7542 8.47039 13.8545 8.72759 13.8545Z"
                    fill="#007BFF"
                  />
                  <path
                    d="M19.1432 8.45694L13.8676 2.74546C13.7769 2.64684 13.6662 2.56795 13.5427 2.51385C13.4191 2.45975 13.2853 2.43164 13.15 2.43132H6.36153C6.03933 2.42755 5.71952 2.48612 5.42037 2.60367C5.12122 2.72123 4.84859 2.89548 4.61805 3.11647C4.38751 3.33746 4.20358 3.60086 4.07676 3.89162C3.94993 4.18239 3.8827 4.49484 3.87891 4.81111V19.0898C3.8827 19.4061 3.94993 19.7185 4.07676 20.0093C4.20358 20.3001 4.38751 20.5635 4.61805 20.7845C4.84859 21.0055 5.12122 21.1797 5.42037 21.2973C5.71952 21.4148 6.03933 21.4734 6.36153 21.4696H16.9127C17.2349 21.4734 17.5547 21.4148 17.8539 21.2973C18.153 21.1797 18.4257 21.0055 18.6562 20.7845C18.8867 20.5635 19.0707 20.3001 19.1975 20.0093C19.3243 19.7185 19.3915 19.4061 19.3953 19.0898V9.09473C19.3947 8.85881 19.3049 8.63152 19.1432 8.45694ZM13.5767 5.28707L16.2339 8.14281H14.2943C14.1928 8.13679 14.0934 8.11098 14.0022 8.06688C13.9109 8.02278 13.8295 7.96127 13.7626 7.88595C13.6958 7.81063 13.645 7.723 13.6131 7.62818C13.5811 7.53336 13.5688 7.43325 13.5767 7.33368V5.28707ZM16.9127 19.5658H6.36153C6.29401 19.5696 6.22638 19.5604 6.16251 19.5385C6.09864 19.5167 6.0398 19.4827 5.98935 19.4385C5.93889 19.3942 5.89783 19.3407 5.86851 19.2809C5.83918 19.221 5.82217 19.1561 5.81846 19.0898V4.81111C5.82217 4.74482 5.83918 4.6799 5.86851 4.62008C5.89783 4.56025 5.93889 4.5067 5.98935 4.46248C6.0398 4.41826 6.09864 4.38425 6.16251 4.36241C6.22638 4.34056 6.29401 4.3313 6.36153 4.33515H11.6371V7.33368C11.6215 8.03489 11.889 8.7137 12.3814 9.22197C12.8738 9.73024 13.551 10.0267 14.2652 10.0466H17.4558V19.0898C17.4521 19.1561 17.4351 19.221 17.4057 19.2809C17.3764 19.3407 17.3353 19.3942 17.2849 19.4385C17.2344 19.4827 17.1756 19.5167 17.1117 19.5385C17.0479 19.5604 16.9802 19.5696 16.9127 19.5658Z"
                    fill="#007BFF"
                  />
                </svg>
                <span>{currentVideo.title}.pptx</span>
              </div>
            </a>
          </div>
        )}

        <div className="video-page__left__back" onClick={goBack}>
          Back to courses
        </div>
      </div>

      <div className="video-page__right">
        <div className="video-page__right__title">All Videos</div>
        <AllVideosList
          allVideos={allVideos}
          selectVideo={selectVideo}
          currentVideo={currentVideo}
        />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(VideoPage);
