import React from "react";

import "./NewOptionsMentorshipTabs.scss";
import { useAuth } from "../../../contexts/Auth";

const NewOptionsMentorshipTabs = ({ activeTab, setActiveTab }) => {
  const {
    hasEmerald,
    hasOptionsMentorshipStarter,
    hasPlatinumProgram,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipAdvanced,
  } = useAuth();
  return (
    <div className="om-tabs">
      {!!(
        hasEmerald ||
        hasOptionsMentorshipStarter ||
        hasPlatinumProgram ||
        hasOptionsMentorshipIntermediate ||
        hasOptionsMentorshipAdvanced
      ) && (
        <div
          className={`om-tab ${activeTab === "overview" ? "active" : ""}`}
          onClick={() => setActiveTab("overview")}
        >
          Manage My Sessions
        </div>
      )}
      <div
        className={`om-tab ${activeTab === "plans" ? "active" : ""}`}
        onClick={() => setActiveTab("plans")}
      >
        View All Price Plans
      </div>
    </div>
  );
};

export default NewOptionsMentorshipTabs;
