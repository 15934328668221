import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import handIcon from "../../assets/images/click_hand.gif";
import "./index.scss";

const MentorshipPromoBanner = ({ link }) => {
  return (
    <div className="mentorship-promo-banner-page">
      <div className="mentorship-promo-banner-content">
        <div className="mentorship-promo-texts-wrapper">
          <div className="mentorship-promo-texts">
            <div className="mentorship-promo-title">
              Book your 1-on-1 Options Mentorship with a Professional Analyst
              today!
            </div>
            <div className="mentorship-promo-info">
              Maximize your profits with the help of a TradeAlgo expert.
            </div>
          </div>
          <Link to={link}>
            <Button className="promo-sign-up-button">
              <img src={handIcon} alt="handIcon" className="promo-hand-icon" />
              Book Now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MentorshipPromoBanner;
