import React from "react";
import { Box } from "@mui/material";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";

const VideoCardSkeleton = () => {
  return (
    <div className="previous-video">
      <div className="previous-video-inner-content">
        <BeautifulSkeleton variant="rectangular" width="100%" height="130px" />
        <Box sx={{ padding: "8px" }}>
          <BeautifulSkeleton variant="text" width="75%" height={20} />
          <BeautifulSkeleton variant="text" width="25%" height={24} />
        </Box>
      </div>
    </div>
  );
};

export default VideoCardSkeleton;
