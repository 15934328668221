import * as React from "react";

const ScannerIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
  >
    <g clipPath="url(#clip0_9557_85933)">
      <path
        d="M24.6659 14.6014C23.5294 13.6647 22.1189 13.0958 20.6339 12.9689L18.4419 10.8702C20.332 10.57 22.2805 10.822 24.0281 11.6036C26.0568 12.511 27.681 14.0722 28.6197 16.0073C29.558 17.9419 29.7529 20.1295 29.1715 22.1866C28.59 24.2442 27.2676 26.0444 25.4311 27.2669C23.5942 28.4896 21.3626 29.0543 19.1299 28.8582C16.8972 28.662 14.812 27.7183 13.2403 26.1979C11.669 24.678 10.7116 22.6799 10.531 20.5567C10.3504 18.4336 10.9575 16.3153 12.2495 14.5742L12.5123 14.2201L12.1938 13.9151L9.81214 11.6348L9.41979 11.2591L9.07785 11.6812C7.01265 14.2303 5.98595 17.4163 6.19495 20.6429C6.40395 23.8693 7.83371 26.9081 10.2104 29.1918C12.5866 31.4749 15.7466 32.8476 19.0978 33.058C22.4489 33.2684 25.7676 32.3027 28.4331 30.3375C31.0991 28.372 32.9304 25.5398 33.5776 22.3679C34.2248 19.1956 33.6418 15.9073 31.9409 13.1199C30.2403 10.3332 27.5407 8.24036 24.3517 7.22733C21.2666 6.24733 17.9233 6.34113 14.9077 7.48653L13.1603 5.81459C15.2979 4.8594 17.6324 4.36379 19.999 4.36721H19.9997C28.9493 4.36721 36.1664 11.3078 36.1664 19.8247C36.1664 28.3415 28.9493 35.2821 19.9997 35.2821C11.0501 35.2821 3.83302 28.3415 3.83302 19.8247L3.83302 19.8241C3.83059 17.5366 4.36032 15.2766 5.38469 13.2079C6.34641 11.2657 7.72182 9.5373 9.42239 8.13329L21.6334 19.8247L19.9997 21.3888L14.6021 16.2209L14.1552 15.793L13.8306 16.3199C13.0343 17.6126 12.6993 19.1216 12.8795 20.6165C13.0597 22.1112 13.7444 23.5052 14.8249 24.5878C15.9051 25.6701 17.3217 26.3822 18.858 26.62C20.3942 26.8579 21.9704 26.6092 23.3477 25.9101C24.7252 25.2108 25.831 24.0977 26.4927 22.7362C27.1546 21.3743 27.3345 19.8408 27.0035 18.3702C26.6725 16.8999 25.8501 15.5773 24.6659 14.6014Z"
        fill={fill}
        stroke={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_9557_85933">
        <rect
          width="40"
          height="38.2979"
          fill="white"
          transform="translate(0 0.671875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ScannerIcon;
