import React, { useMemo } from "react";
import capitalize from "lodash/capitalize";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import PeopleIcon from "@mui/icons-material/People";
import ShieldIcon from "@mui/icons-material/Shield";
import NestedMenuItem from "material-ui-nested-menu-item";
import { ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import ListItemIcon from "@mui/material/ListItemIcon";
import DoneIcon from "@mui/icons-material/Done";
import clsx from "clsx";
import allIndicators from "../../TradingView/allIndicators";
import "./ChartSettingsPage.scss";
import { ConditionalWrapper } from "../../common/Layout/PrivateLayout/SidebarContent";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import ExampleChart from "../../TradingView/ExampleChart";
import { useAuth } from "../../../contexts/Auth";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.tableBackground,
  },
  text: {
    color: theme.palette.primary.text + " !important",
    textDecoration: "none !important",

    "& span": {
      fontSize: "14px!important",
    },
  },
  fill: {
    fill: theme.palette.primary.text + " !important",
  },
}));

function onMouseEnter() {
  if (
    !document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.contains("open-popover")
  ) {
    document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.add("open-popover");
  }
}
function onMouseLeave() {
  if (
    document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.contains("open-popover")
  ) {
    document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.remove("open-popover");
  }
}

const ChartSettingsPage = ({ themeMode, userSettings, setUserSettings }) => {
  const {
    defaultResolution,
    candleType,
    defaultIndicators = [],
    chartSaveMode = 0,
    notifications,
  } = userSettings;

  const { loading } = useAuth();

  const classes = useStyles();

  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);

  const menuData = useMemo(() => {
    const resolutionSubnav = [
      {
        title: "1 Minute",
        onClick: () => setResolution("1"),
        icon: (
          <CandlestickChartIcon
            style={{
              color,
            }}
          />
        ),
        checked: defaultResolution === "1",
      },
      {
        title: "5 Minutes",
        onClick: () => setResolution("5"),
        icon: (
          <CandlestickChartIcon
            style={{
              color,
            }}
          />
        ),
        checked: defaultResolution === "5",
      },
      {
        title: "15 Minutes",
        onClick: () => setResolution("15"),
        icon: (
          <CandlestickChartIcon
            style={{
              color,
            }}
          />
        ),
        checked: defaultResolution === "15",
      },
      {
        title: "30 Minutes",
        onClick: () => setResolution("30"),
        icon: (
          <CandlestickChartIcon
            style={{
              color,
            }}
          />
        ),
        checked: defaultResolution === "30",
      },
      {
        title: "1 Hour",
        onClick: () => setResolution("1h"),
        icon: (
          <CandlestickChartIcon
            style={{
              color,
            }}
          />
        ),
        checked: defaultResolution === "1h",
      },
      {
        title: "1 Day",
        onClick: () => setResolution("1d"),
        icon: (
          <CandlestickChartIcon
            style={{
              color,
            }}
          />
        ),
        checked: defaultResolution === "1d",
      },
    ];

    const candleSubnav = [
      {
        title: "Bars",
        onClick: () => setCandleType("0"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
          >
            <path
              fill={color}
              d="M19 6h-1v7h-3v1h3v8h1v-3h3v-1h-3V6ZM11 7h-1v13H7v1h3v2h1V10h3V9h-3V7Z"
            ></path>
          </svg>
        ),
        checked: candleType === "0",
      },
      {
        title: "Candles",
        onClick: () => setCandleType("1"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill={color}
          >
            <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
            <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
            <path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path>
            <path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path>
          </svg>
        ),
        checked: candleType === "1",
      },
      {
        title: "Hollow Candle",
        onClick: () => setCandleType("9"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill={color}
          >
            <path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path>
            <path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path>
            <path d="M9 8v11h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5z"></path>
            <path d="M10 4h1v5h-1zm0 14h1v5h-1zM8.5 9H10v1H8.5zM11 9h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11z"></path>
          </svg>
        ),
        checked: candleType === "9",
      },
      {
        title: "Line",
        onClick: () => setCandleType("2"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
          >
            <path
              fill={color}
              d="m25.39 7.31-8.83 10.92-6.02-5.47-7.16 8.56-.76-.64 7.82-9.36 6 5.45L24.61 6.7l.78.62Z"
            ></path>
          </svg>
        ),
        checked: candleType === "2",
      },
      {
        title: "Line With Markers",
        onClick: () => setCandleType("14"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill={color}
          >
            <path
              fillRule="evenodd"
              d="m18.43 15.91 6.96-8.6-.78-.62-6.96 8.6a2.49 2.49 0 0 0-2.63.2l-2.21-2.02A2.5 2.5 0 0 0 10.5 10a2.5 2.5 0 1 0 1.73 4.3l2.12 1.92a2.5 2.5 0 1 0 4.08-.31ZM10.5 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm7.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            ></path>
            <path d="M8.37 13.8c.17.3.4.54.68.74l-5.67 6.78-.76-.64 5.75-6.88Z"></path>
          </svg>
        ),
        checked: candleType === "14",
      },
      {
        title: "Stepline",
        onClick: () => setCandleType("15"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill={color}
          >
            <path d="M19 5h5v1h-4v13h-6v-7h-4v12H5v-1h4V11h6v7h4V5Z"></path>
          </svg>
        ),
        checked: candleType === "15",
      },
      {
        title: "Area",
        onClick: () => setCandleType("3"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
          >
            <path
              fill={color}
              fillRule="evenodd"
              d="m25.35 5.35-9.5 9.5-.35.36-.35-.36-4.65-4.64-8.15 8.14-.7-.7 8.5-8.5.35-.36.35.36 4.65 4.64 9.15-9.14.7.7ZM2 21h1v1H2v-1Zm2-1H3v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1V9h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1H6v1H5v1H4v1Zm1 0v1H4v-1h1Zm1 0H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0H7v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0H9v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1h1v1Zm1 0v1h-1v-1h1Zm0-1v-1h-1v1h1Zm0 0v1h1v1h1v-1h-1v-1h-1Zm6 2v-1h1v1h-1Zm2 0v1h-1v-1h1Zm0-1h-1v-1h1v1Zm1 0h-1v1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h1v1Zm1 0h-1v1h1v-1Zm0-1h1v1h-1v-1Zm0-1h1v-1h-1v1Zm0 0v1h-1v-1h1Zm-4 3v1h-1v-1h1Z"
            ></path>
          </svg>
        ),
        checked: candleType === "3",
      },
      {
        title: "HLC Area",
        onClick: () => setCandleType("16"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill={color}
          >
            <path
              fillRule="evenodd"
              d="M22 3h1v1h-1V3Zm0 2V4h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1V9h-1V8h-1V7h-1V6h-1V5h-1v1H9v1H8v1H7v1H6v1H5v1H4v1h1v1H4v1h1v-1h1v-1h1v-1h1v-1h1V9h1V8h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1V9h1V8h1V7h1V6h1V5h-1Zm-1 1V5h1v1h-1Zm-1 1V6h1v1h-1Zm-1 1V7h1v1h-1Zm-1 1V8h1v1h-1Zm-1 1V9h1v1h-1Zm-1 1v-1h1v1h-1Zm-1 0v-1h-1V9h-1V8h-1V7h-1V6h-1v1H9v1H8v1H7v1H6v1H5v1h1v-1h1v-1h1V9h1V8h1V7h1v1h1v1h1v1h1v1h1Zm0 0h1v1h-1v-1Zm.84 6.37 7.5-7-.68-.74-7.15 6.67-4.66-4.65-.33-.34-.36.32-5.5 5 .68.74 5.14-4.68 4.67 4.66.34.35.35-.33ZM6 23H5v1h1v-1Zm0-1H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0v1H7v-1h1Zm0-1H7v-1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0v1H9v-1h1Zm0-1H9v-1h1v1Zm1 0h-1v1h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1h1v1Zm0 0h1v1h-1v-1Zm2 2v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1Zm0 0v-1h-1v1h1Z"
            ></path>
          </svg>
        ),
        checked: candleType === "16",
      },
      {
        title: "Baseline",
        onClick: () => setCandleType("10"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
          >
            <path
              fill={color}
              d="m10.49 7.55-.42.7-2.1 3.5.86.5 1.68-2.8 1.8 2.82.84-.54-2.23-3.5-.43-.68Zm12.32 4.72-.84-.54 2.61-4 .84.54-2.61 4Zm-5.3 6.3 1.2-1.84.84.54-1.63 2.5-.43.65-.41-.65-1.6-2.5.85-.54 1.17 1.85ZM4.96 16.75l.86.52-2.4 4-.86-.52 2.4-4ZM3 14v1h1v-1H3Zm2 0h1v1H5v-1Zm2 0v1h1v-1H7Zm2 0h1v1H9v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Z"
            ></path>
          </svg>
        ),
        checked: candleType === "10",
      },
      {
        title: "Columns",
        onClick: () => setCandleType("13"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
          >
            <path
              fill={color}
              fillRule="evenodd"
              d="M12 7v14h5V7h-5Zm4 1h-3v12h3V8ZM19 15v6h5v-6h-5Zm4 1h-3v4h3v-4ZM5 12h5v9H5v-9Zm1 1h3v7H6v-7Z"
            ></path>
          </svg>
        ),
        checked: candleType === "13",
      },
      {
        title: "High-Low",
        onClick: () => setCandleType("12"),
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
            <path
              fill={color}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.5 7H7v14h5V7H7.5zM8 20V8h3v12H8zm7.5-11H15v10h5V9h-4.5zm.5 9v-8h3v8h-3z"
            ></path>
          </svg>
        ),
        checked: candleType === "12",
      },
      {
        title: "Heikin-Ashi",
        onClick: () => setCandleType("8"),
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width="28"
            height="28"
            fill={color}
          >
            <path d="M9 8v12h3V8H9zm-1-.502C8 7.223 8.215 7 8.498 7h4.004c.275 0 .498.22.498.498v13.004a.493.493 0 0 1-.498.498H8.498A.496.496 0 0 1 8 20.502V7.498z"></path>
            <path d="M10 4h1v3.5h-1z"></path>
            <path d="M17 6v6h3V6h-3zm-1-.5c0-.276.215-.5.498-.5h4.004c.275 0 .498.23.498.5v7c0 .276-.215.5-.498.5h-4.004a.503.503 0 0 1-.498-.5v-7z"></path>
            <path d="M18 2h1v3.5h-1z"></path>
          </svg>
        ),
        checked: candleType === "8",
      },
    ];
    const chartSaveModeSubnav = [
      {
        tooltip: "Save and Load charts privately to your own account.",
        title: "Private Mode",
        onClick: () => setChartSaveMode(0),
        icon: (
          <ShieldIcon
            style={{
              color,
            }}
          />
        ),
        checked: Number(chartSaveMode) === 0,
      },
      {
        tooltip:
          "Gain access to load the community's shared charts, Saved charts will be shared with the community.",
        title: "Community Mode",
        onClick: () => setChartSaveMode(1),
        icon: (
          <PeopleIcon
            style={{
              color,
            }}
          />
        ),
        checked: Number(chartSaveMode) === 1,
      },
    ];
    const checkedCandleTitle = candleSubnav.find((item) => item.checked)?.title;
    const checkedCandleIcon = candleSubnav.find((item) => item.checked)?.icon;
    const checkedResolution = resolutionSubnav.find(
      (item) => item.checked,
    )?.title;
    const checkedSaveModeTitle = chartSaveModeSubnav.find(
      (item) => item.checked,
    )?.title;
    const checkedSaveModeIcon = chartSaveModeSubnav.find(
      (item) => item.checked,
    )?.icon;
    const items = {
      Type: {
        title: checkedCandleTitle,
        className: "settings-page-menu-item",
        icon: checkedCandleIcon,
        subNav: candleSubnav,
      },

      Resolution: {
        title: checkedResolution,
        className: "settings-page-menu-item",
        icon: (
          <AccessTimeIcon
            style={{
              color,
            }}
          />
        ),
        subNav: resolutionSubnav,
      },

      SavingMode: {
        title: checkedSaveModeTitle,
        className: "settings-page-menu-item",
        icon: checkedSaveModeIcon,
        subNav: chartSaveModeSubnav,
      },

      Studies: {
        title: "Select Studies",
        className: "settings-page-menu-item",
        subNav: allIndicators
          .sort((a, b) => a.localeCompare(b))
          .map((indicator) => ({
            title: indicator,
            onClick: () => {
              // does the indicator in storage include this indicator
              if (defaultIndicators?.includes(indicator)) {
                // if so remove that indicator from storage and set it
                const newIndicators = defaultIndicators.filter(
                  (i) => i !== indicator,
                );
                setDefaultIndicators(newIndicators);
              } else {
                // otherwise add it to the storage list
                const indicators = cloneDeep(defaultIndicators);
                const newIndicators = [...indicators, indicator];
                setDefaultIndicators(newIndicators);
              }
            },
            icon: (
              <CandlestickChartIcon
                style={{
                  color,
                }}
              />
            ),
            checked: !!defaultIndicators?.includes(indicator),
          })),
      },
    };

    return items;
  }, [
    color,
    defaultIndicators,
    defaultResolution,
    candleType,
    defaultIndicators,
    chartSaveMode,
    notifications,
  ]);

  const setCandleType = (candleType) => {
    setUserSettings({
      ...userSettings,
      candleType,
    });
  };

  const setResolution = (defaultResolution) => {
    setUserSettings({
      ...userSettings,
      defaultResolution,
    });
  };
  const setDefaultIndicators = (defaultIndicators) => {
    setUserSettings({
      ...userSettings,
      defaultIndicators,
    });
  };
  const setChartSaveMode = (chartSaveMode) => {
    setUserSettings({
      ...userSettings,
      chartSaveMode,
    });
  };

  const renderMenuItems = (item) => {
    return (
      <React.Fragment>
        <NestedMenuItem
          className={item?.className || ""}
          label={
            <React.Fragment>
              {item.icon}
              <ListItemText
                style={{ paddingLeft: "6px" }}
                className={clsx(
                  classes.text,
                  "list-item-text",
                  item?.customClass || "",
                )}
              >
                {item.title}
              </ListItemText>
            </React.Fragment>
          }
          parentMenuOpen={true}
          rightIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M5.1714 7.00328L0.22168 2.05348L1.63589 0.639278L7.9999 7.00328L1.63589 13.3672L0.22168 11.953L5.1714 7.00328Z"
                fill="#CCCCCC"
              />
            </svg>
          }
        >
          {item.subNav.map((subItem, subIndex) => {
            return (
              <React.Fragment key={subIndex}>
                <ConditionalWrapper
                  condition={!!subItem?.tooltip}
                  wrapper={(children) => (
                    <Tooltip placement="top" title={subItem?.tooltip}>
                      {children}
                    </Tooltip>
                  )}
                >
                  <MenuItem
                    className="settings-page-custom-menu-item"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={subItem.onClick}
                  >
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText
                      className={clsx(
                        classes.text,
                        "settings-page-custom-list-item-text",
                      )}
                    >
                      {subItem.title}
                    </ListItemText>
                    {subItem.checked && (
                      <ListItemIcon className="settings-page-done-checked-icon">
                        <DoneIcon style={{ color }} />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                </ConditionalWrapper>
              </React.Fragment>
            );
          })}
        </NestedMenuItem>
      </React.Fragment>
    );
  };

  return (
    <div className="chart-settings-page">
      <div className="chart-settings-page__header">Chart Settings</div>
      <div className="chart-settings-page__subheader">
        Changes here will be reflected in all charts across TradeAlgo
      </div>

      <div className="chart-settings-page__content">
        <div className="chart-settings-page__content__menu">
          <div className="chart-settings-page__content__menu__item">
            <div className="chart-settings-page__content__menu__item__title">
              Chart Type
            </div>
            <div className="chart-settings-page__content__menu__item__data">
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={"100%"}
                  height={43}
                />
              ) : (
                renderMenuItems(menuData["Type"])
              )}
            </div>
          </div>

          <div className="chart-settings-page__content__menu__item">
            <div className="chart-settings-page__content__menu__item__title">
              Chart Resolution
            </div>
            <div className="chart-settings-page__content__menu__item__data">
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={"100%"}
                  height={43}
                />
              ) : (
                renderMenuItems(menuData["Resolution"])
              )}
            </div>
          </div>

          <div className="chart-settings-page__content__menu__item menu__item-space">
            <div className="chart-settings-page__content__menu__item__title">
              Chart Saving Mode
            </div>
            <div className="chart-settings-page__content__menu__item__data">
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={"100%"}
                  height={43}
                />
              ) : (
                renderMenuItems(menuData["SavingMode"])
              )}
            </div>
          </div>

          <div className="chart-settings-page__content__menu__item">
            <div className="chart-settings-page__content__menu__item__title">
              Default Chart Studies
            </div>
            <div className="chart-settings-page__content__menu__item__data">
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={"100%"}
                  height={43}
                />
              ) : (
                renderMenuItems(menuData["Studies"])
              )}
            </div>
          </div>
        </div>

        <div className="chart-settings-page__content__chart_preview">
          <div className="chart-settings-page__content__chart_preview__header">
            Example Chart
          </div>
          <div className="chart-settings-page__content__chart_preview__chart">
            {loading ? (
              <BeautifulSkeleton
                variant="rectangular"
                width={"100%"}
                height={620}
              />
            ) : (
              <ExampleChart
                symbol="AAPL"
                themeMode={capitalize(themeMode)}
                userSettings={userSettings}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(ChartSettingsPage);
