export const supportData = [
  {
    title: "Daytrade With Brian",
    items: [
      {
        title: "How do you use the dashboard/dark pools",
        description:
          "In order to confidently trade the top of the dashboard, we pay attention to the %s in the performance column and look for a strong outperformance on one side (Trending up or Trending down). I personally only use the dashboard during the first hour of the trading day, unless a new ticker shows up and continues to perform well. The top tickers on the green side (Trending up) indicate what the big money is interested in buying for option calls, and I focus on the top 3 or 4. The higher a ticker is on the dashboard, the greater the buying pressure. I feel most confident playing tickers that move up the dashboard.",
        id: 1,
      },
      {
        title: "How big should my trades be?",
        description:
          '<a href="https://docs.google.com/document/d/1iCQOLGSfVJmChE6So7hll-2Q0UNvzP2ZfUyDmOnaHnU/edit?usp=sharing" target="_blank" class="trading-alerts-anchor">Click this link</a> to access the position sizing document.',
        id: 2,
      },
      {
        title:
          "What’s the best trading platform? I can’t day trade, I have less than 25k.",
        description:
          "Trading platforms are like ice cream, everyone likes different flavors and has different preferences. I (Brian) started with and still use Webull and Robinhood, (Ross likes Webull but also uses ThinkorSwim) which have little to no fees for options trading and have cash accounts where you can do as many day trades with less than 25k that your cash allows. For those who don’t have $25,000 to open a margin account, cash accounts can be a good alternative. However, it is important to note that cash accounts do have certain restrictions. Each trade closed will take 1-2 business days to settle before the cash can be used for trading again. If you are considering opening a cash account for options trading, it is a good idea to research how they work. Additionally, it’s important to enable same day expiration date options and/or dates in your platforms settings to ensure the most efficient trading experience",
        id: 3,
      },
      {
        title: "Why don’t you place your trades in telegram?",
        description:
          "Our Telegram channel is designed for those seeking a more conservative and safe approach. We employ swing trades, where positions are held for a day or more, and adopt a slower pace to match this strategy. Despite the lower speed, our Telegram channel maintains a high success rate, and can be an excellent option for those who prefer a more measured approach to trading. It is worth mentioning that I have to monitor the dashboard, options chain for the appropriate strike and open interest and IV (implied volatility), pace the trade myself, and type the ticker, strike, exp date, position size, and cost of entering, and key words. This is very time consuming and telegram is meant for more technical analysis trades where swinging (holding a position longer than a calendar day) makes sense, not trades from the dashboard.",
        id: 4,
      },
      {
        title: "What do you mean by choppy market?",
        description:
          "A choppy market refers to a situation where the price movement in the market lacks a clear directional bias, making it challenging to leverage the momentum or capitalize on significant returns. During choppy market conditions, a conservative investment approach is typically advisable, with a focus on achieving smaller, yet consistent returns of 10-20%. While dark pools are not a foolproof predictor of market direction, they can be incredibly effective when the markets trajectory is apparent, regardless of whether it is moving up or down. However, in the current choppy market environment that has persisted since December 2022, it is important to exercise patience and wait for volume and market participants to return. In the meantime, there may be occasional opportunities to achieve exceptional returns of 100+% by identifying winning trades.",
        id: 5,
      },
      {
        title: "What indicators do you use on your charts?",
        description:
          "I believe that less is more. In order to identify meaningful patterns and establish reliable levels of support and resistance, I prefer to focus on the 1-hour and 4-hour charts for stocks. These larger time frames tend to exhibit greater adherence to the principles of supply and demand. When analyzing the SPY, I limit myself to the 5-minute chart, as this is the only ticker for which I consider this time frame to be suitable. However, during choppy market conditions, I restrict my analysis to charts no smaller than 15-minutes, as smaller time frames are even more prone to unreliability during such periods. I also incorporate technical indicators such as the 10, 50, 100, and 200 SMA (Simple Moving Averages), as well as the RSI (Relative Strength Index) on the 1-hour chart. Furthermore, I rely on my own drawn lines of support and resistance, patterns, volume, OBV indicator (On Balance Volume), and Supply and Demand. I refrain from utilizing any other indicators or techniques in order to maintain a simple and focused approach to my investments",
        id: 6,
      },
      {
        title: "Do you look at anything premarket/ aftermarket?",
        description:
          'I wait until the opening bell to assess the direction that the "big money" wants to take. I find it irrelevant to focus on pre-market or after-market activity, as this tends to be less indicative of the markets actual direction Often, the big players tend to do the opposite of what they were doing during these periods.While some investors may have mastered the pre-market game, I prefer to focus on following the 80% of market participants who move the market privately. These big money players typically dictate the direction of the stock from the opening bell and beyond. By focusing on their movements, I can more effectively navigate the market and make informed investment decisions',
        id: 7,
      },
      {
        title: "Where can I learn technical analysis?",
        description:
          "Trade Algo can be a valuable resource. I personally recommend checking out the Advanced Training page. If you’re looking to pick up technical analysis, like support and resistance and supply and demand, Dane provides video walkthroughs and livestreams to guide you through the basics. For a more personalized approach, I highly recommend considering the mentorship program offered on our platform. It provides tailored coaching and guidance, and you’ll gain a deeper understanding with the help of our trained analysts.",
        id: 8,
      },
      {
        title: "Why don’t you trade off the news?",
        description:
          "I will enter a trade if preferably multiple things line up with my bias. For instance - I could look at AAPL and see that it is: 1. In a bearish wedge and just broke support 2. Under all the SMAs on the 1hr chart 3. Overbought RSI 4. SPY is also going down Then I would feel more confident in jumping in the trade. When I am asked to look up a ticker just for the sake of looking up a ticker with no rhyme or reason I will often ignore it. If you are entering just because of one piece of news, or because it is already high and it should come back down - I personally believe that is gambling.I enter trades when multiple things line up to give the trade the highest probability of coming to fruition.",
        id: 9,
      },
      {
        title:
          "What are the 100+ tickers you look through every day 1 by 1 that are always on the dashboard/dark pools?",
        description:
          '<a href="https://docs.google.com/document/d/1DyFUvTDSa2_zOwOQaMbU8YnwHy5dqQIQU80lrgwh6yQ/edit?usp=sharing" target="_blank" class="trading-alerts-anchor">Click this link</a> to see a Google doc of the 118 stocks Brian looks through.',
        id: 10,
      },
      {
        title:
          "What are your color time frames on your charts on trading view?",
        description:
          "Black 5 min Red = 15 min Pink = 1 hr Orange = 4 hr Aqua green = daily Light purple = weekly Dark purple = monthly",
        id: 11,
      },
    ],
  },
];
