import CompanyPrice from "./CompanyPrice";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import React, { useMemo, useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { numberWithCommas } from "../utilities";
import { useMedia } from "react-media";
import ClosedIcon from "./assets/closed.svg";
import { dummyOptions } from "../TradingView/helpers";
import SparkLines from "../common/SparkLines";
import { sparklineDataLoad } from "../../appRedux/ducks/sparklineData";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";

const GLOBAL_MEDIA_QUERIES = {
  isTablet: "screen and (min-width: 1200px) and (max-width: 1600px)",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    position: "relative",
    padding: 0,
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.chartBackground,
    color: theme.palette.primary.text,
    // width: "98.8%",
    // width: '97%',
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    marginLeft: "4px",
  },
  background: {
    backgroundColor: theme.palette.primary.chartBackground,
    color: theme.palette.primary.text,
    height: "130px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  navLink: {
    marginTop: "60px",
    marginLeft: "0px",
    minWidth: "477px",
  },
  border: {
    borderLeft: `1px solid ${theme.palette.primary.slider}`,
    height: "151px",
    // marginTop: "17px",
    marginLeft: "15px",
    marginRight: "25px",
  },
  border2: {
    borderLeft: `1px solid ${theme.palette.primary.slider}`,
    height: "151px",
    // marginTop: "17px",
    marginRight: "10px",
  },
  hr: {
    marginLeft: "-27px",
    width: "174px",
    marginTop: 11,
    marginBottom: 10,
    borderColor: theme.palette.primary.slider,
  },
  seperator: {
    // marginLeft: "-15px",
    width: "275px",
    marginTop: 6,
    marginBottom: 6,
    borderColor: theme.palette.primary.slider,
  },
  borderColor: {
    borderColor: theme.palette.primary.slider,
  },
  ohlc: {
    color: theme.palette.primary.greyTitles,
    fontWeight: "600",
    fontSize: "14px",
  },
}));
const TabHeaders2 = (props) => {
  const classes = useStyles();
  const {
    tickerDetails,
    securityPrice,
    currentMarketStatus,
    symbols,
    lastPrice,
    setLastPrice,
    themeMode,
    loadSparkline,
    sparklineLoading,
    sparklineData,
  } = props;
  const { name, loading } = tickerDetails;
  const { isTablet } = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });
  const [tickerPriceLow, setTickerPriceLow] = useState({});
  const [tickerPriceHigh, setTickerPriceHigh] = useState({});
  const isStockBeingTraded = useMemo(() => {
    const todayDate = moment.tz("America/New_York").format("YYYY-MM-DD");
    const secDate = moment
      .tz(securityPrice.date, "America/New_York")
      .format("YYYY-MM-DD");
    return todayDate === secDate;
  }, [securityPrice.date]);

  const marketStatus = useMemo(() => {
    if (currentMarketStatus === "open" && !securityPrice?.open_price) {
      return "pre-open";
    }
    if (currentMarketStatus === "after" && !securityPrice?.close_price) {
      return "open";
    }
    return currentMarketStatus;
  }, [currentMarketStatus, securityPrice]);
  const ohlc = useMemo(() => {
    const open = securityPrice?.open_price || 0;
    const high = securityPrice?.bounds?.high || 0;
    const low = securityPrice?.bounds?.low || 0;
    const close =
      marketStatus === "after" || marketStatus === "closed"
        ? securityPrice?.close_price || 0
        : securityPrice?.prev_close_price || 0;

    let lastPriceUsed = {
      "pre-open": parseFloat(
        lastPrice || securityPrice?.last_price || 0,
      ).toFixed(2),
      open: parseFloat(lastPrice || securityPrice?.last_price || 0).toFixed(2),
      after: parseFloat(
        securityPrice?.close_price
          ? securityPrice?.close_price
          : lastPrice || securityPrice?.last_price || 0,
      ).toFixed(2),
      closed: parseFloat(securityPrice?.close_price || 0).toFixed(2),
    }[marketStatus];

    let compare = {
      "pre-open": parseFloat(
        securityPrice?.pre_open || securityPrice?.prev_close_price,
      ).toFixed(2),
      open: parseFloat(securityPrice.open_price).toFixed(2),
      after: parseFloat(securityPrice.open_price).toFixed(2),
      closed: parseFloat(securityPrice.open_price).toFixed(2),
    }[marketStatus];
    return {
      open,
      high,
      low,
      close,
      closeType: securityPrice?.close_price ? "Close" : "Previous Close",
      performancePercent: (
        ((lastPriceUsed - compare) / compare) *
        100
      )?.toFixed(2),
      positivePerformance: ((lastPriceUsed - compare) / compare) * 100 > 0,
      secTicker: securityPrice.bounds.ticker,
    };
  }, [JSON.stringify(securityPrice), marketStatus, lastPrice]);

  useEffect(() => {
    const keys = Object.keys(tickerPriceHigh);
    if (
      lastPrice >= ohlc.high &&
      symbols.value === ohlc.secTicker &&
      !!lastPrice
    ) {
      if (!keys.length) {
        setTickerPriceHigh({
          [ohlc.secTicker]: lastPrice,
        });
      } else {
        if (tickerPriceHigh[symbols.value] < lastPrice) {
          setTickerPriceHigh({
            [ohlc.secTicker]: lastPrice,
          });
        }
      }
    }
  }, [ohlc, symbols.value, lastPrice]);
  useEffect(() => {
    const keys = Object.keys(tickerPriceLow);
    if (
      lastPrice <= ohlc.low &&
      symbols.value === ohlc.secTicker &&
      !!lastPrice
    ) {
      if (!keys.length) {
        setTickerPriceLow({
          [ohlc.secTicker]: lastPrice,
        });
      } else {
        if (tickerPriceLow[symbols.value] > lastPrice) {
          setTickerPriceLow({
            [ohlc.secTicker]: lastPrice,
          });
        }
      }
    }
  }, [ohlc, symbols.value, lastPrice]);

  const bottomPerformance = useMemo(() => {
    let compare = {
      after: parseFloat(securityPrice?.close_price || 0).toFixed(2),
    }[marketStatus];

    let lastPriceUsed = {
      after: parseFloat(lastPrice || securityPrice?.last_price || 0).toFixed(2),
    }[marketStatus];

    const showedPrice = {
      after: lastPrice || securityPrice.last_price || 0,
    }[marketStatus];

    return {
      price: showedPrice,
      change: lastPriceUsed - compare,
      percent: (((lastPriceUsed - compare) / compare) * 100)?.toFixed(2),
    };
  }, [marketStatus, securityPrice, symbols.value, lastPrice]);

  useEffect(() => {
    if (symbols.value) {
      loadSparkline(symbols.value, 1);
      setTickerPriceHigh({});
      setTickerPriceLow({});
    }
  }, [symbols.value]);

  const bottomChange = useMemo(() => {
    return (
      <>
        {
          <span style={{ fontSize: "14px", display: "flex" }}>
            {isTablet && (
              <>
                <br />
              </>
            )}
            <span
              className={clsx(classes.text, "text-bold")}
              style={{ marginLeft: "5px" }}
            >
              {(bottomPerformance?.price || 0).toFixed(2)}
            </span>
            <span
              className="text-bold"
              style={{
                marginLeft: "5px",
                color: String(bottomPerformance.percent).includes("-")
                  ? "red"
                  : "rgba(84, 202, 19, 1)",
                whiteSpace: "nowrap",
              }}
            >
              {String(bottomPerformance.percent).includes("-") ? "" : "+ "}

              {bottomPerformance.change.toFixed(2)}
            </span>
            {bottomPerformance.change > 0 ? (
              <span
                style={{ marginLeft: "5px" }}
                className={`positive-text-color text-bold  price-change`}
              >
                ({"+" + numberWithCommas(bottomPerformance?.percent || "")}%)
              </span>
            ) : (
              <span
                style={{ marginLeft: "5px" }}
                className={`negative-text-color text-bold  price-change`}
              >
                ({numberWithCommas(bottomPerformance?.percent || "")}%)
              </span>
            )}
          </span>
        }
      </>
    );
  }, [bottomPerformance, isTablet]);
  const colors = useMemo(() => {
    let sparklines = ohlc.positivePerformance ? "#5EEECD" : "#F32228";
    return {
      sparklines,
    };
  }, [ohlc]);
  const updatedOptions = useMemo(() => {
    return {
      ...dummyOptions,
      colors: [colors.sparklines],
    };
  }, [colors]);
  const todaysDate = moment().format("MMMM Do, YYYY");
  const CompPrice = useMemo(() => {
    return (
      <CompanyPrice
        lastPrice={lastPrice}
        setLastPrice={setLastPrice}
        ticker={symbols.value}
        marketStatus={marketStatus}
        securityPrice={securityPrice}
      />
    );
  }, [lastPrice, symbols.value, marketStatus, securityPrice]);
  const highToShow = useMemo(() => {
    const keys = Object.keys(tickerPriceHigh);
    if (
      !!keys.length &&
      keys[0] === ohlc.secTicker &&
      ohlc.high < tickerPriceHigh[ohlc.secTicker] &&
      symbols.value === keys[0]
    ) {
      return tickerPriceHigh[ohlc.secTicker];
    }
    return ohlc.high;
  }, [symbols.value, tickerPriceHigh, ohlc]);
  const lowToShow = useMemo(() => {
    const keys = Object.keys(tickerPriceLow);
    if (
      !!keys.length &&
      keys[0] === ohlc.secTicker &&
      ohlc.low > tickerPriceLow[ohlc.secTicker] &&
      symbols.value === keys[0]
    ) {
      return tickerPriceLow[ohlc.secTicker];
    }
    return ohlc.low;
  }, [symbols.value, tickerPriceLow, ohlc]);
  return (
    <div className={clsx("container", classes.root)}>
      <div className="tab-header-container">
        <div className={`company-info company-info-${themeMode}`}>
          <div className="company-info-content">
            <div className="company-status">
              <div className={`company-symbol company-symbol-${themeMode}`}>
                {symbols.value}:US
              </div>
              <div className="market-status">
                {tickerDetails.loading && (
                  <BeautifulSkeleton width={100} animation="wave" />
                )}
                {marketStatus === "pre-market" &&
                isStockBeingTraded &&
                !tickerDetails.loading ? (
                  <>
                    Pre-market
                    {/* {bottomChange} */}
                  </>
                ) : null}
                {marketStatus === "after" &&
                isStockBeingTraded &&
                securityPrice.close_price !== 0 &&
                !tickerDetails.loading ? (
                  <>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      After Hours
                    </span>
                    <span className="flex">{bottomChange}</span>
                  </>
                ) : null}
                {marketStatus === "closed" && !tickerDetails.loading ? (
                  <>
                    <img
                      src={ClosedIcon}
                      alt="market is closed"
                      className="market-status-icon"
                    />
                    Closed
                  </>
                ) : null}
              </div>
            </div>
            <div className="company-name">
              {loading ? (
                <BeautifulSkeleton width={80} animation="wave" />
              ) : (
                name
              )}
            </div>
            <div className="company-price-info">
              {tickerDetails.loading ? (
                <BeautifulSkeleton
                  animation="wave"
                  height={"70px"}
                  width={180}
                  style={{ marginBottom: "25px" }}
                />
              ) : (
                CompPrice
              )}
            </div>
          </div>
          <div className="company-info-chart">
            {sparklineLoading ? (
              <div className="pad">
                <BeautifulSkeleton width={200} />
                <BeautifulSkeleton />
                <BeautifulSkeleton />
                <BeautifulSkeleton />
              </div>
            ) : (
              <SparkLines
                series={[
                  {
                    data: sparklineData.map((el) =>
                      Number(el.close).toFixed(2),
                    ),
                  },
                ]}
                options={updatedOptions}
                type={updatedOptions.chart.type}
                height={updatedOptions.chart.height}
                width={updatedOptions.chart.width}
              />
            )}
          </div>
        </div>

        <div className={`performance-summary performance-summary-${themeMode}`}>
          <div className="performance-summary-header">
            <div
              className={`performance-summary-header-title performance-summary-header-title-${themeMode}`}
            >
              Performance Summary
            </div>
            <div className="performance-summary-header-date">
              {tickerDetails.loading ? (
                <BeautifulSkeleton width={"74%"} animation="wave" />
              ) : (
                todaysDate
              )}
            </div>
          </div>

          <div className="performance-summary-content">
            <div className="performance-summary-content-item">
              <div className="performance-summary-content-item-title">
                {ohlc.closeType}
              </div>
              <div
                className="performance-summary-content-item-value"
                style={{ color: themeMode === "light" ? "#000" : "#FFF" }}
              >
                {tickerDetails.loading ? (
                  <BeautifulSkeleton width={100} animation="wave" />
                ) : (
                  Number(ohlc.close).toFixed(2)
                )}
              </div>
            </div>
            <div className="performance-summary-content-item">
              <div className="performance-summary-content-item-title">Open</div>
              <div
                className="performance-summary-content-item-value"
                style={{ color: themeMode === "light" ? "#000" : "#FFF" }}
              >
                {tickerDetails.loading ? (
                  <BeautifulSkeleton width={100} animation="wave" />
                ) : (
                  Number(ohlc.open).toFixed(2)
                )}
              </div>
            </div>
            <div className="performance-summary-content-item">
              <div className="performance-summary-content-item-title">
                Performance
              </div>
              <div
                className={`performance-summary-content-item-value ${!ohlc.positivePerformance ? "negative-color" : "positive-color"}`}
              >
                {tickerDetails.loading ? (
                  <BeautifulSkeleton width={100} animation="wave" />
                ) : (
                  <>
                    {ohlc.positivePerformance && "+"}
                    {Number(ohlc.performancePercent).toFixed(2)}%
                  </>
                )}
              </div>
            </div>
            <div className="performance-summary-content-item">
              <div className="performance-summary-content-item-title">
                Day Range
              </div>
              <div
                className="performance-summary-content-item-value"
                style={{ color: themeMode === "light" ? "#000" : "#FFF" }}
              >
                {tickerDetails.loading ? (
                  <BeautifulSkeleton width={100} animation="wave" />
                ) : (
                  `$${Number(lowToShow).toFixed(2) + " - $" + Number(highToShow).toFixed(2)}`
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  darkflowCards: state.darkflow,
  sparklineData: state.sparklineData.sparklineData,
  sparklineLoading: state.sparklineData.loading,
  marketStatus: state.companyData.companyData.marketState,
  themeMode: state.themeMode.themeMode,
  symbols: state.symbol.symbol,
  tickerDetails: state.companyData.companyData.tickerDetails,
});

const dispatchToProps = (dispatch) => ({
  loadSparkline: (symbol, resolution) =>
    dispatch(sparklineDataLoad(symbol, resolution)),
});

export default connect(stateToProps, dispatchToProps)(TabHeaders2);
