import MTZ from "moment-timezone";
export const dummyOptions = {
  colors: "",
  chart: {
    type: "area",
    width: "100%",
    height: 120,
    sparkline: {
      enabled: true,
    },
  },
  tooltip: {
    theme: "dark",
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        // eslint-disable-next-line no-unused-vars
        formatter(seriesName, s) {
          return "$";
        },
      },
    },
  },
  stroke: {
    curve: "smooth",
    width: 1.5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 0.5,
      opacityFrom: 0.7,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
};
export default function convertCandles(bars) {
  //create candles based off resolution
  let candles = [];
  let firstCandle = bars[0];
  if (firstCandle.ticker) {
    candles.push({
      ...firstCandle,
      close: Number(firstCandle.last),
      time: MTZ.tz(
        firstCandle.date + " " + firstCandle.openTime,
        "America/New_York",
      ).valueOf(),
    });
  } else {
    candles.push({
      ...firstCandle,
      time: MTZ.tz(
        firstCandle.time.slice(0, -5).split("T")[0] +
          " " +
          firstCandle.time.slice(0, -5).split("T")[1],
        "America/New_York",
      ).valueOf(),
    });
  }
  for (let i = 1; i < bars.length; i++) {
    let candle = bars[i];
    if (candle.ticker) {
      candle = {
        ...candle,
        close: Number(candle.last),
        time: MTZ.tz(
          candle.date + " " + candle.openTime,
          "America/New_York",
        ).valueOf(),
      };
    } else {
      candle = {
        ...candle,
        time: MTZ.tz(
          candle.time.slice(0, -5).split("T")[0] +
            " " +
            candle.time.slice(0, -5).split("T")[1],
          "America/New_York",
        ).valueOf(),
      };
    }
    if (candle.time - candles[candles.length - 1].time >= 300000) {
      candles.push(candle);
    } else {
      const lastCandle = candles[candles.length - 1];
      if (candle.high > lastCandle.high) {
        lastCandle.high = Number(candle.high);
      }
      lastCandle.volume += Number(candle.volume);
      lastCandle.close = Number(candle.close);
      if (candle.low < lastCandle.low) {
        lastCandle.low = Number(candle.low);
      }
    }
  }
  return candles.map((bar) => ({
    ...bar,
    close: Number(bar.close),
    high: Number(bar.high),
    low: Number(bar.low),
    open: Number(bar.open),
  }));
}
