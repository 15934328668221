/* eslint-disable */
import { Helmet } from "react-helmet";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { historicalDarkflowLoad } from "../../../appRedux/ducks/historicalDarkflow";
import { decimal } from "../../homePage/TradeAlgoCard";
import { symbolSetter } from "../../../appRedux/ducks/symbol";
import DarkflowTabs from "../Tabs/DarkflowTabs";
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import TradeAlgoTable from "./TradeAlgoTable";

export const calculateDifference = (removed_price, added_price) => {
  const perf = (parseFloat(removed_price) / parseFloat(added_price) - 1) * 100;
  return decimal(perf);
};
const calculateTimeDifference = (remove_date, add_date) => {
  const remove = moment(remove_date);
  const add = moment(add_date);

  const daysDifference = remove.diff(add, "days");
  if (daysDifference > 0) {
    return daysDifference === 1
      ? `${daysDifference} day`
      : `${daysDifference} days`;
  } else {
    const hoursDifference = remove.diff(add, "hours");

    if (hoursDifference > 0) {
      return hoursDifference === 1
        ? `${hoursDifference} hour`
        : `${hoursDifference} hours`;
    } else {
      const minutesDifference = remove.diff(add, "minutes");
      if (minutesDifference > 0) {
        return minutesDifference === 1
          ? `${minutesDifference} minute`
          : `${minutesDifference} minutes`;
      } else {
        return "1 min";
      }
    }
  }
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.contrastBackground} !important`,
    color: theme.palette.primary.text,
    "& .text-boldest": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
    "& .text-bold": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
  },
  popupBackground: {
    backgroundColor: `${theme.palette.primary.contrastBackground}!important`,
    color: theme.palette.primary.text,
    boxShadow: theme.palette.primary.popBoxShadow,
    "& .text-boldest": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
    "& .text-bold": {
      backgroundColor: theme.palette.primary.contrastBackground,
      color: theme.palette.primary.text,
    },
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
}));

const DarkFlowHistory = ({
  getHistoricDarkFlow,
  historicalDarkflow,
  setSymbol,
  loading,
  themeMode,
}) => {
  const [cap, setCap] = useState("");
  const [up, setUp] = useState(window.location.pathname.includes("Up"));
  const classes = useStyles();
  const trendingStatus = up ? "up" : "down";
  useEffect(() => {
    getHistoricDarkFlow(trendingStatus);
  }, [trendingStatus]);
  const data = useMemo(() => {
    let duplicatedData = [...historicalDarkflow];
    let tickersArray = [];
    let formattedData = [];

    duplicatedData.forEach((item) => {
      tickersArray.push(item.ticker);
    });
    tickersArray = [...new Set(tickersArray)];
    tickersArray.forEach((ticker) => {
      // get first and last element of each ticker;
      let targets = duplicatedData.filter((obj) => obj.ticker === ticker);
      const firstObject = targets[targets.length - 1];
      const lastObject = targets[0];

      const obj = { ...firstObject };
      obj.removed_price = lastObject.removed_price;
      obj.date_remove = lastObject.date_remove;

      formattedData.push(obj);
    });

    if (up) {
      // show positive records only
      formattedData = formattedData.filter(
        (obj) => calculateDifference(obj.removed_price, obj.added_price) > 0,
      );
    } else {
      // show negative records only
      formattedData = formattedData.filter(
        (obj) => calculateDifference(obj.removed_price, obj.added_price) <= 0,
      );
    }

    formattedData.sort(function (a, b) {
      let keyA = calculateDifference(a.removed_price, a.added_price),
        keyB = calculateDifference(b.removed_price, b.added_price);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    if (cap === "small")
      formattedData = formattedData.filter(
        (item) => Number(item.added_price) <= 50,
      );
    else if (cap === "medium")
      formattedData = formattedData.filter(
        (item) =>
          Number(item.added_price) > 50 && Number(item.added_price) <= 150,
      );
    else if (cap === "large")
      formattedData = formattedData.filter(
        (item) => Number(item.added_price) > 150,
      );
    return formattedData.sort((a, b) => {
      let performanceA =
        calculateDifference(a.removed_price, a.added_price) > 0
          ? `+${calculateDifference(a.removed_price, a.added_price)}`
          : calculateDifference(a.removed_price, a.added_price);
      let performanceB =
        calculateDifference(b.removed_price, b.added_price) > 0
          ? `+${calculateDifference(b.removed_price, b.added_price)}`
          : calculateDifference(b.removed_price, b.added_price);
      return up ? performanceB - performanceA : performanceA - performanceB;
    });
  }, [up, cap, historicalDarkflow]);
  const flex = 1;
  const columns = [
    // { field: 'idNo', headerName: "#", flex, renderCell: params => {
    //   return <div style={{ position: "relative", left: "3px"}}>
    //   {params.value }
    // </div>
    // }},
    {
      field: "ticker",
      headerName: "TICKER",
      flex,
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              setSymbol({
                type: "",
                value: params.value,
              });
            }}
            style={{
              position: "relative",
              left: "8px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <Link
              className={classes.text}
              style={{ color: "black" }}
              to={`/company/${params.value}`}
            >
              {params.value}
            </Link>
          </div>
        );
      },
    },
    {
      field: "from",
      headerName: "FROM",
      flex,
      renderCell: (params) => {
        return (
          <div style={{ position: "relative" }}>
            <span className={"item-from"}>
              <div className={clsx(classes.text, "history-item-value")}>
                ${decimal(parseFloat(params.value))}
              </div>
              <div className={clsx(classes.text, "history-item-time")}>
                {moment
                  .parseZone(params.row.date_added)
                  .format("MMM Do h:mm A")}
              </div>
            </span>
          </div>
        );
      },
      sortComparator: (v1, v2, param1, param2) =>
        decimal(parseFloat(v1)) - decimal(parseFloat(v2)),
    },
    {
      field: "to",
      headerName: "TO",
      flex,
      renderCell: (params) => {
        return (
          <div
            style={{
              position: "relative",
            }}
          >
            <div className={classes.text}>
              ${decimal(parseFloat(params.value))}
            </div>
            <div className={classes.text}>
              {moment.parseZone(params.row.date_remove).format("MMM Do h:mm A")}
            </div>
          </div>
        );
      },
      sortComparator: (v1, v2, param1, param2) =>
        decimal(parseFloat(v1)) - decimal(parseFloat(v2)),
    },
    {
      field: "irregularVol",
      headerName: "IRREGULAR VOL",
      flex,
      renderCell: (params) => {
        return (
          <div
            style={{
              height: "27px",
              width: "auto",
              background: up
                ? "rgba(13, 173, 96, 0.05)"
                : themeMode === "dark"
                  ? "rgb(46 4 4)"
                  : "rgb(255, 238, 238",
              color: up
                ? "rgba(13, 173, 96, 1) !important"
                : "rgb(241, 0, 6) !important",
              fontSize: "14px",
              fontWeight: "600",
              borderRadius: "25px",
              position: "relative",
              padding: "10px",
              left: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: up ? "rgba(13, 173, 96, 1)" : "rgb(241, 0, 6) ",
              }}
            >
              {parseFloat(Math.round(params.value * 100) / 100)}x
            </span>
          </div>
        );
      },
      sortComparator: (v1, v2, param1, param2) =>
        parseFloat(Math.round(v1 * 100) / 100) -
        parseFloat(Math.round(v2 * 100) / 100),
    },
    {
      field: "diff",
      headerName: "PERCENT CHANGE",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              position: "relative",
              top: "-2px",
              left: "30px",
            }}
          >
            <div
              className={clsx(
                classes.text,
                `history-item-value ${
                  calculateDifference(
                    params.value.removed_price,
                    params.value.added_price,
                  ) > 0
                    ? "green-color"
                    : "red-color"
                }`,
              )}
            >
              {calculateDifference(
                params.value.removed_price,
                params.value.added_price,
              ) > 0
                ? `+${calculateDifference(
                    params.value.removed_price,
                    params.value.added_price,
                  )}`
                : calculateDifference(
                    params.value.removed_price,
                    params.value.added_price,
                  )}
              %
            </div>
            <div className={clsx(classes.text, "history-item-time")}>
              {calculateTimeDifference(
                params.value.date_remove,
                params.value.date_added,
              )}
            </div>
          </div>
        );
      },
      sortComparator: (v1, v2, param1, param2) =>
        calculateDifference(
          param1.value.removed_price,
          param1.value.added_price,
        ) -
        calculateDifference(
          param2.value.removed_price,
          param2.value.added_price,
        ),
    },
  ];

  const rows =
    data?.map((item, i) => ({
      ...item,
      id: i + 1,
      idNo: i + 1,
      ticker: item.ticker,
      from: item.added_price,
      to: item.removed_price,
      irregularVol: item.json_record.multiplier,
      diff: item,
    })) ?? [];

  const [sortModel, setSortModel] = useState([
    {
      field: "irregularVol",
      sort: "desc",
    },
  ]);
  return (
    <div>
      <Helmet>
        <title>Historical | TradeAlgo</title>
      </Helmet>
      <div className="padding-15-row">
        <div className={classes.background + " card"}>
          <div className="card-body">
            <DarkflowTabs
              setCap={setCap}
              history={true}
              up={up}
              setUp={setUp}
            />
            <TradeAlgoTable
              rows={rows}
              columns={columns}
              loading={loading}
              sortModel={sortModel}
              setSortModel={setSortModel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  historicalDarkflow: state.historicalDarkflow.historicalDarkflow,
  loading: state.historicalDarkflow.loading,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  getHistoricDarkFlow: (trendingStatus) =>
    dispatch(historicalDarkflowLoad(trendingStatus)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});

export default connect(stateToProps, dispatchToProps)(DarkFlowHistory);
