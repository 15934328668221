import React from "react";
import { connect } from "react-redux";
import "./PieChartForSentiment.scss";

const COLORS = ["#4CAF50", "#EA3323"];

const PieChartForSentiment = ({ data, label, percentage, themeMode }) => {
  let total = 0;
  const values = data
    .map((value, index) => {
      const color = COLORS[index % COLORS.length];
      const separatorWidth = 0.5;
      const slice = `${color} ${total}% ${
        (total += value - separatorWidth) - 0.2
      }%`;
      const separator = `${themeMode === "light" ? "white" : "black"} ${total}% ${(total += separatorWidth) - 0.2}%`;
      return `${slice}, ${separator}`;
    })
    .join(", ");
  const gradient = `conic-gradient(${values})`;

  return (
    <div className="pie-chart-for-sentiment" style={{ background: gradient }}>
      <div className="pie-chart-for-sentiment__center">
        <div className="pie-chart-for-sentiment-information">
          <div className="pie-chart-for-sentiment-information-title">
            Sentiment
          </div>
          <div className="pie-chart-for-sentiment-information-percentage">
            {percentage}%
          </div>
          <div
            className={`pie-chart-for-sentiment-information-label ${label === "Bullish" ? "label--bullish" : label === "Bearish" ? "label--bearish" : "label--neutral"}`}
          >
            {label}
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(PieChartForSentiment);
