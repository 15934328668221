import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./index.scss";
import {
  courseDataMap,
  courseTitleMap,
  totalDurationMap,
} from "./optionsVideoMetadata";
import VideoPage from "./VideoPage";
import Telegram from "./assets/Telegram.png";
import Button from "@mui/material/Button";
import Brian from "./assets/Brian.JPG";
import taLogoDark from "./assets/ta-logo-dark.png";
import taLogoWhite from "./assets/ta-logo-white.png";
import TelegramWhite from "./assets/Telegram-White.png";
import { connect } from "react-redux";
import OptionsAcademyChat from "../Chat/OptionsAcademyChat";
import VideoCardsContainer from "./VideoCardsContainer";

const OptionsAcademy = ({ themeMode }) => {
  const { main, sub } = useParams();
  const [currentNav, setCurrentNav] = useState(main);
  const [courseTab, setCourseTab] = useState(sub || "fundamentals");
  const [currentVideo, setCurrentVideo] = useState({});
  const hardHistory = useHistory();
  useEffect(() => {
    if (main) {
      if (currentNav === "courses") {
        hardHistory.push(`/options-academy/${currentNav}/${courseTab}`);
      } else {
        hardHistory.push(`/options-academy/${currentNav}`);
      }
    }
  }, [currentNav, courseTab]);
  const navigateTabs = (tab) => {
    setCurrentVideo({});
    setCurrentNav(tab);
  };
  const allVideos = useMemo(() => {
    return courseDataMap[courseTab];
  }, [courseTab]);
  const time = currentNav === "livestream" ? "2:00pm" : "11:30am";
  return (
    <div>
      <div>
        <div>
          <div className="pl-20 platinum-program-page-navigator mw-800">
            <div
              className={`platinum-program-page-navItem ${currentNav === "real-time-alerts" ? "active" : ""}`}
              onClick={() => navigateTabs("real-time-alerts")}
            >
              Real-time Alerts
            </div>
            <div
              className={`platinum-program-page-navItem ${currentNav === "courses" ? "active" : ""}`}
              onClick={() => navigateTabs("courses")}
            >
              Options Academy
            </div>
            <div
              className={`platinum-program-page-navItem ${currentNav === "livestream" ? "active" : ""}`}
              onClick={() => navigateTabs("livestream")}
            >
              Livestream
            </div>
            <div
              className={`platinum-program-page-navItem ${currentNav === "support" ? "active" : ""}`}
              onClick={() => navigateTabs("support")}
            >
              Support
            </div>
          </div>
          <div className="flex-col pl-20">
            {currentNav !== "real-time-alerts" && (
              <div className="logo-title-wrap">
                <img
                  className="ta-logo-oa"
                  src={themeMode === "dark" ? taLogoWhite : taLogoDark}
                />
                <div className="new-title">Options Academy</div>
              </div>
            )}
          </div>
          {currentNav === "courses" && (
            <div className="pl-20 courses-wrapper">
              {!currentVideo?.videoUrl ? (
                <>
                  <div className="oa-inner-subtitle">
                    Welcome to Trade Algo Options Academy! Our structured
                    video-based curriculum simplifies the complexities of
                    options trading so you can reinforce your learning anytime.
                  </div>
                  <div className=" mw-930 pt-40 platinum-program-page-navigator">
                    <div
                      className={`platinum-program-page-navItem ${courseTab === "fundamentals" ? "active" : ""}`}
                      onClick={() => setCourseTab("fundamentals")}
                    >
                      Fundamentals
                    </div>
                    <div
                      className={`platinum-program-page-navItem ${courseTab === "intermediate" ? "active" : ""}`}
                      onClick={() => setCourseTab("intermediate")}
                    >
                      Intermediate
                    </div>
                    <div
                      className={`platinum-program-page-navItem ${courseTab === "leaps" ? "active" : ""}`}
                      onClick={() => setCourseTab("leaps")}
                    >
                      L.E.A.P.S
                    </div>
                    <div
                      className={`platinum-program-page-navItem ${courseTab === "cash-secured-puts" ? "active" : ""}`}
                      onClick={() => setCourseTab("cash-secured-puts")}
                    >
                      Cash Secured Puts
                    </div>
                    <div
                      className={`platinum-program-page-navItem ${courseTab === "advanced" ? "active" : ""}`}
                      onClick={() => setCourseTab("advanced")}
                    >
                      Advanced
                    </div>
                  </div>
                  <div className="courses-title-wrapper">
                    <span className="course-title">
                      {courseTitleMap[courseTab]}
                    </span>
                    <span className="course-subtitle">
                      {totalDurationMap[courseTab].totalVideos} lessons •{" "}
                      {totalDurationMap[courseTab].duration}
                    </span>
                  </div>
                  <VideoCardsContainer
                    courses={courseDataMap[courseTab]}
                    setCurrentVideo={setCurrentVideo}
                    type={courseTab}
                    courseName={courseTitleMap[courseTab]}
                  />
                </>
              ) : (
                <VideoPage
                  allVideos={allVideos}
                  currentVideo={currentVideo}
                  setCurrentVideo={setCurrentVideo}
                  courseTab={courseTab}
                  setCourseTab={setCourseTab}
                />
              )}
            </div>
          )}

          <div
            className={`${currentNav === "real-time-alerts" ? "d-none" : ""} support-livestream-container`}
          >
            {(currentNav === "livestream" || currentNav === "support") && (
              <>
                {/* Live Streams section */}
                <div className="live-chat-video-wrapper">
                  <div className="livestream-wrap">
                    <iframe
                      className="live-stream-event-video"
                      src={
                        currentNav === "livestream"
                          ? "https://vimeo.com/event/3495126/embed"
                          : "https://vimeo.com/event/3495152/embed"
                      }
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      title="Livestream Learning"
                      frameBorder="0"
                    ></iframe>
                  </div>
                  <div className="chat-wrap">
                    <iframe
                      src={
                        currentNav === "livestream"
                          ? "https://vimeo.com/event/3495126/chat/"
                          : "https://vimeo.com/event/3495152/chat/"
                      }
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      title="livestream-chat"
                    ></iframe>
                  </div>
                </div>
                <div className="platinum-program-page-live-streams-info">
                  <div className="platinum-program-page-live-streams-info-header bottom-flex-container">
                    <div className="card-bg">
                      <div className="d-flex">
                        <img
                          src={Brian}
                          alt="Brian"
                          className="live-streams-author brian-profile"
                        />
                        <div className="platinum-program-page-live-streams-author-info">
                          <div className="platinum-program-page-live-streams-author-name">
                            Bronco Anderson
                          </div>
                          <div className="platinum-program-page-live-streams-author-job">
                            TradeAlgo Professional Analyst
                          </div>
                        </div>
                      </div>
                      <div className="platinum-program-page-live-streams-info-content">
                        <div className="platinum-program-page-live-streams-title">
                          Options Academy Livestream
                        </div>
                        <div className="platinum-program-page-live-streams-description">
                          This is a live trading experience where viewers can
                          learn from a professional trader, Bronco, as he shares
                          his desktop platform and provides analysis, trading
                          tactics, and risk management strategies. The show
                          offers insights into price action on charts and
                          empirical reasons for projected profits.
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="platinum-program-page-live-streams-schedule-title">
                          Live Schedule
                        </div>
                        <div className="platinum-program-page-live-streams-schedule-list">
                          <div className="platinum-program-page-live-streams-schedule-day-item">
                            <div className="platinum-program-page-live-streams-schedule-day">
                              Mon
                            </div>
                            <div className="platinum-program-page-live-streams-schedule-time">
                              {time} EST
                            </div>
                          </div>

                          <div className="platinum-program-page-live-streams-schedule-day-item">
                            <div className="platinum-program-page-live-streams-schedule-day">
                              Tue
                            </div>
                            <div className="platinum-program-page-live-streams-schedule-time">
                              {" "}
                              {time} EST
                            </div>
                          </div>

                          <div className="platinum-program-page-live-streams-schedule-day-item">
                            <div className="platinum-program-page-live-streams-schedule-day">
                              Wed
                            </div>
                            <div className="platinum-program-page-live-streams-schedule-time">
                              {time} EST
                            </div>
                          </div>

                          <div className="platinum-program-page-live-streams-schedule-day-item">
                            <div className="platinum-program-page-live-streams-schedule-day">
                              Thu
                            </div>
                            <div className="platinum-program-page-live-streams-schedule-time">
                              {time} EST
                            </div>
                          </div>

                          <div className="platinum-program-page-live-streams-schedule-day-item">
                            <div className="platinum-program-page-live-streams-schedule-day">
                              Fri
                            </div>
                            <div className="platinum-program-page-live-streams-schedule-time">
                              {time} EST
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trade-alerts-container">
                      <div className="description-blocks mw-n mt-0 br-10">
                        <span className="title">Trade Alerts and Signals</span>
                        <div className="image-title-description">
                          <img
                            src={
                              themeMode === "light" ? Telegram : TelegramWhite
                            }
                            className="bell"
                          />
                          <div className="title-description">
                            <span className="inner-title">
                              Join the Community
                            </span>
                            <span className="inner-description">
                              Bronco provides profitable trade alerts in our
                              exclusive telegram group. Join the community and
                              start making smarter trades.
                            </span>
                          </div>
                        </div>
                        <Button
                          onClick={() =>
                            window.open(
                              "https://t.me/+RL6bcEckMI82ZTYx",
                              "_blank",
                            )
                          }
                          className="supports-widget-item-button"
                        >
                          Join Telegram
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {currentNav === "real-time-alerts" && (
            <>
              <OptionsAcademyChat />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(OptionsAcademy);
