import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Company from "./Company";
import UnusualActivity from "./UnusualActivity";
import "./index.scss";
import "./Equity.scss";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { FundamentalData } from "react-tradingview-embed";
import { numberWithCommas } from "../utilities";
import { makeStyles } from "@material-ui/core";
import LiveOptionsContainer from "../LiveOptions/LiveOptionsContainer";
import { companyDataLoad } from "../../appRedux/ducks/companyData";
import clsx from "clsx";
import { useAuth } from "../../contexts/Auth";
import { optionExpiryLoad } from "../../appRedux/ducks/optionExpirations";
import OptionScannerContainer from "../OptionScanner/OptionScannerContainer";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Sentiment from "./Sentiment";
import TabHeaders2 from "./TabHeaders2";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const ExpandMore = styled((props) => {
  const { expand: _expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.tableBackground,
    "& .card": {
      border: "none !important",
      borderRadius: "24px",
    },
  },
  bottom: {
    backgroundColor: theme.palette.primary.tableBackground,
    color: theme.palette.primary.text,
  },
  description: {
    color: theme.palette.primary.description,
  },
}));
const SecurityContainer = (props) => {
  const { canSeeInternal, loading } = useAuth();
  const classes = useStyles();
  const {
    companyDataLoading,
    symbols,
    setSymbol,
    tickerDetails,
    fetchCompanyData,
    themeMode,
    optionExpiry,
    getOptionExpirations,
    symbolToken,
    securityPrice,
    marketStatus,
  } = props;

  const { symbol } = useParams();

  useEffect(() => {
    fetchCompanyData(symbols.value);
    getOptionExpirations(symbols.value);
  }, [symbols.value]);

  const symbolMemo = useMemo(() => {
    return symbols.value.length ? symbols.value : symbol;
  }, [symbols.value]);
  const [expanded, setExpanded] = useState(true);
  const [expandedOptions, setExpandedOptions] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setSymbol({
      type: "company",
      value: symbol,
    });
  }, []);

  const fundamentalWidget = useMemo(() => {
    if (symbols.value) {
      return (
        <div className={classes.card}>
          <div className={clsx(classes.card, "hide-scroll card")}>
            <div
              className={classes.card + " card-body"}
              style={{
                borderRadius: "16px",
                width: "auto",
                border: "none",
              }}
            >
              <FundamentalData
                className="hide-scroll"
                widgetProps={{
                  symbol: symbols.value,
                  colorTheme: themeMode,
                  width: "100%",
                  isTransparent: themeMode === "light",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  }, [symbols.value, themeMode]);
  const unusualActivity = useMemo(() => {
    return <UnusualActivity ticker={symbolMemo} />;
  }, [symbolMemo]);

  const [lastPrice, setLastPrice] = useState("");
  const [currentTab, setCurrentTab] = useState("summary");
  const tickerSymbol = useMemo(() => {
    return symbolToken.value.length ? symbolToken.value : symbolMemo;
  }, [symbolToken.value, symbolMemo]);
  return (
    <div className="security-page">
      <div className="row">
        <div className="tab-header-wrapper">
          <TabHeaders2
            lastPrice={lastPrice}
            setLastPrice={setLastPrice}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            type="company"
            ticker={tickerSymbol}
            followType="companies"
            pinType="CompanyPrice"
            securityPrice={securityPrice}
            currentMarketStatus={marketStatus}
          />
        </div>

        <div className={"stock-wrapper"}>
          <Company
            ticker={symbolMemo}
            securityPrice={securityPrice}
            currentMarketStatus={marketStatus}
            lastPrice={lastPrice}
            setLastPrice={setLastPrice}
            currentTab={currentTab}
          />
        </div>

        <div className={"fun-joyride-target"}>{fundamentalWidget}</div>
      </div>
      {tickerDetails.notFound ? null : tickerDetails.ticker ||
        companyDataLoading ? (
        <div
          className={clsx(
            classes.bottom,
            "row trade-algo-equity-page-info-row",
          )}
        >
          <div className={"equity-page-info-left-part"}>
            {companyDataLoading ? (
              <div className={classes.description} id="parent">
                <BeautifulSkeleton width={"100%"} />
                <BeautifulSkeleton width={"100%"} />
                <BeautifulSkeleton width={"100%"} />
                <BeautifulSkeleton width={"100%"} />
                <BeautifulSkeleton width={"100%"} />
              </div>
            ) : tickerDetails.ticker === symbols.value ? (
              tickerDetails.description
            ) : (
              "N/A"
            )}
          </div>

          <div className={"equity-page-info-right-part"}>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Sector:</div>
              <div
                className={clsx(classes.description, "equity-page-info-value")}
              >
                {companyDataLoading ? (
                  <BeautifulSkeleton className="details" />
                ) : (
                  tickerDetails.ticker === symbols.value && tickerDetails.sector
                )}
              </div>
            </div>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Industry:</div>
              <div
                className={clsx(classes.description, "equity-page-info-value")}
              >
                {companyDataLoading ? (
                  <BeautifulSkeleton className="industry" />
                ) : (
                  tickerDetails.ticker === symbols.value &&
                  tickerDetails.industry
                )}
              </div>
            </div>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Employees:</div>
              <div
                className={clsx(classes.description, "equity-page-info-value")}
              >
                {companyDataLoading ? (
                  <BeautifulSkeleton className="details" />
                ) : (
                  tickerDetails.ticker === symbols.value &&
                  numberWithCommas(tickerDetails?.total_employees ?? "")
                )}
              </div>
            </div>
            <div className={"equity-page-info-right-part-row"}>
              <div className={"equity-page-info-aspect"}>Website:</div>
              <div
                className={clsx(classes.description, "equity-page-info-value")}
              >
                <a
                  style={{ fontSize: "17px" }}
                  href={
                    tickerDetails.ticker === symbols.value
                      ? tickerDetails.homepage_url
                      : ""
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {companyDataLoading ? (
                    <BeautifulSkeleton className="website" />
                  ) : (
                    tickerDetails.ticker === symbols.value &&
                    tickerDetails.homepage_url
                  )}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <br />
      )}

      {!!(!loading && canSeeInternal) && (
        <>
          <Sentiment />
          <Accordion
            elevation={0}
            sx={{ marginTop: "15px" }}
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Live Options Summary
            </AccordionSummary>
            <AccordionDetails>
              {expanded === "panel1" && (
                <div
                  id="scrollable-target"
                  className="hide-scroll"
                  style={{
                    marginBottom: "30px",
                    borderRadius: 16,
                    height: "auto",
                    maxHeight: "1000px",
                    overflowY: "scroll",
                  }}
                >
                  <LiveOptionsContainer company={true} />
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {!!(
            !!optionExpiry.expirationDates.length &&
            symbols.value === optionExpiry.ticker
          ) && (
            <>
              <Accordion
                elevation={0}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel2-header"
                >
                  Option Chain
                </AccordionSummary>
                <AccordionDetails>
                  {expanded === "panel2" && (
                    <OptionScannerContainer
                      expand={expandedOptions}
                      setExpand={setExpandedOptions}
                      companyTicker={symbols.value}
                      company={true}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <Accordion
            sx={{ marginBottom: "100px" }}
            elevation={0}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Unusual Activity
            </AccordionSummary>
            <AccordionDetails>
              {expanded === "panel3" && (
                <div className="row mt-30">{unusualActivity}</div>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  // securityPrice: state.equities.price,
  securityPrice: state.companyData.companyData.secPrice,
  marketStatus: state.companyData.companyData.marketState,
  symbols: state.symbol.symbol,
  tickerDetails: state.companyData.companyData.tickerDetails,
  themeMode: state.themeMode.themeMode,
  optionExpiry: state.optionExpiry,
  companyDataLoading: state.companyData.companyDataLoading,
  symbolToken: state.symbol.symbol,
});

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
  fetchCompanyData: (symbol) => dispatch(companyDataLoad(symbol)),
  getOptionExpirations: (symbol) => dispatch(optionExpiryLoad(symbol)),
});

export default connect(stateToProps, dispatchToProps)(SecurityContainer);
