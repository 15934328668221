import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const SIGNED_LOAD_TOP = "SIG/LOAD/TOP";
export const SIGNED_LOAD_TOP_SUCCESS = "SIG/LOAD/TOP/SUCCESS";
export const SIGNED_LOAD_TOP_FAILURE = "SIG/LOAD/TOP/FAILURE";

// Action Creators
export const signedLoad = () => ({
  type: SIGNED_LOAD_TOP,
});
export const signedLoadSuccess = (data) => ({
  type: SIGNED_LOAD_TOP_SUCCESS,
  data,
});
export const signedLoadFailure = (error) => ({
  type: SIGNED_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchSigned() {
  try {
    const response = yield axios.get(`${apiURL}/user/sign_agreement`, {
      withCredentials: true,
    });
    yield put(signedLoadSuccess({ response }));
  } catch (error) {
    yield put(signedLoadFailure(error));
  }
}

function* listenSigned() {
  yield takeEvery(SIGNED_LOAD_TOP, fetchSigned);
}

// Root Saga
export function* saga() {
  yield all([fork(listenSigned)]);
}

const INIT_STATE = {
  success: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNED_LOAD_TOP_SUCCESS:
      return {
        ...state,
        success: action.data.success,
      };
    default:
      return state;
  }
};

export default reducer;
