/* eslint-disable */
import {
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  USER_PORTFOLIO_REQUEST,
  USER_PORTFOLIO_SUCCESS,
  USER_PORTFOLIO_FAILURE,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loading: true,
  data: [],
  error: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    }

    case USER_INFO_FAILURE: {
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    }

    default:
      return state;
  }
};
