import { SOCKET_MESSAGE } from "./socket";
import cloneDeep from "lodash/cloneDeep";

const INIT_STATE = {
  currentCandle: {
    values: [],
    received: 0,
  },
  currentPrice: [],
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SOCKET_MESSAGE: {
      const socketChannel = action.channel.split(";");
      if (socketChannel[0] === "current_candle") {
        return {
          ...state,
          currentCandle: {
            values: action.data,
            ticker: action.data[0].ticker,
            received: Date.now(),
          },
        };
      } else if (socketChannel[0] === "equities") {
        const currentPrice = cloneDeep(state.currentPrice);
        action.data.forEach((newPrice) => {
          const equityPrice = currentPrice.findIndex(
            (item) => item.ticker === socketChannel[1],
          );
          if (equityPrice > -1) {
            currentPrice[equityPrice] = {
              ...newPrice,
              ticker: socketChannel[1],
            };
          } else {
            currentPrice.push({ ...newPrice, ticker: socketChannel[1] });
          }
        });
        // console.log(currentPrice, 'currentPrice')
        return {
          ...state,
          currentPrice,
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export default reducer;
