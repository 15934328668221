import { useMemo } from "react";
import CssPop from "../../common/Tables/animations/CssPop";
import PriceDisplay from "../../common/Tables/renderers/PriceDisplay";

function IndividualTickerRow({ item, setTicker, ticker }) {
  const { price, openPrice } = useMemo(() => {
    const livePrice = item.lastPrice.toFixed(2);
    const price = livePrice;
    const openPrice = item.openPrice;

    return {
      price,
      openPrice,
    };
  }, [item.ticker]);

  return (
    <div
      className={`tickers-list__body__item ${ticker.ticker === item.ticker ? "active" : ""}`}
      onClick={() => setTicker(item)}
    >
      <div>
        <div className="tickers-list__body__item__ticker">{item.ticker}</div>
        <div className="tickers-list__body__item__name">
          {item.fullCompanyName}
        </div>
      </div>

      <div>
        <div className="tickers-list__body__item__price">
          <CssPop ticker={item.ticker} comparator={price} />
        </div>
        <div className={`tickers-list__body__item__performance `}>
          <PriceDisplay
            className
            lastPrice={price}
            ticker={item.ticker}
            trend_start={openPrice}
            showPercent
          />
        </div>
      </div>
    </div>
  );
}

export default IndividualTickerRow;
