import {
  all,
  call,
  select,
  put,
  takeEvery,
  fork,
  delay,
} from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import moment from "moment";
// Action Types
export const COMPANY_DATA_LOAD = "CD/LOAD/TOP";
export const COMPANY_DATA_LOAD_SUCCESS = "CD/LOAD/TOP/SUCCESS";
export const COMPANY_DATA_LOAD_FAILURE = "CD/LOAD/TOP/FAILURE";
function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}
// Action Creators
export const companyDataLoad = (symbol) => ({
  type: COMPANY_DATA_LOAD,
  symbol,
});
export const companyDataLoadSuccess = (data, loading = false) => ({
  type: COMPANY_DATA_LOAD_SUCCESS,
  data,
  companyDataLoading: loading,
});
export const companyDataLoadFailure = (error) => ({
  type: COMPANY_DATA_LOAD_FAILURE,
  error,
});
let timeToMarketChange = 1;

const marketStatusByTime = (state) => {
  const currentTime = moment().tz("America/New_York").format("HH:mm:ss");
  const currentDate = moment(currentTime, "HH:mm:ss").tz("America/New_York");
  if (
    currentDate.isBetween(
      moment("16:00:00", "HH:mm:ss").tz("America/New_York"),
      moment("20:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // After Hours (4pm ~ 8pm )
    return "after";
  }

  if (
    currentDate.isBetween(
      moment("20:00:00", "HH:mm:ss").tz("America/New_York"),
      moment("24:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // Market Closed Hours ( 8pm ~ 4am )
    return "closed";
  }

  if (
    currentDate.isBetween(
      moment("00:00:00", "HH:mm:ss").tz("America/New_York"),
      moment("04:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // Market Closed Hours ( 8pm ~ 4am )
    return "closed";
  }

  if (
    currentDate.isBetween(
      moment("09:30:00", "HH:mm:ss").tz("America/New_York"),
      moment("16:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // Market open hours ( 9:30am ~ 4pm )
    return "open";
  }

  if (
    currentDate.isBetween(
      moment("04:00:00", "HH:mm:ss"),
      moment("09:30:00", "HH:mm:ss"),
    )
  ) {
    // Market pre-open hours ( 4am ~ 9:30am )
    return "pre-open";
  }
  return state;
};
const INIT_STATE = {
  companyData: {
    aiScore: {
      symbol: "",
      sentiment: "",
      bull_score: 0,
      bear_score: 0,
      ai_score: 0,
    },
    news: [],
    newsSentiment: 0,
    lastPrice: null,
    tickerDetails: {
      loading: false,
      notFound: false,
      ticker: null,
      name: null,
      market: null,
      locale: null,
      primary_exchange: null,
      type: null,
      active: null,
      currency_name: null,
      cik: null,
      composite_figi: null,
      share_class_figi: null,
      market_cap: null,
      phone_number: null,
      address: { address1: null, city: null, state: null, postal_code: null },
      description: null,
      sic_code: null,
      sic_description: null,
      ticker_root: null,
      homepage_url: null,
      total_employees: null,
      list_date: null,
      branding: { logo_url: null, icon_url: null },
      share_class_shares_outstanding: null,
      weighted_shares_outstanding: null,
      sector: null,
      industry: null,
    },
    marketState: { state: null, ttmc: null },
    secPrice: {
      close_price: null,
      prev_open_price: null,
      prev_close_price: null,
      last_price: null,
      open_price: null,
      pre_open: null,
      performance: null,
      values: {
        week: { date: null, value: null },
        twoweek: { date: null, value: null },
        month: { date: null, value: null },
        threemonth: { date: null, value: null },
        year: { date: null, value: null },
        open: { date: null, value: null },
      },
      date: null,
      bounds: { date: null, ticker: null, high: null, low: null },
    },
  },
  companyDataLoading: true,
};

// Sagas
function* fetchCompanyData(action) {
  try {
    // const { symbol } = action
    const symbol = action?.symbol || "";
    const { companyData } = yield select((state) => state.companyData);
    let load = {
      ...companyData,
    };
    if (symbol) {
      load.tickerDetails.loading = true;
      load.tickerDetails.notFound = false;
      yield put(companyDataLoadSuccess(load, true));
      const response = yield axios.get(`${apiURL}/company-data/${symbol}`, {
        withCredentials: true,
      });
      let data = {
        ...response.data,
        newsSentiment: response.data.sentiment,
      };
      //market state logic
      let marketStatus;
      if (response?.data?.marketState?.ttmc) {
        timeToMarketChange = response?.data?.marketState?.ttmc;
      }
      if (response?.data?.marketState?.state === "closed") {
        marketStatus = "closed";
      } else {
        marketStatus = marketStatusByTime(response?.data?.marketState?.state);
      }
      data.marketState = marketStatus;
      // ticker detail logic
      if (
        response.data.tickerDetails?.statusCode === 404 ||
        response?.data?.tickerDetails?.statusCode === 206 ||
        !response?.data?.tickerDetails?.description?.length
      ) {
        data.tickerDetails = {
          ...response.data.tickerDetails,
          notFound: true,
          loading: false,
        };
      } else {
        data.tickerDetails = {
          ...response.data.tickerDetails,
          notFound: false,
          loading: false,
        };
      }

      yield put(companyDataLoadSuccess(data, false));
    }
  } catch (error) {
    yield put(companyDataLoadFailure(error));
  }
}
function* marketUpdateLoop() {
  while (timeToMarketChange) {
    yield delay(timeToMarketChange + randomNumber(0, 2000));
    yield call(fetchCompanyData);
  }
}

function* listenCompanyDataLoad() {
  yield takeEvery(COMPANY_DATA_LOAD, fetchCompanyData);
}

// Root Saga
export function* saga() {
  yield all([fork(listenCompanyDataLoad), fork(marketUpdateLoop)]);
}

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMPANY_DATA_LOAD_SUCCESS:
      return {
        ...state,
        companyData: action.data,
        companyDataLoading: action.companyDataLoading,
      };
    default:
      return state;
  }
};

export default reducer;
