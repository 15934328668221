// Action Types
export const CANDLE_SET = "CANDLE/SET";

// Action Creators
export const candleSetter = (resolution, candleType) => {
  return {
    type: CANDLE_SET,
    resolution,
    candleType,
  };
};

const INIT_STATE = {
  resolution: localStorage.getItem("resolution") || "15",
  candleType: localStorage.getItem("candleType") || "1",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CANDLE_SET:
      return {
        ...state,
        resolution: action.resolution,
        candleType: action.candleType,
      };
    default:
      return state;
  }
};

export default reducer;
