import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const TOP_PICKS_LOAD_TOP = "TPP/LOAD/TOP";
export const TOP_PICKS_LOAD_TOP_SUCCESS = "TPP/LOAD/TOP/SUCCESS";
export const TOP_PICKS_LOAD_TOP_FAILURE = "TPP/LOAD/TOP/FAILURE";

// Action Creators
export const topPicksLoad = (limit, timeframe) => ({
  type: TOP_PICKS_LOAD_TOP,
  limit,
  timeframe,
});
export const topPicksLoadSuccess = (data, loading) => ({
  type: TOP_PICKS_LOAD_TOP_SUCCESS,
  data,
  loading,
});
export const topPicksLoadFailure = (error) => ({
  type: TOP_PICKS_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchTopPicks({ limit, timeframe }) {
  try {
    yield put(
      topPicksLoadSuccess({
        topPicks: [],
        loading: true,
      }),
    );

    const { data } = yield axios.get(
      `${apiURL}/ai-top-picks-day?limit=${limit}&timeframe=${timeframe}`,
      { withCredentials: true },
    );
    yield put(
      topPicksLoadSuccess({
        topPicks: data,
        loading: false,
      }),
    );
  } catch (error) {
    console.log({ error }, "err in top picks loader");
    yield put(topPicksLoadFailure(error));
  }
}

function* listenTopPicksLoad() {
  yield takeEvery(TOP_PICKS_LOAD_TOP, fetchTopPicks);
}

// Root Saga
export function* saga() {
  yield all([fork(listenTopPicksLoad)]);
}

const INIT_STATE = {
  topPicks: [],
  loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOP_PICKS_LOAD_TOP_SUCCESS:
      return {
        ...state,
        topPicks: action.data.topPicks,
        loading: action.data.loading,
      };
    default:
      return state;
  }
};

export default reducer;
