import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from "@mui/material";
import SubMenu from "./Submenu";

const RecentPromptItem = ({
    item,
    currSessionId,
    setSession,
    fetchSession,
    onDeleteChat,
    onRenameTitle,
}) => {
    const { sessionId, title } = item;
    const [isRenaming, setIsRenaming] = useState(false);
    const [newTitle, setNewTitle] = useState(title);
    const renameInputRef = useRef(null);

    useEffect(() => {
        if (isRenaming && renameInputRef.current) {
            renameInputRef.current.focus();
            renameInputRef.current.select();
        }
    }, [isRenaming]);

    const handleBlur = () => {
        setIsRenaming(false);
        if (newTitle !== title && newTitle.trim() !== "") {
            onRenameTitle(sessionId, newTitle.trim());
        } else {
            setNewTitle(title); // Reset to original title if no changes
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleBlur();
            setIsRenaming(false)
        } else if (e.key === "Escape") {
            setIsRenaming(false);
            setNewTitle(title);
        }
    };

    return (
        <div
            onClick={() => {
                if (!isRenaming) {
                    setSession(sessionId);
                    fetchSession(sessionId);
                }
            }}
            className={`tradegpt-widget-recent-prompts__section__item ${sessionId === currSessionId ? "tgpt-sidebar-active" : ""
                }`}
        >
            <div
                className="tradegpt-widget-recent-prompts__section__item__title"
            >
                {isRenaming ? (
                    <input
                        ref={renameInputRef}
                        type="text"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        className="tradegpt-widget-recent-prompts__rename-input"
                    />
                ) : (
                    <Tooltip placement="right-end" title={newTitle}>
                        <span>{title}</span>
                    </Tooltip>
                )}
            </div>
            <SubMenu
                onRename={() => {
                    setIsRenaming(true);
                }}
                onDelete={() => onDeleteChat(sessionId)}
                sessionId={sessionId}
            />
        </div>
    );
};

export default React.memo(RecentPromptItem);
