import React from "react";
import SettingsTabs from "./SettingsTabs";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";

import "./SettingsPage.scss";
import SubscriptionsPage from "./SubscriptionsPage/SubscriptionsPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import NotificationPage from "./NotificationPage/NotificationPage";
import ChartSettingsPage from "./ChartSettingsPage/ChartSettingsPage";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../appRedux/ducks/userSettings";
import LiveOptionsSettingsPage from "./LiveOptionsSettingsPage/LiveOptionsSettingsPage";

function SettingsPage({ userSettings, setUserSettings }) {
  const hardHistory = useHistory();
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab);
  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    hardHistory.push(activeTab);
  }, [activeTab]);

  return (
    <div className="settings-page">
      <SettingsTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === "profile" && <ProfilePage />}
      {activeTab === "subscriptions" && <SubscriptionsPage />}
      {activeTab === "notifications" && (
        <NotificationPage
          userSettings={userSettings}
          setUserSettings={setUserSettings}
        />
      )}
      {activeTab === "charts" && <ChartSettingsPage />}
      {activeTab === "live_options" && <LiveOptionsSettingsPage />}
    </div>
  );
}

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(SettingsPage);
