import moment from "moment"

export function extractTitleAndDescription(text) {
  const titleRegex = /\*\*(.*?)\*\*/;
  const titleMatch = text.match(titleRegex);

  if (titleMatch && titleMatch[1]) {
    // Extract title and description
    const title = titleMatch[1];
    const description = text.replace(titleRegex, '').trim();

    return { title, description };
  } else {
    // If no title found, return the whole text as description
    return { title: '', description: text };
  }
}

export function randomSelection(sourceArr, n) {
  let newArr = [];
  if (n >= sourceArr.length) {
    return sourceArr;
  }
  for (let i = 0; i < n; i++) {
    let newElem = sourceArr[Math.floor(Math.random() * sourceArr.length)];
    while (newArr.includes(newElem)) {
    newElem = sourceArr[Math.floor(Math.random() * sourceArr.length)];
    }
    newArr.push(newElem);
  }
  return newArr;
}
export const transformArray = (array, user) => {
  const newArray = [];
  const sortedArray = [...array].sort((a, b) => a.id - b.id);
  sortedArray.forEach((item) => {
      newArray.push({
        isBot: false,
        output: item.user_query,
        title: item.session_title,
        date: moment(item.created_at).format("LL"),
        chat_id: `prompt-${item.message_id}`,
      });

      // Create an object for the bot response
      newArray.push({
        date: moment(item.created_at).format("LL"),
        isBot: true,
        outputLoading: false,
        message_id: item.message_id,
        output: item.bot_response,
        chart_data: item?.bot_response_payload?.chart_data, // Assuming these values are default
        chat_id: item.message_id, 
        title: item.session_title,
      });
  });
  return newArray;
};