import React from "react";

import ErrorBoundary from "./ErrorBoundary";
import SecurityContainer from "./SecurityContainer";

const Company = () => {
  return (
    <ErrorBoundary>
      <SecurityContainer />
    </ErrorBoundary>
  );
};

export default Company;
