import { all, put, takeEvery, fork } from "redux-saga/effects";
import moment from "moment";
import { generateBars } from "../../components/TradingView/candleControl";

// Action Types
export const SPARKLINE_DATA_LOAD_TOP = "SPD/LOAD/TOP";
export const SPARKLINE_DATA_LOAD_TOP_SUCCESS = "SPD/LOAD/TOP/SUCCESS";
export const SPARKLINE_DATA_LOAD_TOP_FAILURE = "SPD/LOAD/TOP/FAILURE";

// Action Creators
export const sparklineDataLoad = (symbol, resolution) => ({
  type: SPARKLINE_DATA_LOAD_TOP,
  symbol,
  resolution,
});
export const sparklineDataLoadSuccess = (data, loading) => ({
  type: SPARKLINE_DATA_LOAD_TOP_SUCCESS,
  data,
  loading,
});
export const sparklineDataLoadFailure = (error) => ({
  type: SPARKLINE_DATA_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchSparklineData(action) {
  try {
    const { symbol, resolution } = action;
    yield put(
      sparklineDataLoadSuccess({
        sparklineData: [],
        symbol,
        loading: true,
      }),
    );
    const fromTime = moment().subtract(1, "day").valueOf();
    const toTime = moment().valueOf();
    let timespan = "m";
    const bars = yield generateBars(
      false,
      symbol,
      resolution,
      timespan,
      fromTime,
      toTime,
    );
    yield put(
      sparklineDataLoadSuccess({
        sparklineData: bars,
        loading: false,
        symbol,
      }),
    );
  } catch (error) {
    console.log({ error }, "err in sparkline loader");
    yield put(sparklineDataLoadFailure(error));
  }
}

function* listenSparklineDataLoad() {
  yield takeEvery(SPARKLINE_DATA_LOAD_TOP, fetchSparklineData);
}

// Root Saga
export function* saga() {
  yield all([fork(listenSparklineDataLoad)]);
}

const INIT_STATE = {
  sparklineData: [],
  loading: true,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SPARKLINE_DATA_LOAD_TOP_SUCCESS:
      return {
        ...state,
        sparklineData: action.data.sparklineData,
        loading: action.data.loading,
        symbol: action.data.symbol,
      };
    default:
      return state;
  }
};

export default reducer;
