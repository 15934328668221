import React, { useMemo } from "react";

import "./TradealgoProducts.scss";
import useAuth from "../../contexts/Auth/useAuth";
import TradealgoProduct from "./TradealgoProduct";

const TradealgoProducts = () => {
  const {
    emeraldPath,
    platinumProgramPath,
    optionsMentorshipPath,
    optionsAcademyPath,
  } = useAuth();

  const cardData = useMemo(() => {
    return [
      {
        title: "TradeAlgo Terminal",
        description1: "Experience the full potential",
        description2: "of TradeAlgo",
        buttonLink: "#",
        type: "terminal",
      },
      {
        title: "Emerald Income Alerts",
        description1: "Take Your Trading to the",
        description2: "Next Level",
        buttonLink: emeraldPath,
        type: "emerald",
        logoAlwaysDark: true,
      },
      {
        title: "Platinum Program",
        description1: "Profitable Option Trading",
        description2: "Made Easy",
        buttonLink: platinumProgramPath,
        type: "platinum",
        isDarkLogo: true,
      },
      {
        title: "TradeGPT",
        description1: "Empower Your Trading Decisions with AI-Driven Insights",
        description2: "",
        buttonLink: "/tradegpt",
        type: "tradegpt",
      },
      {
        title: "Options Mentorship",
        description1:
          "1 on 1 Mentorship with our analysts and launch your portfolio to new heights",
        description2: "",
        buttonLink: optionsMentorshipPath,
        type: "mentorship",
      },
      {
        title: "Options Academy ",
        description1: "Demystify the complexities of",
        description2: "trading options",
        buttonLink: optionsAcademyPath,
        type: "academy",
        isDarkLogo: true,
      },
    ];
  }, [
    platinumProgramPath,
    optionsMentorshipPath,
    optionsAcademyPath,
    emeraldPath,
  ]);

  return (
    <div className="ta-products">
      <div className="ta-products__container">
        <div className="ta-products__header">
          <div className="ta-products__title">Our Premium Offerings</div>
          <div className="ta-products__subtitle">
            Gain access to powerful trading tools and resources that will help
            you make informed trading decisions.
          </div>
          <div className="ta-products__subtitle">
            Our platform is designed to help traders of all levels, whether
            you&apos;re a beginner or an experienced trader.
          </div>
        </div>

        <div className="ta-products__cards">
          {cardData.map((card, index) => (
            <TradealgoProduct
              key={index}
              title={card.title}
              description1={card.description1}
              description2={card.description2}
              buttonLink={card.buttonLink}
              type={card.type}
              logoAlwaysDark={card.logoAlwaysDark}
              isDarkLogo={card.isDarkLogo}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TradealgoProducts;
