import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import moment from "moment";
import LiveTradingSessionCard from "./LiveTradingSessionCard";
import PopularStocks from "../PopularStocks/PopularStocks";
import { useAuth } from "../../../contexts/Auth";
import LiveSessionImg1 from "../../../assets/images/liveTradingSessions/1.png";
import LiveSessionImg2 from "../../../assets/images/liveTradingSessions/2.png";
import LiveSessionImg3 from "../../../assets/images/liveTradingSessions/3.png";
import LiveSessionImg4 from "../../../assets/images/liveTradingSessions/4.png";
import LiveSessionImg5 from "../../../assets/images/liveTradingSessions/5.png";
import LiveSessionImg6 from "../../../assets/images/liveTradingSessions/6.png";
import LiveSessionImg7 from "../../../assets/images/liveTradingSessions/7.png";
import LiveSessionImg8 from "../../../assets/images/liveTradingSessions/8.png";
import LiveSessionImg9 from "../../../assets/images/liveTradingSessions/9.png";

import "./LiveTradingSessions.scss";

const LiveTradingSessions = ({ userSettings }) => {
  const { loading, platinumProgramPath, hasEmerald, hasOptionsAcademy } =
    useAuth();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentDate, setCurrentDate] = useState(
    moment().tz("America/New_York"),
  );

  const onHandlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onHandleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const emeraldLiveStreamPath = useMemo(() => {
    return hasEmerald ? "/emerald/livestream" : "/emerald-signup";
  }, [hasEmerald, loading]);

  const optionsAcademyStreamPath = useMemo(() => {
    let path;
    if (hasOptionsAcademy) {
      path = "/options-academy/livestream";
    } else {
      if (userSettings?.oa_renewed || userSettings?.oa_sign_up) {
        path = "/options-academy-thankyou";
      } else {
        path = "/options-academy-signup";
      }
    }
    return path;
  }, [hasOptionsAcademy, loading, userSettings]);

  const liveTradingSessions = useMemo(() => {
    return [
      {
        id: 1,
        img: LiveSessionImg1,
        subtitle: "Trade With Ross",
        title: "Pre-Market Analysis",
        author: "By Ross Santos",
        time: "9:00am - 9:30am EST • Mon - Fri",
        pageURL: "/market-analysis",
        startTime: "09:00:00",
        endTime: "09:30:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 2,
        img: LiveSessionImg2,
        subtitle: "Live Trade with Brian",
        title: "Live Trade Sessions",
        author: "By Brian Mitchell",
        time: "10:30am - 11:30am EST • Mon - Fri",
        pageURL: "/live-chat/livestream",
        startTime: "10:30:00",
        endTime: "11:30:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 3,
        img: LiveSessionImg3,
        subtitle: "Trade With Ross",
        title: "Live Trading Session",
        author: "By Ross Santos",
        time: "9:30am - 10:30am EST • Mon - Fri",
        pageURL: "/market-analysis",
        startTime: "09:30:00",
        endTime: "10:30:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 4,
        img: LiveSessionImg4,
        subtitle: "Platinum Program",
        title: "Daily Livestreams",
        author: "By Eric Armenteros",
        time: "9:30am - 10:30am EST • Mon - Fri",
        pageURL: platinumProgramPath,
        startTime: "09:30:00",
        endTime: "10:30:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 5,
        img: LiveSessionImg5,
        subtitle: "Live Training",
        title: "Group Training",
        author: "By Dane Glisek",
        time: "12:00pm - 1:00pm EST • Wed",
        pageURL: "/live-training",
        startTime: "12:00:00",
        endTime: "13:00:00",
        startDay: 3,
        endDay: 3,
        isLive: false,
      },
      {
        id: 6,
        img: LiveSessionImg6,
        subtitle: "Trade With Ross",
        title: "Live Trading Session",
        author: "By Dane Glisek",
        time: "3:00pm - 4:00pm EST • Mon - Fri",
        pageURL: "/market-analysis",
        startTime: "15:00:00",
        endTime: "16:00:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 7,
        img: LiveSessionImg7,
        subtitle: "Emerald Income",
        title: "Emerald Inc. Alerts Livestream",
        author: "By Bronco Anderson",
        time: "3:00pm - 4:00pm EST • Mon - Fri",
        pageURL: emeraldLiveStreamPath,
        startTime: "15:00:00",
        endTime: "16:00:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 8,
        img: LiveSessionImg8,
        subtitle: "Options Academy",
        title: "Options Academy Livestream",
        author: "By Bronco Anderson",
        time: "3:00pm - 4:00pm EST • Mon - Fri",
        pageURL: optionsAcademyStreamPath,
        startTime: "15:00:00",
        endTime: "16:00:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
      {
        id: 9,
        img: LiveSessionImg9,
        subtitle: "Trade With Ross",
        title: "After Market Recap",
        author: "By Dane Glisek",
        time: "4:00pm - 4:45pm EST • Mon - Fri",
        pageURL: "/market-analysis",
        startTime: "16:00:00",
        endTime: "16:45:00",
        startDay: 1,
        endDay: 5,
        isLive: false,
      },
    ];
  }, [platinumProgramPath, emeraldLiveStreamPath, optionsAcademyStreamPath]);

  const updateTime = () => {
    const cTime = moment().tz("America/New_York").format("HH:mm:ss");
    const cDate = moment(cTime, "HH:mm:ss").tz("America/New_York");
    setCurrentDate(cDate);
  };

  useEffect(() => {
    updateTime();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTime();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const updatedLiveTradingSessions = useMemo(() => {
    return liveTradingSessions.map((session) => {
      const startTime = moment(session.startTime, "HH:mm:ss").tz(
        "America/New_York",
      );
      const endTime = moment(session.endTime, "HH:mm:ss").tz(
        "America/New_York",
      );
      const isLiveTime = currentDate.isBetween(startTime, endTime);
      const isLiveDay =
        currentDate.day() >= session.startDay &&
        currentDate.day() <= session.endDay;
      return {
        ...session,
        isLive: isLiveTime && isLiveDay,
      };
    });
  }, [liveTradingSessions, currentDate]);

  const maxPages = useMemo(
    () => Math.ceil(liveTradingSessions.length / 2),
    [liveTradingSessions.length],
  );

  const startIndex = useMemo(() => (currentPage - 1) * 2, [currentPage]);
  const endIndex = useMemo(() => startIndex + 2, [startIndex]);
  const sortedSessions = useMemo(
    () =>
      updatedLiveTradingSessions.sort((a, b) => {
        return a.isLive === b.isLive ? 0 : a.isLive === false ? 1 : -1;
      }),
    [updatedLiveTradingSessions],
  );

  const checkSessionIsShown = (index) => {
    return index >= startIndex && index < endIndex;
  };

  return (
    <div className="live-trading-sessions">
      <div className="live-trading-sessions-left-part">
        <div className="live-trading-sessions-left-part-header">
          <div className="live-trading-sessions-left-part-title">
            Live Trading Sessions
          </div>
          <div className="pagination--buttons-wrapper">
            <IconButton
              className="pagination--button"
              onClick={onHandlePrevPage}
              disabled={currentPage === 1}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              className="pagination--button"
              onClick={onHandleNextPage}
              disabled={currentPage === maxPages}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        </div>

        <div className="live-trading-sessions-left-part-content">
          {sortedSessions.map((session, index) => (
            <LiveTradingSessionCard
              key={session.id}
              session={session}
              isShown={checkSessionIsShown(index)}
            />
          ))}
        </div>
      </div>

      <div className="live-trading-sessions-right-part">
        <PopularStocks />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(LiveTradingSessions);
