import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import Box from "@mui/material/Box";
import moment from "moment";
import { useMedia } from "react-media";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

export default function BasicDateRangePicker({
  availableDates,
  value,
  setValue,
}) {
  const [open, setOpen] = useState(false);
  const expDates = availableDates.map((d) => moment.utc(d).format("MM/DD/YY"));
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  return (
    <div
      className="dashboard-datepicker"
      style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{ start: "Start", end: "End" }}
      >
        <DateRangePicker
          value={value}
          onClose={() => setOpen(false)}
          open={open}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          shouldDisableDate={(date) => {
            let calDate = moment(date).format("MM/DD/YY");
            return !expDates.includes(calDate);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <div className="ndatepicker">
                <input
                  style={{
                    fontSize: "14px",
                    position: "relative",
                    cursor: "pointer",
                    left: "-3px",
                  }}
                  ref={startProps.inputRef}
                  {...startProps.inputProps}
                  onClick={() => setOpen(true)}
                />
                <svg
                  onClick={() => setOpen(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  cursor="pointer"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#555"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    float: "right",
                    position: "relative",
                    top: isMobile ? "-24px" : "",
                  }}
                  className="feather feather-calendar"
                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                  <line x1="16" y1="2" x2="16" y2="6" />
                  <line x1="8" y1="2" x2="8" y2="6" />
                  <line x1="3" y1="10" x2="21" y2="10" />
                </svg>
              </div>
              <Box sx={{ mx: 2 }}> to </Box>
              <div className="ndatepicker">
                <input
                  style={{
                    // letterSpacing: "3px",
                    fontSize: "14px",
                    position: "relative",
                    cursor: "pointer",
                    left: "-3px",
                  }}
                  ref={endProps.inputRef}
                  {...endProps.inputProps}
                  onClick={() => setOpen(true)}
                />
                <svg
                  onClick={() => setOpen(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  cursor="pointer"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#555"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    float: "right",
                    position: "relative",
                    top: isMobile ? "-24px" : "",
                  }}
                  className="feather feather-calendar"
                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                  <line x1="16" y1="2" x2="16" y2="6" />
                  <line x1="8" y1="2" x2="8" y2="6" />
                  <line x1="3" y1="10" x2="21" y2="10" />
                </svg>
              </div>
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
