/**
 * Socket Saga
 *  This saga is responsible for connecting a socket client to the server and emitting all messages into redux
 */
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

// Global vars
let client;
let ping;
let socketChannel;
let channels = [];
let sendBuffer = [];
let messageLoopHandler;

// Action Types
export const EQ_SOCKET_MESSAGE = "EQ/SOCKETS/MESSAGE";
export const EQ_SOCKET_LISTEN = "EQ/SOCKET/LISTEN";

function connect() {
  if (!localStorage?.auth_token) {
    // No Socket for unauthed users
    return;
  }
  if (typeof client !== "undefined") {
    client = undefined;
  }
  client = new WebSocket(process.env.REACT_APP_WEBSOCKET);
  client.onopen = () => {
    ping = setInterval(() => {
      if (client.readyState !== WebSocket.OPEN) {
        clearInterval(ping);
        return;
      }
      clientSend("ping");
    }, 10000);
    clientSend(JSON.stringify(channels));
  };

  client.onclose = (e) => {
    console.log("equitiesSocket closed", e);
    clearInterval(ping);
    client = undefined;
  };

  client.onerror = (e) => {
    console.log("error", e);
    clearInterval(ping);
    client.close();
    client = undefined;
  };

  socketChannel = eventChannel((emitter) => {
    client.onmessage = (event) => {
      if (typeof ping === "undefined") {
        client.close();
        client = undefined;
      } else if (event.data !== "pong") {
        emitter(JSON.parse(event.data));
      }
    };
    return () => {
      client.close();
    };
  });
}

function clientSend(message) {
  if (!client) {
    sendBuffer.push(message);
    connect();
  } else {
    if (client.readyState === WebSocket.OPEN) {
      if (sendBuffer.length > 0) {
        sendBuffer.forEach((bufferedMessage) => client.send(bufferedMessage));
        sendBuffer = [];
      }
      client.send(message);
    } else {
      sendBuffer.push(message);
    }
  }
}

// ACTION CREATOR
export const socketOnMessage = (data) => {
  return {
    type: EQ_SOCKET_MESSAGE,
    channel: data.channel,
    data: JSON.parse(data?.data || "{}"),
  };
};

export const equtiesSocketListen = (channels) => {
  return {
    type: EQ_SOCKET_LISTEN,
    channels,
  };
};

// SAGA
function* message(item) {
  yield put(socketOnMessage(item));
}

function* messageLoop() {
  if (socketChannel) yield takeEvery(socketChannel, message);
}

function* changeChannel(action) {
  /*
        Live update switch - ability to subscribe live updates by user information
 
        const user = yield select(state => state.auth.userType);
        if (!client && user.userType.indexOf('Platinum')) {
    */
  channels = action.channels;

  if (!client) {
    // First run
    connect();
  }
  if (!messageLoopHandler) {
    messageLoopHandler = yield fork(messageLoop);
  }
  clientSend(JSON.stringify(action.channels));
}

function* channelLoop() {
  yield takeEvery(EQ_SOCKET_LISTEN, changeChannel);
}

export function* saga() {
  yield all([fork(channelLoop)]);
}

const INIT_STATE = {
  channels: [],
  currentPrices: new Map(),
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EQ_SOCKET_LISTEN: {
      return {
        ...state,
        channels: action.channels,
      };
    }
    case EQ_SOCKET_MESSAGE: {
      const { currentPrices } = state;
      const newMap = new Map(currentPrices);
      const { data, channel } = action;
      if (channel.includes("options")) {
        data.forEach((el) => {
          const { symbol, askPrice, bidPrice, price } = el;
          const value = { symbol, askPrice, bidPrice, price };
          newMap.set(symbol, value);
        });
      } else {
        data.forEach((el) => {
          const { eventSymbol, close } = el;
          newMap.set(eventSymbol, close);
        });
      }
      return {
        ...state,
        currentPrices: newMap,
      };
    }
    default:
      return state;
  }
};

export default reducer;
