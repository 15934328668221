import React from "react";
import IconButton from '@mui/material/IconButton';
import { SendIcon } from './Icons';
import StopIcon from '@mui/icons-material/Stop';

const TradeGPTFooter = ({ showRecentPromptsViews, text, setText, handleKeyDown, isLoading, onSendMessage, onCancelRequest, showRecentPrompts, fullscreen, setShowRecentPrompts }) => {
  const handleSendAction = () => {
    if (isLoading) {
      onCancelRequest();
    } else {
      if (text.trim()) {  // Ensure text is not just whitespace
        if(!fullscreen && showRecentPrompts) {
          showRecentPromptsViews()
        }
        onSendMessage(text, false);
        setText('');
      }
    }
  };
  const handleKeyDownWrapper = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Allow new line on Shift + Enter
        return;
      } else {
        // Prevent default behavior and send message on Enter
        e.preventDefault();
        handleSendAction();
      }
    } else {
      handleKeyDown(e); // Pass other key events to original handler
    }
  };
  

  const handleTextChange = (e) => {
    setText(e.target.value);
    e.target.style.height = 'auto'; // Reset height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set new height
  };

  return (
    <div className="tradegpt-widget__content__footer">
      <div className="tradegpt-widget__content__footer__input">
        <textarea
          value={text}
          onChange={handleTextChange}
          placeholder="Ask TradeGPT anything..."
          className="tradegpt-widget-query-input"
          onKeyDown={handleKeyDownWrapper}
          rows={1}
        />
        <IconButton disabled={!isLoading ? !text.length : false} onClick={handleSendAction} className="tradegpt-widget__content__footer__input__send-icon">
          {isLoading ? <StopIcon style={{ color: "#fff" }} /> : <SendIcon />}
        </IconButton>
      </div>
      <div className="tradegpt-widget__content__footer__hint">
        This is for informational purposes only. Consult a professional before making investment decisions.
      </div>
    </div>
  );
};

export default TradeGPTFooter;
