import React from "react";
import { connect } from "react-redux";
import { themeSetter } from "../../../appRedux/ducks/themeMode";

const PublicLayout = (props) => {
  const { children } = props;
  return (
    <>
      <main className={"login-page-content"}>{children}</main>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  themeToggle: (themeMode) => dispatch(themeSetter(themeMode)),
});

export default connect(null, mapDispatchToProps)(PublicLayout);
PublicLayout.defaultProps = {
  fixedPage: "",
  pageCenter: "",
};
