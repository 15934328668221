import { all, put, takeEvery, fork } from "redux-saga/effects";
import axios from "axios";
import { apiURL } from "../actions/helpers";

// Action Types
export const SEARCH_LOAD_TOP = "S/LOAD/TOP";
export const SEARCH_LOAD_TOP_SUCCESS = "S/LOAD/TOP/SUCCESS";
export const SEARCH_LOAD_TOP_FAILURE = "S/LOAD/TOP/FAILURE";
export const SEARCH_LOAD_EXCLUDE = "S/LOAD/EXCLUDE";
export const SEARCH_LOAD_EXCLUDE_SUCCESS = "S/LOAD/EXCLUDE/SUCCESS";
export const SEARCH_LOAD_EXCLUDE_FAILURE = "S/LOAD/EXCLUDE/FAILURE";

// Action Creators
export const searchLoad = (symbol, exclude = false) => ({
  type: exclude ? SEARCH_LOAD_EXCLUDE : SEARCH_LOAD_TOP,
  symbol,
  exclude,
});
export const searchLoadSuccess = (data, loading, exclude = false) => ({
  type: exclude ? SEARCH_LOAD_EXCLUDE_SUCCESS : SEARCH_LOAD_TOP_SUCCESS,
  data,
  loading,
});
export const searchLoadFailure = (error, exclude = false) => ({
  type: exclude ? SEARCH_LOAD_EXCLUDE_FAILURE : SEARCH_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchSymbols(action) {
  try {
    const { symbol, exclude } = action;
    if (!symbol.length) {
      yield put(searchLoadSuccess([], false, exclude));
    } else {
      const response = yield axios.get(`${apiURL}/search/${symbol}`);
      yield put(searchLoadSuccess(response.data, false, exclude));
    }
  } catch (error) {
    yield put(searchLoadFailure(error));
  }
}

function* listenSymbols() {
  yield takeEvery(SEARCH_LOAD_TOP, fetchSymbols);
  yield takeEvery(SEARCH_LOAD_EXCLUDE, fetchSymbols);
}

// Root Saga
export function* saga() {
  yield all([fork(listenSymbols)]);
}

const INIT_STATE = {
  searchSymbols: [],
  excludeSymbols: [],
  symbolsLoading: false,
  excludeLoading: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEARCH_LOAD_TOP_SUCCESS:
      return {
        ...state,
        searchSymbols: action.data,
        symbolsLoading: action.loading,
      };
    case SEARCH_LOAD_EXCLUDE_SUCCESS:
      return {
        ...state,
        excludeSymbols: action.data,
        excludeLoading: action.loading,
      };
    case SEARCH_LOAD_TOP_FAILURE:
    case SEARCH_LOAD_EXCLUDE_FAILURE:
      return {
        ...state,
        error: action.error,
        symbolsLoading: false,
        excludeLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
