import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const TRADE_MESSAGES_LOAD = "TSM/LOAD/TOP";
export const TRADE_MESSAGES_LOAD_SUCCESS = "TSM/LOAD/TOP/SUCCESS";
export const TRADE_MESSAGES_LOAD_FAILURE = "TSM/LOAD/TOP/FAILURE";

// Action Creators
export const tradeMessagesLoad = () => ({
  type: TRADE_MESSAGES_LOAD,
});
export const tradeMessagesLoadSuccess = (data) => ({
  type: TRADE_MESSAGES_LOAD_SUCCESS,
  data,
});
export const tradeMessagesLoadFailure = (error) => ({
  type: TRADE_MESSAGES_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchTradeMessages() {
  try {
    const response = yield axios.get(`${apiURL}/trade-chat-messages`, {
      withCredentials: true,
    });
    yield put(tradeMessagesLoadSuccess(response?.data || []));
  } catch (error) {
    yield put(tradeMessagesLoadFailure(error));
  }
}

function* listenTradeMessagesLoad() {
  yield takeEvery(TRADE_MESSAGES_LOAD, fetchTradeMessages);
}

// Root Saga
export function* saga() {
  yield all([fork(listenTradeMessagesLoad)]);
}

const INIT_STATE = {
  tradeMessages: [],
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRADE_MESSAGES_LOAD_SUCCESS:
      return {
        ...state,
        tradeMessages: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
