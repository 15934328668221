import React, { useEffect, useState } from "react";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Collapse from "@mui/material/Collapse";
import { IconButton } from "@material-ui/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { connect } from "react-redux";

import AuthorAvatar from "../../../assets/images/eric.jpg";
import FAQCategoryIcon from "../../../assets/images/faq-guide-dark.png";
import FAQCategoryIconLight from "../../../assets/images/faq-guide.png";

import "./style.scss";

const SupportComponent = ({ selectNavigation, isShowFAQ, themeMode }) => {
  const [isFAQ, setIsFAQ] = useState(false);
  const [openItems, setOpenItems] = useState([]);
  const categories = [
    "General",
    "Getting Started",
    "Bot Automations",
    "Chart",
    "Indicators",
    "Drawings",
    "Trading",
    "Alerts",
    "Screener",
  ];

  const toggleItem = (index) => {
    let duplicatedItems = [...openItems];

    if (duplicatedItems.includes(index)) {
      duplicatedItems = duplicatedItems.filter((obj) => obj !== index);
    } else {
      duplicatedItems.push(index);
    }

    setOpenItems(duplicatedItems);
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsFAQ(isShowFAQ);
  }, [isShowFAQ]);

  return (
    <>
      {isFAQ ? (
        <div className="support-component">
          <div
            className="support-component-navigator"
            onClick={() => selectNavigation("support")}
          >
            <ArrowCircleLeftOutlinedIcon /> Support
          </div>

          <div className="support-faq-header">
            <div className="support-component-header-info">
              <div className="support-component-title">FAQ</div>
              <div className="support-faq-description">How can we help?</div>
            </div>
          </div>

          <div className="support-faq-search-bar">
            <input
              type="text"
              className="support-faq-search-box"
              placeholder="Find an answer"
            />
          </div>

          <div className="support-faq-all-topics">Browse all topics</div>

          <div className="support-faq-categories">
            {categories.map((category, key) => (
              <div className="support-faq-category" key={key}>
                <div className="support-faq-category-header">
                  <div className="support-faq-category-title">{category}</div>
                  <div className="support-faq-category-available">
                    3 help available
                  </div>
                </div>

                <img
                  src={
                    themeMode === "light"
                      ? FAQCategoryIconLight
                      : FAQCategoryIcon
                  }
                  alt="faq-category"
                  className="support-faq-category-icon"
                />
              </div>
            ))}
          </div>

          <div className="support-faq-section">
            <div className="support-faq-section-title">General</div>

            <div
              className={`support-faq-section-item ${openItems.includes(1) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 1)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers{" "}
                {openItems.includes(1) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(1)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
            <div
              className={`support-faq-section-item ${openItems.includes(2) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 2)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers{" "}
                {openItems.includes(2) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(2)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
            <div
              className={`support-faq-section-item ${openItems.includes(3) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 3)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers{" "}
                {openItems.includes(3) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(3)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
          </div>

          <div className="support-faq-section">
            <div className="support-faq-section-title">Getting Started</div>

            <div
              className={`support-faq-section-item ${openItems.includes(4) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 4)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers?{" "}
                {openItems.includes(4) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(4)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
            <div
              className={`support-faq-section-item ${openItems.includes(5) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 5)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers?{" "}
                {openItems.includes(5) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(5)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
            <div
              className={`support-faq-section-item ${openItems.includes(6) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 6)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers?{" "}
                {openItems.includes(6) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(6)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
          </div>

          <div className="support-faq-section">
            <div className="support-faq-section-title">Bot Automations</div>

            <div
              className={`support-faq-section-item ${openItems.includes(7) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 7)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers?{" "}
                {openItems.includes(7) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(7)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
            <div
              className={`support-faq-section-item ${openItems.includes(8) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 8)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers?{" "}
                {openItems.includes(8) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(8)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
            <div
              className={`support-faq-section-item ${openItems.includes(9) ? "active" : ""}`}
              onClick={toggleItem.bind(this, 9)}
            >
              <div className="support-faq-section-item-main">
                What is MarketMakers?{" "}
                {openItems.includes(9) ? <RemoveIcon /> : <AddIcon />}
              </div>
              <Collapse in={openItems.includes(9)}>
                <div className="support-faq-section-item-more">
                  MarketMakers is a global market data leader on a mission to
                  make investing simple and fair. Modern investors require
                  access to real-time data for independent insights, and
                  MarketMakers provides these insights through cutting-eidge
                  algorithms and AI.
                </div>
              </Collapse>
            </div>
          </div>

          <IconButton
            className="support-faq-scroll-item"
            onClick={scrollToTop.bind(this)}
          >
            <span className="up-circle-button">
              <ArrowUpwardIcon />
            </span>
          </IconButton>
        </div>
      ) : (
        <div className="support-component">
          <div>
            <div>
              <iframe
                className="live-stream-event-video"
                src="https://vimeo.com/event/3162429/embed"
                allow="autoplay; fullscreen; picture-in-picture"
                frameBorder="0"
                allowFullScreen
                title="Q&A"
              ></iframe>
            </div>

            <div className="platinum-program-qa-chat-widget-mobile">
              <iframe
                src="https://vimeo.com/event/3162429/chat/"
                width="100%"
                height="100%"
                frameBorder="0"
                title="Q&A Chat"
              ></iframe>
            </div>
          </div>

          <div className="support-component-info">
            <div className="support-component-info-header">
              <img
                src={AuthorAvatar}
                alt="Brian"
                className="live-streams-author"
              />
              <div className="support-component-author-info">
                <div className="support-component-author-name">
                  Eric Armenteros
                </div>
                <div className="support-component-author-job">
                  TradeAlgo Professional Analyst
                </div>
              </div>
            </div>

            <div className="support-component-info-content">
              <div className="support-component-title">
                The Platinum Live Q&A Session
              </div>
              <div className="support-component-description">
                Join us live for an exciting and informative session on
                profiting from trading! Eric will share his insights and
                strategies on capitalizing the gold market, including technical
                analysis, risk management, Q&A and more.
              </div>
            </div>

            <div className="support-component-schedule">
              <div className="support-component-schedule-title">
                Live Schedule
              </div>
              <div className="support-component-schedule-list">
                <div className="support-component-schedule-day-item">
                  <div className="support-component-schedule-day">Mon</div>
                  <div className="support-component-schedule-time">
                    4:00pm EST
                  </div>
                </div>

                <div className="support-component-schedule-day-item">
                  <div className="support-component-schedule-day">Tue</div>
                  <div className="support-component-schedule-time">
                    4:00pm EST
                  </div>
                </div>

                <div className="support-component-schedule-day-item">
                  <div className="support-component-schedule-day">Wed</div>
                  <div className="support-component-schedule-time">
                    4:00pm EST
                  </div>
                </div>

                <div className="support-component-schedule-day-item">
                  <div className="support-component-schedule-day">Thu</div>
                  <div className="support-component-schedule-time">
                    4:00pm EST
                  </div>
                </div>

                <div className="support-component-schedule-day-item">
                  <div className="support-component-schedule-day">Fri</div>
                  <div className="support-component-schedule-time">
                    4:00pm EST
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = () => ({});

export default connect(stateToProps, dispatchToProps)(SupportComponent);
