import React, { useEffect } from "react";
import "./TickersList.scss";
import RefreshIconLight from "../../../assets/images/refresh_light.svg";
import RefreshIconDark from "../../../assets/images/refresh_dark.svg";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import IndividualTickerRow from "./IndividualTickerRow";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";

const TickersList = ({
  themeMode,
  data,
  ticker,
  setTicker,
  loading,
  refetch,
  timeframe,
  setTimeframe,
}) => {
  useEffect(() => {
    if (data?.length) {
      setTicker(data[0]);
    }
  }, [data]);
  return (
    <div className="tickers-list">
      <div className="tickers-list__header">
        <div className="tickers-list__header__title">Tickers</div>
        {loading ? (
          <BeautifulSkeleton variant="circular" width={35} height={35} />
        ) : (
          <IconButton onClick={() => refetch(20)}>
            <img
              src={themeMode === "light" ? RefreshIconLight : RefreshIconDark}
              alt="refresh"
              className="tickers-list__header__refresh-icon"
            />
          </IconButton>
        )}
      </div>
      <div className="tickers-list__timeframes">
        <div
          className={`tickers-list__timeframe ${timeframe === "60min" ? "active" : ""}`}
          onClick={() => setTimeframe("60min")}
        >
          1 Hour
        </div>
        <div
          className={`tickers-list__timeframe ${timeframe === "4h" ? "active" : ""}`}
          onClick={() => setTimeframe("4h")}
        >
          4 Hours
        </div>
        <div
          className={`tickers-list__timeframe ${timeframe === "24h" ? "active" : ""}`}
          onClick={() => setTimeframe("24h")}
        >
          1 Day
        </div>
      </div>
      <div className="tickers-list__body">
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="tickers-list__body__item load-skel" key={index}>
                <BeautifulSkeleton width={280} height={60} />
              </div>
            ))
          : data?.map((item, key) => (
              <IndividualTickerRow
                key={key}
                item={item}
                ticker={ticker}
                setTicker={setTicker}
              />
            ))}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TickersList);
