import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import "./Emerald.scss";
import EmeraldTabs from "./EmeraldTabs";
import {
  DarkEmeraldBigGreenIcon,
  EmeraldBigGreenIcon,
  EmeraldBigTextIcon,
  IncomeAlertsTextIcon,
} from "../common/Icon";
import EmeraldTutorials from "./EmeraldTutorials";
import EmeraldRealTimeAlerts from "./EmeraldRealTimeAlerts";
import EmeraldLivestream from "./EmeraldLivestream";
import EmeraldSupport from "./EmeraldSupport";
import EmeraldVideoPage from "./EmeraldTutorials/EmeraldVideoPage";

import videoThumbnail1 from "./EmeraldTutorials/assets/1.png";
import videoThumbnail2 from "./EmeraldTutorials/assets/2.png";
import videoThumbnail3 from "./EmeraldTutorials/assets/3.png";
import videoThumbnail4 from "./EmeraldTutorials/assets/4.png";
import videoThumbnail5 from "./EmeraldTutorials/assets/5.png";
import videoThumbnail6 from "./EmeraldTutorials/assets/6.png";
import videoThumbnail7 from "./EmeraldTutorials/assets/7.png";
import videoThumbnail8 from "./EmeraldTutorials/assets/8.png";
import videoThumbnail9 from "./EmeraldTutorials/assets/9.png";
import videoThumbnail10 from "./EmeraldTutorials/assets/10.png";
import videoThumbnail11 from "./EmeraldTutorials/assets/11.png";
import videoThumbnail12 from "./EmeraldTutorials/assets/12.png";

import slides1 from "./EmeraldTutorials/assets/slides/1.pptx";
import slides2 from "./EmeraldTutorials/assets/slides/2.pptx";
import slides3 from "./EmeraldTutorials/assets/slides/3.pptx";
import slides4 from "./EmeraldTutorials/assets/slides/4.pptx";
import slides5 from "./EmeraldTutorials/assets/slides/5.pptx";
import slides6 from "./EmeraldTutorials/assets/slides/6.pptx";
import slides7 from "./EmeraldTutorials/assets/slides/7.pptx";
import slides8 from "./EmeraldTutorials/assets/slides/8.pptx";
import slides9 from "./EmeraldTutorials/assets/slides/9.pptx";
import slides10 from "./EmeraldTutorials/assets/slides/10.pptx";
import slides11 from "./EmeraldTutorials/assets/slides/11.pptx";
import slides12 from "./EmeraldTutorials/assets/slides/12.pptx";

const Emerald = ({ themeMode }) => {
  const hardHistory = useHistory();
  const { tab, videoID } = useParams();
  const [activeTab, setActiveTab] = useState(tab);

  const allVideos = [
    {
      id: 1,
      title: "Credit Spread Introduction",
      videoUrl:
        "https://player.vimeo.com/video/982001480?h=a1238f4649&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "Financial statement analysis is the process of analyzing a company's financial statements to make better economic decisions.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Why Credit Spreads</li>
          <li className="course-li">What You’ll Learn</li>
        </ul>
      ),
      length: "05 min 20s",
      //checked: userSettings?.viewed_videos?.emerald?.video_1 || false,
      type: "fundamental_analysis",
      thumbnail: <img src={videoThumbnail1} />,
      pdf: slides1,
    },
    {
      id: 2,
      title: "What is a Bull Put Spread?",
      videoUrl:
        "https://player.vimeo.com/video/982012521?h=d9ca51781b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "By assessing the financial performance of a company, investors can be better informed about their investments.",
      description: (
        <ul className="course-ul">
          <li className="course-li">What is a Bull Put Spread? </li>
          <li className="course-li">How Does a Bull Put Spread Work?</li>
          <li className="course-li">Bull Put Spread Basics</li>
        </ul>
      ),
      length: "21 min 23s",
      //checked: userSettings?.viewed_videos?.emerald?.video_2 || false,
      type: "fundamental_analysis",
      thumbnail: <img src={videoThumbnail2} />,
      pdf: slides2,
    },
    {
      id: 3,
      title: "What is a Bear Call Spread?",
      videoUrl:
        "https://player.vimeo.com/video/982016170?h=21b302604e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;ap p_id=58479",
      overview:
        "Money management is key to successful trading. It involves setting goals, understanding risk, and making sound decisions. It is important to understand when to enter and exit trades, and a plan for managing losses.",
      description: (
        <ul className="course-ul">
          <li className="course-li">What is a Bear Call Spread?</li>
          <li className="course-li">How Does a Bear Call Spread Work?</li>
          <li className="course-li">Bear Call Spread Basics</li>
        </ul>
      ),
      length: "17 min 19s",
      //checked: userSettings?.viewed_videos?.emerald?.video_3 || false,
      type: "risk_management",
      thumbnail: <img src={videoThumbnail3} />,
      pdf: slides3,
    },
    {
      id: 4,
      title: "Good rules for bull put spreads",
      videoUrl:
        "https://player.vimeo.com/video/982021619?h=a20cbada4b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "Trading plans are essential for a successful trader. A trading plan should be well thought out and contain strategies for entry and exit, risk management, and overall trading objectives.",
      description: (
        <ul className="course-ul">
          <li className="course-li">
            What to Look Out for in Your Bull Put Spreads
          </li>
          <li className="course-li">Managing your Bull Put Spreads</li>
        </ul>
      ),
      length: "18 min 58s",
      //checked: userSettings?.viewed_videos?.emerald?.video_4 || false,
      type: "risk_management",
      thumbnail: <img src={videoThumbnail4} />,
      pdf: slides4,
    },
    {
      id: 5,
      title: "Good rules for bear call spreads",
      videoUrl:
        "https://player.vimeo.com/video/982022483?h=68c2480c95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "Managing risks using sentiment analysis and contracts is key to successful trading, increasing chances of success while minimizing losses.",
      description: (
        <ul className="course-ul">
          <li className="course-li">
            What to Look Out for in Your Bear Call Spreads
          </li>
          <li className="course-li">Managing your Bear Call Spreads</li>
        </ul>
      ),
      length: "17 min 33s",
      //checked: userSettings?.viewed_videos?.emerald?.video_5 || false,
      type: "risk_management",
      thumbnail: <img src={videoThumbnail5} />,
      pdf: slides5,
    },
    {
      id: 6,
      title: "How do I read trade alerts?",
      videoUrl:
        "https://player.vimeo.com/video/982025911?h=6b11138de2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "Traders use support and resistance levels to manage risk and make informed trading decisions by setting stop-loss and take-profit orders, limiting losses and maximizing gains.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Bull Put Spread Alerts</li>
          <li className="course-li">Bear Call Spread Alerts</li>
        </ul>
      ),
      length: "14 min 17s",
      //checked: userSettings?.viewed_videos?.emerald?.video_6 || false,
      type: "risk_management",
      thumbnail: <img src={videoThumbnail6} />,
      pdf: slides6,
    },
    {
      id: 7,
      title: "How do I enter a bull put spread?",
      videoUrl:
        "https://player.vimeo.com/video/982114051?h=d90b0f9fb2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "The first video will provide an introduction to technical analysis and its applications in trading. It will cover the keys to technical analysis, including chart patterns, indicators, and trading strategies.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Knowing Your Broker-Dealer</li>
          <li className="course-li">Entering Your Bull Put Spread</li>
        </ul>
      ),
      length: "20 min 29s",
      //checked: userSettings?.viewed_videos?.emerald?.video_7 || false,
      type: "technical_analysis",
      thumbnail: <img src={videoThumbnail7} />,
      pdf: slides7,
    },
    {
      id: 8,
      title: "How do I enter a bear call spread?",
      videoUrl:
        "https://player.vimeo.com/video/982133757?h=ee5d37a769&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "This strategy helps traders take advantage of the momentum of the market and capitalize on it. It also helps traders avoid costly mistakes by trading against the trend.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Knowing Your Broker-Dealer</li>
          <li className="course-li">Entering Your Bear Call Spread</li>
        </ul>
      ),
      length: "15 min 04s",
      //checked: userSettings?.viewed_videos?.emerald?.video_8 || false,
      type: "technical_analysis",
      thumbnail: <img src={videoThumbnail8} />,
      pdf: slides8,
    },
    {
      id: 9,
      title: "How do I exit a credit spread?",
      videoUrl:
        "https://player.vimeo.com/video/982139220?h=2b7608f4d3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "A major reversal pattern is a chart pattern in technical analysis that indicates a shift in the direction of a stock's price trend. It is used by traders to make decisions about when to buy and sell a stock.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Waiting for Early Gainers</li>
          <li className="course-li">Exits and Errors</li>
          <li className="course-li">Post-Trade Analysis</li>
        </ul>
      ),
      length: "14 min 47s",
      //checked: userSettings?.viewed_videos?.emerald?.video_9 || false,
      type: "technical_analysis",
      thumbnail: <img src={videoThumbnail9} />,
      pdf: slides9,
    },
    {
      id: 10,
      title: "How Can I Forecast My Income with Credit Spreads?",
      videoUrl:
        "https://player.vimeo.com/video/982142208?h=7be23f9a93&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "Continuation patterns are technical analysis indicators used to identify potential changes in the direction of a stock's price. They are typically seen as a sign of a trend's continuation.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Factors Affecting Income</li>
          <li className="course-li">Step-by-Step Forecasting</li>
          <li className="course-li">Practical Tips</li>
        </ul>
      ),
      length: "13 min 11s",
      //checked: userSettings?.viewed_videos?.emerald?.video_10 || false,
      type: "technical_analysis",
      thumbnail: <img src={videoThumbnail10} />,
      pdf: slides10,
    },
    {
      id: 11,
      title: "How do I improve my credit spreads?",
      videoUrl:
        "https://player.vimeo.com/video/982144387?h=18fc7ea583&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "This indicator is used to identify trends in the stock market and is especially useful for traders who are looking to leverage the power of averages in trading.",
      description: (
        <ul className="course-ul">
          <li className="course-li">Tracking Key Metrics</li>
          <li className="course-li">Tools For Tracking</li>
          <li className="course-li">Continuous Improvement Plan</li>
        </ul>
      ),
      length: "11 min 55s",
      //checked: userSettings?.viewed_videos?.emerald?.video_11 || false,
      type: "technical_analysis",
      thumbnail: <img src={videoThumbnail11} />,
      pdf: slides11,
    },
    {
      id: 12,
      title: "What can I do if things go wrong?",
      videoUrl:
        "https://player.vimeo.com/video/982145812?h=539ce92d84&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      overview:
        "Oscillators are a powerful tool in trading, as they provide traders with insight into price trends. They are used by traders to identify potential buy and sell signals, and can be used to analyze the overall market trend.",
      description: (
        <ul className="course-ul">
          <li className="course-li">What Can I Do?</li>
          <li className="course-li">Risk Mitigation Tactics</li>
          <li className="course-li">Rolling and Reversals</li>
        </ul>
      ),
      length: "26 min 59s",
      //checked: userSettings?.viewed_videos?.emerald?.video_12 || false,
      type: "technical_analysis",
      thumbnail: <img src={videoThumbnail12} />,
      pdf: slides12,
    },
  ];

  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);

  const resetView = () => {
    hardHistory.push("/emerald/tutorials");
  };

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    if (activeTab === "tutorials") {
      if (videoID) {
        hardHistory.push(`/emerald/${activeTab}/${videoID}`);
      } else {
        hardHistory.push(`/emerald/${activeTab}`);
      }
    } else {
      hardHistory.push(`/emerald/${activeTab}`);
    }
  }, [activeTab, hardHistory, videoID]);

  return (
    <div className="emerald-page">
      <div className="emerald-page__header">
        <EmeraldTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          resetView={resetView}
        />
      </div>

      <div className="emerald-page__logo-container">
        <div className="emerald-page__logo" onClick={resetView}>
          {themeMode === "light" ? (
            <EmeraldBigGreenIcon />
          ) : (
            <DarkEmeraldBigGreenIcon />
          )}
        </div>
        <div className="emerald-page__text-logos" onClick={resetView}>
          <EmeraldBigTextIcon fill={color} />
          <IncomeAlertsTextIcon fill={color} />
        </div>
        <div className="emerald-page__logo-splitter" />
      </div>

      {activeTab === "tutorials" && !videoID && (
        <EmeraldTutorials allVideos={allVideos} />
      )}
      {activeTab === "tutorials" && videoID && (
        <EmeraldVideoPage allVideos={allVideos} videoID={videoID} />
      )}
      {activeTab === "real-time-alerts" && <EmeraldRealTimeAlerts />}
      {activeTab === "livestream" && <EmeraldLivestream />}
      {activeTab === "support" && <EmeraldSupport />}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(Emerald);
