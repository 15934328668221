import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import "./NewOptionsMentorshipOverview.scss";
import { mentorshipData } from "./mentorshipData";
import AnimatedMentorsDropdown from "../../common/AnimatedMentorsDropdown/AnimatedMentorsDropdown";
import { useAuth } from "../../../contexts/Auth";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import { updateFieldsLoad } from "../../../appRedux/ducks/updateFields";
import { useSnackbar } from "notistack";
import {
  submitAdvanced,
  submitIntermediate,
  submitPlatinum,
  submitStarter,
} from "../AllPricePlans/AllPricePlans";

const NewOptionsMentorshipOverview = ({ setUserSettings, setUserFields }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    mentorshipSessionsUsed,
    userData,
    loading,
    hasEmerald,
    hasOptionsMentorshipStarter,
    hasPlatinumProgram,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipAdvanced,
    mentorshipSessionsStarter,
    mentorshipSessionsIntermediate,
    mentorshipSessionsAdvanced,
    mentorshipSessionsPlatinum,
    mentorshipSessionsEmerald,
  } = useAuth();
  const [selectedLevel, setSelectedLevel] = useState("platinum");
  const [selectedMentor, setSelectedMentor] = useState({});
  const [subtract, setSubtract] = useState({
    starter: 0,
    intermediate: 0,
    advanced: 0,
    platinum: 0,
    emerald: 0,
  });
  const onClickLookup = {
    starter: submitStarter.bind(this),
    intermediate: submitIntermediate.bind(this),
    advanced: submitAdvanced.bind(this),
    platinum: submitPlatinum.bind(this),
    emerald: () => {},
  };
  const handleLevelChange = (level) => {
    setSelectedLevel(level);
  };
  const selectedMentorship = useMemo(() => {
    return mentorshipData[selectedLevel] ?? {};
  }, [selectedLevel, mentorshipData]);
  useEffect(() => {
    setSelectedMentor({});
  }, [selectedLevel]);

  useEffect(() => {
    if (!loading && !!userData) {
      if (selectedLevel === "starter") {
        setUserSettings({
          ...userData?.userSettings,
          om_starter_sign_up: false,
          om_starter_renewed: false,
        });
      }
      if (selectedLevel === "intermediate") {
        setUserSettings({
          ...userData?.userSettings,
          om_intermediate_sign_up: false,
          om_intermediate_renewed: false,
        });
      }
      if (selectedLevel === "advanced") {
        setUserSettings({
          ...userData?.userSettings,
          om_advanced_sign_up: false,
          om_advanced_renewed: false,
        });
      }
      if (selectedLevel === "platinum") {
        setUserSettings({
          ...userData?.userSettings,
          om_platinum_sign_up: false,
          om_platinum_renewed: false,
        });
      }
      if (selectedLevel === "emerald") {
        setUserSettings({
          ...userData?.userSettings,
          om_emerald_sign_up: false,
          om_emerald_renewed: false,
        });
      }
    }
  }, [loading, selectedLevel, userData]);

  useCalendlyEventListener({
    onEventScheduled: () => {
      setSubtract((prev) => ({
        ...prev,
        [selectedLevel]: prev[selectedLevel] + 1,
      }));
      setUserFields({
        training_sessions_used: {
          ...mentorshipSessionsUsed,
          [`options_mentorship_${selectedLevel}`]:
            (mentorshipSessionsUsed[`options_mentorship_${selectedLevel}`] ||
              0) + 1,
        },
      });
      enqueueSnackbar(
        "Your session has been booked, please check your email for confirmation!",
        {
          variant: "success",
        },
      );
    },
  });

  useEffect(() => {
    if (hasOptionsMentorshipStarter) {
      setSelectedLevel("starter");
    }
    if (hasOptionsMentorshipAdvanced) {
      setSelectedLevel("advanced");
    }
    if (hasOptionsMentorshipIntermediate) {
      setSelectedLevel("intermediate");
    }
    if (hasPlatinumProgram) {
      setSelectedLevel("platinum");
    }
    if (hasEmerald) {
      setSelectedLevel("emerald");
    }
  }, [
    hasEmerald,
    hasOptionsMentorshipStarter,
    hasPlatinumProgram,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipAdvanced,
  ]);
  const sessionsLeft = useMemo(() => {
    const calculateSessionsLeft = (totalSessions, levelKey) => {
      const usedSessions =
        mentorshipSessionsUsed[`options_mentorship_${levelKey}`] || 0;
      const remainingSessions =
        totalSessions - usedSessions - subtract[levelKey];
      return remainingSessions <= 0 ? 0 : remainingSessions;
    };

    return {
      starter: calculateSessionsLeft(mentorshipSessionsStarter, "starter"),
      intermediate: calculateSessionsLeft(
        mentorshipSessionsIntermediate,
        "intermediate",
      ),
      advanced: calculateSessionsLeft(mentorshipSessionsAdvanced, "advanced"),
      platinum: calculateSessionsLeft(mentorshipSessionsPlatinum, "platinum"),
      emerald: calculateSessionsLeft(mentorshipSessionsEmerald, "emerald"),
    };
  }, [
    mentorshipSessionsStarter,
    mentorshipSessionsPlatinum,
    mentorshipSessionsIntermediate,
    mentorshipSessionsAdvanced,
    mentorshipSessionsEmerald,
    mentorshipSessionsUsed,
    subtract,
  ]);
  return (
    <div className="om-overview">
      <div className="om-overview-header">
        <div className="om-overview-header__title">Sessions available:</div>
        <div className="om-overview-header-items">
          {Object.keys(sessionsLeft)?.map((key, index) => (
            <div key={index} className="om-overview-header-item">
              <div className="om-overview-header-item__value">
                {sessionsLeft[key]}
              </div>
              <div className="om-overview-header-item__title">{key}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="om-overview__row">
        <div className="om-overview-big-area">
          <div className="om-overview__levels flex">
            {!!hasOptionsMentorshipStarter && (
              <button
                className={`om-overview__level ${selectedLevel === "starter" ? "om-overview__level--selected" : ""}`}
                onClick={() => handleLevelChange("starter")}
              >
                Starter
              </button>
            )}
            {!!hasOptionsMentorshipIntermediate && (
              <button
                className={`om-overview__level ${selectedLevel === "intermediate" ? "om-overview__level--selected" : ""}`}
                onClick={() => handleLevelChange("intermediate")}
              >
                Intermediate
              </button>
            )}
            {!!hasOptionsMentorshipAdvanced && (
              <button
                className={`om-overview__level ${selectedLevel === "advanced" ? "om-overview__level--selected" : ""}`}
                onClick={() => handleLevelChange("advanced")}
              >
                Advanced
              </button>
            )}
            {!!hasPlatinumProgram && (
              <button
                className={`om-overview__level ${selectedLevel === "platinum" ? "om-overview__level--selected" : ""}`}
                onClick={() => handleLevelChange("platinum")}
              >
                Platinum
              </button>
            )}
            {!!hasEmerald && (
              <button
                className={`om-overview__level ${selectedLevel === "emerald" ? "om-overview__level--selected" : ""}`}
                onClick={() => handleLevelChange("emerald")}
              >
                Emerald{" "}
              </button>
            )}
          </div>
        </div>
        <div className="om-overview-small-area" />
      </div>

      <div className="om-overview__row">
        <div className="om-overview-big-area">
          <div className="om-overview__content">
            <div
              className={`om-overview__content-banner ${selectedLevel}-banner`}
            >
              <div className="om-overview__content-banner-label">
                Options Mentorship:
              </div>
              <div className="om-overview__content-banner-title">
                {selectedMentorship.title}
              </div>

              <div className="om-overview__content-banner-mentors">
                <AnimatedMentorsDropdown
                  mentors={selectedMentorship.mentors}
                  selectedMentor={selectedMentor}
                  setSelectedMentor={setSelectedMentor}
                  comingSoon={false}
                />
              </div>
            </div>

            {!selectedMentor.name && (
              <div
                className="om-overview__content-description"
                dangerouslySetInnerHTML={{
                  __html: selectedMentorship.description,
                }}
              />
            )}

            <div className="om-overview__content-channel">
              <span>Access the telegram channel here:</span>
              <a
                href={selectedMentorship.telegramLink}
                target="_blank"
                rel="noreferrer"
              >
                Mentorship Telegram Alerts
              </a>
            </div>

            {!!(
              !sessionsLeft[selectedLevel] && selectedLevel !== "emerald"
            ) && (
              <>
                <div className="om-overview-header-item__value">
                  No More Sessions Left
                </div>
                <Button
                  onClick={onClickLookup[selectedLevel]}
                  className="om-overview__mentorship-purchase"
                  variant="contained"
                >
                  Purchase More
                </Button>
              </>
            )}
            {!!(
              selectedMentor.calendlyLink && sessionsLeft[selectedLevel] > 0
            ) && (
              <InlineWidget
                prefill={{
                  email: userData?.email || "",
                  name:
                    (userData?.firstName || "") +
                    " " +
                    (userData?.lastName || ""),
                }}
                url={selectedMentor.calendlyLink}
              />
            )}
          </div>
        </div>

        <div className="om-overview-small-area">
          <div className="om-overview__mentors">
            <div className="om-overview__mentors-title">
              Book a new session now:
            </div>
            <AnimatedMentorsDropdown
              mentors={selectedMentorship.mentors}
              selectedMentor={selectedMentor}
              setSelectedMentor={setSelectedMentor}
              comingSoon={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserFields: (fields) => dispatch(updateFieldsLoad(fields)),
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(NewOptionsMentorshipOverview);
