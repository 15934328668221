import React from "react";
import FallbackError from "./components/common/FallbackError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      console.trace();
      return (
        <div className="error-boundary-container container">
          <FallbackError />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
