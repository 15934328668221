/* eslint-disable */
import React, { useMemo } from "react";
import classnames from "classnames";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { connect } from "react-redux";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  text: {
    position: "relative",
    top: "9px",
    left: "1px",
    fontWeight: 400,
    fontSize: "16px",
    color: theme.palette.primary.tabHeaderText,
  },
  normalText: {
    color: theme.palette.primary.text,
  },
  customWidth: {
    "& > *": {
      width: "250px !important",
    },
  },
  customWidth2: {
    "& > *": {
      width: "155px !important",
    },
  },
}));

function SettingsTabs({ themeMode, activeTab, setActiveTab }) {
  const classes = useStyles();
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <div className="tabs tabs--justify tabs--bordered-bottom">
      <div className="tabs__wrap profile-settings-tabs">
        <ul className="nav-tabs-dark nav-tabs">
          <li className={clsx(classes.customWidth2, "nav-item nav-item-dark")}>
            <a
              className={clsx(
                "nav-link-dark nav-link",
                classnames({ active: activeTab === "profile" }),
              )}
              onClick={() => toggle("profile")}
              style={{
                color: activeTab === "1" ? color : "#A2A3A5",
                fontFamily: "inter",
                fontSize: "16px",
              }}
            >
              Profile
            </a>
          </li>
          <li className="nav-item">
            <a
              className={clsx(
                "nav-link-dark nav-link",
                classnames({ active: activeTab === "subscriptions" }),
              )}
              onClick={() => toggle("subscriptions")}
              style={{
                color: activeTab === "subscriptions" ? color : "#A2A3A5",
                fontFamily: "inter",
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              My Subscriptions
            </a>
          </li>
          <li className="nav-item">
            <a
              className={clsx(
                "nav-link-dark nav-link",
                classnames({ active: activeTab === "notifications" }),
              )}
              onClick={() => toggle("notifications")}
              style={{
                color: activeTab === "3" ? color : "#A2A3A5",
                fontFamily: "inter",
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              Notifications
            </a>
          </li>
          <li className="nav-item">
            <a
              className={clsx(
                "nav-link-dark nav-link",
                classnames({ active: activeTab === "charts" }),
              )}
              onClick={() => toggle("charts")}
              style={{
                color: activeTab === "charts" ? color : "#A2A3A5",
                fontFamily: "inter",
                fontSize: "16px",
              }}
            >
              Chart Settings
            </a>
          </li>
          <li className="nav-item">
            <a
              className={clsx(
                "nav-link-dark nav-link",
                classnames({ active: activeTab === "live_options" }),
              )}
              onClick={() => toggle("live_options")}
              style={{
                color: activeTab === "live_options" ? color : "#A2A3A5",
                fontFamily: "inter",
                fontSize: "16px",
              }}
            >
              Live Options
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(SettingsTabs);
