import * as React from "react";
import BeautifulSkeleton from "../../Skeletons/BeautifulSkeleton";

export default function SidebarSkeleton() {
  const items = [...Array(11).keys()];
  return (
    <>
      {items.map((i, index) => (
        <BeautifulSkeleton
          key={i}
          style={{ marginBottom: index === 7 ? `10vh` : "" }}
          className="sidebar-skeleton"
          animation="wave"
        />
      ))}
    </>
  );
}
