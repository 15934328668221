/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import TabHeaders2 from "./TabHeaders2";
import CompanyHistory from "./CompanyHistory";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="div">{children}</Typography>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  darkBackground: {
    backgroundColor: theme.palette.primary.tableBackground,
    height: "100%",
  },
  text: {
    color: theme.palette.primary.text,
  },
}));
const Company = (props) => {
  const {
    securityPrice,
    currentMarketStatus,
    lastPrice,
    setLastPrice,
    currentTab,
  } = props;

  const classes = useStyles();

  return (
    <div style={{ borderRadius: "16px" }} className={classes.darkBackground}>
      <div className="row">
        <div className="col-12">
          {/* <Tab.Pane eventKey="summary" className="company-summary-tab"> */}
          <div className="custom-tab-content">
            <TabPanel
              value={currentTab}
              index={"summary"}
              className="company-summary-tab"
            >
              <CompanyHistory
                setLastPrice={setLastPrice}
                lastPrice={lastPrice}
                securityPrice={securityPrice}
                currentMarketStatus={currentMarketStatus}
              />
              {/*<AtsEquity ticker={ticker} />*/}
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  symbol: state.symbol.symbol,
});

export default connect(stateToProps, null)(Company);
