import React, { memo } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import classNames from "classnames";

const colors = {
  REACT_APP_PRIMARY_COLOR: "#6c5dd3",
  REACT_APP_SECONDARY_COLOR: "#ffa2c0",
  REACT_APP_SUCCESS_COLOR: "#46bcaa",
  REACT_APP_INFO_COLOR: "#4d69fa",
  REACT_APP_WARNING_COLOR: "#ffcf52",
  REACT_APP_DANGER_COLOR: "#f35421",
  REACT_APP_LIGHT_COLOR: "#e7eef8",
  REACT_APP_DARK_COLOR: "#1f2128",
};
const Chart = ({
  series,
  options,
  type,
  width,
  height,
  className,
  ...props
}) => {
  return (
    <div
      style={{ lineHeight: "normal !important" }}
      className={classNames(className)}
      {...props}
    >
      <ReactApexChart
        options={{
          colors: [
            colors.REACT_APP_PRIMARY_COLOR,
            colors.REACT_APP_SECONDARY_COLOR,
            colors.REACT_APP_SUCCESS_COLOR,
            colors.REACT_APP_INFO_COLOR,
            colors.REACT_APP_WARNING_COLOR,
            colors.REACT_APP_DANGER_COLOR,
          ],
          plotOptions: {
            candlestick: {
              colors: {
                upward: colors.REACT_APP_SUCCESS_COLOR,
                downward: colors.REACT_APP_DANGER_COLOR,
              },
            },
            boxPlot: {
              colors: {
                upper: colors.REACT_APP_SUCCESS_COLOR,
                lower: colors.REACT_APP_DANGER_COLOR,
              },
            },
          },
          ...options,
        }}
        series={series}
        type={type}
        width={width}
        height={height}
      />
    </div>
  );
};
Chart.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        name: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.arrayOf(
              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            ),
            PropTypes.shape({
              x: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.arrayOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                ),
                PropTypes.object,
              ]),
              y: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.arrayOf(
                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                ),
                PropTypes.object,
              ]),
            }),
          ]),
        ),
      }),
    ]),
  ).isRequired,
  options: PropTypes.shape({
    annotations: PropTypes.object,

    chart: PropTypes.object,

    colors: PropTypes.array,

    dataLabels: PropTypes.object,

    fill: PropTypes.object,

    grid: PropTypes.object,

    labels: PropTypes.array,

    legend: PropTypes.object,

    markers: PropTypes.object,

    noData: PropTypes.object,

    plotOptions: PropTypes.object,

    responsive: PropTypes.array,

    series: PropTypes.array,

    states: PropTypes.object,

    stroke: PropTypes.object,

    subtitle: PropTypes.object,

    theme: PropTypes.object,

    title: PropTypes.object,

    tooltip: PropTypes.object,

    xaxis: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

    yaxis: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  type: PropTypes.oneOf([
    "line",
    "area",
    "bar",
    "pie",
    "donut",
    "scatter",
    "bubble",
    "heatmap",
    "radialBar",
    "rangeBar",
    "candlestick",
    "boxPlot",
    "radar",
    "polarArea",
  ]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
Chart.defaultProps = {
  type: "line",
  width: "100%",
  height: "auto",
  className: null,
};

export default memo(Chart);
