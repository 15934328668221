import introPdf from "./assets/pdfs/intro.pptx";
import optionStrat from "./assets/pdfs/optionStrat.pptx";
import optionPricing from "./assets/pdfs/optionPricing.pptx";
import optionRisks from "./assets/pdfs/optionRisks.pptx";
import techAnal from "./assets/pdfs/techAnal.pptx";
import funAnal from "./assets/pdfs/funAnal.pptx";
import tradingPlat from "./assets/pdfs/tradingPlat.pptx";
import tradingPsych from "./assets/pdfs/tradingPsych.pptx";
import tradingPlan from "./assets/pdfs/tradingPlan.pptx";
import putTogether from "./assets/pdfs/putTogether.pptx";
import placeTrade from "./assets/pdfs/placeTrade.pptx";
import optionGreeks from "./assets/pdfs/optionGreeks.pptx";
import optionMergers from "./assets/pdfs/optionMergers.pptx";
import economicEvents from "./assets/pdfs/economicEvents.pptx";
import tradeVol from "./assets/pdfs/tradeVol.pptx";
import psych from "./assets/pdfs/psych.pptx";
import portHedge from "./assets/pdfs/portHedge.pptx";
import optionTweaks from "./assets/pdfs/optionTweaks.pptx";
import synthetics from "./assets/pdfs/synthetics.pptx";
import advancedTactics from "./assets/pdfs/advancedTactics.pptx";
import introLeaps from "./assets/pdfs/introLeaps.pptx";
import etpLeaps from "./assets/pdfs/etpLeaps.pptx";
import etpLeapsPlus from "./assets/pdfs/etpLeapsPlus.pptx";
import bestLeaps from "./assets/pdfs/bestLeaps.pptx";
import cashSecured from "./assets/pdfs/cashSecured.pptx";
import benefitsCash from "./assets/pdfs/benefitsCash.pptx";
import risdCash from "./assets/pdfs/risdCash.pptx";
import enhanceCash from "./assets/pdfs/enhanceCash.pptx";
import toolsCash from "./assets/pdfs/toolsCash.pptx";
import applied from "./assets/pdfs/applied.pptx";
import vix from "./assets/pdfs/vix.pptx";
import advanced from "./assets/pdfs/advanced.pptx";
import sector from "./assets/pdfs/sector.pptx";
import hAndO from "./assets/pdfs/hAndO.pptx";
import buildSystem from "./assets/pdfs/buildSystem.pptx";
import fairPrice from "./assets/pdfs/fairPrice.pptx";
import tradeJournal from "./assets/pdfs/tradeJournal.pptx";
import advIndicator from "./assets/pdfs/advIndicator.pptx";
import advOptions from "./assets/pdfs/advOptions.pptx";

export const courseTitleMap = {
  fundamentals: "Fundamentals",
  intermediate: "Intermediate",
  leaps: "L.E.A.P.S",
  "cash-secured-puts": "Cash Secured Puts",
  advanced: "Advanced",
};

export const totalDurationMap = {
  fundamentals: { duration: "2 hours 19 minutes", totalVideos: 10 },
  intermediate: { duration: "2 hours 11 minutes", totalVideos: 10 },
  leaps: { duration: "42 minutes", totalVideos: 4 },
  "cash-secured-puts": { duration: "57 minutes", totalVideos: 5 },
  advanced: { duration: "4 hours 1 minute", totalVideos: 10 },
};

export const courseDataMap = {
  fundamentals: [
    {
      title: "Intro to Options",
      length: "13 minutes 54 seconds",
      videoUrl: "https://player.vimeo.com/video/838364778?h=84be2d9442",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What Is An Options Contract?</li>
            <li className="course-li">What Is a Put Option?</li>
            <li className="course-li">What Is a Call Option?</li>
            <li className="course-li">Basic Terminology</li>
            <li className="course-li">Stock Trading vs Options Trading</li>
            <li className="course-li">Benefits and Concerns</li>
          </ul>
        </div>
      ),
      src: "intro",
      pdf: introPdf,
    },
    {
      title: "Basic Strategies",
      length: "18 minutes 9 seconds",
      videoUrl: "https://player.vimeo.com/video/838366593?h=cf1a1ca484",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Buying Calls</li>
            <li className="course-li">Buying Puts</li>
            <li className="course-li">Covered Call</li>
          </ul>
        </div>
      ),
      src: "optionStrat",
      pdf: optionStrat,
    },
    {
      title: "Options Pricing",
      length: "15 minutes 50 seconds",
      videoUrl: "https://player.vimeo.com/video/838367455?h=2f84571efe",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What determines an options price?</li>
            <li className="course-li">How are premiums calculated? </li>
            <li className="course-li">What&apos;s an options chain?</li>
            <li className="course-li">Reading option chains</li>
          </ul>
        </div>
      ),
      src: "optionPricing",
      pdf: optionPricing,
    },
    {
      title: "Options Trading Risks",
      length: "16 minutes 55 seconds",
      videoUrl: "https://player.vimeo.com/video/838368196?h=1d69c77e81",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Portfolio and Position</li>
            <li className="course-li">Position Sizing</li>
            <li className="course-li">Planning your trade</li>
            <li className="course-li">Being Assigned</li>
          </ul>
        </div>
      ),
      src: "optionRisks",
      pdf: optionRisks,
    },
    {
      title: "Basic Technical Analysis",
      length: "18 minutes 43 seconds",
      videoUrl: "https://player.vimeo.com/video/838369197?h=d8c8e9932c",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Support and resistance</li>
            <li className="course-li">Chart patterns</li>
            <li className="course-li">Bulls and bears</li>
            <li className="course-li">Moving averages</li>
            <li className="course-li">Identifying potential entries</li>
          </ul>
        </div>
      ),
      src: "techAnal",
      pdf: techAnal,
    },
    {
      title: "Fundamental Analysis",
      length: "9 minutes 53 seconds",
      videoUrl: "https://player.vimeo.com/video/838369925?h=e01aebf389",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Fundamental Analysis</li>
            <li className="course-li">Financial Statements</li>
            <li className="course-li">Reading Cash Flow Statements</li>
            <li className="course-li">Reading Balance Sheets</li>
            <li className="course-li">Industry Analysis</li>
          </ul>
        </div>
      ),
      src: "funAnal",
      pdf: funAnal,
    },
    {
      title: "Trading Tools and Platforms",
      length: "10 minutes 48 seconds",
      videoUrl: "https://player.vimeo.com/video/838370995?h=8cb04303e3",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Trading Platforms</li>
            <li className="course-li">Finding a Suitable Trading Platform</li>
          </ul>
        </div>
      ),
      src: "tradePlat",
      pdf: tradingPlat,
    },
    {
      title: "Trading Psychology",
      length: "11 minutes 24 seconds",
      videoUrl: "https://player.vimeo.com/video/838373001?h=4ffe32044f",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Trading Psychology</li>
            <li className="course-li">Goals and Expectations</li>
            <li className="course-li">What Is a Call Option?</li>
            <li className="course-li">Loss Aversion </li>
            <li className="course-li">FOMO</li>
            <li className="course-li">Discipline</li>
          </ul>
        </div>
      ),
      src: "tradePsych",
      pdf: tradingPsych,
    },
    {
      title: "Building Trading Plans",
      length: "15 minutes 10 seconds",
      videoUrl: "https://player.vimeo.com/video/838373568?h=abd0ec993d",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Creating a Trading Plan</li>
            <li className="course-li">Trading Diary</li>
          </ul>
        </div>
      ),
      src: "tradingPlan",
      pdf: tradingPlan,
    },
    {
      title: "Putting It Together",
      length: "5 minutes 59 seconds",
      videoUrl: "https://player.vimeo.com/video/838374136?h=dbd83323db",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What You Know Now</li>
            <li className="course-li">Next Steps</li>
          </ul>
        </div>
      ),
      src: "putTogether",
      pdf: putTogether,
    },
  ],
  intermediate: [
    {
      title: "Order Entry for Options",
      length: "14 minutes 5 seconds",
      videoUrl: "https://player.vimeo.com/video/838377004?h=3fd230c745",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Trade Specifics</li>
            <li className="course-li">Limit Orders for Options</li>
            <li className="course-li">Same Day Orders</li>
            <li className="course-li">Real-World Trade Example</li>
          </ul>
        </div>
      ),
      pdf: placeTrade,
      src: "order-entry",
    },
    {
      title: "Options Greek",
      length: "11 minutes 9 seconds",
      videoUrl: "https://player.vimeo.com/video/838377417?h=025f20ece6",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Understanding the Greks</li>
            <li className="course-li">Delta</li>
            <li className="course-li">Gamma</li>
            <li className="course-li">Theta</li>
            <li className="course-li">Vega</li>
            <li className="course-li">RHO</li>
            <li className="course-li">Options Pricing Model</li>
          </ul>
        </div>
      ),
      pdf: optionGreeks,
      src: "option-greeks",
    },
    {
      title: "Merger and Acquisitions",
      length: "9 minutes 19 seconds",
      videoUrl: "https://player.vimeo.com/video/838377698?h=4bb5192810",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Why M&A</li>
            <li className="course-li">About M&A</li>
            <li className="course-li">Types of M&A</li>
            <li className="course-li">Evaluating M&A</li>
            <li className="course-li">Trading Options on the Target Company</li>
            <li className="course-li">Managing Risks</li>
          </ul>
        </div>
      ),
      pdf: optionMergers,
    },
    {
      title: "Economic Events",
      length: "13 minutes 43 seconds",
      videoUrl: "https://player.vimeo.com/video/838378271?h=eeca43e151",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Economic Events</li>
            <li className="course-li">Corporate Events and Earnings</li>
            <li className="course-li">Knowing What Matters</li>
            <li className="course-li">Types of Indicators</li>
            <li className="course-li">Common Indicators</li>
            <li className="course-li">Using Existing Set-Ups More Often</li>
          </ul>
        </div>
      ),
      pdf: economicEvents,
    },
    {
      title: "Volatility Trading",
      length: "9 minutes 45 seconds",
      videoUrl: "https://player.vimeo.com/video/838378718?h=d6a362aa9d",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">
              Low Volality Trading Strategy: The Straddle
            </li>
            <li className="course-li">Volatility Status</li>
            <li className="course-li">Stock or ETF Status</li>
            <li className="course-li">Straddle Price</li>
            <li className="course-li">Steps of Strategy</li>
            <li className="course-li">
              Basic Volatility Trading Strategies: The strangle
            </li>
            <li className="course-li">
              High Volatility Trading Strategy: The Iron Condor
            </li>
          </ul>
        </div>
      ),
      pdf: tradeVol,
    },
    {
      title: "Trading Psychology",
      length: "15 minutes 3 seconds",
      videoUrl: "https://player.vimeo.com/video/838379150?h=324e6e947c",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Disciplined Trading</li>
            <li className="course-li">Psychological Concepts</li>
          </ul>
        </div>
      ),
      pdf: psych,
    },
    {
      title: "Portfolio Management",
      length: "13 minutes 14 seconds",
      videoUrl: "https://player.vimeo.com/video/838379655?h=a23987037d",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Portfolio Hedging</li>
            <li className="course-li">Portfolio Optimization</li>
            <li className="course-li">Generating Income</li>
          </ul>
        </div>
      ),
      pdf: portHedge,
    },
    {
      title: "Option Tweaks",
      length: "14 minutes 21 seconds",
      videoUrl: "https://player.vimeo.com/video/838380345?h=40df989bde",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Rolling</li>
            <li className="course-li">Reversal</li>
            <li className="course-li">Take Profit or Take More?</li>
            <li className="course-li">Make it Triple</li>
            <li className="course-li">Learn from Error</li>
          </ul>
        </div>
      ),
      pdf: optionTweaks,
    },
    {
      title: "Synthetics",
      length: "15 minutes 38 seconds",
      videoUrl: "https://player.vimeo.com/video/838380806?h=f3e843aa28",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Finding Qualified Trades</li>
            <li className="course-li">Order Entry</li>
            <li className="course-li">Synthetic Long Stock</li>
            <li className="course-li">Risks Of Synthetic Positions</li>
            <li className="course-li">Synthetics as Risk Management</li>
          </ul>
        </div>
      ),
      pdf: synthetics,
    },
    {
      title: "Advanced Trading Tactics",
      length: "15 minutes 30 seconds",
      videoUrl: "https://player.vimeo.com/video/838381757?h=ff6d575727",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">First Triggers Sequential</li>
            <li className="course-li">When the Stock Price Hits X</li>
            <li className="course-li">Plain Vanilla To Credit Spread</li>
            <li className="course-li">Benefits of Advanced Tactics</li>
            <li className="course-li">Risks of Advanced Tactics</li>
          </ul>
        </div>
      ),
      pdf: advancedTactics,
    },
  ],
  leaps: [
    {
      title: "Intro LEAPS",
      length: "9 minutes 47 seconds",
      videoUrl: "https://player.vimeo.com/video/838387979?h=deb04aa715",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Seven Minutes to Success</li>
            <li className="course-li">The Dalio Call</li>
            <li className="course-li">My “4-Step Plan”</li>
          </ul>
        </div>
      ),
      pdf: introLeaps,
    },
    {
      title: "ETP LEAPS",
      length: "10 minutes 58 seconds",
      videoUrl: "https://player.vimeo.com/video/838388343?h=5ae0133fde",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What is an ETP?</li>
            <li className="course-li">ETPs vs Mutual Funds </li>
            <li className="course-li">Pros vs Cons of ETPs</li>
          </ul>
        </div>
      ),
      pdf: etpLeaps,
    },
    {
      title: "ETP LEAPS Plus",
      length: "13 minutes 35 seconds",
      videoUrl: "https://player.vimeo.com/video/838388977?h=af92a855b1",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Benefits of Index LEAPS</li>
            <li className="course-li">Minuses of Index LEAPS</li>
            <li className="course-li">Why SPY LEAPS</li>
          </ul>
        </div>
      ),
      pdf: etpLeapsPlus,
    },
    {
      title: "Best Market for Leaps",
      length: "8 minutes 1 second",
      videoUrl: "https://player.vimeo.com/video/838389916?h=85cbaa45d1",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">The Reversal</li>
            <li className="course-li">The Bounce</li>
            <li className="course-li">A Bear and Bounce</li>
            <li className="course-li">The Volatility (aka VIX)</li>
            <li className="course-li">Fed Funds Rate</li>
            <li className="course-li">The Rho Greek</li>
          </ul>
        </div>
      ),
      pdf: bestLeaps,
    },
  ],
  "cash-secured-puts": [
    {
      title: "Cash Secured Puts",
      length: "14 minutes 37 seconds",
      videoUrl: "https://player.vimeo.com/video/838391498",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What&apos;s a Cash Secured Put</li>
            <li className="course-li">Which Stocks are Best? </li>
            <li className="course-li">Is This Like Buying Puts?</li>
            <li className="course-li">Is This Like a Naked Put?</li>
            <li className="course-li">Risks and Rewards</li>
            <li className="course-li">
              Compare Covered Calls to Cash-Secured Puts
            </li>
          </ul>
        </div>
      ),
      pdf: cashSecured,
    },
    {
      title: "Benefits of Cash Secured Puts",
      length: "10 minutes 4 seconds",
      videoUrl: "https://player.vimeo.com/video/838391794?h=c6c5609afa",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Less Cost Per Share</li>
            <li className="course-li">Stock Pays for Itself</li>
            <li className="course-li">How Can a Stock Pay for Itself?</li>
            <li className="course-li">Generating Income</li>
            <li className="course-li">Managing Risk</li>
            <li className="course-li">Capitalizing On Volatility</li>
            <li className="course-li">Opportunities For Long Term Investors</li>
          </ul>
        </div>
      ),
      pdf: benefitsCash,
    },
    {
      title: "Risks and Safety Measures",
      length: "12 minutes 6 seconds",
      videoUrl: "https://player.vimeo.com/video/838392447?h=f1f3207442",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Stock-sized Losses</li>
            <li className="course-li">High Capital Requirements</li>
            <li className="course-li">Effects of Market Movement </li>
            <li className="course-li">Bid/Ask Spread</li>
            <li className="course-li">Managing Risks and Limitations</li>
          </ul>
        </div>
      ),
      pdf: risdCash,
    },
    {
      title: "Enhanced Cash Secured Puts",
      length: "15 minutes 37 seconds",
      videoUrl: "https://player.vimeo.com/video/838392819?h=316dfccd1a",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Finding Cash-Secured Puts</li>
            <li className="course-li">Identified Entries Explained</li>
            <li className="course-li">Dangers of this Approach</li>
          </ul>
        </div>
      ),
      pdf: enhanceCash,
    },
    {
      title: "Tools for Trading Cash Secured Puts",
      length: "5 minutes 2 seconds",
      videoUrl: "https://player.vimeo.com/video/838393408?h=2fa35170e1",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Platforms and Brokers</li>
            <li className="course-li">
              Options Pricing Simulators and Calculators
            </li>
            <li className="course-li">Avoid Pitfalls and Mistakes</li>
          </ul>
        </div>
      ),
      pdf: toolsCash,
    },
  ],
  advanced: [
    {
      title: "Applied Options Moneyness",
      length: "21 minutes 1 second",
      videoUrl: "https://player.vimeo.com/video/838398194?h=aadc86dcf2",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Moneyness</li>
            <li className="course-li">Stock Target Hit</li>
            <li className="course-li">ITM Options</li>
            <li className="course-li">OTM Options</li>
            <li className="course-li">Risk & Reward trade-off</li>
            <li className="course-li">Strategies for ITM & OTM</li>
          </ul>
        </div>
      ),
      pdf: applied,
    },
    {
      title: "The VIXs TA",
      length: "17 minutes 37 seconds",
      videoUrl: "https://player.vimeo.com/video/838398532?h=142a0c753a",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Understanding The VIX</li>
            <li className="course-li">Role Of VIX In Market Analysis</li>
            <li className="course-li">VIX Correlation to SPX</li>
            <li className="course-li">Limitations Of The VIX</li>
          </ul>
        </div>
      ),
      pdf: vix,
    },
    {
      title: "Advanced Trading Strategies Comparisons",
      length: "24 minutes 5 seconds",
      videoUrl: "https://player.vimeo.com/video/838398909?h=5dfea0772d",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">The Straddle</li>
            <li className="course-li">The Butterfly Spread</li>
            <li className="course-li">The Iron Condor</li>
            <li className="course-li">The Proof by Comparison</li>
          </ul>
        </div>
      ),
      pdf: advanced,
    },
    {
      title: "Sector Options Analysis TA",
      length: "27 minutes 57 seconds",
      videoUrl: "https://player.vimeo.com/video/838399975?h=422faead51",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Why S&P Sectors?</li>
            <li className="course-li">Why Learn Sectors?</li>
            <li className="course-li">Why does it matter?</li>
            <li className="course-li">
              Analyzing Option Chain Volume and Open Interest
            </li>
            <li className="course-li">
              Implied Volatility and Sector Analysis
            </li>
            <li className="course-li">
              Sector Size in SPY and Trading Strategies
            </li>
          </ul>
        </div>
      ),
      pdf: sector,
    },
    {
      title: "Heuristics and Options Trading",
      length: "18 minutes 7 seconds",
      videoUrl: "https://player.vimeo.com/video/838400697?h=ff94bdf9ad",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What are Heuristics? </li>
            <li className="course-li">Common Heuristics </li>
            <li className="course-li">Heuristics VS Algos</li>
            <li className="course-li">Dangers of Heuristics</li>
            <li className="course-li">
              Why to apply heuristics sparingly and consistently
            </li>
          </ul>
        </div>
      ),
      pdf: hAndO,
    },
    {
      title: "Building a Trading System",
      length: "14 minutes 35 seconds",
      videoUrl: "https://player.vimeo.com/video/838401162?h=cfa7d00274",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">What is System Building?</li>
            <li className="course-li">Designing Your System</li>
            <li className="course-li">Refining Your System</li>
            <li className="course-li">
              Psychological Considerations in System Building
            </li>
          </ul>
        </div>
      ),
      pdf: buildSystem,
    },
    {
      title: "Fair Price for an Option",
      length: "26 minutes 47 seconds",
      videoUrl: "https://player.vimeo.com/video/838401567?h=4b850b0c17",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Options Pricing in Two Parts</li>
            <li className="course-li">Options Pricing “Components”</li>
            <li className="course-li">Indications of Unfair Prices</li>
            <li className="course-li">Actions to Improve Price/Profit</li>
          </ul>
        </div>
      ),
      pdf: fairPrice,
    },
    {
      title: "Trading Journals and Performance Metrics",
      length: "21 minutes 27 seconds",
      videoUrl: "https://player.vimeo.com/video/838402356?h=85e4021335",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Why Journal Trades?</li>
            <li className="course-li">
              Important Ratios and how to understand them
            </li>
            <li className="course-li">Benefits of Trade Journals</li>
            <li className="course-li">Strategy Refinement</li>
            <li className="course-li">Trading System Tools</li>
          </ul>
        </div>
      ),
      pdf: tradeJournal,
    },
    {
      title: "Advanced Options Indicators",
      length: "42 minutes 12 seconds",
      videoUrl: "https://player.vimeo.com/video/838404038?h=3a7245ea17",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Why are they useful?</li>
            <li className="course-li">Key Advanced Options Indicators</li>
            <li className="course-li">Skew & Smile</li>
            <li className="course-li">Volatility Cone</li>
            <li className="course-li">Put-Call Ratio</li>
            <li className="course-li">Sentiment IndicatorsPut-Call Ratio</li>
          </ul>
        </div>
      ),
      pdf: advIndicator,
    },
    {
      title: "Advanced Options Scanning",
      length: "27 minutes 23 seconds",
      videoUrl: "https://player.vimeo.com/video/838403594?h=de1e49f413",
      description: (
        <div className="course-descript">
          <ul className="course-ul">
            <li className="course-li">Why options scanning?</li>
            <li className="course-li">Key Components</li>
            <li className="course-li">Tools and Software</li>
            <li className="course-li">Common Strategies</li>
            <li className="course-li">Advanced Process</li>
          </ul>
        </div>
      ),
      pdf: advOptions,
    },
  ],
};
