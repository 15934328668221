import React from "react";
import clsx from "clsx";
import classnames from "classnames";
import "./tabs.scss";

function OptionContractTabs({ activeTab, setActiveTab }) {
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div
      style={{ borderBottom: "1px solid transparent", marginBottom: "5px" }}
      className="tabs tabs--justify tabs--bordered-bottom"
    >
      <ul className="nav-tabs">
        <li className="nav-item">
          <a
            className={clsx(
              "nav-link",
              classnames({ active: activeTab === "1" }),
            )}
            onClick={() => toggle("1")}
          >
            Summary
          </a>
        </li>
        <li className="nav-item">
          <a
            className={clsx(
              "nav-link",
              classnames({ active: activeTab === "2" }),
            )}
            onClick={() => toggle("2")}
          >
            Chart
          </a>
        </li>
      </ul>
    </div>
  );
}
export default OptionContractTabs;
