// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1199px) {
  .tradegpt-widget__sidebar__content__prompts {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
    height: auto;
    overflow: auto;
    align-items: stretch;
    max-height: 100px;
  }

  .tradegpt-widget__sidebar__content__prompt {
    width: calc(50% - 5px);
  }

  .tradegpt-widget__content {
    width: 100%;
  }

  /* .tradegpt-widget__content__get_started__items {
    flex-direction: column;
  } */

  .tradegpt-widget__content__get_started__item {
    width: 100%;
  }

  /* .tradegpt-widget__content__messages {
    max-height: 250px;
  } */
}

@media (max-width: 499px) {
  .tradegpt-widget__sidebar__content__prompts {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
    height: auto;
    overflow: auto;
  }

  .tradegpt-widget__sidebar__content__prompt {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./lib/assets/responsiveness.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,oBAAoB;IACpB,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;EAEA;;KAEG;;EAEH;IACE,WAAW;EACb;;EAEA;;KAEG;AACL;;AAEA;EACE;IACE,eAAe;IACf,SAAS;IACT,mBAAmB;IACnB,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["@media (max-width: 1199px) {\r\n  .tradegpt-widget__sidebar__content__prompts {\r\n    flex-wrap: wrap;\r\n    gap: 10px;\r\n    flex-direction: row;\r\n    height: auto;\r\n    overflow: auto;\r\n    align-items: stretch;\r\n    max-height: 100px;\r\n  }\r\n\r\n  .tradegpt-widget__sidebar__content__prompt {\r\n    width: calc(50% - 5px);\r\n  }\r\n\r\n  .tradegpt-widget__content {\r\n    width: 100%;\r\n  }\r\n\r\n  /* .tradegpt-widget__content__get_started__items {\r\n    flex-direction: column;\r\n  } */\r\n\r\n  .tradegpt-widget__content__get_started__item {\r\n    width: 100%;\r\n  }\r\n\r\n  /* .tradegpt-widget__content__messages {\r\n    max-height: 250px;\r\n  } */\r\n}\r\n\r\n@media (max-width: 499px) {\r\n  .tradegpt-widget__sidebar__content__prompts {\r\n    flex-wrap: wrap;\r\n    gap: 10px;\r\n    flex-direction: row;\r\n    height: auto;\r\n    overflow: auto;\r\n  }\r\n\r\n  .tradegpt-widget__sidebar__content__prompt {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
