import React from "react";
import { SearchIcon } from "./Icons";
import RecentPromptItem from "./RecentPromptsItem";
import { Skeleton } from "@mui/material";

const RecentPrompts = ({
  onDeleteChat,
  onRenameTitle,
  fullscreen,
  chats,
  searchTerm,
  setSearchTerm,
  fetchSession,
  currSessionId,
  setSession,
  loading,
}) => {
  const timePeriods = [
    "Today",
    "Yesterday",
    "Previous 7 Days",
    "Previous 30 Days",
    "Over 30 Days Ago",
  ];

  return (
    <div
      className={`tradegpt-widget-recent-prompts ${
        fullscreen ? "height-95" : ""
      }`}
    >
      <div className="p-16">
        <div className="tradegpt-widget-recent-prompts__search">
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
            placeholder="Search"
            className="tradegpt-widget-recent-prompts__search_input"
          />
          <SearchIcon className="tradegpt-widget-recent-prompts__search_icon" />
        </div>
      </div>
      <div className="tradegpt-widget-recent-prompts__sections">
        {timePeriods.map((time) => {
          if (loading) {
            // Render skeletons when loading
            return (
              <div
                key={time}
                className="tradegpt-widget-recent-prompts__section"
              >
                <div className="tradegpt-widget-recent-prompts__section__title">
                  {time}
                </div>
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    width={213}
                    height={38}
                    style={{ marginBottom: 8 }}
                  />
                ))}
              </div>
            );
          } else if (chats[time] && chats[time].length > 0) {
            // Render actual items when not loading and chats exist
            return (
              <div
                key={time}
                className="tradegpt-widget-recent-prompts__section"
              >
                <div className="tradegpt-widget-recent-prompts__section__title">
                  {time}
                </div>
                {chats[time].map((item) => (
                  <RecentPromptItem
                    key={item.sessionId}
                    item={item}
                    currSessionId={currSessionId}
                    setSession={setSession}
                    fetchSession={fetchSession}
                    onDeleteChat={onDeleteChat}
                    onRenameTitle={onRenameTitle}
                  />
                ))}
              </div>
            );
          } else {
            // Don't render the section if there are no chats and not loading
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default RecentPrompts;
