import { Tooltip, CircularProgress } from '@mui/material';
import React from 'react';
import ReactMarkdown from "react-markdown";
import _ from 'lodash';
import gfm from "remark-gfm";
import { useMemo } from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { TradeGPTBotIconDark, TradeGPTBotIconLight } from '../MyComponent/Icons';
import rehypeRaw from "rehype-raw";
import TypingAnimation from '../MyComponent/TypingAnimation';

const renderers = {
  a: ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
};
const containsLaTeX = text => /\\\[|\\\(|```latex/.test(text);

function ChatMessage({ fullscreen, message, loading = false, showActions = false, darkTheme = false, onCopy, onLike, onDislike }) {
  const renderContent = (output) => {
    if (containsLaTeX(output)) {
      return <TypingAnimation text={output} components={renderers} />;
    }
    else {
      return (
        <ReactMarkdown
          remarkPlugins={[gfm]}
          rehypePlugins={[rehypeRaw]}
          components={renderers}
          className={`markdown-body ${fullscreen ? '' : 'max-w-3'} ${!message?.isBot ? "chat-bubble" : ''}`}
        >
          {output}
        </ReactMarkdown>
      );
    }
  };
  return (
    <>
      <div className="w-full text-token-text-primary">
        <div className="justify-center text-base md:gap-6 m-auto">
          <div className={`flex flex-1 text-base mx-auto gap-3 w-full ${!message?.isBot ? 'flex-reverse' : ''}`}>
            {
              message?.isBot && (
                <div className="flex-shrink-0 flex flex-col relative items-end">
                  <div className="pt-0.5">
                    <div className="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full">
                      <div className="relative flex">
                        {(darkTheme ? <TradeGPTBotIconLight width={24} height={24} /> : <TradeGPTBotIconDark width={24} height={24} />)}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            <div className={`relative flex w-full flex-col ${message?.isBot ? 'custom-tradegpt-widget-message-content' : 'flex-end'}`}>
              {!!message?.isBot && (
                <div className={`tradegpt-widget-username tradegpt-widget-bot-name`}>TradeGPT</div>
              )}
              <div className={`flex-col gap-1 md:gap-3 ${!message?.isBot ? 'text-right' : ''}`}>
                <div className="flex flex-grow flex-col max-w-full">
                  <div data-message-author-role="user" className="min-h-[20px] overflow-y-hidden break-words overflow-x-auto flex flex-col gap-3 tradegpt-widget__content__message__text">
                    <div className='markdown-body'>
                      {loading && !message.outputLoading ? (
                        <div style={{ marginLeft: '10px' }} className="flex items-center space-x-2 mt-1">
                          <CircularProgress size={20} style={{ color: '#A6A6A6' }} />
                          <span className="text-sm text-token-text-secondary">Analyzing ...</span>
                        </div>
                      ) : renderContent(message.output)}
                    </div>

                    {
                      message?.isBot && !message.outputLoading && (
                        <div className='tradegpt-widget-message-actions'>
                          {onCopy &&
                            <Tooltip title="Copy">
                              <button onClick={(e) => onCopy(message.output)} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                <ContentPasteIcon />
                              </button>
                            </Tooltip>
                          }
                          {onLike && onDislike &&
                            <>
                              {onLike &&
                                <Tooltip title="Good response">
                                  <button onClick={() => onLike()} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                    <ThumbUpOffAltIcon />
                                  </button>
                                </Tooltip>
                              }
                              {onDislike &&
                                <Tooltip title="Bad response">
                                  <button onClick={() => onDislike()} className={`tradegpt-widget-message-action ${!showActions ? 'invisible-button' : ''}`}>
                                    <ThumbDownOffAltIcon />
                                  </button>
                                </Tooltip>
                              }
                            </>
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatMessage
