import * as React from "react";

const CapMovementIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
  >
    <g clipPath="url(#clip0_9537_86538)">
      <path
        d="M8.56634 31.4914C8.74666 31.5708 8.95825 31.6169 9.18065 31.6169C9.78776 31.6169 10.3107 31.2727 10.5475 30.7766L10.5511 30.7674L15.7181 19.7221L20.1722 21.1805C20.3177 21.23 20.486 21.2588 20.6603 21.2588C21.0919 21.2588 21.4802 21.0838 21.7531 20.8041L21.7543 20.8029L29.3173 13.0934V14.6921C29.3173 15.4863 29.9905 16.1309 30.82 16.1309C31.6495 16.1309 32.3227 15.4863 32.3227 14.6921V9.45956L32.3179 9.43769C32.3071 9.30877 32.2807 9.18791 32.2398 9.07511L32.2434 9.08547C32.2302 9.0095 32.2145 8.94389 32.1941 8.87944L32.1965 8.8898C32.158 8.82534 32.1184 8.77009 32.0751 8.71829L32.0775 8.72175C32.015 8.61125 31.9428 8.51456 31.8599 8.42938L31.8611 8.43054L31.8479 8.41212C31.7938 8.37298 31.7313 8.33615 31.6663 8.30277L31.6591 8.29932C31.492 8.15544 31.2708 8.06566 31.0268 8.06221H31.0256C30.9679 8.04609 30.8981 8.03228 30.826 8.02307L30.8188 8.02192H25.3549C24.5254 8.02192 23.8521 8.6665 23.8521 9.46071C23.8521 10.2549 24.5254 10.8995 25.3549 10.8995H27.3457L20.2263 18.159L15.3935 16.5764C15.2481 16.5269 15.081 16.4981 14.9067 16.4981C14.2996 16.4981 13.7766 16.8434 13.5398 17.3407L13.5362 17.3499L7.80896 29.591C7.72601 29.7637 7.67792 29.9663 7.67792 30.1792C7.67792 30.7605 8.03858 31.2623 8.55672 31.4891L8.56634 31.4914ZM36.8309 34.4944H4.67246V3.70441C4.67246 2.9102 3.99923 2.26562 3.16972 2.26562C2.34022 2.26562 1.66699 2.9102 1.66699 3.70441V35.9332C1.66699 36.7274 2.34022 37.372 3.16972 37.372H36.8309C37.6604 37.372 38.3337 36.7274 38.3337 35.9332C38.3337 35.139 37.6604 34.4944 36.8309 34.4944Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_9537_86538">
        <rect
          width="40"
          height="38.2979"
          fill="white"
          transform="translate(0 0.671875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CapMovementIcon;
