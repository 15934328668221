import { Link } from "react-router-dom";
import React from "react";

const LiveTradingSessionCard = ({ session, isShown }) => {
  return (
    <Link
      to={session.pageURL}
      className={`live-trading-session-item ${isShown ? "session-shown" : ""}`}
    >
      <img
        src={session.img}
        alt="Live Session"
        className="live-trading-session-bg"
      />
      <div className="live-trading-session-body">
        <div className="live-trading-session-subtitle">{session.subtitle}</div>
        <div className="live-trading-session-title">{session.title}</div>
        <div className="live-trading-session-author">{session.author}</div>
        <div className="live-trading-session-time">{session.time}</div>
      </div>
      <div
        className={`live-trading-session-badge ${session.isLive ? "live" : "upcoming"}`}
      >
        {session.isLive ? "Live" : "Upcoming"}
      </div>
    </Link>
  );
};

export default LiveTradingSessionCard;
