import React, { useMemo } from "react";
import taAvatar from "./taAvatar.png";
import activeCheck from "./activeCheckbox.png";
import inactiveCheck from "./inactiveCheckbox.png";
import SchoolIcon from "@mui/icons-material/School";
import DiamondIcon from "@mui/icons-material/Diamond";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import moment from "moment";
import "./SubscriptionsPage.scss";
import { useAuth } from "../../../contexts/Auth";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
const planLookup = {
  TradeAlgo: taAvatar,
  "Platinum Program": (
    <DiamondIcon
      sx={{
        width: "60px",
        height: "45px",
      }}
    />
  ),
  "Options Mentorship": (
    <OndemandVideoIcon
      sx={{
        width: "60px",
        height: "40px",
        position: "relative",
        bottom: "2px",
      }}
    />
  ),
  "Options Academy": (
    <SchoolIcon
      sx={{
        width: "60px",
        height: "45px",
      }}
    />
  ),
};
function SubscriptionsPage() {
  const { userData, loading } = useAuth();

  const rows = useMemo(() => {
    const active =
      userData?.subscriptions?.active?.map((i) => ({
        title: i.subscription_type,
        subtitle: i.subscription_subtype,
        status: i.active ? "Active" : "Expired",
        expires:
          i.product_sub_months == 999
            ? "Lifetime"
            : moment(i.expires).format("MMM Do YY"),
        price: i.sales_price,
        months: i.product_sub_months,
        days: i.product_sub_days,
        period: i.recurring_period,
      })) || [];
    const expired =
      userData?.subscriptions?.inactive?.map((i) => ({
        title: i.subscription_type,
        subtitle: i.subscription_subtype,
        status: i.active ? "Active" : "Expired",
        expires:
          i.product_sub_months == 999
            ? "Lifetime"
            : moment(i.expires).format("MMM Do YY"),
        price: i.sales_price,
        months: i.product_sub_months,
        days: i.product_sub_days,
        period: i.recurring_period,
      })) || [];
    return { expired, active };
  }, [userData]);
  return (
    <div className="subscriptions-settings-page">
      <div className="subscriptions-settings-page__header">
        My Subscriptions
      </div>

      <div className="subscriptions-settings-item">
        <div className="subscriptions-settings-item__title active-plan">
          Active Subscriptions
        </div>
        <div className="subscriptions-settings-item__plan">
          <div className="subscriptions-settings-item__plan-header">
            <div className="subscriptions-settings-item__plan-title">
              Subscription Plan
            </div>
            <div className="subscriptions-settings-item__plan-status">
              Status
            </div>
            <div className="subscriptions-settings-item__plan-price">Price</div>
            <div className="subscriptions-settings-item__plan-action">
              Actions
            </div>
          </div>

          {loading ? (
            <BeautifulSkeleton
              variant="rectangular"
              width={"100%"}
              height={200}
            />
          ) : (
            <div className="sub-table-rows">
              {rows?.active?.map((i, index) => {
                return (
                  <div className="sub-table-row" key={index}>
                    <div className="plan-wrapper">
                      <div className="plan-logo">
                        {" "}
                        {i.title === "TradeAlgo" ? (
                          <img src={planLookup[i.title]} />
                        ) : (
                          planLookup[i.title]
                        )}{" "}
                      </div>
                      <div className="text-wrapp">
                        <div className="plan-title"> {i.title} </div>
                        <div className="plan-subtitle"> {i.subtitle} </div>
                      </div>
                    </div>
                    <div className="status-wrapper">
                      <div className="inner-status">
                        <div className="status-text">
                          {" "}
                          <img
                            className="sub-status-logo"
                            src={activeCheck}
                          />{" "}
                          Active{" "}
                        </div>
                        <div className="sub-exp-date">{i.expires} </div>
                      </div>
                    </div>
                    <div className="sub-price-wrapper">
                      <div className="price-text">${i.price} </div>
                      <div className="price-range"> {i.period}</div>
                    </div>
                    <div className="sub-actions-wrapper">
                      <div className="actions-text">Auto Bill</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="subscriptions-settings-item">
        <div className="subscriptions-settings-item__title">
          Expired Subscriptions
        </div>
        <div className="subscriptions-settings-item__plan">
          <div className="subscriptions-settings-item__plan-header">
            <div className="subscriptions-settings-item__plan-title">
              Subscription Plan
            </div>
            <div className="subscriptions-settings-item__plan-status">
              Status
            </div>
            <div className="subscriptions-settings-item__plan-price">Price</div>
            <div className="subscriptions-settings-item__plan-action">
              Actions
            </div>
          </div>

          {loading ? (
            <BeautifulSkeleton
              variant="rectangular"
              width={"100%"}
              height={200}
            />
          ) : (
            <div className="sub-table-rows">
              {rows?.expired?.map((i, index) => {
                return (
                  <div className="sub-table-row" key={index}>
                    <div className="plan-wrapper">
                      <div className="plan-logo">
                        {" "}
                        {i.title === "TradeAlgo" ? (
                          <img src={planLookup[i.title]} />
                        ) : (
                          planLookup[i.title]
                        )}{" "}
                      </div>
                      <div className="text-wrapp">
                        <div className="plan-title"> {i.title} </div>
                        <div className="plan-subtitle"> {i.subtitle} </div>
                      </div>
                    </div>
                    <div className="status-wrapper">
                      <div className="inner-status">
                        <div className="expired-status-text">
                          {" "}
                          <img
                            className="sub-status-logo"
                            src={inactiveCheck}
                          />{" "}
                          Expired{" "}
                        </div>
                        <div className="sub-exp-date">{i.expires} </div>
                      </div>
                    </div>
                    <div className="sub-price-wrapper">
                      <div className="price-text">${i.price} </div>
                      <div className="price-range"> {i.period}</div>
                    </div>
                    <div className="sub-actions-wrapper">
                      <div className="actions-text">Auto Bill</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionsPage;
