import React, { useEffect, useState } from "react";
import { OpenChannel } from "sendbird-uikit";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.css";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import "../Chat/ChatContainer.scss";
import { liveChatSetter } from "../../appRedux/ducks/hideLiveChat";
import { connect } from "react-redux";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  card: {
    "& .MuiCardContent-root": {
      height: "100% !important",
    },
  },
}));

function Conversation({
  onClose,
  channelUrl,
  hideChat,
  liveChatExpanded,
  setHideChat,
}) {
  const classes = useStyles();
  const [expand, setExpand] = useState(liveChatExpanded);
  const [fullscreen, setFullscreen] = useState(false);
  useEffect(() => {
    setHideChat(hideChat, expand);
  }, [expand]);
  return (
    <div
      style={{
        position: "fixed",
        top: fullscreen ? "20px" : "150px",
        right: fullscreen ? "0px" : "20px",
        zIndex: 10000,
      }}
    >
      <Card
        className={clsx(classes.card, fullscreen ? "fullscreen-chat-ui" : "")}
        style={{
          height: liveChatExpanded ? "69vh" : "360px",
          width: "360px",
          position: "relative",
        }}
      >
        <span
          style={{
            cursor: "pointer",
            justifyContent: "flex-end",
            position: "absolute",
            zIndex: 1000,
            right: 0,
            top: 5,
          }}
        >
          <IconButton
            onClick={() => {
              setFullscreen(!fullscreen);
              setExpand(false);
            }}
            style={{
              height: "10px",
              width: "10px",
              marginRight: fullscreen ? "0px" : "12px",
            }}
          >
            {fullscreen ? (
              <CloseFullscreenIcon fontSize="medium" className="dmode-white" />
            ) : (
              <OpenInFullIcon fontSize="medium" className="dmode-white" />
            )}
          </IconButton>
          {!fullscreen && (
            <IconButton
              onClick={() => setExpand(!expand)}
              style={{ height: "10px", width: "10px" }}
              aria-label="close"
            >
              {liveChatExpanded ? (
                <ExpandMoreIcon fontSize="large" className="dmode-white" />
              ) : (
                <ExpandLessIcon fontSize="large" className="dmode-white" />
              )}
            </IconButton>
          )}
          <IconButton onClick={onClose}>
            <CloseIcon className="dmode-white" />
          </IconButton>
        </span>
        <CardContent style={{ height: "400px", padding: 0 }}>
          <OpenChannel
            renderCustomMessage={(message) => {
              if (
                (message._sender.userId === "Py7jBgYDxVTgiP6jVOHb38aDL4F3" ||
                  message._sender.userId === "5o6pwQ3h37RBXd9bgyJEn94isjf1" ||
                  message._sender.userId === "1sFupMUajFfYleW6JDBK9CMjg8A3") &&
                message.type !== "image/png"
              ) {
                const renderUserMessage = () => (
                  <div className="sendbird-msg-hoc sendbird-msg--scroll-ref">
                    <div className="sendbird-openchannel-user-message">
                      <div className="sendbird-openchannel-user-message__left">
                        <div
                          className="sendbird-context-menu"
                          style={{ display: "inline" }}
                        >
                          <div
                            className="sendbird-openchannel-user-message__left__avatar sendbird-avatar"
                            style={{ height: "28px", width: "28px" }}
                          >
                            <div
                              className="sendbird-avatar-img sendbird-image-renderer"
                              style={{
                                width: "100%",
                                minWidth: "28px",
                                maxWidth: "400px",
                                height: "28px",
                              }}
                            >
                              <div
                                className="sendbird-avatar-img--default"
                                style={{ width: "28px", height: "28px" }}
                              >
                                <div
                                  className=" sendbird-icon sendbird-icon-user sendbird-icon-color--content"
                                  style={{
                                    width: "16.1px",
                                    minWidth: "16.1px",
                                    height: "16.1px",
                                    minHeight: "16.1px",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 64 64"
                                    style={{ height: "100%", width: "100%" }}
                                  >
                                    <path
                                      className="icon-user_svg__fill"
                                      d="M42.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L50.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H21.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L8 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM32 5.333c7.364 0 13.333 5.97 13.333 13.334C45.333 26.03 39.363 32 32 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm0 5.334a8 8 0 100 16 8 8 0 000-16z"
                                      fill="#000"
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sendbird-openchannel-user-message__right">
                        <div className="sendbird-openchannel-user-message__right__top">
                          <span
                            className={`${message._sender.userId === "Py7jBgYDxVTgiP6jVOHb38aDL4F3" ? "brian" : "ross"} sendbird-openchannel-user-message__right__top__sender-name sendbird-label sendbird-label--caption-2 `}
                          >
                            {message._sender.nickname}
                          </span>
                          <span className="sendbird-openchannel-user-message__right__top__sent-at sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-3">
                            {moment(message.createdAt).format("LT")}
                          </span>
                        </div>
                        <div className="sendbird-openchannel-user-message__right__bottom">
                          <span className="sendbird-openchannel-user-message__right__bottom__message sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-1">
                            {message.message}
                          </span>
                        </div>
                      </div>
                      <div className="sendbird-openchannel-user-message__context-menu"></div>
                    </div>
                  </div>
                );
                return renderUserMessage;
              }
            }}
            channelUrl={channelUrl}
          />
        </CardContent>
      </Card>
    </div>
  );
}

const stateToProps = (state) => ({
  hideChat: state.hideLiveChat.hideChat,
  liveChatExpanded: state.hideLiveChat.liveChatExpanded,
});

const dispatchToProps = (dispatch) => ({
  setHideChat: (open, liveChatExpanded) =>
    dispatch(liveChatSetter(open, liveChatExpanded)),
});

export default connect(stateToProps, dispatchToProps)(Conversation);
