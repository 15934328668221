import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { useMedia } from "react-media";
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};
const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "25px",
      background: "white !important",
    },
    "&  .MuiAutocomplete-endAdornment": {
      display: "none",
    },
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      color: "grey",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    // '& .MuiOutlinedInput-root': {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
    // },
  },
}));

function StreamSearch({ setCurrentVideo, history, metaData }) {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const widthRegex = /width="(.*?)"/;
  const heightRegex = /height="(.*?)"/;
  return (
    <Autocomplete
      className={classes.root}
      style={{
        position: "relative",
        top: "-20px",
        right: isMobile ? "0px" : "64px",
      }}
      id="highlights-demo"
      sx={{ width: 450 }}
      options={metaData}
      disablePortal={true}
      noOptionsText={"No Matching Videos"}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <span style={{ position: "relative", left: "20px" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4121 14.4121L20 20"
                      stroke="#A2A3A5"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                    <path
                      d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                      stroke="#A2A3A5"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>

                  {params.InputProps.endAdornment}
                </span>
              </React.Fragment>
            ),
          }}
          style={{ border: "none !important" }}
          label="Search Videos"
          margin="normal"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);
        const el = option;
        return (
          <li {...props}>
            <div
              onClick={() => {
                setCurrentVideo({
                  live: false,
                  url: el.embed.html
                    .replace(widthRegex, 'width="100%"')
                    .replace(heightRegex, 'height="700px"'),
                  date: moment(el.created_time).format("LLL"),
                  description: el.description,
                });
                history.push(
                  "/live-sessions?video=" +
                    el.uri.split("/")[el.uri.split("/").length - 1],
                );
              }}
            >
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text.replace("mp4", "")}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}

export default StreamSearch;
