import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import PublicLayout from "../components/common/Layout/PublicLayout";
import { useAuth } from "../contexts/Auth";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

// Public Route prevents access to pages that has the property "protected" set, if the user is authenticated already.
const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const {
    authUser,
    authToken,
    userData,
    isUserBasic,
    canSeeInternal,
    loading,
    authenticateRequest,
  } = useAuth();
  const cookies = new Cookies();
  const history = useHistory();
  const search = useLocation().search;
  const lastLocation = rest.location.state && rest.location.state.from.pathname;
  const token = new URLSearchParams(search).get("token");
  useEffect(() => {
    if (authUser && authToken && !loading) {
      if (rest.type === "protected") {
        history.push(
          lastLocation && lastLocation !== "/signout"
            ? lastLocation
            : canSeeInternal
              ? "/home/Intraday/Auto/Up"
              : "/platinum-program/sign-up",
        );
      }
      if (rest.type === "mobile") {
        if (token) {
          const env = process.env["REACT_APP_ENV"];
          const ssoAtStr = `sso_at_${env}`;
          // Set a cookie with the token using universal-cookie
          authenticateRequest(token);
          cookies.set(ssoAtStr, token, {
            domain: ".tradealgo.com",
            path: "/",
            secure: true,
            sameSite: "strict",
          });
          console.log({ cookies });
        }
      }
    }
  }, [
    authToken,
    authUser,
    history,
    isUserBasic,
    lastLocation,
    rest.type,
    userData,
    canSeeInternal,
    loading,
  ]);

  return (
    <Route
      {...rest}
      render={(props) =>
        rest.customLayout ? (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        ) : (
          <PublicLayout {...rest}>
            <Component {...props} />
          </PublicLayout>
        )
      }
    />
  );
};

export default PublicRoute;
