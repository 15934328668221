// Action Types
export const RUN_JOURNEY_SET = "RJ/SET";

// Action Creators
export const runJourneySetter = (run) => {
  return {
    type: RUN_JOURNEY_SET,
    run,
  };
};

const INIT_STATE = {
  run: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RUN_JOURNEY_SET:
      return {
        ...state,
        run: action.run,
      };
    default:
      return state;
  }
};

export default reducer;
