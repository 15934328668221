import * as React from "react";

const TAIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 19 20"
    fill="none"
  >
    <path
      d="M18.1446 20C18.0602 19.9608 17.9205 19.8995 17.7816 19.8307C16.827 19.3569 15.8727 18.882 14.9186 18.4062C14.1291 18.0109 13.3433 17.6075 12.5538 17.2114C11.7168 16.7917 10.8782 16.3745 10.0382 15.9598L7.02803 14.465C5.95046 13.9301 4.87439 13.3949 3.7998 12.8594C3.71392 12.8183 3.63083 12.7716 3.55108 12.7198C3.33223 12.572 3.27247 12.3991 3.38078 12.1634C3.64818 11.5819 3.92678 11.0048 4.19568 10.4233C4.49445 9.78716 4.77753 9.14729 5.07257 8.51111C5.59492 7.38506 6.11778 6.25999 6.64112 5.13591C7.15999 4.02217 7.67985 2.90942 8.20071 1.79765C8.43525 1.29669 8.67277 0.797949 8.90582 0.296989C8.98648 0.124091 9.10151 0.0199092 9.30692 0.00143723C9.52353 -0.0140792 9.6393 0.0974916 9.71997 0.265956C9.93957 0.722091 10.1562 1.17946 10.3698 1.63806C10.688 2.323 10.9957 3.01311 11.3214 3.6951C11.3739 3.79642 11.4009 3.90882 11.4 4.02269C11.3991 4.13656 11.3703 4.24851 11.3162 4.349C11.001 5.00513 10.7014 5.66864 10.393 6.32846C9.81881 7.5555 9.24368 8.78228 8.66754 10.0088C8.61377 10.1248 8.56297 10.2423 8.51816 10.362C8.42554 10.6191 8.54505 10.8711 8.82739 11.0115C9.65947 11.4267 10.4953 11.8361 11.3266 12.2535C12.5038 12.8446 13.6772 13.4417 14.8529 14.0335C15.3257 14.2714 15.7985 14.5108 16.278 14.7332C16.4564 14.8212 16.5953 14.9715 16.6679 15.1551C17.3048 16.5176 17.9387 17.8816 18.5696 19.247C18.6122 19.3314 18.6412 19.4219 18.6555 19.5152C18.6921 19.7872 18.4829 20.0036 18.1446 20Z"
      fill="black"
    />
    <path
      d="M3.00242 13.9601L3.00119 13.9627C2.99356 13.959 2.98987 13.9579 2.98987 13.9579C2.98987 13.9579 2.99313 13.9589 2.99944 13.9598C3.00021 13.9599 3.001 13.96 3.00182 13.9601L3.03436 13.8926L2.70089 13.6487M3.00242 13.9601L3.10641 13.9413C3.1135 13.9318 3.11723 13.9283 3.11723 13.9283C3.11723 13.9283 3.1138 13.9316 3.10663 13.9362L3.03748 13.8872L3.21777 13.512M3.00242 13.9601L3.03476 13.8929L3.09965 13.9403C3.09099 13.9451 3.07901 13.9504 3.06347 13.9546C3.03882 13.9614 3.01712 13.9618 3.00242 13.9601ZM2.70089 13.6487L3.03708 13.8869L3.21777 13.512M2.70089 13.6487C2.83758 13.4618 3.00714 13.4108 3.21777 13.512M2.70089 13.6487C2.64625 13.7259 2.5997 13.8083 2.56197 13.8948L3.21777 13.512M5.24588 15.0673C5.58036 15.2306 5.91311 15.3931 6.24618 15.5585C6.57276 15.7217 6.89494 15.8865 7.21088 16.0579C6.68866 16.3344 6.15786 16.6038 5.6251 16.8722C4.52612 17.4241 3.42688 17.9746 2.3274 18.5235L2.32703 18.5237C2.21384 18.5804 2.10085 18.637 1.988 18.6935C1.51151 18.9323 1.03762 19.1697 0.561975 19.4021C1.14797 18.1388 1.7356 16.876 2.32487 15.6136L2.32541 15.6124C2.44896 15.346 2.575 15.0693 2.70021 14.7945C2.80674 14.5606 2.91267 14.3281 3.01595 14.1043L3.01605 14.1044L3.02024 14.0948C3.03499 14.0609 3.05188 14.0281 3.07079 13.9963C3.41904 14.1648 3.76383 14.3361 4.11144 14.5088C4.29273 14.5988 4.47478 14.6893 4.65849 14.7799L4.65927 14.7803C4.85567 14.8767 5.05107 14.9721 5.24588 15.0673Z"
      fill="black"
      stroke="black"
    />
  </svg>
);

export default TAIcon;
