import React from "react";
import Button from "@mui/material/Button";

import "./style.scss";
import TelegramImg from "../../../assets/images/telegram.png";

const RealTimeTradingAlerts = ({ selectNavigation }) => {
  const goToRealTime = () => {
    selectNavigation("real-time-trading-alerts");
  };

  return (
    <div className="platinum-program-page-real-time-trading-content">
      <div className="platinum-program-page-real-time-trading-info">
        <div className="platinum-program-page-real-time-trading-body">
          <div className="platinum-program-page-real-time-trading-title">
            Real-time Trading Alerts
          </div>
          <div className="platinum-program-page-real-time-trading-description">
            Our team of experts delivers real-time, profitable trading
            opportunities straight to your phone via the telegram app. Maximize
            your profits, minimize your risks. Join now and start seeing the
            benefits of being a part of our insider community.
          </div>
          <Button
            className="platinum-program-page-real-time-trading-join-button"
            onClick={goToRealTime.bind(this)}
          >
            Join Real-time Trading Alerts
          </Button>
        </div>

        <div className="trading-image-wrapper">
          <img src={TelegramImg} alt="trading" className="trading-image" />
        </div>
      </div>
    </div>
  );
};

export default RealTimeTradingAlerts;
