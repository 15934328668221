import { apiURL } from "../../../appRedux/actions/helpers";
import { getMatchBounds } from "../../utilities";
import { StockIcon, FundIcon, EtfIcon } from "../Icon";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@material-ui/core";
import axios from "../../../helpers/axios";
import useDebounce from "../../OptionScanner/useDebounce";
import { symbolSetter } from "../../../appRedux/ducks/symbol";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import closeIcon from "./close.png";
const styles = {
  input: {
    position: "relative",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
    },
  },
};
const getMatchScore = (security, query) => {
  if (security.ticker && security.ticker.toLowerCase() === query) {
    return 0;
  }

  if (security.ticker && security.ticker.toLowerCase().startsWith(query)) {
    return 1;
  }

  if (security.ticker && security.ticker.toLowerCase().includes(query)) {
    return 2;
  }

  if (security.name && security.name.toLowerCase().startsWith(query)) {
    return 3;
  }

  return 4;
};
const useStyles = makeStyles((theme) => ({
  root: {
    "@media (max-width: 992px)": {
      minWidth: "210px!important",
    },
    "& .MuiChip-sizeMedium": {
      display: "none",
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
    },
    "& .MuiTextField-root": {
      margin: "0!important",
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: "Inter",
      position: "relative",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.primary.searchBg,
      borderRadius: "20px",
      height: "40px",
      width: "100%",
      paddingRight: "35px!important",
      border: theme.palette.primary.searchBorder,
      color: theme.palette.primary.text,
    },
    "&  .MuiAutocomplete-endAdornment": {
      display: "none",
      visibility: "none",
    },
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      display: "none",
      position: "relative",
      top: "32px",
      // color: "rgba(162, 163, 165, 1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  option: {
    "& .MuiAutocomplete-option": {
      paddingLeft: "7px !important",
      border: "none !important",
    },
    "& .mr-2 > svg": {
      fill: theme.palette.primary.svg,
      stroke: theme.palette.primary.svg,
    },
  },
}));

function SearchBar({ setSymbol, isMobile, setMobileSearch }) {
  const hardHistory = useHistory();
  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 400);
  const CancelToken = axios.CancelToken;
  const lastAbortController = useRef();
  const savedSearch = localStorage.getItem("savedSearchTradersAlgo");
  const search = async (params, cancelToken) => {
    try {
      const response = await axios.get(`${apiURL}/search/${params}`, {
        cancelToken: cancelToken.token,
        withCredentials: true,
      });

      formatSearchResult(response.data, params);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.log("error searching", error);
      }
    }
  };

  const formatSearchResult = async (data, value) => {
    value = value.toString().toLowerCase();
    let stocks = data
      .filter(
        (item) => item.type === "common_stock" && item.ticker && item.name,
      )
      .map((s) => {
        return {
          ...s,
          value: s.ticker,
          type: "stock",
        };
      });

    let funds = data
      .filter(
        (item) =>
          (item.fundId && item.tickerSymbol) || item.type === "mutual_fund",
      )
      .map((f) => {
        return {
          ...f,
          ticker: f.tickerSymbol || f.ticker,
          value: f.tickerSymbol || f.ticker,
          type: "fund",
        };
      });

    let etfs = data
      .filter((item) => item.type === "etf" && item.ticker && item.name)
      .map((e) => {
        return {
          ...e,
          value: e.ticker,
          type: "etf",
        };
      });

    let newResult = [...stocks, ...funds, ...etfs];
    newResult = newResult
      .map((security) => {
        security.score = getMatchScore(security, value.toLowerCase());
        return security;
      })
      .sort((a, b) => {
        return a.score - b.score;
      });

    newResult = newResult.filter(
      (x) =>
        x.name?.toString().toLowerCase().includes(value) ||
        x.ticker?.toString().toLowerCase().includes(value),
    );

    setResults(newResult);
  };
  useEffect(() => {
    if (debouncedSearchValue === "") {
      return setResults([]);
    }
    if (lastAbortController.current) {
      lastAbortController.current.cancel("aborted");
    }
    const currentAbortController = CancelToken.source();
    lastAbortController.current = currentAbortController;

    search(debouncedSearchValue, currentAbortController);
  }, [debouncedSearchValue]);
  const getMatchValue = (value) => {
    const items = [];
    while (value) {
      const bounds = getMatchBounds(value, searchValue);
      if (!bounds) {
        items.push({
          value,
          isHighlighted: false,
        });
        break;
      }
      const nonMatch = value.slice(0, bounds.start);
      if (nonMatch) {
        items.push({
          value: nonMatch,
          isHighlighted: true,
        });
      }
      const match = value.slice(bounds.start, bounds.end);
      items.push({
        value: match,
        isHighlighted: true,
      });
      value = value.slice(bounds.end);
    }
    return items;
  };

  const renderHighlight = (value) => {
    return value.substring(0, searchValue.length).toString().toLowerCase() ===
      searchValue.toString().toLowerCase() ? (
      <>
        <span className="text-blue text-ellipsis">
          {value.substring(0, searchValue.length)}
        </span>
        {value.substring(searchValue.length)}
      </>
    ) : (
      <span>{value}</span>
    );
  };

  const renderName = (value) => {
    const items = getMatchValue(value);
    return items.map((item, index) => {
      return (
        <span key={index}>
          {item.isHighlighted ? renderHighlight(item.value) : item.value}
        </span>
      );
    });
  };

  const renderTicker = (value) => {
    const items = getMatchValue(value);
    return items.map((item, index) => {
      return (
        <span key={index}>
          {item.isHighlighted ? renderHighlight(item.value) : item.value}
        </span>
      );
    });
  };

  const renderItemDetails = (
    icon,
    ticker,
    name,
    type,
    localStorage = false,
    props,
  ) => {
    return (
      <div
        className={clsx(classes.option, "d-flex align-items-center")}
        {...props}
      >
        <div className="d-flex ">
          <span
            style={{ fonstSize: "14px" }}
            className="text-ellipsis mui-search-dropdown-label"
          >
            <span className="mr-2">{icon}</span>
            <span style={{ fonstSize: "14px !important" }}>
              {localStorage ? ticker : renderTicker(ticker)}
            </span>
          </span>
          <span
            style={{ fontSize: "15px !important", maxWidth: "210px" }}
            className="mr-2 text-ellipsis"
          >
            {localStorage ? name : renderName(name)}
          </span>
        </div>

        <div className="ml-auto">
          <span>{type}</span>
        </div>
      </div>
    );
  };

  const resultsMemo = useMemo(() => {
    let localOptions = JSON.parse(savedSearch);
    if (results.length) return results;
    else if (!!localOptions?.length && !searchValue.length) return localOptions;
    else return [];
  }, [results, savedSearch, searchValue]);
  return (
    <div className="row header-search">
      <div className={clsx(classes.option, "col-12")}>
        <Autocomplete
          className={classes.root}
          style={{ position: "relative", width: "100%", minWidth: "380px" }}
          id="header-search-inputbox"
          options={resultsMemo}
          loading={false}
          disablePortal={true}
          multiple={false}
          onChange={(e, i) => {
            if (i) {
              const value = i?.ticker;
              setSymbol({
                value,
                type: "company",
              });
              const url = `/company/${value}`;
              hardHistory.push(url);
            }
          }}
          noOptionsText={"No Matching Tickers Found"}
          filterOptions={(options) => options}
          getOptionLabel={(option) => option.ticker}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              InputProps={{
                ...params.InputProps,
                classes: { input: styles["input"] },
                endAdornment: (
                  <React.Fragment>
                    <span
                      style={{
                        position: "relative",
                        left: isMobile ? "30px" : "20px",
                      }}
                    >
                      {isMobile ? (
                        <IconButton
                          onClick={() => setMobileSearch(false)}
                          aria-label="delete"
                          size="small"
                        >
                          <img src={closeIcon} fontSize="inherit" />
                        </IconButton>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.4121 14.4121L20 20"
                            stroke="#B7B4C7"
                            strokeWidth="2"
                            strokeLinecap="square"
                          />
                          <path
                            d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                            stroke="#B7B4C7"
                            strokeWidth="2"
                            strokeLinecap="square"
                          />
                          {params.InputProps.endAdornment}
                        </svg>
                      )}
                    </span>
                  </React.Fragment>
                ),
              }}
              style={{ border: "none !important" }}
              placeholder="Search Tickers..."
              margin="normal"
            />
          )}
          renderOption={(props, option) => {
            let icon = <StockIcon />;
            let type = "Stock";
            if (option.type === "fund") {
              icon = <FundIcon />;
              type = "Fund";
            }
            if (option.type === "etf") {
              icon = <EtfIcon />;
              type = "ETF";
            }
            return renderItemDetails(
              icon,
              option.ticker,
              option.name,
              type,
              option?.localStorage,
              props,
            );
          }}
        />
      </div>
    </div>
  );
}

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});
export default connect(null, dispatchToProps)(SearchBar);
