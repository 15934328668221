import React from "react";
import "./sidebarStyles.scss";
import SidebarContent2 from "./SidebarContent";
import { connect } from "react-redux";

const Sidebar = ({ themeMode }) => {
  return (
    <React.Fragment>
      <div
        className={`vertical-menu ${themeMode === "light" ? "light-mode" : "dark-mode"} `}
      >
        <div data-simplebar className="h-100">
          <SidebarContent2 />
        </div>
      </div>
    </React.Fragment>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(Sidebar);
