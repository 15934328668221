import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import { widget } from "../../charting_library/charting_library.esm.js";
import Datafeed, { closeSocket, getUnsub } from "./datafeed";
import useWindowSize from "../../hooks/useWindowSize";
import MTZ from "moment-timezone";
import { useAuth } from "../../contexts/Auth";
import { apiURL, isInternalWS } from "../../appRedux/actions/helpers";
import FreeTierDatafeed from "./FreeTierDatafeed";
import { getLanguageFromURL } from "./ChartGrid/FavoritesContainer.js";

function ExampleChart({ symbol, themeMode, userSettings }) {
  const { userData, canSeeInternal } = useAuth();
  const userId = userData?.userId;
  let resolution = userSettings?.defaultResolution || "15";
  const indicators = userSettings?.defaultIndicators || [];
  const candleType = userSettings?.candleType || "1";
  const settingsMemo = useMemo(() => {
    return `${resolution},${indicators},${candleType}`;
  }, [resolution, indicators, candleType]);
  //0 = private 1 = community
  const chartSaveMode = userSettings?.chartSaveMode || 0;
  const [widgetRef, setWidgetRef] = useState({});
  const windowSize = useWindowSize();
  const unsubRef = useRef(getUnsub());
  useEffect(() => {
    unsubRef.current = getUnsub();
  }, [getUnsub()]);
  useEffect(() => {
    return () => {
      setTimeout(() => {
        if (!isInternalWS) {
          Datafeed.unsubscribeBars("full-unsub");
        }
      }, 1200);
    };
  }, []);
  useEffect(() => {
    return () => {
      unsubRef?.current && unsubRef?.current();
    };
  }, []);
  useEffect(() => {
    return () => closeSocket();
  }, []);
  const disabled_features = [
    "use_localstorage_for_settings",
    "display_market_status",
    "popup_hints",
    "header_screenshot",
    // "widget_logo",
    // "header_indicators",
    "header_compare",
    // "left_toolbar",
    "header_symbol_search",
    "header_settings",
    "header_fullscreen_button",
  ];

  useEffect(() => {
    setWidgetRef({});
    const widgetOptions = {
      // debug: true,
      studies_overrides: {
        "williams alligator.jaw length": 13,
        "williams alligator.teeth length": 8,
        "williams alligator.lips length": 5,
        "williams alligator.jaw offset": 8,
        "williams alligator.teeth offset": 5,
        "williams alligator.lips offset": 3,
      },
      symbol,
      interval: resolution,
      datafeed: canSeeInternal ? Datafeed : FreeTierDatafeed,
      symbol_search_request_delay: 1000,
      container: "tv_chart_container",
      library_path: "/charting_library/",
      locale: getLanguageFromURL() || "en",
      time_frames: [
        { text: "5Y", resolution: "1D", description: "5 Years" },
        { text: "1Y", resolution: "1D", description: "1 Year" },
        { text: "6M", resolution: "1D", description: "6 Months" },
        { text: "3M", resolution: "1D", description: "3 Months" },
        { text: "1M", resolution: "1", description: "1 Month" },
        { text: "5D", resolution: "1", description: "5 Days" },
        { text: "1D", resolution: "1", description: "1 Day" },
      ].reverse(),
      header_widget_buttons_mode: "adaptive",
      disabled_features,
      enabled_features: [
        // "use_localstorage_for_settings",
        "side_toolbar_in_fullscreen_mode",
        "chart_style_hilo",
        // "extended_hours",
        "secondary_series_extend_time_scale",
        // "high_density_bars",
        "header_in_fullscreen_mode",
        "hide_last_na_study_output",
        "seconds_resolution",
      ],
      load_last_chart: false,
      fullscreen: false,
      autosize: true,
      width: "100%",
      timezone: "America/New_York",
      client_id: "patternscanner",
      theme: themeMode,
      user_id: userId,
      auto_save_delay: 10,
      charts_storage_url: apiURL,
      // 1.3 = community , 1.1 = private
      charts_storage_api_version: chartSaveMode ? "1.3" : "1.1",
      overrides: {
        "mainSeriesProperties.showCountdown": true,
      },
      loading_screen: {
        backgroundColor: "#222222",
        foregroundColor: "#229712",
      },
    };
    // if logged in to test account allow saving of chart
    if (userId === "PzhoHfWuv6dxNChrwoCfffjh3of2") {
      widgetOptions.charts_storage_url = apiURL;
      widgetOptions.charts_storage_api_version = "1.1";
    }
    if (symbol) {
      const now = MTZ.utc().valueOf() / 1000;
      const tvWidget = new widget(widgetOptions);
      tvWidget.onChartReady(() => {
        setWidgetRef(tvWidget);
        let chartStart = MTZ.utc().subtract(8, "hours").valueOf() / 1000;
        tvWidget
          .activeChart()
          .onIntervalChanged()
          .subscribe(null, function () {
            tvWidget
              .activeChart()
              .setVisibleRange(
                { from: chartStart, to: now },
                { percentRightMargin: 5 },
              );
          });
      });
    }
  }, [userId, themeMode, chartSaveMode, canSeeInternal]);

  useEffect(() => {
    if (Object.keys(widgetRef).length) {
      const chart = widgetRef.activeChart();
      const studies = chart.getAllStudies();
      // Check if volume study already exists
      const volumeStudy = studies.find(
        (study) => study.name.toLowerCase() === "volume",
      );
      // If volume study doesn't exist, remove all studies and create volume
      if (!volumeStudy) {
        chart.removeAllStudies();
        chart.createStudy("volume");
      } else {
        studies.forEach((study) => {
          if (study.name.toLowerCase() !== "volume") {
            chart.removeEntity(study.id); // Use removeEntity to remove other studies
          }
        });
      }

      // Set the chart type, resolution, and add other indicators
      chart.setChartType(Number(candleType));
      chart.setResolution(resolution);
      indicators?.forEach((indicator) => chart.createStudy(indicator));
    }
  }, [settingsMemo, widgetRef]);

  useEffect(() => {
    if (document.getElementById("tv_chart_container")) {
      const currentWidth =
        document.getElementById("tv_chart_container").clientWidth;
      const maxHeight = currentWidth * 0.6;
      document.getElementById("tv_chart_container").style.maxHeight =
        `${maxHeight}px`;
    }
  }, [windowSize]);
  return (
    <>
      {symbol && (
        <div id="tv_chart_container" className={"TVChartContainer example"} />
      )}
    </>
  );
}

export default ExampleChart;
