import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Collapse from "@mui/material/Collapse";
import "../PlatinumProgram/RealTimeTradingAlertsComponent/style.scss";
import { supportData } from "./supportData";
import Button from "@mui/material/Button";
import NotificationBell from "../../assets/images/notification-bell.png";
import Livestreaming from "../../assets/images/live-streaming.png";
import NotificationBellLight from "../../assets/images/alert-icon-light.png";
import LivestreamingLight from "../../assets/images/livestream-icon-light.png";
import { connect } from "react-redux";
const Support = ({ tab, setTab, themeMode }) => {
  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    let duplicatedItems = [...openItems];

    if (duplicatedItems.includes(index)) {
      duplicatedItems = duplicatedItems.filter((obj) => obj !== index);
    } else {
      duplicatedItems.push(index);
    }

    setOpenItems(duplicatedItems);
  };

  return (
    <div className="support-wrapper">
      <div className="real-time-trading-alerts-component support-faq">
        <div className="real-time-trading-alerts-title">
          Frequently Asked Questions
        </div>
        {supportData.map((section, sectionKey) => (
          <div className="real-time-trading-alerts-section" key={sectionKey}>
            <div className="real-time-trading-alerts-section-title">
              {section.title}
            </div>

            {section.items.map((item) => (
              <div
                className={`real-time-trading-alerts-section-item ${openItems.includes(item.id) ? "active" : ""}`}
                onClick={toggleItem.bind(this, item.id)}
                key={item.id}
              >
                <div className="real-time-trading-alerts-section-item-main">
                  {item.title}{" "}
                  {openItems.includes(item.id) ? <RemoveIcon /> : <AddIcon />}
                </div>
                <Collapse in={openItems.includes(item.id)}>
                  <div
                    className="real-time-trading-alerts-section-item-more"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Collapse>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="support-block-wrapper">
        <div className="description-blocks mt-0">
          <span className="title">Real-Time Alerts</span>
          <div className="image-title-description">
            <img
              src={
                themeMode === "light" ? NotificationBell : NotificationBellLight
              }
              className="bell"
            />
            <div className="title-description">
              <span className="inner-title">Real-Time Alerts & Support</span>
              <span className="inner-description">
                Brian Mitchell provide profitable Trade Alerts and answers
                question in the live Trade Board and Chat
              </span>
            </div>
          </div>
          <Button
            onClick={() =>
              setTab(
                tab === "real-time-trading-alerts"
                  ? "livestream"
                  : "real-time-trading-alerts",
              )
            }
            className="supports-widget-item-button"
          >
            {tab === "real-time-trading-alerts"
              ? "Livestreams"
              : "Real-Time Alerts"}
          </Button>
        </div>
        <div className="description-blocks mt-0">
          <span className="title">Day Trading Livestream</span>
          <div className="image-title-description">
            <img
              src={themeMode === "light" ? Livestreaming : LivestreamingLight}
              className="bell"
            />
            <div className="title-description">
              <span className="inner-title">LiveStreams</span>
              <span className="inner-description">
                Brian Mitchell answers all your questions live.{" "}
              </span>
            </div>
          </div>
          <Button
            onClick={() => setTab("livestream")}
            className="supports-widget-item-button"
          >
            Livestreams
          </Button>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(Support);
