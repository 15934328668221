import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: "98%",
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    color: "#3183FF",
  };
}

function PlatMentorDropown({ themeMode, mentor, setMentor, availableMentors }) {
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMentor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: "95%", mt: 3 }}>
        <Select
          multiple={false}
          displayEmpty
          value={mentor}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return (
              <em
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: themeMode === "dark" ? "white" : "#1C1C1E",
                }}
              >
                {selected.length === 0
                  ? "Select Your Mentor"
                  : selected.join(", ")}
              </em>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>Select Your Mentor</em>
          </MenuItem>
          {availableMentors.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, mentor, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(PlatMentorDropown);
