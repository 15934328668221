import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
// Action Types
export const EXCLUSIVE_MEMBERS_LOAD = "EM/LOAD/TOP";
export const EXCLUSIVE_MEMBERS_LOAD_SUCCESS = "EM/LOAD/TOP/SUCCESS";
export const EXCLUSIVE_MEMBERS_LOAD_FAILURE = "EM/LOAD/TOP/FAILURE";

// Action Creators
export const exclusiveMembersLoad = () => ({
  type: EXCLUSIVE_MEMBERS_LOAD,
});
export const exclusiveMembersLoadSuccess = (data) => ({
  type: EXCLUSIVE_MEMBERS_LOAD_SUCCESS,
  data,
});
export const exclusiveMembersLoadFailure = (error) => ({
  type: EXCLUSIVE_MEMBERS_LOAD_FAILURE,
  error,
});

// Sagas
function* fetchExclusiveMembers() {
  try {
    const response = yield axios.get(`${apiURL}/exclusive-members`, {
      withCredentials: true,
    });
    yield put(exclusiveMembersLoadSuccess(response.data));
  } catch (error) {
    yield put(exclusiveMembersLoadFailure(error));
  }
}

function* listenExclusiveMembersLoad() {
  yield takeEvery(EXCLUSIVE_MEMBERS_LOAD, fetchExclusiveMembers);
}

// Root Saga
export function* saga() {
  yield all([fork(listenExclusiveMembersLoad)]);
}

const INIT_STATE = {
  exclusiveMembers: "",
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXCLUSIVE_MEMBERS_LOAD_SUCCESS:
      return {
        ...state,
        exclusiveMembers: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
