import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const SWING_TRADES_LOAD_TOP = "SWT/LOAD/TOP";
export const SWING_TRADES_LOAD_TOP_SUCCESS = "SWT/LOAD/TOP/SUCCESS";
export const SWING_TRADES_LOAD_TOP_FAILURE = "SWT/LOAD/TOP/FAILURE";

// Action Creators
export const swingTradesLoad = (page) => ({
  type: SWING_TRADES_LOAD_TOP,
  page,
});
export const swingTradesLoadSuccess = (data) => ({
  type: SWING_TRADES_LOAD_TOP_SUCCESS,
  data,
});
export const swingTradesLoadFailure = (error) => ({
  type: SWING_TRADES_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchSwingTrades(action) {
  try {
    const { page } = action;
    yield put(
      swingTradesLoadSuccess({
        swingTrades: [],
        loading: true,
        noData: false,
      }),
    );
    const response = yield axios.get(`${apiURL}/swing-trades?page=${page}`, {
      withCredentials: true,
    });
    // check if we are on page 0 and if there are no swing trades
    if (page === 0 && response.data === "") {
      // set the noDataFlag to true
      yield put(
        swingTradesLoadSuccess({
          swingTrades: [],
          loading: false,
          noData: true,
        }),
      );
    } else {
      yield put(
        swingTradesLoadSuccess({
          swingTrades: response.data,
          loading: false,
          noData: false,
        }),
      );
    }
  } catch (error) {
    yield put(swingTradesLoadFailure(error));
  }
}

function* listenSwingTradesLoad() {
  yield takeEvery(SWING_TRADES_LOAD_TOP, fetchSwingTrades);
}

// Root Saga
export function* saga() {
  yield all([fork(listenSwingTradesLoad)]);
}

const INIT_STATE = {
  swingTrades: [],
  loading: true,
  noData: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SWING_TRADES_LOAD_TOP_SUCCESS:
      return {
        ...state,
        swingTrades: action.data.swingTrades,
        loading: action.data.loading,
        noData: action.data.noData,
      };
    default:
      return state;
  }
};

export default reducer;
