// Action Types
export const CHANGE_NAME = "CN/SET";

// Action Creators
export const nameChange = (change) => {
  return {
    type: CHANGE_NAME,
    change,
  };
};

const INIT_STATE = {
  change: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_NAME:
      return {
        ...state,
        change: action.change,
      };
    default:
      return state;
  }
};

export default reducer;
