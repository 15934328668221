import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { courseDataMap } from "./optionsVideoMetadata";

function CourseDropdown({ courseTab, setCourseTab, setCurrentVideo }) {
  const [course, setCourse] = useState(courseTab);

  const handleChange = (event) => {
    setCourse(event.target.value);
    setCourseTab(event.target.value);
    setCurrentVideo(courseDataMap[event.target.value][0]);
  };

  return (
    <FormControl sx={{ minWidth: 220 }} size="small">
      <Select value={course} onChange={handleChange}>
        <MenuItem value={"fundamentals"}>Fundamentals</MenuItem>
        <MenuItem value={"intermediate"}>Intermediate</MenuItem>
        <MenuItem value={"leaps"}>L.E.A.P.S</MenuItem>
        <MenuItem value={"cash-secured-puts"}>Cash Secured Puts</MenuItem>
        <MenuItem value={"advanced"}>Advanced</MenuItem>
      </Select>
    </FormControl>
  );
}
export default CourseDropdown;
