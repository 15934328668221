/**
 * Socket Saga
 *  This saga is responsible for connecting a socket client to the server and emitting all messages into redux
 */
import { all, put, takeEvery, fork } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

// Global vars
let client;
let ping;
let socketChannel;
let channels = [];
let sendBuffer = [];
let messageLoopHandler;

// Action Types
export const SOCKET_MESSAGE = "SOCKETS/MESSAGE";
export const SOCKET_LISTEN = "SOCKET/LISTEN";
export const CLEAN_MESSAGE_QUEUE = "SOCKETS/MESSAGE/CLEAN";
export const CLEAN_UNREAD_MESSAGE_COUNT = "SOCKETS/UNREAD/MESSAGE/CLEAN";
export const CLEAN_TRADE_MESSAGE_QUEUE = "SOCKETS/TRADE_MESSAGE/CLEAN";
function connect() {
  if (!localStorage?.auth_token) {
    // No Socket for unauthed users
    return;
  }
  if (typeof client !== "undefined") {
    client = undefined;
  }
  client = new WebSocket(process.env.REACT_APP_WEBSOCKET);
  client.onopen = () => {
    ping = setInterval(() => {
      if (client.readyState !== WebSocket.OPEN) {
        clearInterval(ping);
        return;
      }
      clientSend("ping");
    }, 10000);
    clientSend(JSON.stringify(channels));
  };

  client.onclose = (e) => {
    console.log("socket closed", e);
    clearInterval(ping);
    client = undefined;
  };

  client.onerror = (e) => {
    console.log("error", e);
    clearInterval(ping);
    client.close();
    client = undefined;
  };

  socketChannel = eventChannel((emitter) => {
    client.onmessage = (event) => {
      if (typeof ping === "undefined") {
        client.close();
        client = undefined;
      } else if (event.data !== "pong") {
        emitter(JSON.parse(event.data));
      }
    };
    return () => {
      client.close();
    };
  });
}

function clientSend(message) {
  if (!client) {
    sendBuffer.push(message);
    connect();
  } else {
    if (client.readyState === WebSocket.OPEN) {
      if (sendBuffer.length > 0) {
        sendBuffer.forEach((bufferedMessage) => client.send(bufferedMessage));
        sendBuffer = [];
      }
      client.send(message);
    } else {
      sendBuffer.push(message);
    }
  }
}

// ACTION CREATOR
export const socketOnMessage = (data) => {
  return {
    type: SOCKET_MESSAGE,
    channel: data.channel,
    data: JSON.parse(data?.data || "{}"),
  };
};

export const socketListen = (channels) => {
  return {
    type: SOCKET_LISTEN,
    channels,
  };
};

export const cleanMessageQueue = () => {
  return {
    type: CLEAN_MESSAGE_QUEUE,
    snackbarMessages: [],
  };
};
export const cleanUnreadMessageCount = () => {
  return {
    type: CLEAN_UNREAD_MESSAGE_COUNT,
    unreadMessagesCount: 0,
  };
};

export const cleanTradeMessagesQueue = () => {
  return {
    type: CLEAN_TRADE_MESSAGE_QUEUE,
    tradeMessages: [],
  };
};

// SAGA
function* message(item) {
  yield put(socketOnMessage(item));
}

function* messageLoop() {
  if (socketChannel) yield takeEvery(socketChannel, message);
}

function* changeChannel(action) {
  /*
        Live update switch - ability to subscribe live updates by user information
 
        const user = yield select(state => state.auth.userType);
        if (!client && user.userType.indexOf('Platinum')) {
    */
  channels = action.channels;

  if (!client) {
    // First run
    connect();
  }
  if (!messageLoopHandler) {
    messageLoopHandler = yield fork(messageLoop);
  }
  clientSend(JSON.stringify(action.channels));
}

function* channelLoop() {
  yield takeEvery(SOCKET_LISTEN, changeChannel);
}

export function* saga() {
  yield all([fork(channelLoop)]);
}

const INIT_STATE = {
  channels: [],
  snackbarMessages: [],
  unreadMessagesCount: 0,
  currentPrices: new Map(),
  tradeMessages: new Map(),
  alertMessages: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SOCKET_LISTEN: {
      return {
        ...state,
        channels: action.channels,
      };
    }
    case SOCKET_MESSAGE: {
      if (action.channel !== "CHAT" && action.channel !== "TRADE_MESSAGE") {
        const { currentPrices } = state;
        const newMap = new Map(currentPrices);
        const { data, channel } = action;
        data.forEach((el) => {
          const { price } = el;
          let symbol;
          if (channel.includes("trade")) {
            symbol = el.eventSymbol;
          } else {
            symbol = el.symbol;
          }
          newMap.set(symbol, price);
        });
        return {
          ...state,
          currentPrices: newMap,
        };
      }
      if (action.channel === "CHAT") {
        return {
          ...state,
          snackbarMessages: action.data,
          unreadMessagesCount: state.unreadMessagesCount + action.data.length,
        };
      } else {
        const { tradeMessages } = state;
        const tradeMessageMap = new Map(tradeMessages);
        action.data.forEach((item) => {
          tradeMessageMap.set(Number(item.message_id), item.trade_status);
        });
        return {
          ...state,
          tradeMessages: tradeMessageMap,
          alertMessages: action.data,
        };
      }
    }
    case CLEAN_MESSAGE_QUEUE: {
      return {
        ...state,
        snackbarMessages: [],
      };
    }
    case CLEAN_TRADE_MESSAGE_QUEUE: {
      return {
        ...state,
        tradeMessages: [],
      };
    }
    case CLEAN_UNREAD_MESSAGE_COUNT: {
      return {
        ...state,
        unreadMessagesCount: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
