import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const DOCUSIGN_LOAD_TOP = "DOC/LOAD/TOP";
export const DOCUSIGN_LOAD_TOP_SUCCESS = "DOC/LOAD/TOP/SUCCESS";
export const DOCUSIGN_LOAD_TOP_FAILURE = "DOC/LOAD/TOP/FAILURE";

// Action Creators
export const docusignLoad = (userData, name) => ({
  type: DOCUSIGN_LOAD_TOP,
  userData,
  name,
});
export const docusignLoadSuccess = (data) => ({
  type: DOCUSIGN_LOAD_TOP_SUCCESS,
  data,
});
export const docusignLoadFailure = (error) => ({
  type: DOCUSIGN_LOAD_TOP_FAILURE,
  error,
});

// Sagas
function* fetchDocusign(action) {
  try {
    const { userData } = action;
    let success = false;
    // will be true if query param is present
    const body = {
      signerEmail: userData.email,
      signerName: userData?.firstName + " " + userData?.lastName,
      callbackUrl: window.location.href,
    };

    // if ((userData?.agreement_signed || false) === false) {
    const response = yield axios.post(`${apiURL}/docusigned`, body);
    window.location.href = response.data.redirectUrl;
    success = response.data;
    // }
    yield put(docusignLoadSuccess({ success }));
  } catch (error) {
    yield put(docusignLoadFailure(error));
  }
}

function* listenDocusign() {
  yield takeEvery(DOCUSIGN_LOAD_TOP, fetchDocusign);
}

// Root Saga
export function* saga() {
  yield all([fork(listenDocusign)]);
}

const INIT_STATE = {
  success: false,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOCUSIGN_LOAD_TOP_SUCCESS:
      return {
        ...state,
        success: action.data.success,
      };
    default:
      return state;
  }
};

export default reducer;
