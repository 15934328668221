import React from "react";
import EmeraldCommonPage from "../EmeraldCommonPage";

const EmeraldSupport = () => {
  return (
    <>
      <EmeraldCommonPage
        title="Support Q&A Livestream"
        subTitle="Emerald Income Alerts Q&A Livestream"
        description="This is a 30 minute live Q&A sessions where viewers can learn from a professional trader, Bronco, as he shares his desktop platform and provides analysis, trading tactics, and risk management strategies. The show offers insights into price action on charts and empirical reasons for projected profits."
        eventID="4440794"
        from="support"
      />
    </>
  );
};

export default EmeraldSupport;
