import "./customProgressBar.scss";

const ProgressBar = (props) => {
  const bigTitle = props.bigTitle;
  let percentage = props.percentage;
  percentage = percentage > 100 ? 100 : percentage;
  const title = props.title;
  const value = props.value;
  const color = props.color;
  const deg = percentage ? ((360 / 100) * percentage) / 2 : 0;
  const themeMode = props.themeMode;
  return (
    <div className={`circle-wrap-${themeMode}`}>
      <div className="circle">
        <div
          className="mask full"
          style={{
            transform: `rotate(${deg}deg)`,
            animation: `${percentage ? "fill ease-in-out 2s" : "none"}`,
          }}
        >
          <div
            className="fill"
            style={{
              backgroundColor: `${color}`,
              transform: `rotate(${deg}deg)`,
              animation: `${percentage ? "fill ease-in-out 2s" : "none"}`,
            }}
          />
        </div>
        <div className="mask half">
          <div
            className="fill"
            style={{
              backgroundColor: `${color}`,
              transform: `rotate(${deg}deg)`,
              animation: `${percentage ? "fill ease-in-out 2s" : "none"}`,
            }}
          />
        </div>
        <div className="inside-circle-bg">
          <div
            className="inside-circle"
            style={{
              boxShadow: `${color}33 0px 0px 12px 6px`,
              filter: `drop-shadow(0px 0px 12px ${color}33)`,
            }}
          >
            {title && (
              <div className={`inside-circle-title-${themeMode}`}>{title}</div>
            )}
            {!bigTitle && percentage && (
              <div className={`inside-circle-percentage-${themeMode}`}>
                {percentage}%
              </div>
            )}
            {bigTitle && (
              <div className={`inside-circle-percentage-${themeMode}`}>
                {bigTitle}
              </div>
            )}
            {value && (
              <div
                className={`inside-circle-value-${themeMode}`}
                style={{ color: color }}
              >
                {value}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
