import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import logo from "../../assets/images/logo-black.png";
import "./modal.css";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledButton = styled(Button)(() => ({
  borderRadius: "5px",
  background: "linear-gradient(269deg, #5EEECD 0%, #007BFF 100%)",
  textDecoration: "none",
  color: "white",
  textTransform: "none",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  width: "200px",
}));
export default function WelcomeModal({
  user,
  fetchUser,
  setJourneyViewed,
  setShowJourney,
  addUsername,
  setRun,
}) {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState("1");
  const [username, setUsername] = React.useState("");
  const { pathname } = useLocation();
  const history = useHistory();
  const hasDisplayName = !!user?.display_name;
  const handleSkip = () => {
    setStep("3");
  };
  const handleClose = () => {
    setOpen(false);
    setShowJourney(false);
    setJourneyViewed(true);
  };

  const beginTutorial = (event) => {
    event.preventDefault();
    if (hasDisplayName) {
      if (!pathname.includes("/home")) {
        history.push("/home/Intraday/Auto/Up");
      }
      setRun(true);
      setShowJourney(false);
    } else {
      setStep("2");
    }
  };
  const changeName = (event) => {
    event.preventDefault();
    addUsername(username);
    if (!pathname.includes("/home")) {
      history.push("/home/Intraday/Auto/Up");
    }
    setLoading(true);
    setTimeout(() => {
      fetchUser();
      setLoading(false);
      setOpen(false);
      setStep("1");
    }, 2400);
    setRun(true);
    setShowJourney(false);
  };

  const contentBySlide = {
    1: (
      <>
        <DialogContent>
          <DialogContentText className="modal-desc">
            Give us three minutes and we’ll show you everything you need to know
            to start trading with us successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-title-div">
          <StyledButton onClick={beginTutorial}>Begin Tutorial</StyledButton>
          <Button sx={{ color: "#E5E5E5" }} onClick={handleSkip}>
            Skip Tutorial
          </Button>
        </DialogActions>
      </>
    ),
    2: (
      <>
        <DialogContent>
          <DialogContentText className="modal-desc">
            Please enter a username, this username will be used in chat
            channels, don&apos;t worry you can always change it later.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-title-div">
          <TextField
            required
            id="outlined-required"
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <StyledButton disabled={username?.length <= 3} onClick={changeName}>
            {loading ? <CircularProgress /> : "Add Username"}
          </StyledButton>
        </DialogActions>
      </>
    ),
    3: (
      <>
        <DialogContent>
          <DialogContentText className="modal-desc">
            Our tutorial is designed to guide you through our platform and
            provide you the best experience. Are you sure you want to skip the
            tutorial entirely?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-title-div">
          <StyledButton onClick={beginTutorial}>Resume</StyledButton>
          <Button sx={{ color: "#E5E5E5" }} onClick={handleClose}>
            Skip Tutorial
          </Button>
        </DialogActions>
      </>
    ),
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <>
        <DialogTitle>
          <div className="modal-title-div">
            <img style={{ width: "42px" }} src={logo} />
            <div className="modal-gradient-text">
              {step === "3" ? "Skip Tutorial" : "Welcome to Tradealgo"}
            </div>
          </div>
        </DialogTitle>
        {contentBySlide[step]}
      </>
    </Dialog>
  );
}
