import * as React from "react";

const HistoricalDarkflowIcon = ({ fill }) => (
  <svg
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9537_86562)">
      <path
        d="M11.6667 26.0357H12.1667V25.5357V19.1528V18.6528H11.6667H10H9.5V19.1528V25.5357V26.0357H10H11.6667ZM21.6667 26.0357H22.1667V25.5357V12.7698V12.2698H21.6667H20H19.5V12.7698V25.5357V26.0357H20H21.6667ZM31.6667 26.0357H32.1667V25.5357V6.3868V5.8868H31.6667H30H29.5V6.3868V25.5357V26.0357H30H31.6667ZM14.5 28.2272H7.16667V16.4613H14.5V28.2272ZM24.5 28.2272H17.1667V10.0783H24.5V28.2272ZM34.5 28.2272H27.1667V3.69531H34.5V28.2272ZM36.1667 34.6102H5.5V32.4187H36.1667V34.6102Z"
        fill={fill}
        stroke={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_9537_86562">
        <rect width="40" height="38.2979" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HistoricalDarkflowIcon;
