import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import { SOCKET_MESSAGE } from "./socket";
import moment from "moment";
import compact from "lodash/compact";

// Action Types
export const ATS_EQUITIES_LOAD = "ATS/EQ/LOAD";
export const ATS_EQUITIES_LOAD_SUCCESS = "ATS/EQ/SUCCESS";
export const ATS_EQUITIES_LOAD_FAILURE = "ATS/EQ/FAILURE";
export const ATS_EQUITIES_SUMMARY_LOAD = "ATS/EQ/SUMMARY/LOAD";
export const ATS_EQUITIES_SUMMARY_LOAD_SUCCESS = "ATS/EQ/SUMMARY/SUCCESS";
export const ATS_EQUITIES_SUMMARY_LOAD_FAILURE = "ATS/EQ/SUMMARY/FAILURE";

export const ATS_EQUITIES_LOAD_MORE = "ATS/EQ/MORE/LOAD";
export const ATS_EQUITIES_LOAD_SUCCESS_MORE = "ATS/EQ/MORE/SUCCESS";
export const ATS_EQUITIES_LOAD_FAILURE_MORE = "ATS/EQ/MORE/FAILURE";

const RECORD_THRESHOLD = 100000;
const TABLE_LIMIT = 100;

// Action Creators
export const atsEquitiesLoad = (ticker, timeframe = "") => ({
  type: ATS_EQUITIES_LOAD,
  ticker,
  timeframe,
});
export const atsEquitiesSummaryLoad = (ticker) => ({
  type: ATS_EQUITIES_SUMMARY_LOAD,
  ticker,
});
export const atsEquitiesLoadSuccess = (payload) => ({
  type: ATS_EQUITIES_LOAD_SUCCESS,
  payload,
});
export const atsEquitiesSummaryLoadSuccess = (payload) => ({
  type: ATS_EQUITIES_SUMMARY_LOAD_SUCCESS,
  payload,
});

export const atsEquitiesLoadFailure = (error) => ({
  type: ATS_EQUITIES_LOAD_FAILURE,
  error,
});
export const atsEquitiesLoadMore = (ticker, time) => ({
  type: ATS_EQUITIES_LOAD_MORE,
  ticker,
  time,
});
export const atsEquitiesLoadSuccessMore = (payload) => ({
  type: ATS_EQUITIES_LOAD_SUCCESS_MORE,
  payload,
});

export const atsEquitiesLoadFailureMore = (error) => ({
  type: ATS_EQUITIES_LOAD_FAILURE_MORE,
  error,
});

// Sagas
function* fetchChartData(action) {
  // Table
  try {
    const { ticker, timeframe } = action;
    const response = yield axios.get(
      `${apiURL}/ats/equities?ticker=${ticker}&limit=100000${timeframe ? `&timeframe=${timeframe}` : ""}`,
      { withCredentials: true },
    );
    if (!response.data) {
      yield put(atsEquitiesLoadFailure("no data found: ats equity"));
      return;
    }
    yield put(atsEquitiesLoadSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(atsEquitiesLoadFailure(error));
  }
}
function* fetchMoreChartData(action) {
  // Table
  try {
    const response = yield axios.get(
      `${apiURL}/ats/equities?ticker=${action.ticker}&last_time=%27${action.time}%27`,
      { withCredentials: true },
    );
    const tableData = yield select((state) => state.ats?.table);
    if (!response.data) {
      yield put(atsEquitiesLoadFailureMore("no data found: ats equity"));
      return;
    } else {
      if (
        response.data[response.data.length - 1].lastTime !==
        tableData[tableData.length - 1].lastTime
      ) {
        yield put(atsEquitiesLoadSuccessMore(response.data));
      }
    }
  } catch (error) {
    console.log("error", error);
    yield put(atsEquitiesLoadFailureMore(error));
  }
}

function* fetchSummaryData(action) {
  const splitChannels = (action?.channel || "").split(";");
  if (
    splitChannels[0] !== "current_finra" &&
    action.type !== ATS_EQUITIES_SUMMARY_LOAD
  ) {
    // No reload
    return;
  }

  let ticker = action.ticker;
  if (splitChannels[0] === "current_finra") {
    ticker = splitChannels[1];
  }

  // Summary
  try {
    const response = yield axios.get(`${apiURL}/ats/all-snapshots/${ticker}`, {
      withCredentials: true,
    });
    if (!response.data) {
      yield put(atsEquitiesLoadFailure("no data found: ats equity summary"));
      return;
    }
    yield put(atsEquitiesSummaryLoadSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(atsEquitiesLoadFailure(error));
  }
}

function* listenFetchChart() {
  yield takeEvery(ATS_EQUITIES_LOAD, fetchChartData);
}

function* listenFetchSummary() {
  yield takeEvery(ATS_EQUITIES_SUMMARY_LOAD, fetchSummaryData);
  // yield takeEvery(SOCKET_MESSAGE, fetchSummaryData);
}
function* listenMoreChartData() {
  yield takeEvery(ATS_EQUITIES_LOAD_MORE, fetchMoreChartData);
}
// Root Saga
export function* saga() {
  yield all([
    fork(listenFetchChart),
    fork(listenFetchSummary),
    fork(listenMoreChartData),
  ]);
}

const INIT_STATE = {
  table: [],
  summary: {},
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ATS_EQUITIES_LOAD:
      return {
        ...state,
        table: [],
      };
    case ATS_EQUITIES_SUMMARY_LOAD:
      return {
        ...state,
        summary: {},
      };
    case ATS_EQUITIES_LOAD_SUCCESS:
      return {
        ...state,
        table: action.payload,
      };
    case SOCKET_MESSAGE: {
      if (!state?.table) return state; // No live updates until we have the table
      const splitChannels = (action?.channel || "").split(";");
      if (splitChannels[0] === "current_finra") {
        const newData = compact(
          (action?.data || []).map((item) => {
            if (
              (item.totalMinPrice / item.totalMinTrades) * item.totalMinVolume <
              RECORD_THRESHOLD
            ) {
              return null;
            }
            return {
              ticker: item.ticker,
              totalTrades: item.totalMinTrades,
              totalVolume: item.totalMinVolume,
              totalPrice: item.totalMinPrice,
              openTime: item.openTime,
              lastTime: moment().tz("America/New_York").format("HH:mm:ss"),
              received: Date.now(),
            };
          }),
        );
        let newTable = newData.concat(state.table);
        if (newTable.length > TABLE_LIMIT) {
          newTable.splice(TABLE_LIMIT);
        }
        return {
          ...state,
          table: newTable,
        };
      } else {
        return state;
      }
    }
    case ATS_EQUITIES_SUMMARY_LOAD_SUCCESS:
      return {
        ...state,
        summary: action.payload,
      };
    case ATS_EQUITIES_LOAD_SUCCESS_MORE:
      return {
        ...state,
        table: state.table.concat(action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
