import React, { useMemo } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import clsx from "clsx";
import "./tabs.scss";
import { defaultVideoState } from "../../../helpers/defaultVideoState";

const LiveSessionsTab = ({
  themeMode,
  activeTab,
  setActiveTab,
  currentVideo,
  setCurrentVideo,
  tutorials = false,
}) => {
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);

  return (
    <div
      style={{ position: "relative", left: tutorials ? "35px" : "0px" }}
      className="tabs tabs--justify tabs--bordered-bottom"
    >
      <div>
        <div
          style={{
            width: "fit-content",
            display: "flex",
            alignItems: "start",
            whiteSpace: "nowrap",
          }}
          className="tabs__wrap"
        >
          <ul className="nav-tabs">
            <li className="nav-item">
              <a
                className={clsx(
                  "nav-link",
                  classnames({ active: activeTab === "1" }),
                )}
                onClick={() => toggle("1")}
                style={{
                  color: activeTab === "1" ? color : "#A2A3A5",
                  fontFamily: "inter",
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                }}
              >
                {tutorials ? "Tutorials" : "Previous Streams"}
              </a>
            </li>
            {!tutorials && !currentVideo.live && (
              <li className="nav-item">
                <a
                  disabled={currentVideo.live}
                  className={clsx(
                    "nav-link",
                    classnames({ active: activeTab === "2" }),
                  )}
                  onClick={() => {
                    setCurrentVideo(defaultVideoState);
                  }}
                  style={{
                    color: activeTab === "2" ? color : "#A2A3A5",
                    fontFamily: "inter",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Go Live
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <br />
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(LiveSessionsTab);
