import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { TableSkeleton } from "../Skeletons/TableSkeleton";
import React, { Suspense, useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMedia } from "react-media";
import { connect } from "react-redux";
import clsx from "clsx";
import "./TradeAlgoTable.scss";
import CustomToolbar from "./CustomToolbar";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
// import useDebounce from "../../OptionScanner/useDebounce";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.text,
    borderBottomColor: "rgb(60, 64, 67)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottomColor: "rgb(60, 64, 67)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MobileTable({ rows = [], columns, loading, sortModel }) {
  if (!rows.length && !loading) {
    return (
      <div
        className={clsx(
          "tradealgo-table-no-data",
          "MuiDataGrid-windowContainer",
        )}
        style={{ top: "56px", overflowY: "hidden" }}
      >
        No Rows
      </div>
    );
  }
  return (
    <TableContainer elevation={0}>
      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          className={"tradealgo-table-background tradealgo-mobile-table"}
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {columns.map((col, index) => {
                if (col.hide) return null;
                return (
                  <StyledTableCell
                    className={"tradealgo-table-text"}
                    key={index}
                  >
                    {col.headerName}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...rows]
              ?.sort((a, b) => b[sortModel] - a[sortModel])
              ?.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    {columns?.map((col, j) => {
                      if (col.hide) return null;
                      if (col.renderCell) {
                        let params = {
                          value: row[columns[j]?.field],
                          row,
                        };
                        return (
                          <StyledTableCell key={j} component="th" scope="row">
                            {col?.renderCell(params)}
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell key={index} component="th" scope="row">
                          {typeof row[columns[j].field] === "object"
                            ? ""
                            : row[columns[j].field]}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
const DesktopTable = ({
  rows,
  columns,
  loading,
  sortModel,
  setSortModel,
  tableClassName,
  hasRowClassName,
  rowClassName,
  customToolbar = false,
  tableID = "",
  userSettings,
  setUserSettings,
  threshold,
}) => {
  const apiRef = useGridApiRef();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [columnsOrder, setColumnsOrder] = useState([]);
  const [density, setDensity] = useState("standard"); // Default density

  function handleColumnOrderChange() {
    const newOrder = apiRef.current.state.columns.orderedFields;
    if (customToolbar && tableID) {
      setUserSettings({
        ...userSettings,
        columnsOrder: {
          ...userSettings.columnsOrder,
          [tableID]: newOrder,
        },
      });
      setColumnsOrder(newOrder);
    }
  }

  function handleColumnVisibilityModelChange(model) {
    if (customToolbar && !!tableID) {
      setUserSettings({
        ...userSettings,
        colVisibilityModel: {
          ...userSettings?.colVisibilityModel,
          [tableID]: model,
        },
      });
      setColumnVisibilityModel(model);
    }
  }
  const handleDensityChange = (newDensity) => {
    if (customToolbar && tableID) {
      setUserSettings({
        ...userSettings,
        density: {
          ...userSettings.density,
          [tableID]: newDensity,
        },
      });
      setDensity(newDensity);
    }
  };

  useEffect(() => {
    if (
      userSettings &&
      userSettings?.density &&
      userSettings?.density[tableID]
    ) {
      setDensity(userSettings.density[tableID]);
    }
    if (
      !!userSettings &&
      !!userSettings?.colVisibilityModel &&
      !!userSettings?.colVisibilityModel[tableID]
    ) {
      setColumnVisibilityModel(userSettings?.colVisibilityModel[tableID]);
    }
    if (
      userSettings &&
      userSettings?.columnsOrder &&
      userSettings?.columnsOrder[tableID]
    ) {
      setColumnsOrder(userSettings?.columnsOrder[tableID]);
    }
  }, [userSettings, tableID]);

  const orderedColumns = useMemo(() => {
    if (columnsOrder && columnsOrder.length > 0) {
      // Reorder columns based on saved order
      const reorderedColumns = columnsOrder
        .map((field) => columns.find((col) => col.field === field))
        .filter(Boolean); // Filter out any undefined columns

      // Include any new columns not in the saved order
      const newColumns = columns.filter(
        (col) => !columnsOrder.includes(col.field),
      );

      return [...reorderedColumns, ...newColumns];
    } else {
      return columns;
    }
  }, [columnsOrder, columns]);
  const visibleColumns = useMemo(() => {
    return orderedColumns.filter(
      (col) => columnVisibilityModel[col.field] !== false,
    );
  }, [orderedColumns, columnVisibilityModel]);

  const visibleColumnsCount = visibleColumns.length;
  const adjustedColumns = useMemo(() => {
    if (visibleColumnsCount < threshold) {
      return orderedColumns.map((col) => {
        return {
          ...col,
          flex: 1,
          minWidth: undefined,
        };
      });
    } else {
      return orderedColumns;
    }
  }, [orderedColumns, visibleColumnsCount, threshold]);
  if (loading) {
    return <TableSkeleton />;
  }
  return (
    <DataGridPro
      apiRef={apiRef}
      columnVisibilityModel={columnVisibilityModel}
      density={density}
      onDensityChange={handleDensityChange}
      onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
      onColumnOrderChange={handleColumnOrderChange}
      rows={rows}
      className={tableClassName}
      columns={adjustedColumns}
      initialState={{
        columns: {
          columnVisibilityModel: columnVisibilityModel,
        },
        pinnedColumns: {
          left: [columns.length > 0 ? columns[0]?.field || "" : ""],
        },
      }}
      slots={{
        toolbar: customToolbar ? CustomToolbar : "",
      }}
      hideFooterPagination
      disableColumnMenu
      sortingOrder={["asc", "desc"]}
      hideFooter
      autoHeight
      throttleRowsMs={2000}
      sortModel={sortModel}
      scrollbarSize={1}
      getRowClassName={hasRowClassName ? rowClassName : ""}
      onSortModelChange={(model) => {
        if (
          model[0]?.field !== sortModel[0]?.field ||
          model[0]?.sort !== sortModel[0]?.sort
        ) {
          setSortModel(model);
        }
      }}
    />
  );
};

const TradeAlgoTable = ({
  tableClassName = "",
  rows,
  columns,
  loading = false,
  sortModel,
  setSortModel,
  hasRowClassName = false,
  rowClassName = "",
  customToolbar = false,
  tableID = "",
  setUserSettings,
  userSettings,
  threshold = 0,
}) => {
  const { isMobile } = useMedia({ queries: mediaQuery }); // Custom hook for media queries

  return (
    <Suspense fallback={<TableSkeleton />}>
      {isMobile ? (
        <MobileTable
          sortModel={sortModel[0]?.field}
          loading={loading}
          rows={rows}
          columns={columns}
        />
      ) : (
        <div className={"tradealgo-table-datagrid"}>
          <div style={{ height: "fit-content", width: "100%" }}>
            <DesktopTable
              customToolbar={customToolbar}
              sortModel={sortModel}
              loading={loading}
              rows={rows}
              columns={columns}
              tableClassName={tableClassName}
              setSortModel={setSortModel}
              hasRowClassName={hasRowClassName}
              rowClassName={rowClassName}
              tableID={tableID}
              setUserSettings={setUserSettings}
              userSettings={userSettings}
              threshold={threshold}
            />
          </div>
        </div>
      )}
    </Suspense>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});
export default connect(stateToProps, dispatchToProps)(TradeAlgoTable);
