import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
export default function CustomToolbar() {
  return (
    <div className="custom-toolbar">
      <div className="toolbar-item">
        <GridToolbarColumnsButton />
      </div>
      <div className="toolbar-item">
        <GridToolbarDensitySelector />
      </div>
      {/* <div className="toolbar-item">
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem
            options={{ fileName: "TradeAlgo-Table-Data" }}
          />
        </GridToolbarExportContainer>
      </div> */}
    </div>
  );
}
