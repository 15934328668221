import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMemo } from "react";
import { countries } from "./countries";

function CountrySelect({ value, setValue, setCurrState }) {
  const optionValue = useMemo(() => {
    return countries.find((i) => i?.country === value) || "";
  }, [countries, value]);
  return (
    <Autocomplete
      value={optionValue || ""}
      sx={{ width: "363px" }}
      options={countries}
      autoHighlight
      onChange={(e, v) => {
        if (value !== v?.country || "") {
          setCurrState("");
        }
        setValue(v?.country || "");
      }}
      getOptionLabel={(option) => option?.country || ""}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.alpha2Code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.alpha2Code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.country} ({option.alpha2Code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default CountrySelect;
