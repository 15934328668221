import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import { streamsLoad } from "../../appRedux/ducks/pastStreams";
import { useEffect } from "react";
import { connect } from "react-redux";

function PaginationComponent({
  videoKey,
  fetchStreams,
  total,
  customPageSize,
}) {
  const [page, setPage] = useState(1);
  function handlePageChange(e, newPage) {
    setPage(newPage);
  }
  useEffect(() => {
    fetchStreams(videoKey, page, total, customPageSize);
  }, [page, videoKey]);
  const count = Math.ceil(total / customPageSize);
  return (
    <Pagination
      shape="circular"
      page={page}
      onChange={handlePageChange}
      count={count}
      boundaryCount={3}
      size="large"
      showFirstButton
      showLastButton
    />
  );
}
const stateToProps = (state) => ({
  pastStreams: state.streams.streams,
  loading: state.streams.loading,
  total: state.streams.total,
});
const dispatchToProps = (dispatch) => ({
  fetchStreams: (endpoint, page, total, per_page) =>
    dispatch(streamsLoad(endpoint, page, total, per_page)),
});
export default connect(stateToProps, dispatchToProps)(PaginationComponent);
