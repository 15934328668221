/* eslint-disable */
import Chip from "@mui/material/Chip";
import React, { useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from "@material-ui/core";
const styles = {
  "input-label": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    color: "red",
  },

  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#E5E5E5",
    },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiChip-sizeMedium": {
      display: "none",
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#E5E5E5",
    },
    "& .MuiTextField-root": {
      margin: "0!important",
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: "Inter",
      position: "relative",
      top: "0",
      padding: "0 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      borderRadius: "0",
      height: "36px",
      width: "100%",
      padding: "0 !important",

      "& input::-webkit-input-placeholder": {
        color: "rgba(0, 0, 0, 0.38)",
        opacity: 1,
      },
    },
    "&  .MuiAutocomplete-endAdornment": {
      display: "none",
      visibility: "none",
    },
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      display: "none",
      position: "relative",
      top: "32px",
      // color: "rgba(162, 163, 165, 1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));

function OptionTickerSearch({
  options,
  loading,
  setTicker,
  symbol,
  setSymbol,
  // setFilter,
  tickers,
  // fetch,
  customMinWidth,
  setTickerString,
  exclude = false,
  setDefaultTickers = false,
  fromSettingsPage = false,
}) {
  const classes = useStyles();
  const allTickersString = useMemo(() => {
    return tickers.length ? tickers.map((o) => o.ticker).join() : "";
  }, [tickers]);

  useEffect(() => {
    setTickerString(allTickersString);
  }, [allTickersString]);

  return (
    <div className="option-ticker-search">
      {!fromSettingsPage && (
        <div className="option-ticker-search-label">
          {exclude ? "Exclude" : "Include"}
        </div>
      )}

      <div className="option-ticker-search-input">
        <Autocomplete
          className={classes.root}
          style={{
            position: "relative",
            width: "100%",
            minWidth: customMinWidth ? `${customMinWidth}px` : "250px",
          }}
          options={options}
          loading={loading}
          value={tickers}
          multiple={true}
          noOptionsText={"No Matching Tickers Found"}
          getOptionLabel={(option) => option.ticker + " - " + option.name}
          // isOptionEqualToValue={(option, value) => option.ticker === symbol}
          onChange={(e, value) => {
            var non_duplidated_data = [
              ...value
                .reduce((map, obj) => map.set(obj.ticker, obj), new Map())
                .values(),
            ];
            if (non_duplidated_data) {
              setTicker(non_duplidated_data);
            }
          }}
          renderInput={(params) => (
            <TextField
              // aria-id="live-options-input"
              {...params}
              onChange={(e, value) => {
                setSymbol(e.target.value);
              }}
              value={symbol}
              InputProps={{
                ...params.InputProps,
                classes: { input: styles["input"] },
              }}
              className="option-ticker-search-input-field"
              margin="normal"
              placeholder={exclude ? "Exclude Tickers" : "Search Tickers"}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(
              option.ticker + " - " + option.name,
              inputValue,
            );
            const parts = parse(option.ticker + " - " + option.name, matches);
            const el = option;

            const newProps = { ...props };
            delete newProps.key;

            return (
              <li key={el.ticker} {...newProps}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
      {!!tickers.length > 0 && (
        <div className="selected-tickers">
          {tickers.map((d, i) => {
            return (
              <Chip
                key={i}
                className="selected-ticker-chip"
                deleteIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g opacity="0.7">
                      <path
                        d="M8.00016 1.33334C4.3135 1.33334 1.3335 4.31334 1.3335 8.00001C1.3335 11.6867 4.3135 14.6667 8.00016 14.6667C11.6868 14.6667 14.6668 11.6867 14.6668 8.00001C14.6668 4.31334 11.6868 1.33334 8.00016 1.33334ZM11.3335 10.3933L10.3935 11.3333L8.00016 8.94001L5.60683 11.3333L4.66683 10.3933L7.06016 8.00001L4.66683 5.60668L5.60683 4.66668L8.00016 7.06001L10.3935 4.66668L11.3335 5.60668L8.94016 8.00001L11.3335 10.3933Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                }
                label={d.ticker}
                onDelete={() => {
                  let clone = [...tickers];
                  let data = clone.filter((x) => x.ticker !== d.ticker);
                  setTicker(data);
                  if (!data.length && !!setDefaultTickers) {
                    setDefaultTickers("");
                  }
                }}
              />
            );
          })}

          <span
            className="clear-all-chips-button"
            onClick={() => {
              setTicker([]);
              if (!!setDefaultTickers) setDefaultTickers("");
            }}
          >
            Clear all
          </span>
        </div>
      )}
    </div>
  );
}
export default OptionTickerSearch;
