// Action Types
export const MODAL_CLOSED_SET = "MCL/SET";

// Action Creators
export const modalClosedSetter = (closed) => {
  return {
    type: MODAL_CLOSED_SET,
    closed,
  };
};

const INIT_STATE = {
  closed: 0,
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MODAL_CLOSED_SET:
      return {
        ...state,
        closed: action.closed,
      };
    default:
      return state;
  }
};

export default reducer;
