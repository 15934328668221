import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import { makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import CollapsibleTable from "../common/Tables/CollapsableOptionsTable";
import moment from "moment";
import "../OptionScanner/option-scanner.scss";
import { returnRows } from "../OptionScanner/OptionScannerContainer";
import { swingTradesOptionsLoad } from "../../appRedux/ducks/swingTradesOptions";
import { optionAlertDatesLoad } from "../../appRedux/ducks/getOptionAlertDates";
import RangePicker from "./RangePicker";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: `${theme.palette.primary.contrastBackground} !important`,
    color: theme.palette.primary.text,
  },
  header: {
    margin: "0 10px 15px 10px",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  text2: {
    color: theme.palette.primary.text + " !important",
    fontSize: "1rem",
    lineHeight: "19px",
  },
}));
const SwingTradesOptionsContainer = ({
  getDates,
  optionDates,
  loading,
  dxFeedListen,
  swingTradesOptions = [],
  fetchSwingTradesOptions,
  socketData,
  themeMode,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState([
    moment.utc().format("MM/DD/YY"),
    moment.utc().format("MM/DD/YY"),
  ]);
  useEffect(() => {
    getDates();
  }, []);
  useEffect(() => {
    optionDates.length && fetchSwingTradesOptions(value[0], value[1]);
  }, [value]);

  useEffect(() => {
    const maxDate = optionDates.sort((a, b) => moment(b).diff(moment(a)));
    maxDate.length &&
      setValue([
        moment.utc(maxDate[0]).format("MM/DD/YY"),
        moment.utc(maxDate[0]).format("MM/DD/YY"),
      ]);
  }, [optionDates]);

  const allTickers = useMemo(() => {
    return swingTradesOptions ? swingTradesOptions?.map((i) => i.symbol) : [];
  }, [loading]);
  useEffect(() => {
    if (allTickers.length > 1) {
      dxFeedListen(allTickers, true);
    }
  }, [allTickers]);
  useEffect(() => {
    return () => dxFeedListen([], true, true);
  }, []);

  const liveRowData = useMemo(() => {
    const calls = returnRows(socketData, swingTradesOptions);
    return calls;
  }, [swingTradesOptions, socketData]);
  return (
    <div style={{ textAlign: "center" }}>
      <Helmet>
        <title>SwingTrade AI | TradeAlgo</title>
      </Helmet>
      <br />
      <div className="padding-15-row">
        <div className={classes.background + " card"}>
          <div className="card-body">
            <RangePicker
              value={value}
              setValue={setValue}
              availableDates={optionDates}
            />
            <CollapsibleTable
              swingTrades={true}
              loading={loading}
              expDate={""}
              // symbol={symbol}
              calls={true}
              rows={liveRowData}
              livePrice={0}
              midpointChange={false}
              themeMode={themeMode}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  swingTradesOptions: state.swingTradesOptions.swingTradesOptions,
  noData: state.swingTradesOptions.noData,
  loading: state.swingTradesOptions.loading,
  socketData: state.dxFeedSocket.socketData,
  optionDates: state.optionAlertDates.dates,
});

const dispatchToProps = (dispatch) => ({
  fetchSwingTradesOptions: (start, end) =>
    dispatch(swingTradesOptionsLoad(start, end)),
  dxFeedListen: (symbols, options, disconnect) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect)),
  getDates: () => dispatch(optionAlertDatesLoad()),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(SwingTradesOptionsContainer);
