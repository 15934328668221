import React from "react";

import BeautifulSkeleton from "./BeautifulSkeleton";
import "./MovementBlockSkeleton.scss";

const MovementBlockSkeleton = ({ width, height = 48 }) => {
  return (
    <div className="movement-block-skeletons">
      <BeautifulSkeleton
        width={width}
        height={height}
        className="movement-block-skeleton"
      />
      <BeautifulSkeleton
        width={width}
        height={height}
        className="movement-block-skeleton"
      />
      <BeautifulSkeleton
        width={width}
        height={height}
        className="movement-block-skeleton"
      />
      <BeautifulSkeleton
        width={width}
        height={height}
        className="movement-block-skeleton"
      />
      <BeautifulSkeleton
        width={width}
        height={height}
        className="movement-block-skeleton"
      />
      <BeautifulSkeleton
        width={width}
        height={height}
        className="movement-block-skeleton"
      />
    </div>
  );
};

export default MovementBlockSkeleton;
