import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = (props) => {
  const { className, style, children } = props;
  const element = (
    <div className="loader-view">
      <CircularProgress />
      {children}
    </div>
  );
  return (
    <div className={`${className}`} style={style}>
      {element}
    </div>
  );
};

Loader.defaultProps = {
  style: null,
  className: "",
  type: "multi",
  variant: "dark",
};

export default Loader;
