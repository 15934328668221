import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { darkflowTableLoad } from "../../appRedux/ducks/darkflowTable";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { abbreviate, decimal } from "../homePage/TradeAlgoCard";
import DarkflowTabs from "../common/Tabs/DarkflowTabs";
import { useHistory, useLocation } from "react-router-dom";
import SparkLinesRow from "../common/Tables/ResponsiveTableRows/SparkLinesRow";
import SparkLines from "../common/SparkLines";
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import CssPop from "../common/Tables/animations/CssPop";
import { useMedia } from "react-media";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { isInternalWS } from "../../appRedux/actions/helpers";
import isNull from "lodash/isNull";
import { TableSkeleton } from "../common/Skeletons/TableSkeleton";
import PriceDisplay from "../common/Tables/renderers/PriceDisplay";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  contrastBackground: {
    backgroundColor: theme.palette.primary.contrastBackground,
  },
  text: {
    color: theme.palette.primary.text + " !important",
    textDecoration: "none",
  },
}));

const DashboardTableContainer = ({
  darkflowTable,
  getDarkFlow,
  setSymbol,
  currentPrices = new Map(),
  marketStatus,
  popLoading,
  popularStocks,
  isPopularTab,
  setIsPopularTab,
  up,
  setUp,
  currentCards,
  themeMode,
  darkflowActiveHeaderTab,
  isShownTradealgoCards,
  toggleShownTradealgoCards,
  hideShowMore = false,
}) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const { weekly } = darkflowTable;
  const classes = useStyles();
  const [customLoading, setCustomLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "shareGain",
      sort: "desc",
    },
  ]);
  useEffect(() => {
    getDarkFlow("", pathname.includes("Weekly"));
  }, []);

  useEffect(() => {
    setCustomLoading(true);
    let timeout = setTimeout(() => {
      setCustomLoading(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, [up]);

  const loading = useMemo(() => {
    return isPopularTab ? popLoading : darkflowTable.loading;
  }, [darkflowTable.loading, isPopularTab, popLoading]);
  const columns = useMemo(() => {
    let columnList = isPopularTab
      ? [
          {
            field: "symbol",
            headerName: "TICKER",
            minWidth: 120,
            disableReorder: true, // This makes the column non-draggable
            renderCell: (params) => {
              return (
                <div
                  onClick={() => {
                    setSymbol({
                      type: "",
                      value: params.value,
                    });
                    history.push(`/company/${params.value}`);
                  }}
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    left: "8px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {params.value}
                </div>
              );
            },
          },

          {
            field: "livePrice",
            headerName: "SHARE PRICE",
            minWidth: 120,
            renderCell: (params) => {
              const livePrice = params.value;
              return (
                <div
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    left: "12px",
                    fontWeight: 500,
                    fontSize: "14px",
                    display: "flex",
                  }}
                >
                  <CssPop
                    comparator={`${livePrice}`}
                    ticker={params.row.symbol}
                  />
                </div>
              );
            },
          },
          {
            field: "performancePercent",
            minWidth: 120,
            headerName: "PERFORMANCE",
            renderCell: (params) => {
              return (
                <span
                  style={{
                    position: "relative",
                    left: "25px",
                    fontWeight: 600,
                  }}
                >
                  <PriceDisplay
                    lastPrice={params.row.livePrice}
                    ticker={params.row.symbol}
                    trend_start={params.row.openPrice}
                    showPercent
                  />{" "}
                </span>
              );
            },
          },
          {
            field: "performanceChange",
            minWidth: 120,
            headerName: "CHANGE",
            renderCell: (params) => {
              return (
                <span
                  style={{
                    position: "relative",
                    left: "13px",
                    fontWeight: 600,
                  }}
                >
                  <PriceDisplay
                    lastPrice={params.row.livePrice}
                    ticker={params.row.symbol}
                    trend_start={params.row.openPrice}
                  />
                </span>
              );
            },
          },
          {
            field: "darkPoolActivity",
            headerName: "DARK POOL ACTIVITY",
            minWidth: 120,
            sortComparator: (v1, v2) => {
              let dv1 = (v1 - 1) * 100;
              let dv2 = (v2 - 1) * 100;
              return Number(dv1) - Number(dv2);
            },
            renderCell: (params) => {
              const darkPoolActivity = ((params.value - 1) * 100).toFixed(2);
              return (
                <div
                  style={{
                    height: "27px",
                    width: "auto",
                    background:
                      darkPoolActivity > 0
                        ? "rgba(62, 180, 137, 0.10)"
                        : themeMode === "dark"
                          ? "rgb(46 4 4)"
                          : "rgba(255, 59, 48, 0.10)",
                    color: darkPoolActivity > 0 ? "#3EB489" : "#FF3B30",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "600",
                    borderRadius: "20px",
                    position: "relative",
                    left: "0",
                    padding: "4px 10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                >
                  {darkPoolActivity}%
                </div>
              );
            },
          },
          {
            field: "sparkLine",
            headerName: "SPARKLINE",
            minWidth: 180,
            renderCell: (params) => {
              const livePrice =
                (
                  currentPrices?.get(params?.row?.symbol) || params?.row?.price
                )?.toFixed(2) || 0;
              const dummyOptions = {
                colors: [
                  params.row.performanceRatio > 0 ? "#5EEECD" : "#F32228",
                ],
                chart: {
                  type: "area",
                  height: 45,
                  sparkline: {
                    enabled: true,
                  },
                  width: 170,
                },
                tooltip: {
                  theme: "dark",
                  fixed: {
                    enabled: false,
                  },
                  x: {
                    show: false,
                  },
                  y: {
                    title: {
                      // eslint-disable-next-line no-unused-vars
                      formatter(seriesName) {
                        return "   $";
                      },
                    },
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: 1.5,
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shadeIntensity: 0.1,
                    opacityFrom: 0.7,
                    opacityTo: 0,
                    stops: [0, 95],
                  },
                },
              };
              return (
                <div className="spark-row">
                  <SparkLines
                    style={{ lineHeight: "normal !important" }}
                    series={[
                      {
                        data: [...(params?.value || []), Number(livePrice)],
                      },
                    ]}
                    options={dummyOptions}
                    type={dummyOptions.chart.type}
                    height={dummyOptions.chart.height}
                    width={dummyOptions.chart.width}
                  />
                </div>
              );
            },
          },
          {
            field: "sentiment",
            headerName: "CALL RATIO",
            minWidth: 120,
            sortComparator: (v1, v2) =>
              v2.replace("%", "") - v1.replace("%", ""),
            renderCell: (params) => {
              const value = params.value;
              const replaced = value.replace("%", "");
              // Check if the value is a valid number or a stringified number
              const isValidNumber =
                !isNaN(parseFloat(replaced)) && isFinite(replaced);

              const displayValue = isValidNumber ? value : "N/A";

              return (
                <div
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    left: isMobile ? "0px" : "25px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  {displayValue}
                </div>
              );
            },
          },
          {
            field: "marketCap",
            headerName: "MARKET CAP",
            minWidth: 120,
            renderCell: (params) => {
              return (
                <div
                  className={classes.text}
                  style={{
                    position: "relative",
                    left: "17px",
                    fontSize: "15px",
                  }}
                >
                  {params.value ? `$${abbreviate(params.value)}` : "-"}
                </div>
              );
            },
          },

          {
            field: "dayVolume",
            headerName: "SHARE VOLUME",
            minWidth: 180,
            renderCell: (params) => {
              return (
                <div
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    textAlign: "center",
                    left: "27px",
                    fontSize: "15px",
                  }}
                >
                  {params.value ? `$${abbreviate(params.value)}` : "-"}
                </div>
              );
            },
          },
        ]
      : [
          {
            field: "ticker",
            headerName: "TICKER",
            minWidth: 90,
            disableReorder: true, // This makes the column non-draggable
            renderCell: (params) => {
              return (
                <div
                  onClick={() => {
                    history.push(
                      `/company/${params.value}${weekly ? "?resolution=1d" : ""}`,
                    );
                    setSymbol({
                      type: "",
                      value: params.value,
                    });
                  }}
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    left: "8px",
                    // top: "9px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  {params.value}
                </div>
              );
            },
          },

          {
            field: "livePrice",
            headerName: "SHARE PRICE",
            minWidth: 120,
            renderCell: (params) => {
              const livePrice = params.value;
              const ticker = params.row.ticker;
              return (
                <span
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    left: "17px",
                    fontWeight: 500,
                    fontSize: "14px",
                    display: "flex",
                  }}
                >
                  <CssPop ticker={ticker} comparator={`${livePrice}`} />
                </span>
              );
            },
          },
          {
            field: "performancePercent",
            minWidth: 160,
            headerName: weekly ? "28 DAY PERFORMANCE" : "PERFORMANCE",
            renderCell: (params) => {
              return (
                <div
                  style={{
                    fontWeight: "600",
                    position: "relative",
                    left: "-10px",
                    margin: "0 auto",
                  }}
                >
                  <PriceDisplay
                    lastPrice={params.row.livePrice}
                    ticker={params.row.ticker}
                    trend_start={params.row.trend_start}
                    showPercent
                  />
                </div>
              );
            },
          },
          {
            field: "performanceChange",
            minWidth: 120,
            headerName: "CHANGE",
            renderCell: (params) => {
              return (
                <div
                  style={{
                    fontWeight: "600",
                    position: "relative",
                    left: "8px",
                  }}
                >
                  <PriceDisplay
                    lastPrice={params.row.livePrice}
                    ticker={params.row.ticker}
                    trend_start={params.row.trend_start}
                  />
                </div>
              );
            },
          },
          {
            field: "news_sentiment",
            headerName: "NEWS SENTIMENT",
            minWidth: 130,
            renderCell: (params) => {
              const newsSentiment = params.value;
              const isNulled = params.value === "N/A";
              return newsSentiment !== "N/A" && newsSentiment !== undefined ? (
                <div
                  className={`ai-table-score ${newsSentiment >= 0 || isNulled ? "" : "negative-score"}`}
                >
                  {newsSentiment > 0 ? `+${newsSentiment}` : newsSentiment}
                </div>
              ) : (
                <div />
              );
            },
          },
          {
            field: "ai_score_value",
            headerName: "AI SCORING",
            minWidth: 90,
            renderCell: (params) => {
              const score = params.value;
              return score !== "N/A" && score !== undefined ? (
                <div
                  className={`ai-table-score ${score >= 0 ? "" : "negative-score"}`}
                >
                  {score}%
                </div>
              ) : (
                <div />
              );
            },
          },
          {
            field: "darkPoolActivity",
            headerName: "DARK POOL ACTIVITY",
            minWidth: 180,
            renderCell: (params) => {
              return (
                <div
                  style={{
                    height: "27px",
                    width: "auto",
                    background: up
                      ? "rgba(13, 173, 96, 0.05)"
                      : themeMode === "dark"
                        ? "rgb(46 4 4)"
                        : "rgb(255, 238, 238",
                    color: up ? "rgba(13, 173, 96, 1)" : "rgb(241, 0, 6)",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "600",
                    borderRadius: "25px",
                    position: "relative",
                    left: "0",
                    padding: "4px 10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                  }}
                >
                  <span>+{decimal(Number(params.value * 100))}%</span>
                </div>
              );
            },
          },
          {
            field: "sparkLine",
            headerName: "SPARKLINE",
            minWidth: 160,
            renderCell: (params) => {
              return (
                <SparkLinesRow
                  marketStatus={marketStatus}
                  params={params}
                  up={up}
                />
              );
            },
          },
          {
            field: "sentiment",
            headerName: "CALL RATIO",
            minWidth: 120,
            sortComparator: (v1, v2) =>
              v2.replace("%", "").replace("-", "") -
              v1.replace("%", "").replace("-", ""),
            renderCell: (params) => {
              return (
                <div
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    left: isMobile ? "-10px" : "30px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  {params.value}
                </div>
              );
            },
          },
          {
            field: "marketCap",
            headerName: "MARKET CAP",
            minWidth: 120,
            renderCell: (params) => {
              return (
                <div
                  className={clsx(classes.text, "text-fix")}
                  style={{ position: "relative", left: "10px" }}
                >
                  {params.value ? `$${abbreviate(params.value)}` : "-"}
                </div>
              );
            },
            sortComparator: (v1, v2) => v1 - v2,
          },

          {
            field: "shareVolume",
            headerName: "SHARE VOLUME",
            minWidth: 120,
            renderCell: (params) => {
              return (
                <div
                  className={clsx(classes.text, "text-fix")}
                  style={{
                    position: "relative",
                    textAlign: "center",
                    left: "27px",
                  }}
                >
                  {params.value ? `${abbreviate(params.value)}` : "-"}
                </div>
              );
            },
            sortComparator: (v1, v2) => v1 - v2,
          },
        ];

    if (weekly && !isPopularTab) {
      columnList.push({
        field: "date_flagged",
        headerName: "DATE ADDED",
        minWidth: 120,
        renderCell: (params) => {
          return (
            <div
              className={clsx(classes.text, "text-fix")}
              style={{ position: "relative", left: weekly ? "7px" : "-10px" }}
            >
              {moment
                .tz(params.value, "America/New_York")
                .format("MMM DD, YYYY")}
            </div>
          );
        },
      });
    }

    return columnList;
  }, [isPopularTab, marketStatus, isMobile, up, themeMode, weekly]);
  useEffect(() => {
    isPopularTab
      ? setSortModel([
          {
            field: "openPrice",
            sort: up ? "desc" : "asc",
          },
        ])
      : weekly
        ? setSortModel([
            {
              field: "marketCap",
              sort: "desc",
            },
          ])
        : setSortModel([
            {
              field: "shareGain",
              sort: up ? "desc" : "asc",
            },
          ]);
  }, [isPopularTab, up, themeMode, weekly]);

  const rows = useMemo(() => {
    return isPopularTab
      ? popularStocks.map((item) => {
          const { symbol, price, openPrice } = item;
          const livePrice =
            (currentPrices?.get(symbol) || price)?.toFixed(2) || 0;
          const performancePercent =
            Math.round((livePrice / openPrice - 1) * 1000) / 10;
          const performanceChange = livePrice - openPrice;
          const totalPremium =
            parseInt(item?.darkflow?.options?.put_total_prem) +
            parseInt(item?.darkflow?.options?.call_total_prem);
          const ratio =
            parseInt(item?.darkflow?.options?.call_total_prem) / totalPremium;
          return {
            ...item,
            livePrice,
            performancePercent,
            performanceChange,
            sentiment: Number(ratio * 100).toFixed(0) + "%",
          };
        })
      : currentCards
          .map((item, i) => {
            const {
              ticker,
              last_price,
              market_cap,
              trend_start,
              multiplier,
              ats,
              ai_score,
              chart,
            } = item;
            const livePrice = (
              currentPrices?.get(ticker) ||
              last_price ||
              0
            )?.toFixed(2);
            const performanceChange = livePrice - trend_start;
            const performancePercent =
              Math.round((livePrice / trend_start - 1) * 1000) / 10;
            return {
              id: i + 1,
              idNo: i + 1,
              ticker: ticker,
              marketCap: market_cap || 0,
              darkPoolActivity: multiplier || 0,
              shareVolume: ats?.current?.day_volume || 0,
              sparkLine: chart.data,
              livePrice,
              performancePercent,
              performanceChange,
              ai_score_value: (ai_score?.ai_score || 0)?.toFixed(0),
              news_sentiment: isNull(ai_score?.news_sentiment)
                ? "N/A"
                : ai_score?.news_sentiment?.toFixed(0),
              ...item,
            };
          })
          .filter((item) => {
            const livePrice = (
              currentPrices?.get(item?.ticker) ||
              item?.last_price ||
              0
            )?.toFixed(2);
            const performance =
              Math.round((livePrice / item.trend_start - 1) * 1000) / 10;
            return up
              ? performance > 0 && !!item.market_cap
              : performance < 0 && !!item.market_cap;
          });
  }, [currentCards, up, isPopularTab, popularStocks, themeMode, sortModel]);
  // Memoize the TradeAlgoTable component rendering to avoid unnecessary re-renders
  const MemoizedTradeAlgoTable = useMemo(() => {
    return (
      <TradeAlgoTable
        customToolbar
        hasRowClassName
        rowClassName={() => "joyride-row"}
        tableClassName="joyride-dash"
        rows={rows}
        columns={columns}
        loading={loading || customLoading}
        sortModel={sortModel}
        setSortModel={setSortModel}
        tableID={isPopularTab ? "popular-dashboard" : "main-dashboard-darkflow"}
        threshold={9}
      />
    );
  }, [rows, columns, loading, customLoading, sortModel, setSortModel]);
  return (
    <div className="padding-15-row">
      <div className="dashboard-table-container-title">
        Stocks Performance Summary
      </div>
      <div
        // aria-id="dashboard-card"
        className={classes.contrastBackground + " card"}
      >
        <div className="card-body">
          <DarkflowTabs
            up={up}
            setUp={setUp}
            popular={true}
            setIsPopularTab={setIsPopularTab}
            darkflowActiveHeaderTab={darkflowActiveHeaderTab}
            isShownTradealgoCards={isShownTradealgoCards}
            toggleShownTradealgoCards={toggleShownTradealgoCards}
            hideShowMore={hideShowMore}
          />
          <div
            style={{ height: "fit-content", width: "100%", marginTop: "5px" }}
          >
            {loading || customLoading ? (
              <TableSkeleton />
            ) : (
              MemoizedTradeAlgoTable
            )}

            {isPopularTab && (
              <>
                <br />
                <br />
                <br />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const stateToProps = (state) => ({
  darkflowTable: state.darkflowTable,
  subs: state.Socket.channels,
  marketStatus: state.marketStatus.marketStatus,
  popularStocks: state.popularStocks.popularStocks,
  popLoading: state.popularStocks.loading,
  themeMode: state.themeMode.themeMode,
  currentPrices: isInternalWS
    ? state.EquitiesSocket.currentPrices
    : state.dxFeedSocket.socketData,
});
const dispatchToProps = (dispatch) => ({
  getDarkFlow: (risk, weekly) => dispatch(darkflowTableLoad(risk, weekly)),
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});
export default connect(stateToProps, dispatchToProps)(DashboardTableContainer);
