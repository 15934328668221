import React from "react";

const initialState = {
  isActivePopUp: false,
  setIsActivePopUp: () => {},
};

const LayoutContext = React.createContext(initialState);

export default LayoutContext;
