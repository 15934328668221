import React from "react";

import VideoCard from "./VideoCard";
import "./VideoCardsContainer.scss";

const VideoCardsContainer = ({
  courses,
  setCurrentVideo,
  type,
  courseName,
  from = "options",
}) => {
  return (
    <div className="video-cards-container">
      {courses?.map((card, i) => (
        <VideoCard
          card={card}
          setCurrentVideo={setCurrentVideo}
          key={i}
          title={card.title}
          length={card.length}
          videoUrl={card.videoUrl}
          courseName={courseName}
          from={from}
          type={type}
        />
      ))}
    </div>
  );
};

export default VideoCardsContainer;
