import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { CalendarIcon } from "../../../common/Icon";

const useStyles = makeStyles(() => ({
  datePicker: {
    cursor: "pointer",
    "& ": {
      border: "none !important",
    },
  },
}));

const NDatePicker = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { availableDates, themeMode } = props;
  const expDates = availableDates.map((d) =>
    moment.utc(Number(d)).format("MM/DD/YY"),
  );

  const color = themeMode === "dark" ? "#5EEECD" : "#007BFF";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className={classes.datePicker}
          disabled={!expDates.length}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={props?.value}
          disablePast
          inputFormat={"MMMM do, yyyy"}
          onChange={props?.onChange}
          shouldDisableDate={(date) => {
            let calDate = moment(date).format("MM/DD/YY");
            return !expDates.includes(calDate);
          }}
          renderInput={({ inputRef, inputProps }) => (
            <div className="ndatepicker">
              <input
                ref={inputRef}
                {...inputProps}
                onClick={() => setOpen(true)}
              />
              <CalendarIcon fill={color} onClick={() => setOpen(true)} />
            </div>
          )}
          {...props}
        />
      </LocalizationProvider>
    </>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(NDatePicker);
