import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import SocialCare from "../../../assets/images/social-care.gif";
import SocialCareDark from "../../../assets/images/social-care-dark.gif";

import "./index.scss";

const SocialCareComponent = ({ themeMode }) => {
  return (
    <Link to="/options-mentorship/platinum">
      <div className="past-videos-private-session">
        <img
          src={themeMode === "light" ? SocialCare : SocialCareDark}
          alt="social-care"
          className="social-care-img"
        />
        <div className="past-videos-private-session-content">
          Click here to buy Mentorship Sessions.
        </div>
      </div>
    </Link>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = () => ({});

export default connect(stateToProps, dispatchToProps)(SocialCareComponent);
